export class AggregationData {
  id: number;
  title: string;
  doc_count: number;

  constructor(id: number, title: string, doc_count: number) {
    this.id = id;
    this.title = title;
    this.doc_count = doc_count;
  }
}
