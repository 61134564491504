interface InstalledServiceUpdateData{
  id: number;
  type: string;
  attributes: InstalledServiceUpdateDataAttributes;
}

interface InstalledServiceUpdateDataAttributes{
  reviewed: boolean;
}

export class InstalledServiceUpdateDto {
  data: InstalledServiceUpdateData;

  constructor(data: InstalledServiceUpdateData){
    this.data = {
      id: data.id,
      type: data.type,
      attributes: data.attributes
    };
  }
}
