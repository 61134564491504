export class FilterAggregationOption {
  value: number | string;
  label: string;
  doc_count?: number | undefined;

  constructor(id: number | string, title: string, doc_count?: number | undefined) {
    this.value = id;
    this.label = title.replace('ITGlue-', '');
    this.doc_count = doc_count;
  }
}