<div class="comment-form">
  <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
    <div class="uploads-list">
      <span *ngFor="let upload of uploads" class="upload-item">
        <span nz-icon nzType="paper-clip" [nzTheme]="'outline'" class="upload-prefix-icon"></span>
        <span class="upload-filename">{{ upload.attributes.original_filename }}</span>
        <span class="upload-remove" (click)="removeUpload(upload)"><span nz-icon nzType="delete" [nzTheme]="'outline'"></span></span>
      </span>
    </div>
    <textarea [froalaEditor]="froalaEditorOptions"
              formControlName="description">
    </textarea>
    <button type="submit"
            class="submit-button"
            nz-button
            nzType="primary"
            nzSize="default"
            nzShape="round"
            [nzLoading]="submitting"
            [disabled]="!commentForm.valid">
      Send
      <i nz-icon nzType="send" [nzTheme]="'outline'"></i>
    </button>
  </form>
</div>
