import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { formatDate } from "@angular/common";
import Service from "src/app/utilities/models/service/service";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import { ServicesService } from "src/app/services/services.service";
import { LoaderService } from "src/app/services/loader.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import FroalaHelper from "src/app/utilities/helpers/froala";
import { differenceInCalendarDays } from 'date-fns';
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ServiceRemoveRequestData, ServiceRemoveRequestDto } from 'src/app/utilities/models/dto/serviceRemoveRequestDto';
import InstalledServiceDetail from 'src/app/utilities/models/service/installedServiceDetail';

interface IModalData {
  service: Service;
  serviceMetric: ServiceMetric;
  rows: InstalledServiceDetail[];
}

@Component({
  selector: 'app-remove-service',
  templateUrl: './remove-service.component.html',
  styleUrls: ['./remove-service.component.scss']
})
export class RemoveServiceComponent implements OnInit {

  today: Date = new Date();
  form: FormGroup = new FormGroup({
    'effective_date': new FormControl<string | undefined>({ value: undefined, disabled :false}, Validators.required),
    'additional_information': new FormControl<string | undefined>({ value: undefined, disabled :false } ),
  });
  loaderVisible: boolean = false;
  submittingRequest: boolean = false;
  editor: any;
  froalaEditorOptions: Object;
  managedUsersService: Service;
  managedEndpointsService: Service;
  saasProtectionService: Service;
  empistEdrService: Service;
  service: Service;
  serviceMetric: ServiceMetric;
  services: Service[];
  serviceMetrics: ServiceMetric[];

  constructor(private serviceService: ServicesService,
              private loaderService: LoaderService,
              private msg: NzMessageService,
              private destroyRef: DestroyRef,
              private modalRef: NzModalRef,
              @Inject(NZ_MODAL_DATA) readonly nzModalData: IModalData) {}

  ngOnInit() {
    this.froalaEditorOptions = this.initializeFroalaEditor();
    this.service = this.nzModalData?.service;
    this.serviceMetric = this.nzModalData?.serviceMetric;
    this.services = this.serviceService.services$.getValue();
    this.serviceMetrics = this.serviceService.serviceMetrics$.getValue();
    this.setStaticServices();
    this.initForm();

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      })
  }

  initForm() {
    this.form = new FormGroup({
      'effective_date': new FormControl<string | undefined>({ value: undefined, disabled :false}, Validators.required),
      'additional_information': new FormControl<string | undefined>({ value: undefined, disabled :false } ),
    });

    if(this.nzModalData?.service.id == this.managedUsersService?.id){
      this.form = new FormGroup({
        'effective_date': new FormControl<string | undefined>({ value: undefined, disabled :false}, Validators.required),
        'additional_information': new FormControl<string | undefined>({ value: undefined, disabled :false } ),
        'remove_all_licenses': new FormControl<string | undefined>({ value: undefined, disabled :false } ),
      });
    }

    if(this.nzModalData?.service.id == this.saasProtectionService?.id){
      this.form = new FormGroup({
        'effective_date': new FormControl<string | undefined>({ value: undefined, disabled :false}, Validators.required),
        'additional_information': new FormControl<string | undefined>({ value: undefined, disabled: false } ),
        'confirm_removal': new FormControl<boolean | undefined>({ value: undefined, disabled: false }, Validators.required ),
      });
    }

    if (this.nzModalData?.service.id == this.empistEdrService?.id || this.nzModalData?.service.id == this.managedEndpointsService?.id) {
      this.form = new FormGroup({
        'effective_date': new FormControl<string | undefined>({ value: undefined, disabled: false }, Validators.required),
        'additional_information': new FormControl<string | undefined>({ value: undefined, disabled: false }),
        'additional_action': new FormControl<boolean | undefined>({ value: undefined, disabled: false }),
        'understand_reconnect': new FormControl<boolean | undefined>({ value: undefined, disabled: false }, Validators.requiredTrue),
      });
    }
  }

  closeModal() {
    this.modalRef.destroy();
  }

  setStaticServices() {
    const managedUsersService = this.services.find(service => service.attributes.system_key == 'digcore-users')
    if(managedUsersService) this.managedUsersService = managedUsersService;

    const managedEndpointsService = this.services.find(service => service.attributes.system_key == 'ticketing-assets')
    if(managedEndpointsService) this.managedEndpointsService = managedEndpointsService;

    const saasProtectionService = this.services.find(service => service.attributes.system_key == 'digcore-datto-license')
    if(saasProtectionService) this.saasProtectionService = saasProtectionService;

    const empistEdrService = this.services.find(service => service.attributes.system_key == 'sentinelone-inspector')
    if(empistEdrService) this.empistEdrService = empistEdrService;
  }

  onSubmit() {
    if(!this.form.valid){
      this.msg.error('Please fill in all the required fields', {
        nzDuration: 5000,
        nzPauseOnHover: true
      });
      return;
    }

    if(this.nzModalData?.service.id == this.managedUsersService?.id && this.form.get('confirm_removal')?.value){
      this.msg.error('Please approval the removal of historic backups', {
        nzDuration: 5000,
        nzPauseOnHover: true
      });
      return;
    }

    const payload = this.prepareRemoveRequestPayload();
    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your request is being submitted.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your request has been submitted successfully!');
    this.loaderService.setLoadedSecondaryText('');

    this.serviceService.serviceRemoveRequest(payload)
      .subscribe({
        next: (response: any) => {
          this.loaderService.setProcessing(false);
          if(response.data.id){
            setTimeout(() => {
              this.loaderService.setLoaderVisible(false);
              this.modalRef.triggerOk();
            }, 2000)
          }
        },
        error:(error) => { console.log(error) },
        complete: () => {}
      });
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) <= 0;

  private initializeFroalaEditor(): Object {
    return {
      ...FroalaHelper.getConfig({ placeholderText: 'Start typing...' }),
      events: {
        'initialized': (editor: any) => {
          this.editor = editor.getEditor();
        },
      }
    };
  }

  private prepareRemoveRequestPayload(): ServiceRemoveRequestDto {
    const effectiveDate = this.form.get('effective_date')?.value;
    const effectiveDateFormatted = formatDate(effectiveDate, 'yyyy-MM-ddTHH:mm:ssZZZZZ', 'en-US');
    const remove_all_licenses = this.form.get('remove_all_licenses')?.value;
    const additionalInformation = this.form.get('additional_information')?.value;
    const additional_action = this.form.get('additional_action')?.value;

    const data: ServiceRemoveRequestData = {
      type: 'contact_requests',
      attributes: {
        "is_removal": true,
        "affected_identifiers": this.nzModalData.rows.map((row: InstalledServiceDetail) => row.id).join(','),
        "notes": additionalInformation,
        "due_date": effectiveDateFormatted,
        "all_sub_services": remove_all_licenses == true ? true : false,
        "additional_action": additional_action == true ? true : false,
      },
      relationships: {
        service_metric: {
          data: {
            id: this.serviceMetric.id,
            type: 'service_metrics'
          }
        }
      }
    }

    return new ServiceRemoveRequestDto(data);
  }
}
