<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'support']">Support</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','support']" class="back-button"><i nz-icon nzType="left" nzTheme="outline"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <ticketList></ticketList>
      </div>
    </div>
  </nz-content>
</nz-layout>
