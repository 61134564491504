import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { User } from "src/app/utilities/models/user/user";

@Injectable({ providedIn: "root" })
export class UploadsService {
  constructor(private http: HttpClient) {
  }

  /*
  - Requires FormData -
  uploaded_file: (binary)
  used_for: string (eg: "attachment")
  uploadable_type?: string (eg: "Ticketing::Ticket")
  */
  create(payload: FormData){
    const requestUrl = environment.api_url + 'operator/v1/uploads';
    const headers = {
      'x-skip-headers': 'true'
    }

    return this.http.post(requestUrl, payload, { headers: headers});
  }

  delete(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/uploads/' + id;
    const headers = {
      'x-skip-headers': 'true'
    }

    return this.http.delete(requestUrl, { headers: headers});
  }
}