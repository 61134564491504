<nz-layout>
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'news']">News</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="post">{{ post.attributes.title }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout" *ngIf="post">
    <div nz-row nzJustify="start">
      <div nz-col nzSpan="12">
        <a [routerLink]="['/app','news']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></a>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24" class="text-center">
        <h1>Edit Post</h1>
      </div>
    </div>
    <nz-row nzJustify="center">
      <nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <form class="user-information-form"
              nz-form
              nzLayout="vertical"
              [formGroup]="postForm"
              (ngSubmit)="onSubmit()">

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24">Image</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <img *ngIf="post.attributes.featured_image_url; else noImage"
                   [src]="post.attributes.featured_image_url"
                   class="post-image"
                   alt="" />

              <ng-template #noImage>
                <img src="/assets/images/no-image-found.svg" class="post-image" alt=""/>
              </ng-template>

              <nz-upload nzAccept="image/*"
                         nzAction="..."
                         nzFileType="image/png,image/jpeg,image/jpg,image/gif"
                         nzLimit="1"
                         [(nzFileList)]="fileList"
                         [nzShowUploadList]="false"
                         [nzBeforeUpload]="onBeforeUpload">
                <div class="upload-container">
                  <p class="ant-upload-drag-icon">
                    <span nz-icon nzType="inbox"></span>
                  </p>
                  <p class="ant-upload-text">Click or drag file to this area to upload</p>
                </div>
              </nz-upload>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24">Title</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input formControlName="title"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24">Description</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <textarea nz-input formControlName="description"></textarea>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24">Content</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <textarea [froalaEditor]="froalaEditorOptions"
                        formControlName="content">
              </textarea>
            </nz-form-control>
          </nz-form-item>

          <nz-row nzJustify="end">
            <nz-col nzSpan="20">
              <nz-form-item class="ant-row-horizontal">
                <nz-form-label>Featured</nz-form-label>
                <nz-form-control>
                  <nz-switch formControlName="sticky">
                  </nz-switch>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col>
              <button nz-button
                      type="submit"
                      nzType="primary"
                      nzShape="round"
                      nzSize="large"
                      [nzLoading]="loading">
                Submit
              </button>
            </nz-col>
          </nz-row>
        </form>
      </nz-col>
    </nz-row>
  </nz-content>
</nz-layout>
