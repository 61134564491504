<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flows']">Flows</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flows','account']">Account Flows</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','flows']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span
            class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div>
          <h1>
            Flows and Flow Categories
            <ng-container *ngIf="account">for {{ account.attributes.title }}</ng-container>
          </h1>

          <div class="account-selector">
            <div>
              Please select an account to list the available flow categories available to this account.
            </div>

            <nz-select nzShowSearch nzPlaceHolder="Please select account" [(ngModel)]="selectedAccountId" (ngModelChange)="selectAccount()">
              <nz-option *ngFor="let accountOption of accounts"
                         [nzLabel]="accountOption.attributes.title"
                         [nzValue]="accountOption.id"></nz-option>
            </nz-select>
          </div>

          <div class="add-category-to-account-container">
            <div>Add a flow category to this account</div>
            <div>
              <nz-select [(ngModel)]="selectedFlowCategory">
                <nz-option *ngFor="let flowCategory of sortedFlowCategories"
                           [nzLabel]="pathByCategory[flowCategory.id]"
                           [nzDisabled]="flowCategoryIsAvailableToAccount(flowCategory, selectedAccountId)"
                           [nzValue]="flowCategory">
                </nz-option>
              </nz-select>
              <button nz-button nzShape="round" nzType="primary" nzSize="small" (click)="addCategoryToAccount(selectedFlowCategory, selectedAccountId)">Add</button>
            </div>
          </div>

          <div nz-col nzSpan="24">
            <div nz-row nzJustify="center" *ngIf="flowCategoriesLoading">
              <nz-spin nzSimple></nz-spin>
            </div>
          </div>

          <nz-collapse [nzBordered]="false" *ngIf="selectedAccountId && account && flowCategories && !flowCategoriesLoading">
            <nz-collapse-panel [nzHeader]="'Flow Categories available to '+ account.attributes.title" [nzActive]="true">
              <div class="flow-categories">
                <ng-container *ngFor="let item of categoryTree">
                  <div class="flow-category" *ngIf="flowCategoryIsAvailableToAccount(item.category, selectedAccountId)">
                    <div class="flow-category-title">
                      {{ item.category.attributes.title }}
                      <button nz-button nzShape="round" nzSize="small" class="remove-category" nzDanger (click)="removeCategoryFromAccount(item.category, account.id)">Remove</button>
                    </div>
                    <nz-divider></nz-divider>
                    <div *ngFor="let flow of flowsByCategory[item.category.id]" class="flow">
                      {{ flow.attributes.title }}
                    </div>
                    <ng-container *ngFor="let subItem of item.children">
                      <div class="flow-sub-category" *ngIf="flowCategoryIsAvailableToAccount(subItem.category, selectedAccountId)">
                        <div class="flow-category-title">
                          {{ subItem.category.attributes.title }}
                          <button nz-button nzShape="round" nzSize="small" nzDanger class="remove-category" (click)="removeCategoryFromAccount(subItem.category, account.id)">Remove</button>
                        </div>
                        <nz-divider></nz-divider>
                        <div *ngFor="let flow of flowsByCategory[subItem.category.id]" class="flow">
                          {{ flow.attributes.title }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
          <br/>
          <nz-collapse [nzBordered]="false" class="public-unavailable" *ngIf="selectedAccountId && account && flowCategories && !flowCategoriesLoading">
            <nz-collapse-panel [nzHeader]="'Public Flow Categories excluded from ' + account.attributes.title" [nzActive]="true">
              <div class="flow-categories">
                <ng-container *ngFor="let item of categoryTree">
                  <div class="flow-category" *ngIf="item.category.attributes.public === true && !flowCategoryIsAvailableToAccount(item.category, selectedAccountId)">
                    <div class="flow-category-title">{{ item.category.attributes.title }}</div>
                    <nz-divider></nz-divider>
                    <div *ngFor="let flow of flowsByCategory[item.category.id]" class="flow">
                      {{ flow.attributes.title }}
                    </div>
                    <div *ngFor="let subItem of item.children" class="flow-sub-category">
                      <div class="flow-category-title">{{ subItem.category.attributes.title }}</div>
                      <nz-divider></nz-divider>
                      <div *ngFor="let flow of flowsByCategory[subItem.category.id]" class="flow">
                        {{ flow.attributes.title }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </nz-collapse-panel>
          </nz-collapse>

          <br/>
          <nz-collapse [nzBordered]="false" class="private-unavailable" *ngIf="selectedAccountId && account && flowCategories && !flowCategoriesLoading">
            <nz-collapse-panel [nzHeader]="'Private Flow Categories excluded from ' + account.attributes.title" [nzActive]="true">
              <div class="flow-categories">
                <ng-container *ngFor="let item of categoryTree">
                  <div class="flow-category" *ngIf="item.category.attributes.public !== true && !flowCategoryIsAvailableToAccount(item.category, selectedAccountId)">
                    <div class="flow-category-title" nz-tooltip [nzTooltipTitle]="getCategoryTooltip(item.category)">{{ item.category.attributes.title }}</div>
                    <nz-divider></nz-divider>
                    <div *ngFor="let flow of flowsByCategory[item.category.id]" class="flow">
                      {{ flow.attributes.title }}
                    </div>
                    <div *ngFor="let subItem of item.children" class="flow-sub-category">
                      <div class="flow-category-title">{{ subItem.category.attributes.title }}</div>
                      <nz-divider></nz-divider>
                      <div *ngFor="let flow of flowsByCategory[subItem.category.id]" class="flow">
                        {{ flow.attributes.title }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
