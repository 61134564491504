import { Component, DestroyRef, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { ServicesService } from "src/app/services/services.service";
import { UsersService } from "src/app/services/users.service";
import { AssetResourcesService } from "src/app/services/assetResources.service";
import { DattoLicensesService } from "src/app/services/dattoLicenses.service";
import { User } from "src/app/utilities/models/user/user";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import Service from "src/app/utilities/models/service/service";
import { ColumnItem, DataItem } from "src/app/utilities/models/service/serviceData";
import { AddServiceComponent } from "../add-service/add-service.component";
import { RemoveServiceComponent } from "../remove-service/remove-service.component";
import { AssetResource } from "src/app/utilities/models/assetResource/assetResource";
import { StaticFilterOption } from "src/app/utilities/models/service/staticFilterOption";
import InstalledServiceDetail from "src/app/utilities/models/service/installedServiceDetail";
import * as XLSX from 'xlsx';

interface ServiceMetricColumn {
  column: string;
  key: string;
  label: string;
  type: string;
}

@Component({
  selector: 'app-installed-services-details',
  templateUrl: './installedServiceDetails.component.html',
  styleUrls: ['./installedServiceDetails.component.scss']
})
export class InstalledServiceDetailsComponent implements OnInit {
  installedServiceDetails: InstalledServiceDetail[] = [];
  service: Service;
  serviceMetric: ServiceMetric;
  services: Service[] = [];
  serviceMetrics: ServiceMetric[] = [];
  serviceDetailsMetrics: ServiceMetric[] = [];
  loading: boolean = false;
  loggedInUser: User;
  columns: ColumnItem[];
  keys: any[];
  indeterminate = false;
  checked = false;
  listOfCurrentPageData: readonly DataItem[] = [];
  setOfCheckedId = new Set<number>();
  serviceMetricHasRemove: boolean = false;
  serviceMetricHasAdd: boolean = false;
  addServiceModal: NzModalRef;
  removeServiceModal: NzModalRef;
  accountId: number;
  loaderVisible: boolean = false;
  totalCpu: number;
  totalRamInMB: number;
  managedUsersService: Service;
  managedEndpointsService: Service;
  saasProtectionService: Service;
  staticFilterInUse: string;
  staticFilterOptions: {id: number, label: string, count: number}[] = [];
  selectedFilterOption: {id: number, label: string} = { id: 0, label:'All'};
  installedServiceId: number;
  serviceMetricId: number;
  serviceId: number;
  priceTierOptions: any[] = [];
  installedServiceValuesDetails = signal<InstalledServiceDetail[]>([]);
  routeParams: Params;
  hasPendingRemovals: boolean;
  staticServiceIds: number[] = [];
  staticServiceKeys: string[] = ['digcore-users', 'ticketing-assets', 'digcore-datto-license'];
  userServices: any = {};
  userServiceLoading: number | undefined = undefined;

  constructor(private serviceService: ServicesService,
              private userService: UsersService,
              private assetResourceService: AssetResourcesService,
              private activatedRoute: ActivatedRoute,
              private modal: NzModalService,
              private dattoLicenseService: DattoLicensesService,
              private destroyRef: DestroyRef){

  }

  ngOnInit() {
    if(this.userService.loggedInUser?.id) {
      this.loggedInUser = this.userService.loggedInUser;
      if (this.loggedInUser?.relationships?.account?.id) {
        this.accountId = +this.loggedInUser?.relationships.account.id;
      }
    }
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        if(!params) return;

        this.routeParams = params;
        this.setOfCheckedId.clear();
        if(params['id'] > 0){
          this.serviceId = +params['id'];
        }
        if(params['isid']){
          this.installedServiceId = +params['isid'];
        }
        if(params['mid']){
          if(this.serviceMetricId != +params['mid']){
            this.installedServiceDetails = [];
            this.installedServiceValuesDetails.set([]);
          }
          this.serviceMetricId = +params['mid'];
        }
        this.setServicesAndMetrics();
      }
    });

    this.serviceService.services$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(services: Service[]) => {
          this.services = services;
        },
        error:(error) => { console.log(error); },
        complete:() => {},
      })

    this.serviceService.serviceMetrics$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(serviceMetrics: ServiceMetric[]) => {
          this.serviceMetrics = serviceMetrics;
        },
        error:(error) => { console.log(error); },
        complete:() => {},
      })
  }

  setServiceIdFromParams(): void {
    if (this.service.attributes.system_key == 'digcore-users') {
      this.setStaticFilterOptions();
    }else if (this.service.attributes.system_key == 'ticketing-assets') {
      this.setStaticFilterOptions();
    }else if (this.service.attributes.system_key == 'digcore-datto-license') {
      this.setStaticFilterOptions();
    }
  }

  private setServicesAndMetrics() {
    this.services = this.serviceService.services$.getValue()
    this.serviceMetrics = this.serviceService.serviceMetrics$.getValue();

    if(this.services.length == 0){
      return this.getServices();
    }else{
      this.setCurrentService();
      this.setStaticServices();
      this.setServiceIdFromParams();
      this.getData();
    }
  }

  private setCurrentService() {
    const service: Service | undefined = this.services.find((s: Service) => +s.id == this.routeParams['id']);
    const serviceMetric: ServiceMetric | undefined = this.serviceMetrics.find((s: ServiceMetric) => s.id == this.routeParams['mid']);

    if(service && serviceMetric) {
      this.service = service;
      this.serviceMetric = serviceMetric;
    }
  }

  getServices() {
    this.loading = true;
    this.serviceService.getServices(1000,1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          const services = response.data.map((bsData: any) => new Service(bsData, response.included));
          const serviceMetrics = response.included?.filter((includedObj: any) => includedObj.type == 'service_metrics')
            .map((metric: any) => new ServiceMetric(metric, response.included));
          this.serviceService.setServices(services);
          this.serviceService.setServiceMetrics(serviceMetrics);
          this.services = services;
          this.serviceMetrics = serviceMetrics;
          this.setCurrentService();
          this.setStaticServices();
          this.setServiceIdFromParams();
          this.getData();
        },
        error: (_error) => {},
        complete: () => {
          this.loading = false;
        }
      })
  }

  setStaticServices() {
    const managedUsersService = this.services.find(service => service.attributes.system_key == 'digcore-users')
    if(managedUsersService) this.managedUsersService = managedUsersService;
    const managedEndpointsService = this.services.find(service => service.attributes.system_key == 'ticketing-assets')
    if(managedEndpointsService) this.managedEndpointsService = managedEndpointsService;
    const saasProtectionService = this.services.find(service => service.attributes.system_key == 'digcore-datto-license')
    if(saasProtectionService) this.saasProtectionService = saasProtectionService;

    this.staticServiceIds = this.services.filter((service: Service) => service.attributes.system_key && this.staticServiceKeys.includes(service.attributes.system_key)).map((service: Service) => service.id);
  }

  get installedServiceFilteredDetails() {
    if(!this.selectedFilterOption || this.selectedFilterOption?.id == 0) return this.installedServiceValuesDetails();

    const column: ServiceMetricColumn | undefined = this.getColFromAttribute(this.staticFilterInUse);
    if(column){
      return this.installedServiceValuesDetails().filter( (item: any) => {
        return item['attributes'][column.column] == this.selectedFilterOption.label;
      })
    }

    return this.installedServiceValuesDetails();
  }

  getData() {
    if(!this.service?.id || !this.serviceMetric?.id) return;

    if(this.service.attributes.system_key && this.staticServiceKeys.includes(this.service.attributes.system_key)){
      switch(this.service.attributes.system_key){
        case 'digcore-users': this.getAccountManagedUsers(); break;
        case 'ticketing-assets': this.getAccountManagedEndpoints(); break;
        case 'digcore-datto-license': this.getSaasProtectionData(); break;
      }
    } else {
      this.loading = true;
      if(!this.service?.attributes?.external_identifier){
        const installedServiceMetricIds: number[] = this.service?.relationships?.service_metrics?.map(serviceMetric => serviceMetric.id) ?? [];
        this.serviceService.getAllInstalledServiceDetails([this.installedServiceId], installedServiceMetricIds)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (response: any) => {
              this.installedServiceDetails = response.data.map((row: any) => new InstalledServiceDetail(row, response.included));
              this.installedServiceValuesDetails.set(this.installedServiceDetails);
              this.hasPendingRemovals = this.installedServiceDetails.some((item: any) => item.attributes.mark_for_deletion);
            },
            complete: () => {
              this.loading = false;
            }
          });
      }else {
        this.setServiceDetailMetrics();
        this.serviceService.getInstalledServiceDetails(this.installedServiceId, this.serviceMetricId)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (response: any) => {
              this.installedServiceDetails = response.data.map((row: any) => {
                if (this.serviceMetric?.attributes.title?.includes('VMware vCenter: Datastores')) {
                  row.attributes.col8 = row.attributes.col5 - row.attributes.col4;
                }

                return new InstalledServiceDetail(row, response.included)
              });
              this.installedServiceValuesDetails.set(this.installedServiceDetails);
              this.hasPendingRemovals = this.installedServiceDetails.some((item: any) => item.attributes.mark_for_deletion);

              this.setColumnsAndKeys();
              if (this.service && this.serviceMetric?.id) {
                this.serviceMetricHasRemove = this.service.hasRemoveMethod(this.serviceMetric);
                this.serviceMetricHasAdd = this.service.hasAddMethod(this.serviceMetric);

                if (this.serviceMetric.attributes.title == 'VMware vCenter: Virtual Machines') {
                  this.totalCpu = 0;
                  this.totalRamInMB = 0;
                  this.installedServiceDetails.forEach((row: any) => {
                    this.totalCpu += parseInt(row.attributes.col5);
                    this.totalRamInMB += parseInt(row.attributes.col1);
                  })
                }
                this.setStaticFilterOptions();
              }
            },
            complete: () => {
              this.loading = false;
            }
          })
      }
    }
  }

  setServiceDetailMetrics() {
    const serviceDetailsMetricIds = this.service?.relationships?.service_metrics?.map((metric: any) => metric.id) ?? [];
    this.serviceDetailsMetrics = this.serviceMetrics.filter((service_metric: ServiceMetric) => service_metric.attributes.metric_type == 'detail' && serviceDetailsMetricIds.includes(service_metric.id));
  }

  setStaticFilterOptions() {
    switch(this.service?.id){
      case this.saasProtectionService?.id:
        this.staticFilterOptions = [
          new StaticFilterOption(0, 'All', 0 ),
          new StaticFilterOption(1, 'Active', 0 ),
          new StaticFilterOption(2, 'Archived', 0 )
        ];
        this.setStaticMetricsCount('status');
        this.staticFilterInUse = 'status';
        this.selectedFilterOption = { id: 0, label : 'All' };
        break;
      case this.managedUsersService?.id:
        this.getUsersStaticFilterOptions();
        this.setStaticMetricsCount('price_tier');
        this.staticFilterInUse = 'price_tier';
        this.selectedFilterOption = { id: 0, label : 'All' };
        break;
      case this.managedEndpointsService?.id:
        this.staticFilterOptions = [
          new StaticFilterOption(0, 'All', 0 ),
          new StaticFilterOption(15, 'Workstation', 0 ),
          new StaticFilterOption(52, 'Server', 0 )
        ];
        this.setStaticMetricsCount('type');
        this.staticFilterInUse = 'type';
        this.selectedFilterOption = { id: 0, label : 'All' };
        break;
      default:
        break;
    }
  }

  getLogoByService(service: Service){
    const logo = service.getLogo();

    return logo?.includes('https://') ? logo : '../../../' + logo;
  }

  displayValue(row: InstalledServiceDetail, attr: string, purpose: string = ''){
    return this.serviceService.displayValue(row, attr, purpose, this.serviceMetric, this.service, this.installedServiceId);
  }

  getServiceMetricTitleById(id: number | undefined) {
    const serviceMetric = this.serviceMetrics.find(serviceMetric => serviceMetric.id == id);

    return serviceMetric?.getTitle();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly InstalledServiceDetail[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  removeItems() {
    const rows = this.installedServiceDetails.filter((data: any) => {
      return this.setOfCheckedId.has(data.id)
    });
    this.sendRequest(rows);
  }

  sendRequest(rows: InstalledServiceDetail[]): void {
    if(this.service) {
      this.showRemoveServiceModal(rows);
    }
  }

  showAddServiceModal() {
    this.addServiceModal = this.modal.create({
      nzClassName: 'add-service-modal',
      nzContent: AddServiceComponent,
      nzFooter: null,
      nzCentered: true,
      nzWidth: '680px',
      nzData: {
        service: this.service,
        serviceMetric: this.serviceMetric,
        rows: this.installedServiceDetails ?? []
      }
    });
  }

  showRemoveServiceModal(rows: InstalledServiceDetail[]) {
    this.removeServiceModal = this.modal.create({
      nzClassName: 'remove-service-modal',
      nzContent: RemoveServiceComponent,
      nzFooter: null,
      nzCentered: true,
      nzWidth: '680px',
      nzData: {
        service: this.service,
        serviceMetric: this.serviceMetric,
        rows: rows
      },
      nzOnOk: (instance: RemoveServiceComponent) => {
        new Promise((resolve) => {
          this.getData();
          this.setOfCheckedId.clear();

          resolve(true);
        })

      }
    });
  }

  exportToExcel(){
    const de: any = this.serviceMetric.attributes.details_explanation;
    let d = Array.isArray(de) ? de : de ? JSON.parse(de) : [];
    const columns: any = {};
    d.forEach((col: any) => {
      if(col.key) columns[col.column] = col.label;
    })

    const serviceTitle: string = this.service ? this.service.getTitle() : '';
    const exportData = this.installedServiceDetails?.map((item: any) => {
      const retObj: any = {}
      Object.keys(item.attributes).forEach(column => {
        if (columns[column]) {
          retObj[columns[column]] = this.displayValue(item, column, 'export');
        }
      })

      return retObj
    }) ?? [];

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, serviceTitle);
    const filename = 'export-data.xlsx';
    XLSX.writeFile(wb, filename);
  }

  applyStaticFilter(filterOption: {id: number, label: string, count: number}) {
    this.selectedFilterOption = { id: filterOption.id, label: filterOption.label };
  }

  getUserServices(id: number) {
    if(this.userServices[id]) return;

    this.userServiceLoading = id;
    this.serviceService.getServiceSubscriptions(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.userServices[id] = response.data.map((item: any) => item.attributes.title);
          this.userServiceLoading = undefined;
        }
      });
  }

  private setStaticMetricsCount(attribute: string) {
    this.staticFilterOptions = this.staticFilterOptions.map(option => {
      let count;
      if(option.label == 'All'){
        count = this.installedServiceDetails?.length ?? 0;
      }else{
        const column: ServiceMetricColumn | undefined = this.getColFromAttribute(attribute);
        count = column ? this.installedServiceDetails?.filter((row: any) => row['attributes'][column.column] == option.label)?.length ?? 0 : 0;
      }

      return {
        ...option,
        count: count
      }
    })
  }

  private setColumnsAndKeys(): void {
    const result = this.serviceMetric.getColumnAndKeys();
    this.keys = result.keys;
    this.columns = result.columns;
  }

  private getAccountManagedUsers() {
    const accountId = this.loggedInUser?.relationships?.account?.id;
    this.loading = true;
    let reportArr: ReportParam[] = [];
    if(this.loggedInUser.attributes.type == 'Ticketing::Agent' && accountId) {
      reportArr.push({ "key": "accounts.id", "operator": ["eq"], "value": [accountId] });
    }
    reportArr.push({ "key": "active", "operator": ["eq"], "value": "true" });
    this.userService.getAccountUsers(reportArr)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
      next: (response: any) => {
        const normalizedResponse: any= {
          data: response.data.map((row: any) => {
            const user = new User(row, response.included);

            return {
              id: row.id,
              type: row.type,
              attributes: {
                col1: user.attributes.firstname,
                col2: user.attributes.lastname,
                col3: user.attributes.email,
                col4: user.attributes.created_at,
                col5: user.attributes.last_login ?? undefined,
                col6: user.relationships?.price_tier?.attributes?.title ?? undefined,
                mark_for_deletion: user.attributes.mark_for_deletion,
              }
            }
          })
        }
        this.installedServiceDetails = normalizedResponse.data.map((row: any) => new InstalledServiceDetail(row, normalizedResponse.included));
        this.installedServiceValuesDetails.set(this.installedServiceDetails);
        this.hasPendingRemovals = this.installedServiceDetails.some((item: any) => item.attributes.mark_for_deletion);
        this.setColumnsAndKeys();

        if(this.service && this.serviceMetric?.id) {
          this.serviceMetricHasRemove = this.service.hasRemoveMethod(this.serviceMetric);
          this.serviceMetricHasAdd = this.service.hasAddMethod(this.serviceMetric);
        }
        this.setStaticFilterOptions();
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  private getAccountManagedEndpoints() {
    let reportArr: ReportParam[] = [];
    reportArr.push({ "key": "kaseya_detail.external_identifier", "operator": ["exist"], "value": ["-"] });
    const accountId = this.loggedInUser.relationships?.account?.id;
    if(this.loggedInUser.attributes.type == 'Ticketing::Agent' && accountId){
      reportArr.push({ "key": "accounts.id", "operator": ["eq"], "value": [accountId] });
    }

    this.loading = true;
    this.assetResourceService.getManagedEndpoints(10000, 1, reportArr)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          const assets = response.data.map((row: any) => new AssetResource(row, response.included));
          const normalizedResponse: any = this.normalizeAssetResourcesResponse(response, assets);
          this.installedServiceDetails = normalizedResponse.data.map((row: any) => new InstalledServiceDetail(row, normalizedResponse.included));
          this.installedServiceValuesDetails.set(this.installedServiceDetails);
          this.hasPendingRemovals = this.installedServiceDetails.some((item: any) => item.attributes.mark_for_deletion);
          this.setColumnsAndKeys();

          if (this.service && this.serviceMetric) {
            this.serviceMetricHasRemove = this.service.hasRemoveMethod(this.serviceMetric);
            this.serviceMetricHasAdd = this.service.hasAddMethod(this.serviceMetric);
          }
          this.setStaticFilterOptions();
        },
        complete: () => {
          this.loading = false;
        }
      })

  }

  private normalizeAssetResourcesResponse(response: any, assets: AssetResource[]) {
    return {
      data: assets.map((row: any) => {
        return {
          id: row.id,
          type: row.type,
          attributes: {
            col1: row.attributes.title,
            col2: (row.relationships?.kaseya_detail?.id) ? row.relationships.kaseya_detail.attributes.computer_name : '',
            col3: (row.relationships?.asset_resource_type) ? row.relationships.asset_resource_type?.attributes.title : '',
            col4: (row.relationships?.contact) ? row.relationships.contact.attributes.fullname : '',
            col5: (row.relationships?.kaseya_detail?.id) ? row.relationships.kaseya_detail.attributes.os_info : '',
            col6: (row.attributes.serial_number) ? row.attributes.serial_number : '',
            col7: (row.relationships?.kaseya_detail?.attributes?.first_check_in) ? row.relationships.kaseya_detail.attributes.first_check_in : '',
            col8: (row.relationships?.kaseya_detail?.attributes?.last_check_in_time) ? row.relationships.kaseya_detail.attributes.last_check_in_time : '',
            mark_for_deletion: row.attributes.mark_for_deletion,
          }
        }
      }),
      meta: {
        total_count: response.meta.total_count
      }
    }
  }

  private getSaasProtectionData() {
    const accountId = this.loggedInUser?.relationships?.account?.id;
    const request = this.loggedInUser.attributes.type == 'Ticketing::Agent' ? this.dattoLicenseService.getDattoLicenses(accountId) : this.dattoLicenseService.getDattoLicenses();

    this.loading = true;
    request
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          const normalizedResponse: any= {
            data: response.data.map((row: any) => {
              return {
                id: row.id,
                type: row.type,
                attributes: {
                  col1: row.attributes.name,
                  col2: row.attributes.main_identifier,
                  col3: row.attributes.date_added,
                  col4: row.attributes.status ? 'Active' : 'Archived',
                  col5: row.attributes.billable ? 'Yes' : 'No',
                  mark_for_deletion: row.attributes.mark_for_deletion,
                }
              }
            })
          }
          this.installedServiceDetails = normalizedResponse.data.map((row: any) => new InstalledServiceDetail(row, normalizedResponse.included));
          this.installedServiceValuesDetails.set(this.installedServiceDetails);
          this.hasPendingRemovals = this.installedServiceDetails.some((item: any) => item.attributes.mark_for_deletion);
          this.setColumnsAndKeys();
          this.setStaticFilterOptions();
          if(this.service && this.serviceMetric) {
            this.serviceMetricHasRemove = this.service.hasRemoveMethod(this.serviceMetric);
            this.serviceMetricHasAdd = this.service.hasAddMethod(this.serviceMetric);
          }
        },
        complete: () => {
          this.loading = false;
        }
      })
  }

  private getColFromAttribute(attribute: string) {
    const de: any = this.serviceMetric?.attributes.details_explanation;
    let d = Array.isArray(de) ? de : de ? JSON.parse(de) : [];
    const column: ServiceMetricColumn | undefined = d ? d.find((row: any) => row.key == attribute || (row.key == 'empist_aiup_type' && attribute == 'price_tier')) : undefined;

    return column;
  }

  private getUsersStaticFilterOptions(): void {
    if(this.priceTierOptions.length == 0) {
      this.userService.getPriceTiers()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.priceTierOptions = response.data.map((row: any) => {
              return {
                id: row.id,
                label: row.attributes.title,
                count: undefined
              }
            });
            this.priceTierOptions.unshift(new StaticFilterOption(0, 'All', 0));
          }
        });
    }else{
      this.staticFilterOptions = [...this.priceTierOptions];
    }
  }
}
