import { Component, OnInit, forwardRef, Input, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import FroalaHelper from 'src/app/utilities/helpers/froala';
import { UploadsService } from 'src/app/services/uploads.service';

@Component({
  selector: 'froala-editor',
  templateUrl: './froala.component.html',
  styleUrl: './froala.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FroalaComponent)
    },
  ]
})
export class FroalaComponent implements ControlValueAccessor, OnInit {
  disabled = false;
  private _text: string = '';
  private touched = false;
  private onChange = (value: any) => {};
  private onTouched = () => {};

  @Input() options: any; // https://froala.com/wysiwyg-editor/docs/options/

  @Input()
  get text(): any { return this._text; }
  set text(value: any) {
    if (this._text !== value) {
      this._text = value;
      this.writeValue(this._text);
    }
  }

  writeValue(value: any) {
    this.text = value;
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;

    if (this.disabled) {
      this.elementRef.nativeElement.classList.add('is-disabled');
    } else {
      this.elementRef.nativeElement.classList.remove('is-disabled');
    }
  }

  editor: any;
  froalaEditorOptions: Object;

  constructor(public elementRef: ElementRef,
              private uploadsService: UploadsService) {}

  ngOnInit() {
    this.froalaEditorOptions = this.initializeFroalaEditor();
  }

  private initializeFroalaEditor(): Object {
    let init = {
      ...FroalaHelper.getConfig({ placeholderText: 'Start typing…' }),
      toolbarButtons: ['bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertTable'],
      toolbarSticky: false,
      events: {
        'initialized': (editor: any) => {
          this.editor = editor.getEditor();
        },
        'image.beforeUpload': (files: FileList) => {
          if (!files.length) return false;

          const payload = this.prepareUploadPayload(files[0], 'inline');
          this.uploadsService.create(payload).subscribe({
            next: (response: any) => {
              const url = response.data.attributes.permalink;
              this.editor.image.insert(url, false, null, this.editor.image.get(), { link: url });

              return false;
            },
            error: (error) => {
              console.error(error);
            }
          });

          return false;
        },
      },
      ...this.options,
    };

    return init;
  }

  private prepareUploadPayload(file: File, userFor: string) {
    const payload = new FormData();
    payload.append('used_for', userFor);
    payload.append('uploaded_file', file);

    return payload;
  }
}
