import { AccountAttributes } from "../account/accountAttributes";
import { AssetResourceAttributes } from "../assetResource/assetResourceAttributes";
import { RelationshipData } from "../relationshipData";
import { DattoLicenseAttributes } from "../service/dattoLicenseAttributes";
import { InstalledServiceAttributes } from "../service/installedServiceAttributes";
import { InstalledServiceDetailAttributes } from "../service/installedServiceDetailAttributes";
import { ServiceMetricAttributes } from "../service/serviceMetricAttributes";
import { UserAttributes } from "../user/userAttributes";

export class ServiceHistoryRelationships {
  account?: RelationshipData<AccountAttributes>;
  installed_service?: RelationshipData<InstalledServiceAttributes>;
  service_metric?: RelationshipData<ServiceMetricAttributes>;
  serviceable?: RelationshipData<InstalledServiceDetailAttributes | UserAttributes | AssetResourceAttributes | DattoLicenseAttributes>;
  // installed_service_detail?: RelationshipData<InstalledServiceDetailAttributes>;
  // user?: RelationshipData<UserAttributes>;
  // asset_resource?: RelationshipData<AssetResourceAttributes>;
  // datto_license?: RelationshipData<DattoLicenseAttributes>;
}