import { ObjectData } from '../objectData';

interface TicketAvailableAgentAttributes {
  avatar: string | null;
  fullname: string;
  meeting_days: string[];
  meeting_available_from: string | null;
  meeting_available_until: string | null;
}

export class TicketAvailableAgent extends ObjectData<TicketAvailableAgentAttributes, {}>{
  constructor(data: ObjectData<TicketAvailableAgentAttributes, {}>, included?: Object[]){
    super(data, included);
  }
}