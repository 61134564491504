<div (click)="showCategory(flowCategory.id)"
      class="support-category"
      [id]="'flowCategory_' + flowCategory.id"
      nz-col
      [nzXs]="{ span: 24 }"
      [nzSm]="{ span: 24 }"
      [nzMd]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXl]="{ span: 8 }"
      [nzXXl]="{ span: 8 }">
  <div class="support-category-content">
    <div class="support-category-icon">
      <img *ngIf="flowCategory.attributes.featured_image_url"
          [src]="flowCategory.attributes.featured_image_url"
          [alt]="flowCategory.attributes.title"/>
      <span nz-icon nzType="ant-design" nzTheme="outline" *ngIf="!flowCategory.attributes.featured_image_url"></span>
    </div>
    <div class="support-category-details">
      <span class="support-category-item-title">
        {{ flowCategory.attributes.title }}
      </span>
      <span class="support-category-item-text" [innerHTML]="flowCategory.attributes.description">
      </span>
    </div>
  </div>
  <div *ngIf="showFlows"
       class="category-popover"
       (outsideClick)="showCategory(flowCategory.id)"
       outsideClick>
    <div class="support-section-label" *ngIf="1">{{ flowCategory.attributes.title }}</div>
    <div class="flows-container" [ngClass]="{'has-children': childFlowCategories.length }">
      <div class="flows">
        <div *ngFor="let flow of flows">
          <a class="support-section-item" [routerLink]="['/app', 'support', 'tickets', 'new', flow.id]">
            <span>{{ flow.attributes.title }}</span>
          </a>
        </div>
      </div>
      <div *ngIf="childFlowCategories.length" class="child-category">
        <ng-container div *ngFor="let child of childFlowCategories">
          <div class="support-section-sub-label">{{ child.category.attributes.title }}</div>
          <div *ngFor="let flow of child.category.relationships?.flows">
            <a class="support-section-item" [routerLink]="['/app', 'support', 'tickets', 'new', flow.id]">
              <span>{{ flow.attributes.title }}</span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>