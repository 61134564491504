import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { CommentsService } from "src/app/services/comments.service";
import { Comment } from "src/app/utilities/models/ticket/conversation/comment";
import { ConversationsService } from "src/app/services/conversations.service";
import { RelationshipData } from "src/app/utilities/models/relationshipData";
import { UploadRelationAttribute } from "src/app/utilities/models/ticket/uploadRelationAttribute";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'conversationComments',
  templateUrl: './conversationComments.component.html',
  styleUrls: ['./conversationComments.component.scss']
})
export class ConversationCommentsComponent implements OnInit {
  @Input() fragment: string | null;
  conversationId: number | undefined;
  commentsLoading: boolean = false;
  comments: Comment[] = [];
  expandedCommentAttachments: number;

  constructor(private commentsService: CommentsService,
              private conversationService: ConversationsService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.conversationService.activeConversationIdSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (conversationId: number | undefined) => {
          if(conversationId !== undefined) {
            this.commentsLoading = true;
            this.commentsService.getComments(conversationId)
              .subscribe({
                next: (commentData: any) => {
                  this.comments = commentData.data.map((commentAttr: any) => new Comment(commentAttr, commentData.included));
                  this.commentsLoading = false;
                }
              });
          }
        }
      });
  }

  ngAfterViewChecked() {
    if (this.fragment) {
      const conversationId = this.fragment.split("_")[0];
      const commentId = this.fragment.split("_")[1];
      const domId = document.getElementById('#' + conversationId + '_' + commentId);

      if (domId !== null) {
        domId.scrollIntoView();
      }
    }
  }

  isImage(upload: RelationshipData<UploadRelationAttribute>) {
    let isImage = false;
    switch(upload.attributes.content_type) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/webp':
      case 'image/avif':
        isImage = true;
        break;
    }

    return isImage;
  }

  expandAttachments(commentId: number) {
    this.expandedCommentAttachments = commentId;
  }

  commentHasMoreAttachments(comment: Comment) {
    return comment.relationships?.uploads ? comment.relationships?.uploads.length > 2 : false;
  }

  restAttachmentsNumber(comment: Comment) {
    let more = comment.relationships?.uploads ? comment.relationships?.uploads.length - 2 : 0;
    return more > 0 ? more : 0;
  }
}