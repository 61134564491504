export interface FlowControlCreateAttributes {
  input_type: string;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  options?: {value: string, label: string}[];
}

export interface FlowControlCreateRelationships {
  flow: {
    data: {
      id: number;
      type: string;
    }
  }
}

export interface FlowControlCreateData {
  type: string;
  attributes: FlowControlCreateAttributes;
  relationships: FlowControlCreateRelationships;
}


export class FlowControlCreateDto {
  data: FlowControlCreateData;

  constructor(data: FlowControlCreateData) {
    this.data = data;
  }
}