import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { RansackParam } from "../utilities/models/parameters/ransackParam/ransackParam";

@Injectable({ providedIn: "root" })
export class ServiceHistoriesService {

  constructor(private http: HttpClient) {

  }

  getServiceHistories(pageSize: number = 20, pageIndex: number = 1, params?: RansackParam[]) {
    let requestUrl = environment.api_url + 'operator/v1/service_histories?include=installed_service,service_metric,account,serviceable';
    let requestParams: any = {
      "page[per]": pageSize,
      "page[number]": pageIndex
    };
    if(params){
      params.forEach((param: RansackParam) => {
        requestParams[`q[${param.key}_${param.operator}]`] = param.value;
      })
    }

    return this.http.get(requestUrl, { params: requestParams });
  }
}