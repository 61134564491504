<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Services</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24" class="installed-services">
        <div nz-row nzJustify="center">
          <div nz-col>
            <h1>Services</h1>
          </div>
        </div>
        <div nz-row class="installed-services-top">
          <div nz-col nzSpan="24">
            <div nz-row>
              <div nz-col class="installed-services-top-text">
                Introducing the comprehensive list of services and subscriptions via the EMPIST 360 platform.<br>
                Gain visibility into subscribed services and conveniently request any necessary changes.
              </div>
            </div>
            <nz-divider></nz-divider>
          </div>
        </div>
        <div nz-row class="list-selection-row">
          <div nz-col nzSpan="12" [nzXs]="24" [nzSm]="12" class="filter-selection"></div>
          <div nz-col nzSpan="12" [nzXs]="24" [nzSm]="12" class="create-ticket-container">
            <a [routerLink]="['/app', 'services', 'request-change']" class="request-support">
              Request Support <span nz-icon nzType="question-circle" [nzTheme]="'outline'"></span>
            </a>
          </div>
        </div>
        <div nz-row *ngIf="loading || requestsLoading" nzJustify="center">
          <div nz-col nzSpan="24" class="data-loading">
            <nz-spin nzSimple></nz-spin>
          </div>
        </div>

        <ng-container *ngIf="installedServicesViewData?.length">
          <ng-container *ngFor="let installedServiceViewData of installedServicesViewData">
            <div class="installed-service" [routerLink]="installedServiceViewData.routePath" *ngIf="installedServiceViewData.visible">
              <div class="installed-service-logo">
                <img [src]="installedServiceViewData.logo" [alt]="installedServiceViewData.title"/>
              </div>
              <div class="installed-service-metric-details">
                <div class="installed-service-title">
                  {{ installedServiceViewData.title }}
                  <span class="installed-service-account" *ngIf="shouldShowAccountInList">{{ installedServiceViewData.account?.attributes?.title ?? 'All accounts' }}</span>
                  <span class="installed-service-description">{{ installedServiceViewData.description }}</span>
                </div>
                <div class="installed-service-metrics" >
                  <div class="installed-service-metric" *ngFor="let metric of installedServiceViewData.detailMetrics">
                    <div class="installed-service-metric-title">{{ metric.title }}</div>
                    <div class="installed-service-metric-value">{{ metric.count }}</div>
                  </div>
                </div>
              </div>
              <div class="installed-service-action" [routerLink]="installedServiceViewData.routePath">
                <span nz-icon nzType="arrow-right" [nzTheme]="'outline'" *ngIf="installedServiceViewData.hasInstalledServiceDetails; else noDetails"></span>
                <ng-template #noDetails><span nz-icon nzType="minus" [nzTheme]="'outline'"></span></ng-template>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="addOnServices?.length">
          <div class="unavailable-services">
            <h2>Add-On Services</h2>
            <div>
              The list below is the list of services EMPIST is offering, but are not currently assigned to your account.<br>
              If you are interested on having any of these services, don't hesitate to contact us.
            </div>
          </div>
          <ng-container *ngFor="let addOnService of addOnServices">
            <div class="installed-service unavailable">
              <div class="installed-service-logo">
                <img [src]="addOnService.getLogo()" [alt]="addOnService.getTitle()" />
              </div>
              <div class="installed-service-metric-details">
                <div class="installed-service-title">
                  {{ addOnService.getTitle() }}
                  <span class="installed-service-description">{{ addOnService.getDescription() }}</span>
                </div>
              </div>
              <div class="installed-service-action">
                <a [routerLink]="['/app', 'services', addOnService.id, 'add-on-service-request']" class="learn-more">
                  <span>Learn More</span>
                  <img src="/assets/images/arrow-more.svg" alt="learn more"/>
                </a>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </nz-content>
</nz-layout>
