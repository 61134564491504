import { User } from "../user/user";
import { Upload } from "../upload/upload";

interface CommentCreateDtoData{
  type: string;
  attributes: CommentCreateDtoAttributes;
  relationships: CommentCreateDtoRelationships
}
interface CommentCreateDtoAttributes{
  description: string,
  public: boolean
}
interface CommentCreateDtoRelationships{
  conversation:{
    data:{
      id: number;
      type: string;
    }
  };
  author: {
    data: {
      id: number;
      type: string;
    }
  };
  ticket: {
    data: {
      id: number;
      type: string;
    }
  };
  uploads?: {
    data: Upload[]
  }
}

export class CommentCreateDto {
  data: CommentCreateDtoData;

  constructor(description: string, conversationId: number, ticketId: number, loggedInUser: User, uploads?: Upload[]){
    this.data = {
      attributes: {
        description: description,
        public: true
      },
      type: 'comments',
      relationships:{
        conversation:{
          data:{
            id: conversationId,
            type: 'conversations'
          }
        },
        author: {
          data: {
            id: loggedInUser.id,
            type: loggedInUser.attributes.type
          }
        },
        ticket: {
          data: {
            id: ticketId,
            type: 'tickets'
          }
        },
      }
    }
    if(uploads){
      this.data.relationships.uploads = {
        data: uploads
      }
    }
  }

}
