export default class DateHelper {

  static getFormattedDate(d: Date): string {
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
  }

  static dateAddMonths(d: Date, monthsToAdd: number): Date {
    d.setMonth(d.getMonth() + monthsToAdd);

    return d;
  }

  static dateAddDays(d: Date, daysToAdd: number): Date {
    const ms = d.getTime() + 86400 * 1000 * daysToAdd;

    return new Date(ms);
  }
}