export class RansackParam {
  key: string;
  operator: string;
  value: string;

  constructor(key: string, operator: string, value: string) {
    this.key = key;
    this.operator = operator;
    this.value = value;
  }
}
