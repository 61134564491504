import { ReportParam } from "../parameters/reportParam/reportParam";

export class NavFilter {
  options: { id: number, title: string, response_key: string, filters: ReportParam[] }[] = [];

  constructor(current_user_id: number) {
    this.options.push({
      id: 1,
      title: "All Tickets",
      response_key: "all",
      filters: this.filters(1, current_user_id)
    });
    this.options.push({
      id: 2,
      title: "All Open Tickets",
      response_key: "all_open",
      filters: this.filters(2, current_user_id)
    });
    this.options.push({
      id: 3,
      title: "My Open Tickets",
      response_key: "my_open",
      filters: this.filters(3, current_user_id)
    });
    this.options.push({
      id: 4,
      title: "My Closed Tickets",
      response_key: "my_closed",
      filters: this.filters(4, current_user_id)
    });
  }

  filters(id: number, current_user_id: number): ReportParam[] {
    switch (id) {
      case 1: {
        return NavFilter.all();
      }
      case 2: {
        return NavFilter.all_open();
      }
      case 3: {
        return NavFilter.my_open(current_user_id);
      }
      case 4: {
        return NavFilter.my_closed(current_user_id);
      }
    }

    return [];
  }

  private static all(): ReportParam[] {
    let reportArr: ReportParam[] = [];

    reportArr.push({ key: "merged_into", value: ["false"], operator: ["eq"] });

    return reportArr;
  }

  private static all_open(): ReportParam[] {
    let reportArr: ReportParam[] = [];

    reportArr.push({ key: "statuses.state", value: ["in_progress"], operator: ["in"] });
    reportArr.push({ key: "merged_into", value: ["false"], operator: ["eq"] });

    return reportArr;
  }

  private static my_open(current_user_id: number): ReportParam[] {
    let reportArr: ReportParam[] = [];

    reportArr.push({ key: "requesters.id", value: [current_user_id.toString()], operator: ["in"] });
    reportArr.push({ key: "statuses.state", value: ["in_progress"], operator: ["in"] });
    reportArr.push({ key: "merged_into", value: ["false"], operator: ["eq"] });

    return reportArr;
  }

  private static my_closed(current_user_id: number): ReportParam[] {
    let reportArr: ReportParam[] = [];

    reportArr.push({ key: "requesters.id", value: [current_user_id.toString()], operator: ["in"] });
    reportArr.push({ key: "statuses.state", value: ["completed"], operator: ["in"] });
    reportArr.push({ key: "merged_into", value: ["false"], operator: ["eq"] });

    return reportArr;
  }
}