export interface FlowCreateAttributes {
  title: string;
  platform_name: string;
  subject: string;
  label?: string;
  active: boolean;
}

interface FlowCategoryData {
  data: {
    id: number;
    type: string;
  }[]
}

interface FlowControlsData {
  data: {
    id: number;
    type: string;
  }[];
}

export interface FlowCreateRelationships {
  flow_categories?: FlowCategoryData;
  flow_controls?: FlowControlsData;
}

export interface FlowCreateData {
  type: string;
  attributes: FlowCreateAttributes;
  relationships: FlowCreateRelationships;
}

export class FlowCreateDto {
  data: FlowCreateData;

  constructor(data: FlowCreateData) {
    this.data = data;
  }


}