import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { throwError, switchMap } from 'rxjs';
import { TokenService } from './token.service';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService,
              private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {

    const token = this.tokenService.getToken();
    const refreshToken = this.tokenService.getRefreshToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          'x-source': 'emp360-webapp'
        }
      });
    }

    if(!request.headers.has('x-skip-headers')){
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          setHeaders: {
            'content-type': 'application/vnd.api+json'
          }
        });
      }


      if (!request.headers.has('Accept')) {
        request = request.clone({
          headers: request.headers.set('Accept', 'application/vnd.api+json')
        });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('Interceptor handle request', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          //console.log(error);
          if (error.error?.code && error.error.code === 'expired_token') {
            return this.authService.refreshToken({ refresh_token: refreshToken }).pipe(
              switchMap((response: any) => {
                return next.handle(this.addTokenHeader(request, response.access_token));
              }),
              catchError((err:any) => {
                this.tokenService.removeToken();
                this.tokenService.removeRefreshToken();
                this.authService.login();

                return throwError(err);
              })
            );
          } else if (error.status == 401 && error.message == 'Unauthorized') {
            return this.authService.refreshToken({ refresh_token: refreshToken }).pipe(
              switchMap((response: any) => {
                return next.handle(this.addTokenHeader(request, response.access_token));
              }),
              catchError((err:any) => {
                this.tokenService.removeToken();
                this.tokenService.removeRefreshToken();
                this.authService.login();

                return throwError(err);
              })
            );
          } else {
            this.tokenService.removeToken();
            this.tokenService.removeRefreshToken();
            this.authService.login();
          }
        }
        return throwError(error);
      }));
  }


  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
