import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Account } from "../utilities/models/account/account";

@Injectable({ providedIn: "root" })
export class AccountsService {
  accounts: Account[] = [];
  constructor(private http: HttpClient) {
  }

  getAccounts() {
    const url = environment.api_url + 'operator/v1/accounts';
    const requestParams = {
      "page[per]": 10000,
      "page[number]": 1,
      "sort": "title",
      "q[active_true]": 1
    };

    return this.http.get(url, {params: requestParams});
  }

  getAccount(id: number) {
    const url = environment.api_url + 'operator/v1/accounts/' + id;

    return this.http.get(url);
  }

  getCustomerAccounts(str?: string) {
    const url = environment.api_url + 'operator/v1/accounts/list';
    const requestParams: { [key: string]: any } = {
      "page[per]": 50,
      "page[number]": 1,
      "sort": "title",
      "q[active_true]": "1",
      "q[account_type_id_eq]": "1"
    };
    if(str) {
      requestParams['q[title_cont]'] = str;
    }

    return this.http.get(url, {params: requestParams});
  }
}
