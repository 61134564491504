<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
        <nz-breadcrumb breadcrumb nzSeparator="·">
          <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
          <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
          <nz-breadcrumb-item>Support</nz-breadcrumb-item>
        </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col nzSpan="18" nzOffset="0">
        <h4>App</h4>
        <h1>Support</h1>
      </div>
      <div nz-col nzSpan="6" class="top-right-cta">
        <button nz-button [routerLink]="['/app', 'support', 'tickets']" class="button-black" nzShape="round">Existing Tickets</button>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSm]="24" [nzMd]="12" nzOffset="0">
        Gain a comprehensive overview of support requests on the <strong>EMPIST 360</strong> platform. You can easily monitor the status of your requests using the "Existing Tickets" section, use the search to find something specific or submit a new request using the options below.
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzXs]="24" [nzSm]="18" [nzMd]="12" nzOffset="0">
        <nz-input-group [nzSuffix]="suffixIconSearch" [class]="searchString == '' ? 'option-limiter ' : 'option-limiter searching'" >
          <input nz-input placeholder="How can we help?" [(ngModel)]="searchString" (ngModelChange)="filterSectionsAndItems()"/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
        <div class="search-results-box" *ngIf="searchString != ''">
          <div class="result-box-items">
            <div class="result-box-item" *ngFor="let ticketFlow of filteredFlows">
              <a [routerLink]="['/app', 'support', 'tickets', 'new']">
                {{ ticketFlow.attributes.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="searchString == ''">
      <div class="groups-spacer"></div>
        <div class="categories-container">
          <ng-container *ngFor="let item of categoryTree">
            <app-flow-category-item [flowCategory]="item.category" [childFlowCategories]="item.children"/>
          </ng-container>
        </div>
    </div>
    <div *ngIf="searchString != ''" class="search-results">
      <div class="results-label"><strong>{{ filteredFlows.length }} Results</strong> with "{{ searchString }}" in context.</div>
      <div nz-row>
        <div nz-col
             nzSpan="6"
             [nzXs]="24"
             [nzSm]="12"
             *ngFor="let category of filteredItems"
             class="search-result">
          <div class="category-label">{{ category.attributes.title }}</div>
          <div *ngFor="let flow of category.flows">
            <a [routerLink]="['/app', 'support', 'tickets', 'new', flow.id]"
               class="support-section-item">
              <span>{{ flow.attributes.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
