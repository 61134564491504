import DateHelper from "./dateHelper";
import { FilterAggregation } from "../models/filters/filterAggregation";
import { FilterAggregationOption } from "../models/filters/filterAggregationOption";

export class FilterHelper {
  static setAggregationsToSelectOptions(response: FilterAggregation, aggregation_key: string | null | undefined): FilterAggregationOption[] {
    if (response && aggregation_key) {
      const agg = response.meta.aggregations[aggregation_key];
      if (agg) {
        const options: FilterAggregationOption[] = [];
        Object.keys(agg).forEach((id: any) => {
          if (agg[id]['doc_count'] > 0) {
            options.push(new FilterAggregationOption(id, agg[id]['title'], agg[id]['doc_count']));
          }
        });

        return options;
      }
    }

    return [];
  }

  static getDateNowAsString(): string {
    return DateHelper.getFormattedDate(new Date());
  }

  static getXDaysAheadAsString(days: number): string {
    return DateHelper.getFormattedDate(DateHelper.dateAddDays(new Date(), days));
  }
}