import { ObjectData } from "../../objectData";
import { FlowControlAttributes } from "./flowControlAttributes";
import { FlowControlRelationships } from "./flowControlRelationships";

export class FlowControl extends ObjectData<FlowControlAttributes, FlowControlRelationships> {

  constructor(data: ObjectData<FlowControlAttributes, FlowControlRelationships>, included: Object[]) {
    super(data, included);
    this.type = data.type;
    if (data.relationships && included) {
      this.relationships = FlowControl.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): FlowControlRelationships {
    let flowRelationships = new FlowControlRelationships();

    return flowRelationships;
  }
}