<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'invoices']">Invoices</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Billing Support Request</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span routerLink=".." class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" class="request-change-container">
        <div nz-row nzJustify="center">
          <div nz-col>
            <h1>Billing Support</h1>
          </div>
        </div>
        <div nz-row class="support-top">
          <div nz-col nzSpan="24">
            <div nz-row nzJustify="center">
              <div nz-col>
                You can submit a request for support using the form below
              </div>
            </div>
            <nz-divider></nz-divider>
            <form class="request-change-form"
                  nz-form
                  nzLayout="vertical"
                  [formGroup]="invoiceRequestForm"
                  (ngSubmit)="onSubmit()">
              <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-form-item class="ticket-create-form-item">
                      <nz-form-label nzFor="description" nzRequired>
                        Subject
                      </nz-form-label>
                      <nz-form-control>
                        <input nz-input [placeholder]="''" formControlName="subject" />
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item class="ticket-create-form-item">
                      <nz-form-label nzFor="description" nzRequired>
                        Please describe your issue
                      </nz-form-label>
                      <nz-form-control>
                        <textarea rows="4" nz-input [placeholder]="''" formControlName="description" [froalaEditor]="froalaEditorOptions"></textarea>
                      </nz-form-control>
                    </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="24" class="actions-container">
                  <button nz-button type="button" nzType="default" nzShape="round" nzSize="large" routerLink="..">Cancel</button>
                  <button nz-button type="submit" nzType="primary" nzShape="round" nzSize="large" [disabled]="!invoiceRequestForm.valid">Send Request</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <custom-loader *ngIf="loaderVisible"></custom-loader>
  </nz-content>
</nz-layout>
