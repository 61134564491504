import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { FlowCreateDto } from "../utilities/models/dto/flowCreateDto";
import { FlowUpdateDto } from "../utilities/models/dto/flowUpdateDto";
import { FlowCategoryCreateDto } from "../utilities/models/dto/flowCategoryCreateDto";
import { FlowCategoryUpdateDto } from "../utilities/models/dto/flowCategoryUpdateDto";
import { QueryParam } from "../utilities/models/parameters/queryParam/queryParam";
import { FlowControlCreateDto } from "../utilities/models/dto/flowControlCreateDto";
import { FlowControlUpdateDto } from "../utilities/models/dto/flowControlUpdateDto";
import { FlowControlUpdatePositionDto } from "../utilities/models/dto/flowControlUpdatePositionDto";
import { FlowCategoryUpdateAccountsDto } from "../utilities/models/dto/flowCategoryUpdateAccountsDto";

@Injectable({ providedIn: "root" })
export class FlowsService {

  constructor(private http: HttpClient) { }

  getFlows(queryParams: QueryParam[] = [], perPage: number = 10000, page: number = 1, sortField: string = '', sortOrder: string = '', searchString: string = '') {
    const requestUrl = environment.api_url + 'operator/v1/flows?include=flow_categories,flow_controls';
    const sort = sortOrder == "descend" ? '-' + sortField : sortField;
    let requestParams: any = {
      "page[per]": perPage,
      "page[number]": page,
      "sort": sort
    };

    queryParams.forEach((queryParam: QueryParam) => {
      requestParams[`q[${queryParam.key}]`] = queryParam.value;
    })
    if(searchString){
      requestParams['q[title_cont]'] = searchString;
    }

    return this.http.get(requestUrl, { params: requestParams });
  }

  getFlow(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/flows/'+id+'?include=flow_categories,flow_controls';

    return this.http.get(requestUrl);
  }

  createFlow(payload: FlowCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/flows';

    return this.http.post<any>(requestUrl, payload);
  }

  updateFlow(payload: FlowUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/flows/'+payload.data.id;

    return this.http.patch<any>(requestUrl, payload);

  }

  deleteFlow(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/flows/'+id;

    return this.http.delete<any>(requestUrl);
  }

  getFlowCategories(queryParams: QueryParam[] = [], perPage: number = 20, page: number = 1, sortField: string = '', sortOrder: string = '', searchString: string = '') {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories?include=accounts';
    const sort = sortOrder == "descend" ? '-' + sortField : sortField;
    let requestParams: any = {
      "page[per]": perPage,
      "page[number]": page,
      "sort": sort
    };

    queryParams.forEach((queryParam: QueryParam) => {
      requestParams[`q[${queryParam.key}]`] = queryParam.value;
    })
    if(searchString){
      requestParams['q[title_cont]'] = searchString;
    }

    return this.http.get(requestUrl, { params: requestParams });
  }

  getFlowCategoriesWithFlows(queryParams: QueryParam[] = [], perPage: number = 10000, page: number = 1, sortField: string = '', sortOrder: string = '', searchString: string = '') {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories?include=accounts,flows&q[flows_active_eq]=true';
    const sort = sortOrder == "descend" ? '-' + sortField : sortField;
    let requestParams: any = {
      "page[per]": perPage,
      "page[number]": page,
      "sort": sort
    };

    queryParams.forEach((queryParam: QueryParam) => {
      requestParams[`q[${queryParam.key}]`] = queryParam.value;
    })
    if(searchString){
      requestParams['q[title_cont]'] = searchString;
    }

    return this.http.get(requestUrl, { params: requestParams });
  }

  getFlowCategory(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories/'+id+'?include=uploads,accounts';

    return this.http.get(requestUrl);
  }

  createFlowCategory(payload: FlowCategoryCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories';

    return this.http.post<any>(requestUrl, payload);
  }

  updateFlowCategory(payload: FlowCategoryUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories/'+payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }

  deleteFlowCategory(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories/'+id;

    return this.http.delete<any>(requestUrl);
  }

  getFlowControls(flowId: number) {
    const requestUrl = environment.api_url + 'operator/v1/flow_controls?q[flow_id_eq]=flowId';

    return this.http.get(requestUrl);
  }

  createFlowControl(payload: FlowControlCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/flow_controls';

    return this.http.post<any>(requestUrl, payload);
  }

  updateFlowControl(payload: FlowControlUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/flow_controls/'+payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }

  deleteFlowControl(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/flow_controls/'+id;

    return this.http.delete<any>(requestUrl);
  }

  updateFlowControlPosition(payload: FlowControlUpdatePositionDto) {
    const requestUrl = environment.api_url + 'operator/v1/flow_controls/'+payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }

  flowCategoryUpdateAccounts(payload: FlowCategoryUpdateAccountsDto) {
    const requestUrl = environment.api_url + 'operator/v1/flow_categories/'+payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }
}