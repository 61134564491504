import { Component, DestroyRef, OnInit } from '@angular/core';
import { User } from "src/app/utilities/models/user/user";
import { UsersService } from "src/app/services/users.service";
import { PostsService } from "src/app/services/posts.service";
import { Post } from "src/app/utilities/models/post/post";
import { NzMessageService } from "ng-zorro-antd/message";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { NavFilter } from "src/app/utilities/models/navFilters/navFilter";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { TicketsService } from "src/app/services/tickets.service";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loggedInUser: User;
  userAccountTitle: string;
  userAccountId: number | undefined;
  tenantAccountTitle: string = 'EMPIST';
  userAccountPosts: Post[];
  tenantAccountPosts: Post[];
  navFilter: NavFilter;
  filters: ReportParam[];
  latestTickets: Ticket[];
  loading = false;
  userAccountPostsLoading: boolean;
  tenantAccountPostsLoading: boolean;
  postsIndexPermission: Permission | undefined;
  invoicesPermission: Permission | undefined;
  installedServicePermission: Permission | undefined;
  hasAccountManagement: boolean = false;

  constructor(private userService: UsersService,
              private postService: PostsService,
              private msg: NzMessageService,
              private ticketService: TicketsService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit(): void {
    this.loggedInUser = this.userService.loggedInUser;
    this.userAccountId = this.loggedInUser.relationships?.account?.id;
    this.userAccountPostsLoading = true;
    this.tenantAccountPostsLoading = true;
    this.getPosts();
    this.navFilter = new NavFilter(this.userService.loggedInUser.id);
    this.getLatestTickets();
    this.postsIndexPermission = this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'index');
    this.invoicesPermission = this.userService.findPermission('Digcore::Invoice', 'ticketing/operator/v1/invoices', 'index');
    this.installedServicePermission = this.userService.findPermission('Digcore::InstalledService', 'ticketing/operator/v1/installed_services', 'index');
    this.hasAccountManagement = ['account_admin_contact_role', 'admin_contact_role', 'billing_contact_role', 'account_agent_role'].includes(this.loggedInUser?.relationships?.role?.attributes?.system_key ?? '');
  }

  getPosts() {
    if (this.loggedInUser?.relationships?.account) {
      this.userAccountTitle = this.loggedInUser.relationships.account.attributes.title;
      this.postService.getPostsDashboard()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.userAccountPosts = response.data.filter((post:any) => post.relationships.account.data.id == this.userAccountId).map((post: any) => {
                return new Post(post, response.included)
              })
            this.tenantAccountPosts = response.data.filter((post:any) => post.relationships.account.data.id != this.userAccountId).map((post: any) => {
                return new Post(post, response.included)
              })
            this.userAccountPostsLoading = false;
            this.tenantAccountPostsLoading = false;
          },
          error: _err => {
            this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
            this.userAccountPostsLoading = false;
          }
        });
    }
  }

  getLatestTickets() {
    this.loading = true;
    this.filters = this.navFilter.filters(2, this.userService.loggedInUser.id)
    this.ticketService.getTicketListFromView(this.filters, 3, 1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.latestTickets = [];
          response.data.forEach((ticket: any) => {
            let tmpTicket = new Ticket(ticket, response.included);
            tmpTicket.relationships!.status!.attributes!.status_data = { background_color: this.userService.statusArray.find(status => status.id == tmpTicket.relationships?.status?.id)?.background_color || 'Black' }
            this.latestTickets.push(tmpTicket);
          });
          this.loading = false;
        },
        error: err => {
          this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
          console.log(err);
          this.loading = false;
        }
      });
  }
}
