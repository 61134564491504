import { IncludedData } from "../../includedData";
import { ObjectData } from "../../objectData";
import { RelationshipData } from "../../relationshipData";
import { AccountRelationAttribute } from "../accountRelationAttribute";
import { FlowAttributes } from "../flow/flowAttributes";
import { UploadRelationAttribute } from "../uploadRelationAttribute";
import { FlowCategoryAttributes } from "./flowCategoryAttributes";
import { FlowCategoryRelationships } from "./flowCategoryRelationships";

export class FlowCategory extends ObjectData<FlowCategoryAttributes, FlowCategoryRelationships> {

  constructor(data: ObjectData<FlowCategoryAttributes, FlowCategoryRelationships>, included: Object[]) {
    super(data, included);
    this.type = data.type;
    if (data.relationships && included) {
      this.relationships = FlowCategory.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): FlowCategoryRelationships {
    let relationships = new FlowCategoryRelationships();

    relationships.accounts = FlowCategory.normalizeAccountsRelation(relationObject, included);
    relationships.uploads = FlowCategory.normalizeUploadsRelation(relationObject, included);
    relationships.flows = FlowCategory.normalizeFlowsRelation(relationObject, included);

    return relationships;
  }

  private static normalizeUploadsRelation(relationObject: any, included: Object[]): RelationshipData<UploadRelationAttribute>[] | undefined {
    if (!(relationObject?.uploads?.data?.length > 0)) return undefined;

    return relationObject.uploads.data.map((upload: any) => {
      let uploadRelation = new RelationshipData<UploadRelationAttribute>();

      uploadRelation.id = +upload.id;
      uploadRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == uploadRelation.type &&
          includedObject.id == uploadRelation.id
      });
      uploadRelation.attributes = (tmp as IncludedData<UploadRelationAttribute>).attributes
      return uploadRelation;
    })
  }

  private static normalizeAccountsRelation(relationObject: any, included: Object[]): RelationshipData<AccountRelationAttribute>[] | undefined {
    if (!relationObject?.accounts?.data?.length) return undefined;

    return relationObject.accounts.data.map((data: any) => {
      let relation = new RelationshipData<AccountRelationAttribute>();

      relation.id = +data.id;
      relation.type = data.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == relation.type &&
          includedObject.id == relation.id
      });
      relation.attributes = (tmp as IncludedData<AccountRelationAttribute>).attributes
      return relation;
    });
  }

  private static normalizeFlowsRelation(relationObject: any, included: Object[]): RelationshipData<FlowAttributes>[] | undefined {
    if (!relationObject?.flows?.data?.length) return undefined;

    return relationObject.flows.data.map((data: any) => {
      let relation = new RelationshipData<FlowAttributes>();

      relation.id = +data.id;
      relation.type = data.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == relation.type &&
          includedObject.id == relation.id
      });
      relation.attributes = (tmp as IncludedData<FlowAttributes>).attributes
      return relation;
    });
  }
}