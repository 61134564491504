import { Component } from '@angular/core';

@Component({
  selector: 'instantSupport',
  templateUrl: './instantSupport.component.html',
  styleUrls: ['./instantSupport.component.scss']
})
export class InstantSupport {

}
