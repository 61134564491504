export interface FlowControlUpdateAttributes {
  input_type: string;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  options?: {value: string, label: string}[];
}

export interface FlowControlUpdateRelationships {

}

export interface FlowControlUpdateData {
  id: number;
  type: string;
  attributes: FlowControlUpdateAttributes;
  relationships: FlowControlUpdateRelationships;
}


export class FlowControlUpdateDto {
  data: FlowControlUpdateData;

  constructor(data: FlowControlUpdateData) {
    this.data = data;
  }
}