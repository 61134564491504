import { Component, DestroyRef, OnInit } from "@angular/core";
import { UsersService } from "src/app/services/users.service";
import { TicketsService } from "src/app/services/tickets.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { User } from "src/app/utilities/models/user/user";
import { FormControl, FormGroup, UntypedFormGroup, Validators } from "@angular/forms";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { UploadsService } from "src/app/services/uploads.service";
import {TicketCreateDto, TicketCreateDtoRelationship} from "src/app/utilities/models/dto/ticketCreateDto";
import { RelationshipData } from "src/app/utilities/models/relationshipData";
import FroalaHelper from "src/app/utilities/helpers/froala";
import { LoaderService } from "src/app/services/loader.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NzMessageService } from "ng-zorro-antd/message";
import { FlowsService } from "src/app/services/flows.service";
import { FlowControl } from "src/app/utilities/models/ticket/flowControl/flowControl";
import { Flow } from "src/app/utilities/models/ticket/flow/flow";
import { OnspringTicketCreateDto } from "src/app/utilities/models/dto/onspringTicketCreateDto";
import { OnspringService } from "src/app/services/onspring.service";
import { FlowControlAttribute } from "src/app/utilities/models/ticket/flow/flowControlAttribute";

@Component({
  selector: 'ticketNew',
  templateUrl: './ticketNew.component.html',
  styleUrls: ['./ticketNew.component.scss']
})
export class TicketNewComponent implements OnInit {
  ticketCreateForm: UntypedFormGroup;
  permission: Permission | undefined;
  accountId: number;
  requesterId: number;
  loggedInUser: User;
  editor: any;
  froalaEditorOptions: Object;
  flow: Flow | undefined;
  loaderVisible: boolean = false;
  formControls: FlowControl[];
  platform: string = 'Deskware';
  flowHasSubjectControl: boolean = false;
  allowSubmitTo3rdPartyPlatform: boolean = true;
  loading: boolean = false;

  constructor(private userService: UsersService,
              private ticketService: TicketsService,
              private onspringService: OnspringService,
              private loaderService: LoaderService,
              private flowsService: FlowsService,
              private uploadService: UploadsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private msg: NzMessageService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    this.permission = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');
    this.froalaEditorOptions = this.initializeFroalaEditor();
    this.ticketCreateForm = this.getFormGroup();
    this.setForm();

    this.activatedRoute.params
      .subscribe({
        next: (params: Params) => {
          if(params['id'] > 0) {
            this.getFlow(params['id']);
          }
        }
      }
    );

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      });
  }

  private getFlow(id: number) {
    this.loading = true;
    this.flowsService.getFlow(id)
      .pipe( takeUntilDestroyed(this.destroyRef) )
      .subscribe({
        next:(response: any) => {
          this.flow = new Flow(response.data, response.included);
          this.formControls = this.flow?.relationships?.flow_controls?.map((control: any) => {
            control.attributes.name = 'control_'+control.attributes.name;
            return new FlowControl(control, []);
          }) || [];
          this.addFormControls();
          this.platform = this.flow.attributes.platform_name ?? 'Deskware';
          this.loading = false;
        },
        error:(error) => {
          console.log(error);
          this.loading = false;
        }
      });
  }

  onSubmit(){
    switch(this.flow?.attributes.platform_name.toLocaleLowerCase()) {
      case 'onspring': return this.onSpringSubmit();
      case 'deskware': return this.deskwareSubmit();
      default: return this.deskwareSubmit();
    }
  }

  private preparePayload(): TicketCreateDto {
    const ticketCreateAttributes = {
      subject: this.ticketCreateForm.get('subject')?.value,
      description: this.getDescription(this.ticketCreateForm.value) //this.ticketCreateForm.get('description')?.value,
    }
    const ticketCreateRelationships: TicketCreateDtoRelationship = {
      account: this.prepareData("accounts", this.ticketCreateForm.get('account')?.value),
      requester: this.prepareData("users", this.ticketCreateForm.get('requester')?.value),
    }

    return new TicketCreateDto("tickets", ticketCreateAttributes, ticketCreateRelationships);
  }

  private prepareData(type: string, valueId: number){
    return { data: { id: valueId, type: type }};
  }

  private setForm(){
    this.requesterId = this.loggedInUser.id;
    this.ticketCreateForm.get('requester')?.setValue(this.requesterId);
    if(this.loggedInUser.relationships?.account){
      this.accountId = this.loggedInUser.relationships.account.id;
      this.ticketCreateForm.get('account')?.setValue(this.accountId);
    }
  }

  private getFormGroup(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      'subject': new FormControl<string>({ value: '', disabled: this.disableByProperty('subject') }, Validators.required),
      'description': new FormControl<string>({ value: '', disabled: this.disableByProperty('description') }),
      'account': new FormControl<number | undefined>({ value: undefined , disabled: this.disableByProperty('account_id') }, Validators.required),
      'requester': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('requester_id') }, Validators.required),
    });

    return formGroup;
  }

  private addFormControls() {
    this.flow?.relationships?.flow_controls?.forEach((control: RelationshipData<FlowControlAttribute>) => {
      if(control.attributes.name == 'subject' || control.attributes.name == 'control_subject' || control.attributes.name.includes('-subject')) {
        this.flowHasSubjectControl = true;
      }
      if(this.flow?.attributes.platform_name.toLocaleLowerCase() == 'onspring') {
        this.flow.attributes.subject = '-';
        this.flowHasSubjectControl = true;
      }
      switch (control.attributes.input_type) {
        case 'select':
          this.ticketCreateForm.addControl(control.attributes.name, new FormControl<number | undefined>( undefined ));
          break;
        case 'input':
        case 'textarea':
        case 'date':
        case 'wysiwyg':
          this.ticketCreateForm.addControl(control.attributes.name, new FormControl<string>(''));
          break;
        case 'checkbox':
          this.ticketCreateForm.addControl(control.attributes.name, new FormControl<boolean | undefined>(false));
          break;
        case 'boolean':
          this.ticketCreateForm.addControl(control.attributes.name, new FormControl<boolean | undefined>(false));
          break;
        case 'radio':
          this.ticketCreateForm.addControl(control.attributes.name, new FormControl<string | undefined>(undefined));
          break;
        case 'hidden':
          this.ticketCreateForm.addControl(control.attributes.name, new FormControl<string>(control.attributes.label));
          break;
      }
      if (control.attributes.required) {
        this.ticketCreateForm.controls[control.attributes.name].setValidators(Validators.required);
      }
      if (control.attributes.name == 'email') {
        this.ticketCreateForm.controls[control.attributes.name].setValidators([Validators.required, Validators.email]);
      }
    });
  }

  private disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private getSubject(formGroupValue: any) {
    if(this.flow?.attributes.subject && this.flow?.attributes.subject.trim() != '') {
      return this.flow?.attributes.subject;
    }

    if(this.ticketCreateForm.value.control_subject) return this.ticketCreateForm.value.control_subject;

    const controlKey = Object.keys(this.ticketCreateForm.controls).find((key: string) => key.includes('-subject'));
    if(controlKey) return this.ticketCreateForm.controls[controlKey].value;

    return this.ticketCreateForm.value.subject;
  }

  private getDescription(formGroupValue: any) {
    return this.flow?.relationships?.flow_controls?.map((control: RelationshipData<FlowControlAttribute>) => '<p><strong>'+control.attributes.label + '</strong><br>' + formGroupValue[control.attributes.name] + '</p><br>').join('') ?? '';
  }

  private initializeFroalaEditor(): Object {
    return {
      ...FroalaHelper.getConfig({ placeholderText: 'Start typing...' }),
      events: {
        'initialized': (editor: any) => {
          this.editor = editor.getEditor();
        },
        'image.beforeUpload': (files: FileList) => {
          if (!files.length) return false;

          const payload = this.prepareUploadPayload(files[0]);
          this.uploadService.create(payload).subscribe({
            next: (response: any) => {
              const url = response.data.attributes.permalink;
              this.editor.image.insert(url, false, null, this.editor.image.get(), { link: url });

              return false;
            }
          });
          return false;
        },
      }
    };
  }

  private prepareUploadPayload(file: File) {
    const payload = new FormData();
    payload.append('used_for', 'inline');
    payload.append('uploaded_file', file);

    return payload;
  }

  private deskwareSubmit() {
    const valid = this.setAndValidateForm();
    if(!valid) { return; }

    this.displayLoader();
    const payload: TicketCreateDto = this.preparePayload();

    this.ticketService.createTicket(payload).subscribe({
      next: (response: any) => {
        this.ticketCreateForm.reset();
        this.loaderService.setProcessing(false);
        if(response.data.attributes.code){
          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
            this.router.navigate(['/app','support','tickets',response.data.attributes.code]).then( _ => console.log(''));
          }, 2000)
        }
      }
    });
  }

  private onSpringSubmit() {
    if(!this.allowSubmitTo3rdPartyPlatform){
      this.msg.error("Onspring integation is under construction", { nzDuration: 3000, nzPauseOnHover: false });
      return true;
    }

    const valid = this.setAndValidateForm();
    if(!valid) { return; }

    const payload: OnspringTicketCreateDto | null = this.preparePayloadForOnspring();
    if(payload == null) return;

    this.displayLoader();
    return this.onspringService.createTicket(payload).subscribe({
              next: (response: any) => {
                if(response?.data?.id > 0){
                  this.ticketCreateForm.reset();
                  this.loaderService.setProcessing(false);
                  setTimeout(() => {
                    this.loaderService.setLoaderVisible(false);
                    this.router.navigate(['/app','support']).then( _ => console.log(''));
                  }, 2000)
                }else{
                  console.log(response)
                  this.loaderService.setProcessing(false);
                  this.loaderService.setLoaderVisible(false);
                  if(response?.status == 422) {
                    this.msg.error('Something went wrong', {
                      nzDuration: 5000,
                      nzPauseOnHover: true
                    });
                  }
                }
              },
              error: (error) => {
                console.log('Error',error);
                this.loaderService.setProcessing(false);
                this.loaderService.setLoaderVisible(false);

                this.msg.error('Something went wrong', {
                  nzDuration: 5000,
                  nzPauseOnHover: true
                });
              }
            });
  }

  private setAndValidateForm() {
    const subject = this.getSubject(this.ticketCreateForm.value);
    this.ticketCreateForm.get('subject')?.setValue(subject);
    //const description = this.getDescription(this.ticketCreateForm.value);
    //this.ticketCreateForm.get('description')?.setValue(description);
    if(!this.ticketCreateForm.valid){
      Object.values(this.ticketCreateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
          console.log(control)
        }
      });

      this.msg.error('Please fill in all the required fields', {
        nzDuration: 5000,
        nzPauseOnHover: true
      });
      return false;
    }

    return true;
  }

  private displayLoader() {
    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your ticket is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your ticket was created!');
    this.loaderService.setLoadedSecondaryText('');
  }

  private preparePayloadForOnspring(): OnspringTicketCreateDto | null {
    const formValues = Object.keys(this.ticketCreateForm.value).filter(key => key.includes('control_') || key == 'subject')
      .map(key => {
        return {
          flowControlId: this.getFLowControl(key),
          name: key.replace('control_',''),
          value: this.ticketCreateForm.value[key]
        }
      })
    if(this.flow?.relationships?.flow_categories?.length){
      const payload: OnspringTicketCreateDto = {
        requesterEmail: this.loggedInUser.attributes.email,
        requesterFullname: `${this.loggedInUser.attributes.firstname} ${this.loggedInUser.attributes.lastname}`,
        flowId: this.flow.id,
        flowCategoryId: this.flow?.relationships?.flow_categories[0]?.id ?? undefined,
        formData: formValues
      }

      return payload;
    }

    return null;
  }

  private getFLowControl(formControlName: string) {
    return this.flow?.relationships?.flow_controls?.find(flowControl => flowControl.attributes.name == formControlName)?.id ?? undefined;
  }
}
