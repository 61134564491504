import { Component, DestroyRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { UsersService } from "src/app/services/users.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private userService: UsersService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit(): void {
    let params = this.route.snapshot.queryParams;

    this.authService.getToken(params['code'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: _data => {
          this.userService.prepareApp();

          const initialUrl = localStorage.getItem('initial_url');
          if (initialUrl) {
            localStorage.removeItem('initial_url');
            this.router.navigateByUrl(initialUrl).then(_ => true);
          } else {
            this.router.navigate(['/app', 'dashboard']).then(_ => true);
          }
        },
        error: _err => {
          this.router.navigate(['/info']).then(_ => false);
        }
      });
  }
}
