import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ConfigurationsService {
  timezones = new Subject<string[]>();

  constructor(
    private http: HttpClient) {
  }

  getTimezones(){
    this.timezones.next([]);
    const requestUrl = environment.api_url + 'operator/v1/global_configurations/time_zones';
    this.http.get(requestUrl).subscribe({
      next: (response: any) => {
        this.timezones.next(Object.values(response.data[0]?.attributes?.message));
      },
      error: _err => {
        this.timezones.next([]);
      }
    });
  }
}