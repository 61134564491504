import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { QueryParam } from "../utilities/models/parameters/queryParam/queryParam";
import { ChangeRequestCreateDto } from "../utilities/models/dto/changeRequestCreateDto";
import { DBExportPayload } from "../utilities/models/dto/dbExportPayloadDto";

@Injectable({ providedIn: "root" })
export class InvoicesService {
  constructor(private http: HttpClient) {
  }

  get_invoices(queryParams: QueryParam[], perPage: number = 20, page: number = 1, sortField: string, sortOrder: string, searchString: string = '') {
    let requestUrl = environment.api_url + 'operator/v1/invoices?include=account,invoice_status';
    const sort = sortOrder == "descend" ? '-' + sortField : sortField;
    let requestParams: any = {
      //"q[account_id_eq]": accountId,
      "page[per]": perPage,
      "page[number]": page,
      "sort": sort
    };

    queryParams.forEach((queryParam: QueryParam) => {
      requestParams[`q[${queryParam.key}]`] = queryParam.value;
    })
    if(searchString){
      requestParams['q[uniq_number_cont]'] = searchString;
    }

    return this.http.get(requestUrl, { params: requestParams });
  }

  get_invoice(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/invoices/' + id + '?include=line_items,account';

    return this.http.get(requestUrl);
  }

  getInvoicesTotalCount(queryParams: QueryParam[]) {
    const requestUrl = environment.api_url + 'operator/v1/invoices';
    let requestParams: { [key: string]: string | number } = {
      "page[per]": 1,
    };
    queryParams.forEach((queryParam: QueryParam) => {
      requestParams[`q[${queryParam.key}]`] = queryParam.value;
    })

    return this.http.get(requestUrl, { params: requestParams });
  }

  getInvoiceStatuses() {
    const requestUrl = environment.api_url + 'operator/v1/invoice_statuses';

    return this.http.get(requestUrl);
  }

  billingQuestion(payload: ChangeRequestCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/invoices/billing_question';

    return this.http.post<any>(requestUrl, payload);
  }

  exportInvoices(payload: DBExportPayload) {
    let requestUrl = environment.api_url + 'operator/v1/invoices/export?';
    const params: string[] = Object.keys(payload).map(key => `${key}=${payload[key]}`);
    requestUrl += 'page[per]=10000&' + params.join('&');

    return this.http.post<any>(requestUrl, { });
  }
}
