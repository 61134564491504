export interface FlowControlUpdatePositionAttributes {
  position: number;
}

export interface FlowControlUpdatePositionData {
  id: number;
  type: string;
  attributes: FlowControlUpdatePositionAttributes;
}

export class FlowControlUpdatePositionDto {
  data: FlowControlUpdatePositionData;

  constructor(data: FlowControlUpdatePositionData) {
    this.data = data;
  }
}