<nz-layout>
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flows']">Flows</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flow-categories']">Categories</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>New</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','flow-categories']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24" class="text-center">
        <h1>New Flow Category</h1>
      </div>
    </div>

    <nz-spin nzSimple *ngIf="loading"></nz-spin>

    <nz-row nzJustify="center">
      <nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <form class="user-information-form"
            nz-form
            nzLayout="vertical"
            [formGroup]="form"
            (ngSubmit)="onSubmit()">

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">Image</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <div *ngIf="upload?.id">
              <img [src]="upload.attributes.permalink" alt=""/><br/><br/>
            </div>
            <nz-upload nzAccept="image/*"
                       nzAction="..."
                       nzFileType="image/png,image/jpeg,image/jpg,image/gif"
                       nzLimit="1"
                       [(nzFileList)]="fileList"
                       [nzShowUploadList]="false"
                       [nzBeforeUpload]="onBeforeUpload">
              <div class="upload-container">
                <p class="ant-upload-drag-icon">
                  <span nz-icon nzType="inbox"></span>
                </p>
                <p class="ant-upload-text">Click or drag file to this area to upload</p>
              </div>
            </nz-upload>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24" [nzRequired]="true">Title</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="title"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">Description</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <textarea nz-input formControlName="description"></textarea>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">Parent Category</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-select formControlName="parent_id"
                       nzAllowClear
                       nzShowSearch
                       nzPlaceHolder="Select the flow category">
              <nz-option *ngFor="let flowCategory of flowCategories"
                [nzLabel]="flowCategory.attributes.title"
                [nzValue]="flowCategory.id">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">Public</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-switch formControlName="public"></nz-switch>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">
            Account
            <span class="info" *ngIf="form.controls['public'].value == false">(This flow category will be available only to the selected accounts)</span>
            <span class="info" *ngIf="form.controls['public'].value == true">(This flow category will not be available to the selected accounts)</span>
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-select formControlName="accounts"
                       nzAllowClear
                       nzShowSearch
                       nzMode="multiple"
                       [nzLoading]="accountsLoading"
                       (nzOpenChange)="accountSelectOpenChange($event)">
              <nz-option *ngFor="let account of accounts"
                [nzLabel]="account.attributes.title"
                [nzValue]="account.id">
              </nz-option>
              <nz-option *ngIf="accountsLoading" nzDisabled nzCustomContent>
                <span nz-icon nzType="loading" class="loading-icon"></span>
                Loading Data...
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-row nzJustify="end" nzGutter="10">
          <nz-col class="align-left">
            <button nz-button
                    nzDanger
                    type="button"
                    nzShape="round"
                    nzSize="large"
                    [nzLoading]="loading">
              Delete
            </button>
          </nz-col>
          <nz-col>
            <button nz-button
                    type="submit"
                    nzType="primary"
                    nzShape="round"
                    nzSize="large"
                    [nzLoading]="loading">
              Submit
            </button>
          </nz-col>
        </nz-row>
      </form>
      </nz-col>
    </nz-row>
  </nz-content>
</nz-layout>
