import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UsersService } from "src/app/services/users.service";
import { User } from "src/app/utilities/models/user/user";
import { take } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import PermissionHelper from 'src/app/utilities/helpers/permissionHelper';
import { Permission } from "src/app/utilities/models/permissions/permission";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { UploadsService } from "src/app/services/uploads.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { UserUpdateDto } from "src/app/utilities/models/dto/userUpdateDto";
import { AuthService } from "src/app/auth/auth.service";
import { LoaderService } from "src/app/services/loader.service";
import { NzModalRef } from "ng-zorro-antd/modal";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'set-password-form-component',
  templateUrl: './setPasswordForm.component.html',
  styleUrls: ['./setPasswordForm.component.scss']
})
export class SetPasswordFormComponent implements OnInit {
  @ViewChild('passwordInput') passwordInput: ElementRef;
  user: User;
  setPasswordForm: FormGroup;
  permission: Permission | undefined;
  isUploading: boolean = false;
  loading: boolean = false;
  showChangePasswordModal: boolean = true;
  fileList: NzUploadFile[] = [];
  showSetPasswordForm: boolean = false;
  visibleLoader: boolean = false;

  constructor(private modal: NzModalRef,
              private userService: UsersService,
              private msg: NzMessageService,
              private uploadsService: UploadsService,
              private authService: AuthService,
              private loaderService: LoaderService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    this.userService.userSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(user: User) => {
          this.user = user
        }
      });

    this.permission = this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'update');
    if(!this.permission) return;

    this.setPasswordForm = new FormGroup({
      'password': new FormControl<string>('', Validators.compose([Validators.required, Validators.minLength(6)]) ),
      'confirm_password': new FormControl<string>( '', Validators.compose([Validators.required, Validators.minLength(6)]) ),
      'avatar': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'avatar') }),
    })

    this.showChangePasswordModal = this.userService.loggedInUser.attributes.password_updated_at == null;
    this.visibleLoader = this.loaderService.loaderVisible;
    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.visibleLoader = value;
        }
      })
  }

  hideModal() {
    this.showChangePasswordModal = false;
  }

  onSubmit() {
    this.updatePassword();
  }

  onBeforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.handleUpload();

    return false;
  };

  private handleUpload() {
    if (this.fileList.length) {
      this.isUploading = true;
      const payload = this.prepareUploadPayload();

      this.uploadsService.create(payload).pipe(take(1)).subscribe({
        next: ( _response : any) => {
          this.fileList = [];
          this.userService.getCurrentUser();
          this.msg.success('Avatar was uploaded successfully', { nzDuration: 5000, nzPauseOnHover: true });
        },
        error: ( _error ) => {
          this.msg.success('There was an error, please try again', { nzDuration: 5000, nzPauseOnHover: true });
          this.isUploading = false;
        },
        complete: () => {
          this.isUploading = false;
        }
      });
    }
  }

  private prepareUploadPayload(){
    const payload = new FormData();

    payload.append('used_for', 'avatar');
    payload.append('uploadable_type', this.userService.loggedInUser.attributes.type);
    payload.append('uploadable_id', this.userService.loggedInUser.id.toString());

    // this WILL NOT work: formData.append('uploaded_file', this.fileList[0]);
    this.fileList.forEach((file: any) => {
      payload.append('uploaded_file', file);
    });

    return payload;
  }

  private preparePasswordPayload(): UserUpdateDto{
    const userUpdateAttributes = {
      password: this.setPasswordForm.get('password')?.value,
      confirm_password: this.setPasswordForm.get('confirm_password')?.value
    }
    const userUpdateRelationships = {};

    return new UserUpdateDto(this.user.id, "users", userUpdateAttributes, userUpdateRelationships);
  }

  updatePassword() {
    const password = this.setPasswordForm.get('password')?.value;
    const confirm_password = this.setPasswordForm.get('confirm_password')?.value;
    if(!this.setPasswordForm.valid || password !== confirm_password) return;

    this.loading = true;
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Welcome '+this.userService.loggedInUser.attributes.firstname+'!');
    this.loaderService.setLoadingSecondaryText('EMPIST 360 is preparing for you a tailor-made onboarding.');
    this.loaderService.setProcessing(true);

    const payload = this.preparePasswordPayload();
    this.userService.updateUser(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: _ => {
          this.loading = false;
          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
            this.destroyModal();
          }, 3000)
        }
      });
  }

  setShowPasswordForm(value: boolean) {
    this.showSetPasswordForm = value;
    if(value){
      setTimeout(() => this.passwordInput.nativeElement.focus(), 0);
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }
}