import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { Onspring } from "src/platforms/platforms";

@Injectable({ providedIn: "root" })
export class OnspringService {
  constructor(private http: HttpClient,
    private msg: NzMessageService,
    private router: Router) {
  }

  createTicket(payload: any) {
    const requestUrl = Onspring.baseUrl + '/ticket-request/create-ticket';

    return this.http.post<any>(requestUrl, payload, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }});
  }
}