import { IncludedData } from "../../includedData";
import { ObjectData } from "../../objectData";
import { RelationshipData } from "../../relationshipData";
import { UserRelationAttribute } from "../userRelationAttribute";
import { ConversationParticipantRelationships } from "./conversationParticipantRelationships";
import { EmailParticipantAttribute } from "../emailParticipantAttribute";


export class ConversationParticipant extends ObjectData<EmailParticipantAttribute, ConversationParticipantRelationships> {
  constructor(data: ObjectData<EmailParticipantAttribute, ConversationParticipantRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included)
      this.relationships = ConversationParticipant.normalizeRelation(data.relationships, included);
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ConversationParticipantRelationships {
    let conversationParticipantRelationships = new ConversationParticipantRelationships();

    conversationParticipantRelationships.participant = ConversationParticipant.normalizeParticipantRelationship(relationObject, included, 'participant');

    return conversationParticipantRelationships;
  }

  static normalizeParticipantRelationship(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttribute> | undefined {
    let userRelation = new RelationshipData<UserRelationAttribute>();
    if (!relationObject[key]?.data?.id) return undefined;

    userRelation.id = +relationObject[key].data.id;
    userRelation.type = relationObject[key].data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == userRelation.type &&
        includedObject.id == userRelation.id
    });
    userRelation.attributes = (tmp as IncludedData<UserRelationAttribute>).attributes

    return userRelation;
  }

  public fullname(): string {
    return this.relationships?.participant?.attributes?.firstname + ' ' + this.relationships?.participant?.attributes?.lastname;
  }
}