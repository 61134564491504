export interface FlowUpdateAttributes {
  title: string;
  platform_name: string;
  subject: string;
  label?: string;
  active: boolean;
}

interface FlowCategoryData {
  data: {
    id: number;
    type: string;
  }[]
}

interface FlowControlsData {
  data: {
    id: number;
    type: string;
  }[];
}

export interface FlowUpdateRelationships {
  flow_categories?: FlowCategoryData;
  flow_controls?: FlowControlsData;
}

export interface FlowUpdateData {
  id: number;
  type: string;
  attributes: FlowUpdateAttributes;
  relationships: FlowUpdateRelationships;
}


export class FlowUpdateDto {
  data: FlowUpdateData;

  constructor(data: FlowUpdateData) {
    this.data = data;
  }
}