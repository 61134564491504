import { Component, DestroyRef, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NzModalService } from 'ng-zorro-antd/modal';

import { TicketsService } from "src/app/services/tickets.service";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { ConversationsService } from "src/app/services/conversations.service";
import { Conversation } from "src/app/utilities/models/ticket/conversation/conversation";
import { ConversationParticipant } from "src/app/utilities/models/ticket/conversation/conversationParticipant";
import { UsersService } from "src/app/services/users.service";
import { User } from "src/app/utilities/models/user/user";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'ticketConversations',
  templateUrl: './ticketConversations.component.html',
  styleUrls: ['./ticketConversations.component.scss']
})
export class TicketConversationsComponent implements OnInit {
  user: User;
  ticket: Ticket;
  conversationArray: Conversation[] = [];
  urlFragment: string | null;
  conversationParticipantsList: ConversationParticipant[] = [];

  activeConversationId: number | undefined;
  activeConversation: Conversation;
  selectedConversation: Conversation;

  constructor(private ticketService: TicketsService,
              private conversationService: ConversationsService,
              private userService: UsersService,
              private modal: NzModalService,
              private route: ActivatedRoute,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    this.urlFragment = this.route.snapshot.fragment;
    this.conversationArray = this.conversationService.conversationsArray;
    this.conversationService.conversationsSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.conversationArray = response;
        }
      });
    this.activeConversationId = this.conversationService.activeConversationId;
    this.conversationService.activeConversationIdSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (conversationId: number | undefined) => {
          this.activeConversationId = conversationId
        }
      });
  }
}
