import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzIconTestModule } from "ng-zorro-antd/icon/testing";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzMentionModule } from "ng-zorro-antd/mention";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

const ngZorroModules = [
  NzFormModule,
  NzInputModule,
  NzButtonModule,
  NzCheckboxModule,
  ReactiveFormsModule,
  NzCarouselModule,
  NzLayoutModule,
  NzMenuModule,
  NzIconTestModule,
  NzDropDownModule,
  NzAvatarModule,
  NzSpinModule,
  NzAlertModule,
  NzListModule,
  NzCardModule,
  NzPaginationModule,
  NzBadgeModule,
  NzTableModule,
  FormsModule,
  NzDividerModule,
  NzSelectModule,
  NzTagModule,
  NzSkeletonModule,
  NzCollapseModule,
  NzModalModule,
  NzCommentModule,
  NzToolTipModule,
  NzTypographyModule,
  NzSpaceModule,
  NzSwitchModule,
  NzUploadModule,
  NzMessageModule,
  NzPopoverModule,
  NzBreadCrumbModule,
  NzDatePickerModule,
  NzEmptyModule,
  NzTabsModule,
  NzAutocompleteModule,
  NzPopconfirmModule,
  NzRadioModule,
  NzTreeSelectModule,
  NzInputNumberModule,
]

@NgModule({
  imports: [
    CommonModule,
    ...ngZorroModules,
  ],
  declarations: [
  ],
  exports: [
    ...ngZorroModules
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
  ]
})
export class NgZorroModule {}
