import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from "./auth/auth.service";
import { TokenService } from "./auth/token.service";
import { UsersService } from "./services/users.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService,
              private tokenService: TokenService,
              private userService: UsersService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;

    if (this.checkLogin(url) && !this.userService.permissionsArray.length) {
     return this.userService.fetchPermissions().pipe(
        tap(() => this.checkRouteRules(route))
      )
    } else {
      const checkLoginResult = this.checkLogin(url);
      const checkRouteRulesResult = this.checkRouteRules(route);

      if (!checkLoginResult) {
        return this.router.createUrlTree(['/']);
      } else if (!checkRouteRulesResult) {
        return this.router.createUrlTree(['app', 'dashboard']);
      } else {
        return true;
      }
    }
  }

  private checkLogin(url: string): boolean {
    if (this.tokenService.getToken()) return true;

    localStorage.setItem('initial_url', url);
    this.authService.login();
    return false;
  }

  private checkRouteRules(route: ActivatedRouteSnapshot): boolean {
    let hasRolePermission = this.checkRoutePermissionByUserRole(route);
    let hasTypePermission = this.checkRoutePermissionByUserType(route);
    let hasRoutePermission = this.hasPermissionForRoute(route);

    /*
    console.log(route.routeConfig?.path);
    console.log('hasRolePermission: ', hasRolePermission);
    console.log('hasTypePermission: ', hasTypePermission);
    console.log('hasRoutePermission: ', hasRoutePermission);
    console.log('---');
    */

    return hasRolePermission && hasTypePermission && hasRoutePermission;
  }

  private checkRoutePermissionByUserRole(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path;
    const userRole = this.userService.loggedInUser?.relationships?.role?.attributes?.system_key ?? '';
    let result = true;

    if (path == 'account-management' && !['account_admin_contact_role', 'admin_contact_role', 'billing_contact_role', 'account_agent_role'].includes(userRole)) {
      result = false;
    }

    return true;
  }

  private checkRoutePermissionByUserType(route: ActivatedRouteSnapshot): boolean {
    const agentOnlyRoutes = ['services-audit'];
    const contactOnlyRoutes = ['services', 'support'];

    let result = true;
    const baseSegment = route.url[0].path;

    switch (this.userService.loggedInUser?.attributes.type) {
      case 'Ticketing::Agent':
        result = !contactOnlyRoutes.includes(baseSegment);
        break;
      case 'Digcore::Contact':
        result = !agentOnlyRoutes.includes(baseSegment);
        break;
    }

    return result;
  }

  private hasPermissionForRoute(route: ActivatedRouteSnapshot): boolean {
    let permission = true;

    switch (route.routeConfig?.path) {
      case 'support/tickets':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'index');
        break;
      case 'support/tickets/:id':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'update');
        break;
      case 'support/tickets/new':
      case 'support/tickets/new/:id':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');
        break;
      case 'profile':
        permission = !!this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'profile');
        break;
      case 'news':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'index');
        break;
      case 'news/new':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'create');
        break;
      case 'news/:id':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'show');
        break;
      case 'news/:id/edit':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'update');
        break;
      case 'invoices':
        permission = !!this.userService.findPermission('Digcore::Invoice', 'ticketing/operator/v1/invoices', 'index');
        break;
      case 'invoices/:id':
        permission = !!this.userService.findPermission('Digcore::Invoice', 'ticketing/operator/v1/invoices', 'show');
        break;
      case 'assets':
        permission = !!this.userService.findPermission('Ticketing::AssetResource', 'ticketing/operator/v1/asset_resources', 'index');
        break;
      case 'assets/:id':
        permission = !!this.userService.findPermission('Ticketing::AssetResource', 'ticketing/operator/v1/asset_resources', 'update');
        break;
      case 'flows':
      case 'flows/account':
        permission = !!this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'index');
        break;
      case 'flows/new':
        permission = !!this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'create');
        break;
      case 'flows/:id':
        permission = !!this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'show');
        break;
      case 'flow-categories':
      case 'flow-categories/account':
      case 'flow-categories/account/:id':
        permission = (!!this.userService.findPermission('Ticketing::FlowCategory', 'ticketing/operator/v1/flow_categories', 'index'))
          && (!!this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'index'));
        break;
      case 'flow-categories/new':
        permission = !!this.userService.findPermission('Ticketing::FlowCategory', 'ticketing/operator/v1/flow_categories', 'create');
        break;
      case 'flow-categories/:id':
        permission = !!this.userService.findPermission('Ticketing::FlowCategory', 'ticketing/operator/v1/flow_categories', 'show');
        break;
      case 'services':
      case 'services-audit':
      case 'services-change-history':
      case 'additional-services':
        permission = !!this.userService.findPermission('Digcore::InstalledService', 'ticketing/operator/v1/installed_services', 'index');
        break;
      case 'account-management/escalation':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'change_status');
        break;
    }

    return !!permission;
  }
}
