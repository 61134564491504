export interface PostUpdateDtoRelationships {
  uploads?: { data: { id: number, type: string }[] };
}

export interface PostUpdateDtoAttributes {
  content?: string;
  description?: string;
  title?: string;
  sticky?: boolean;
  visible?: boolean;
}

export class PostUpdateDto {
  data:{
    id: number;
    type: string;
    attributes?: PostUpdateDtoAttributes;
    relationships?: PostUpdateDtoRelationships;
  }

  constructor(id:number, type: string, attributes?: PostUpdateDtoAttributes | null, relationships?: PostUpdateDtoRelationships | null) {
    this.data = {
      id: id,
      type: type
    }
    if(attributes){
      this.data.attributes = attributes;
    }
    if(relationships){
      this.data.relationships = relationships;
    }
  }
}