import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { ConversationUpdateDto } from "../utilities/models/dto/conversationUpdateDto";
import { ConversationCreateDto } from "../utilities/models/dto/conversationCreateDto";
import { Conversation } from "../utilities/models/ticket/conversation/conversation";

@Injectable({ providedIn: "root" })
export class ConversationsService {
  activeConversationId: number | undefined;
  activeConversationIdSubject = new Subject<number | undefined>();
  conversationsArray: Conversation[];
  conversationsSubject = new Subject<Conversation[]>();

  constructor(private http: HttpClient) {
  }

  getConversations(ticketId: number) {
    const requestUrl = environment.api_url + 'operator/v1/conversations';
    let requestParams = {
      "q[ticket_id_eq]": ticketId,
      "sort": "-updated_at"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getConversation(conversationId: number) {
    const requestUrl = environment.api_url + 'operator/v1/conversations/' + conversationId;
    let requestParams = {
      include: 'user_participants'
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  setConversations(conversations: Conversation[]) {
    this.conversationsArray = conversations;
    this.conversationsSubject.next(conversations);
  }

  getParticipants(conversationId: number) {
    const requestUrl = environment.api_url + `operator/v1/conversation_participants`;
    let requestParams = {
      "q[conversation_id_eq]": conversationId,
      include: "participant"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  addParticipant(payload: Object) {
    const requestUrl = environment.api_url + 'operator/v1/conversation_participants';

    return this.http.post<any>(requestUrl, payload);
  }

  removeParticipant(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/conversation_participants/' + id;

    return this.http.delete<any>(requestUrl);
  }

  createConversation(payload: ConversationCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/conversations';

    return this.http.post<any>(requestUrl, payload);
  }

  updateConversation(payload: ConversationUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/conversations/' + payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }

  setActiveConversation(conversationId: number | undefined) {
    this.activeConversationId = conversationId;
    this.activeConversationIdSubject.next(conversationId);
  }
}