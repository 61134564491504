export class TfaEnable {
  id: number;
  type: string;
  attributes: {
    provition_url: string;
  }

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.attributes = data.attributes;
  }
}