import { LineItemRelationAttribute } from "./lineItemRelationAttribute";
import { RelationshipData } from "../relationshipData";
import { AccountRelationAttribute } from "./accountRelationAttribute";
import { InvoiceStatusRelationAttribute } from "./InvoiceStatusRelationAttribute";

export class InvoiceRelationships {
  account: RelationshipData<AccountRelationAttribute> | undefined;
  line_items: RelationshipData<LineItemRelationAttribute>[] | undefined;
  invoice_status?: RelationshipData<InvoiceStatusRelationAttribute> | undefined;
}
