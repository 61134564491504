import { UploadRelationAttribute } from "../ticket/uploadRelationAttribute";

export interface ServiceUpdateData{
  id: number;
  type: string;
  attributes: ServiceUpdateDataAttributes;
  relationships?: ServiceUpdateDataRelationships;
}

export interface ServiceUpdateDataAttributes{
  title: string;
  description?: string;
}

export interface ServiceUpdateDataRelationships{
  uploads: {
    data: [{
      id: number;
      type: string;
    }]
  };
}

export class ServiceUpdateDto {
  data: ServiceUpdateData;

  constructor(data: ServiceUpdateData){
    this.data = {
      id: data.id,
      type: data.type,
      attributes: data.attributes
    };
    if(data.relationships){
      this.data.relationships = data.relationships;
    }
  }
}
