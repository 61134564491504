<nz-layout>
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flows']">Flows</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Edit</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','flows']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24" class="text-center">
        <h1>Edit Flow</h1>
      </div>
    </div>

    <nz-spin nzSimple *ngIf="loading"></nz-spin>

    <nz-row nzJustify="center">
      <nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <form nz-form
              nzLayout="vertical"
              [formGroup]="form"
              (ngSubmit)="onSubmit()">

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24" [nzRequired]="true">Active</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-switch formControlName="active"></nz-switch>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24" [nzRequired]="true">Title</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input formControlName="title" placeholder="Type the flow title"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24" [nzRequired]="true">Platform</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-select formControlName="platform_name" nzPlaceHolder="Select the platform to submit to">
                <nz-option *ngFor="let option of platforms"
                  [nzLabel]="option.label"
                  [nzValue]="option.value">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24">New Ticket Subject</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input formControlName="subject" placeholder="Type the subject here"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24">Label (Used internally to identify the flow)</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input formControlName="label" placeholder="Type the label here"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="24" [nzRequired]="true">Category</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-select formControlName="flow_categories"
                         nzAllowClear
                         nzShowSearch
                         nzPlaceHolder="Select the flow category"
                         nzMode="multiple">
                <nz-option *ngFor="let flowCategory of flowCategories"
                  [nzLabel]="flowCategory.attributes.title"
                  [nzValue]="flowCategory.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-row nzJustify="end" nzGutter="10">
            <nz-col class="align-left">
              <button nz-button
                      nzDanger
                      type="button"
                      nzShape="round"
                      nzSize="large"
                      [nzLoading]="loading"
                      nz-popconfirm
                      nzPopconfirmTitle="Are you sure?"
                      [nzPopconfirmShowArrow]="false"
                      (nzOnConfirm)="delete()">
                Delete
              </button>
            </nz-col>
            <nz-col>
              <button nz-button
                      type="submit"
                      nzType="primary"
                      nzShape="round"
                      nzSize="large"
                      [nzLoading]="loading">
                Submit
              </button>
            </nz-col>
          </nz-row>
        </form>

        <nz-divider></nz-divider>

        <div class="flow-control-list-label">
          <div class="flow-label">Flow Controls <span>(drag and drop to define the controls order)</span></div>
          <div class="add-new-control-container" *ngIf="!flowControlInEdit && permissionFlowControlCreate">
            <button nz-button
                    type="button"
                    nzShape="round"
                    nzSize="default"
                    [nzLoading]="loading"
                    (click)="setShowCreateFlowControlForm(true)">
              Add new control to flow
            </button>
          </div>
        </div>

        <ngx-sortable [items]="flowControls"
                      [name]="'List'"
                      [showHeader]="false"
                      [arrowKeySort]="false"
                      [listStyle]="{width: '100%', border: 'none'}"
                      (dropped)="flowControlChangedPosition($event)">
          <ng-template let-item="item">
              <div class="sortable-list-item flow-control-container">
                <ng-container *ngIf="flowControlInEdit?.id != item.id">
                  <div nz-col [nzSpan]="22">
                    <div nz-row>
                      <div nz-col [nzSpan]="8">Label</div>
                      <div nz-col [nzSpan]="14">{{ item.attributes.label }}</div>
                    </div>
                    <div nz-row>
                      <div nz-col [nzSpan]="8">Type</div>
                      <div nz-col [nzSpan]="14">{{ item.attributes.input_type }}</div>
                    </div>
                    <div nz-row>
                      <div nz-col [nzSpan]="8">Name</div>
                      <div nz-col [nzSpan]="14">{{ item.attributes.name || '-' }}</div>
                    </div>
                    <div nz-row>
                      <div nz-col [nzSpan]="8">Placeholder</div>
                      <div nz-col [nzSpan]="14">{{ item.attributes.placeholder || '-' }}</div>
                    </div>
                    <div nz-row>
                      <div nz-col [nzSpan]="8">Required</div>
                      <div nz-col [nzSpan]="14">{{ item.attributes.required === true ? 'Yes' : 'No' }}</div>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="2" class="flow-control-edit" (click)="setFlowControlInEdit(item)" *ngIf="permissionFlowControlUpdate">
                    Edit
                    <span nz-icon nzType="right" nzTheme="outline"></span>
                  </div>
                </ng-container>
                <ng-container *ngIf="flowControlInEdit?.id == item.id">
                  <div nz-col [nzSpan]="24">
                    <app-flow-control-form *ngIf="flowControlInEdit"
                      [flow]="flow"
                      [flowControl]="item"
                      (formClose)="setFlowControlInEdit(undefined)"
                      (formSubmit)="getFlow(flow.id)"
                    />
                  </div>
                </ng-container>
              </div>
          </ng-template>
        </ngx-sortable>

        <div class="flow-control-container add-new" *ngIf="showCreateFlowControlForm">
          <app-flow-control-form [flow]="flow"
                                 [flowControl]="undefined"
                                 (formClose)="setShowCreateFlowControlForm(false)"
                                 (formSubmit)="getFlow(flow.id)"
          />
        </div>
      </nz-col>
    </nz-row>
  </nz-content>
</nz-layout>
