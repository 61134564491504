import { ObjectData } from "../objectData";
import { AssetResourceAttributes } from "./assetResourceAttributes";
import { AssetResourceRelationships } from "./assetResourceRelationships";
import { RelationshipData } from "../relationshipData";
import { IncludedData } from "../includedData";
import { AccountRelationAttribute } from "./accountRelationAttribute";
import { UploadRelationAttribute } from "./uploadRelationAttribute";
import { StatusRelationAttribute } from "./statusRelationAttribute";
import { TypeRelationAttribute } from "./typeRelationAttribute";
import { ManufacturerRelationAttribute } from "./manufacturerRelationAttribute";
import { TicketRelationAttribute } from "./ticketRelationAttribute";
import { UserRelationAttribute } from "../ticket/userRelationAttribute";
import { KaseyaDetailRelationAttributes } from "./KaseyaDetailRelationAttributes";

export class AssetResource extends ObjectData<AssetResourceAttributes, AssetResourceRelationships> {

  constructor(data: ObjectData<AssetResourceAttributes, AssetResourceRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = AssetResource.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): AssetResourceRelationships {
    let assetResourceRelationships = new AssetResourceRelationships();

    assetResourceRelationships.account = AssetResource.normalizeAccountRelation(relationObject, included);
    assetResourceRelationships.uploads = AssetResource.normalizeUploadRelation(relationObject, included);
    assetResourceRelationships.asset_resource_status = AssetResource.normalizeStatusRelation(relationObject, included);
    assetResourceRelationships.asset_resource_type = AssetResource.normalizeTypeRelation(relationObject, included);
    assetResourceRelationships.asset_resource_manufacturer = AssetResource.normalizeManufacturerRelation(relationObject, included);
    assetResourceRelationships.contact = AssetResource.normalizeUserRelation(relationObject, included, 'contact');
    assetResourceRelationships.managed_by = AssetResource.normalizeUserRelation(relationObject, included, 'managed_by');
    assetResourceRelationships.tickets = AssetResource.normalizeTicketsRelation(relationObject, included);
    assetResourceRelationships.related_asset_resources = AssetResource.normalizeRelatedAssetsRelation(relationObject, included);
    assetResourceRelationships.kaseya_detail = AssetResource.normalizeKaseyaDetailRelation(relationObject, included);

    return assetResourceRelationships;
  }

  private static normalizeAccountRelation(relationObject: any, included: Object[]): RelationshipData<AccountRelationAttribute> | undefined {
    let accountRelation = new RelationshipData<AccountRelationAttribute>();
    if (!relationObject?.account?.data?.id) return undefined;

    accountRelation.id = +relationObject.account.data.id;
    accountRelation.type = relationObject.account.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == accountRelation.type &&
        includedObject.id == accountRelation.id
    });
    accountRelation.attributes = (tmp as IncludedData<AccountRelationAttribute>).attributes

    return accountRelation;
  }

  private static normalizeUploadRelation(relationObject: any, included: Object[]): RelationshipData<UploadRelationAttribute>[] | undefined {
    if (!(relationObject?.uploads?.data?.length > 0)) return undefined;

    return relationObject.uploads.data.map((upload: any) => {
      let uploadRelation = new RelationshipData<UploadRelationAttribute>();

      uploadRelation.id = +upload.id;
      uploadRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == uploadRelation.type &&
          includedObject.id == uploadRelation.id
      });
      uploadRelation.attributes = (tmp as IncludedData<UploadRelationAttribute>).attributes
      return uploadRelation;
    })
  }

  private static normalizeStatusRelation(relationObject: any, included: Object[]): RelationshipData<StatusRelationAttribute> | undefined {
    let statusRelation = new RelationshipData<StatusRelationAttribute>();
    if (!relationObject?.asset_resource_status?.data?.id) return undefined;

    statusRelation.id = +relationObject.asset_resource_status.data.id;
    statusRelation.type = relationObject.asset_resource_status.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == statusRelation.type &&
        includedObject.id == statusRelation.id
    });
    statusRelation.attributes = (tmp as IncludedData<StatusRelationAttribute>).attributes;
    statusRelation.attributes.title = statusRelation.attributes.title.replace('ITGlue-','').replace('Integration - ','');

    return statusRelation;
  }

  private static normalizeTypeRelation(relationObject: any, included: Object[]): RelationshipData<TypeRelationAttribute> | undefined {
    let typeRelation = new RelationshipData<TypeRelationAttribute>();
    if (!relationObject?.asset_resource_type?.data?.id) return undefined;

    typeRelation.id = +relationObject.asset_resource_type.data.id;
    typeRelation.type = relationObject.asset_resource_type.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == typeRelation.type &&
        includedObject.id == typeRelation.id
    });
    typeRelation.attributes = (tmp as IncludedData<TypeRelationAttribute>).attributes;
    typeRelation.attributes.title = typeRelation.attributes.title.replace('ITGlue-','').replace('Integration - ','');

    return typeRelation;
  }

  private static normalizeManufacturerRelation(relationObject: any, included: Object[]): RelationshipData<ManufacturerRelationAttribute> | undefined {
    let manufacturerRelation = new RelationshipData<ManufacturerRelationAttribute>();
    if (!relationObject?.asset_resource_manufacturer?.data?.id) return undefined;

    manufacturerRelation.id = +relationObject.asset_resource_manufacturer.data.id;
    manufacturerRelation.type = relationObject.asset_resource_manufacturer.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == manufacturerRelation.type &&
        includedObject.id == manufacturerRelation.id
    });
    manufacturerRelation.attributes = (tmp as IncludedData<ManufacturerRelationAttribute>).attributes
    manufacturerRelation.attributes.title = manufacturerRelation.attributes.title.replace('ITGlue-','').replace('Integration - ','');

    return manufacturerRelation;
  }

  private static normalizeUserRelation(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttribute> | undefined {
    let userRelation = new RelationshipData<UserRelationAttribute>();
    if (!relationObject[key]?.data?.id) return ;

    userRelation.id = relationObject[key].data.id;
    userRelation.type = relationObject[key].data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == userRelation.type &&
        includedObject.id == userRelation.id
    });
    userRelation.attributes = (tmp as IncludedData<UserRelationAttribute>).attributes

    return userRelation;
  }

  private static normalizeTicketsRelation(relationObject: any, included: Object[]): RelationshipData<TicketRelationAttribute>[] | undefined {
    if (!(relationObject?.tickets?.data?.length > 0)) return undefined;

    return relationObject.tickets.data.map((upload: any) => {
      let ticketRelation = new RelationshipData<TicketRelationAttribute>();

      ticketRelation.id = +upload.id;
      ticketRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == ticketRelation.type &&
          includedObject.id == ticketRelation.id
      });
      ticketRelation.attributes = (tmp as IncludedData<TicketRelationAttribute>).attributes

      return ticketRelation;
    })
  }

  private static normalizeRelatedAssetsRelation(relationObject: any, included: Object[]): RelationshipData<AssetResourceAttributes>[] | undefined {
    if (!(relationObject?.related_asset_resources?.data?.length > 0)) return undefined;

    return relationObject.related_asset_resources.data.map((upload: any) => {
      let assetResourceRelation = new RelationshipData<AssetResourceAttributes>();

      assetResourceRelation.id = +upload.id;
      assetResourceRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == assetResourceRelation.type &&
          includedObject.id == assetResourceRelation.id
      });
      assetResourceRelation.attributes = (tmp as IncludedData<AssetResourceAttributes>).attributes

      return assetResourceRelation;
    })
  }

  private static normalizeKaseyaDetailRelation(relationObject: any, included: Object[]): RelationshipData<KaseyaDetailRelationAttributes> | undefined {
    if (!relationObject?.kaseya_detail?.data?.id) return undefined;

    let relation = new RelationshipData<KaseyaDetailRelationAttributes>();

    relation.id = +relationObject.kaseya_detail.data.id;
    relation.type = relationObject.kaseya_detail.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == relation.type &&
        includedObject.id == relation.id
    });
    relation.attributes = (tmp as IncludedData<KaseyaDetailRelationAttributes>).attributes

    return relation;
  }
}
