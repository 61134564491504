export class Feature {
  title_1: string;
  title_2: string;
  description: string;
  image: string;
  image_alt: string;

  constructor(data: any){
    this.title_1 = data.title_1;
    this.title_2 = data.title_2;
    this.description = data.description;
    this.image = data.image;
    this.image_alt = data.image_alt;
  }
}