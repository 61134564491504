import InstalledServiceValue from "./service/installedServiceValue";
import Service from "./service/service";

export interface AccountDataInstalledService {
  id: number;
  title: string;
  reviewed: boolean;
  service: Service | undefined;
  detailMetrics: {
    id: number;
    title: string;
    value: any[],
    count: number
  }[]
}

export class AccountServicesData {
  id: number;
  title: string;
  services_notes: string|null;
  open: boolean;
  changesCount: number;
  loading: boolean;
  dataFetched: boolean;
  installedServices: AccountDataInstalledService[];
  installedServiceValues: InstalledServiceValue[];

  constructor(id: number, title: string) {
    this.id = id;
    this.title = title;
    this.services_notes = null;
    this.open = false;
    this.changesCount = 0;
    this.loading = false;
    this.dataFetched = false;
    this.installedServices = [];
    this.installedServiceValues = [];
  }
}
