import { Component, DestroyRef, OnInit } from "@angular/core";
import { PostsService } from "src/app/services/posts.service";
import { UsersService } from "src/app/services/users.service";
import { UploadsService } from "src/app/services/uploads.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { take } from "rxjs";
import { Post } from "src/app/utilities/models/post/post";
import { NzMessageService } from "ng-zorro-antd/message";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { FormControl, FormGroup } from "@angular/forms";
import FroalaHelper from "src/app/utilities/helpers/froala";
import { PostUpdateDto, PostUpdateDtoAttributes, PostUpdateDtoRelationships } from "src/app/utilities/models/dto/postUpdateDto";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-edit-post',
  templateUrl: './editPost.component.html',
  styleUrls: ['./editPost.component.scss']
})
export class EditPostComponent implements OnInit {
  isUploading = false;
  fileList: NzUploadFile[] = [];
  post: Post;
  permission: Permission | undefined;
  postForm: FormGroup;
  froalaEditorOptions: Object;
  editor: any;
  loading: boolean = false;

  constructor(private postService: PostsService,
              private userService: UsersService,
              private uploadService: UploadsService,
              private msg: NzMessageService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.permission = this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'update');
    this.froalaEditorOptions = this.initializeFroalaEditor();

    this.initForm();
    this.activateRoute.params.subscribe({
        next: (params: Params) => {
          this.getPost(params['id']);
        }
      }
    );
  }

  getPost(id: number) {
    this.postService.getPost(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) =>{
          this.post = new Post(response.data, response.included);
          this.setForm();
        }
      })
  }

  private disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private initForm(){
    this.postForm = new FormGroup({
      'title': new FormControl({ value: '', disabled: this.disableByProperty('title') }),
      'description': new FormControl({ value: '', disabled: this.disableByProperty('description') }),
      'content': new FormControl({ value: '', disabled: this.disableByProperty('content') }),
      'sticky': new FormControl({ value: '', disabled: this.disableByProperty('sticky') }),
    })
  }

  private setForm(){
    this.postForm.get('title')?.setValue(this.post.attributes.title);
    this.postForm.get('description')?.setValue(this.post.attributes.description);
    this.postForm.get('content')?.setValue(this.post.attributes.content);
    this.postForm.get('sticky')?.setValue(this.post.attributes.sticky);
  }

  onBeforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.handleUpload();

    return false;
  };

  private handleUpload() {
    if (this.fileList.length) {
      this.isUploading = true;
      const payload = this.prepareUploadPayload();

      this.uploadService.create(payload)
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            this.fileList = [];
            this.updatePostFeaturedImage(response.data.id);
            this.msg.success('Image was uploaded successfully', { nzDuration: 5000, nzPauseOnHover: true });
          },
          error: ( _ ) => {
            this.msg.success('There was an error, please try again', { nzDuration: 5000, nzPauseOnHover: true });
            this.isUploading = false;
          },
          complete: () => {
            this.isUploading = false;
          }
        });
    }
  }

  private prepareUploadPayload(){
    const payload = new FormData();

    payload.append('used_for', 'featured_image');
    payload.append('uploadable_type', 'Digcore::Post');
    payload.append('uploadable_id', this.post.id.toString());

    // this WILL NOT work: formData.append('uploaded_file', this.fileList[0]);
    this.fileList.forEach((file: any) => {
      payload.append('uploaded_file', file);
    });

    return payload;
  }

  private updatePostFeaturedImage(id: number) {
    const payload:PostUpdateDto = this.prepareUpdateImagePayload(id);
    this.postService.updatePost(payload)
      .subscribe({
        next: _ => {
          this.postService.getPost(this.post.id).subscribe({
            next:(postResponse: any) =>{
              this.post.attributes.featured_image_url = postResponse.data.attributes.featured_image_url;
            }
          })
        }
      })
  }

  private prepareUpdateImagePayload(id: number): PostUpdateDto {
    const postUpdateRelationships:PostUpdateDtoRelationships = {
      uploads:{
        data: [{
          id: id,
          type: 'uploads'
        }]
      }
    }

    return new PostUpdateDto(this.post.id, 'posts', {}, postUpdateRelationships);
  }

  onSubmit() {
    this.loading = true;
    const payload = this.prepareUpdatePayload();

    this.postService.updatePost(payload)
      .subscribe({
        next: () => {
          this.loading = false;
          this.router.navigate(['/app/news/'+this.post.id]).then( _ => console.log('redirect to logins'));
        },
        error:(err) => {
          this.loading = false;
          this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
        }
      });

    return true;
  }

  private prepareUpdatePayload() {
    const postUpdateAttributes:PostUpdateDtoAttributes = {
      title: this.postForm.get('title')?.value,
      description: this.postForm.get('description')?.value,
      content: this.postForm.get('content')?.value,
      sticky: this.postForm.get('sticky')?.value,
    };

    return new PostUpdateDto(this.post.id, 'posts', postUpdateAttributes, undefined);
  }

  private initializeFroalaEditor(): Object {
    return {
      ...FroalaHelper.getConfig({ placeholderText: 'Start typing...' })
    };
  }
}
