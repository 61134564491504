export interface PostCreateDtoRelationships {
  author: { data: { id: number, type: string } };
  account: { data: { id: number, type: string } };
  uploads?: { data: { id: number, type: string }[] };
}

export interface PostCreateDtoAttributes {
  content: string;
  description: string;
  title: string;
  sticky: boolean;
  visible?: boolean;
}

export class PostCreateDto {
  data:{
    type: string;
    attributes: PostCreateDtoAttributes;
    relationships: PostCreateDtoRelationships;
  }

  constructor(type: string, attributes: PostCreateDtoAttributes, relationships: PostCreateDtoRelationships) {
    this.data = {
      type: type,
      attributes: attributes,
      relationships: relationships
    }
  }
}