import { Injectable } from "@angular/core";
import jwt_decode from 'jwt-decode';
import { JwtToken } from "../utilities/models/jwtToken/jwtToken";

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

@Injectable({ providedIn: "root" })
export class TokenService {

  getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  saveToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  getDecodedToken(): JwtToken | null{
    const token: string | null = this.getToken();

    return !token ? null : jwt_decode(token);
  }
}
