export class StaticFilterOption {
  id: number;
  label: string;
  count: number;

  constructor(id: number, label: string, count: number) {
    this.id = id;
    this.label = label;
    this.count = count;
  }
}
