<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'dashboard']">Dashboard</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'account-management']">Account Management</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Billing and Invoice Assistance</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','account-management']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col>
        <h1>Billing and Invoice Assistance</h1>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col [nzXs]="24" [nzSm]="18" [nzMd]="16" [nzLg]="12">
        Need help with a billing or invoicing matter? We’re here to assist you. Please use the form below to submit your inquiry, and our team will respond promptly to resolve any questions or concerns regarding your account. Your satisfaction is our priority.
      </div>
    </div>

    @if (formGroup) {
      <div nz-row>
        <div nz-col [nzXs]="24" [nzSm]="18" [nzMd]="16" [nzLg]="12" class="account-management-form-wrapper">
          <nz-divider></nz-divider>

          <form class="request-change-form"
                nz-form
                nzLayout="vertical"
                [formGroup]="formGroup"
                (ngSubmit)="onSubmit()">
            <nz-form-item class="ticket-create-form-item">
              <nz-form-label nzFor="description" nzRequired>
                Please select
              </nz-form-label>
              <nz-form-control>
                <nz-select [(ngModel)]="selectedFormGroupIndex"
                            [ngModelOptions]="{ standalone: true }">
                  @for (item of formGroupOptions; track $index) {
                    <nz-option [nzValue]="$index" [nzLabel]="item"></nz-option>
                  }
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            @for (field of selectedFormGroup.fields; track field.name) {
              <nz-form-item class="ticket-create-form-item" [ngClass]="'account-management-form-item_' + field.name">
                <nz-form-label [nzFor]="field.name" [nzRequired]="field.required">
                  {{field.label}}
                </nz-form-label>
                <nz-form-control>
                  @switch (field.type) {
                    @case ('boolean') {
                      <nz-switch [formControlName]="field.name" nzCheckedChildren="Yes" nzUnCheckedChildren="No" />
                    }
                    @case ('date') {
                      <nz-date-picker [formControlName]="field.name" />
                    }
                    @case ('number') {
                      <nz-input-number [formControlName]="field.name" />
                    }
                    @case ('text') {
                      <input nz-input [formControlName]="field.name" [name]="field.name" />
                    }
                    @case ('wysiwyg') {
                      <froala-editor [formControlName]="field.name" />
                    }
                    @case ('invoices') {
                      <nz-select [formControlName]="field.name"
                                  [nzLoading]="isFetchingInvoices"
                                  [nzMode]="(field?.multiple) ? 'multiple' : 'default'"
                                  nzShowSearch
                                  nzServerSearch
                                  (nzOnSearch)="onSearchInvoices($event)">
                        @for (invoice of invoices; track invoice.attributes.uniq_number) {
                          <nz-option [nzLabel]="invoice.attributes.uniq_number" [nzValue]="invoice.attributes.uniq_number"></nz-option>
                        }
                      </nz-select>
                    }
                  }
                </nz-form-control>
              </nz-form-item>
            }

            <div class="actions-container">
              <button nz-button
                      type="submit"
                      nzType="primary"
                      nzShape="round"
                      nzSize="large"
                      [disabled]="formGroup && !formGroup.valid">
                Send Request
              </button>
            </div>
          </form>
        </div>
      </div>
    }

  </nz-content>
</nz-layout>

<custom-loader *ngIf="isLoaderVisible"></custom-loader>
