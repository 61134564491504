interface ServiceCreateData{
  type: string;
  attributes: ServiceCreateDataAttributes;
}

export interface ServiceCreateDataAttributes{
  title: string;
  description?: string;
}

export class ServiceCreateDto {
  data: ServiceCreateData;

  constructor(data: ServiceCreateData){
    this.data = {
      type: data.type,
      attributes: data.attributes
    };
  }
}
