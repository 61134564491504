import { TokenService } from "src/app/auth/token.service";

const tokenService = new TokenService();
export default class FroalaHelper{

  public static getConfig(options?: Object){
    const config: Object = {
      key: "FSC2H-9B1A2B3B2D3C1B1sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2F1E4G1B4B8D7B5==",
      attribution: false,
      charCounterCount: false,
      toolbarButtons:['bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertTable', 'html']
      ,
      quickInsertTags: [],
      ...options || {},
    }

    return config;
  }
}