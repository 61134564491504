import { Component, DestroyRef, effect, model, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { ServicesService } from 'src/app/services/services.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { AccountManagementFormGroup } from 'src/app/utilities/models/accountManagement/forms';
import InstalledService from 'src/app/utilities/models/service/installedService';
import Service from 'src/app/utilities/models/service/service';
import { User } from 'src/app/utilities/models/user/user';
import { TicketCreateDto, TicketCreateDtoRelationship } from 'src/app/utilities/models/dto/ticketCreateDto';

@Component({
  selector: 'account-management-upgrades',
  templateUrl: './upgrades.component.html',
  styleUrl: './upgrades.component.scss'
})
export class AccountManagementUpgradesComponent implements OnInit {
  formGroup: FormGroup;
  formGroups: AccountManagementFormGroup[] = [
    {
      label: 'Hardware Upgrade (e.g., servers, workstations)',
      fields: [
        {
          label: 'Type of Hardware Needed (e.g., Server, Workstation, Network Device)',
          name: 'hardware_type',
          required: true,
          type: 'text',
        },
        {
          label: 'Quantity Required',
          name: 'quantity',
          required: true,
          type: 'text',
        },
        {
          label: 'Preferred Brand or Specifications',
          name: 'preferences',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for Upgrade',
          name: 'reason_for_upgrade',
          required: true,
          type: 'text',
        },
        {
          label: 'Desired Installation Date',
          name: 'installation_date',
          required: true,
          type: 'date',
        }
      ]
    },
    {
      label: 'Software Update or New Installation',
      fields: [
        {
          label: 'Type of Software Needed (e.g., Office Suite, Security Software)',
          name: 'software_type',
          required: true,
          type: 'text',
        },
        {
          label: 'Number of Licenses Required',
          name: 'number_of_licences',
          required: true,
          type: 'number',
        },
        {
          label: 'Reason for Update or New Installation',
          name: 'reason',
          required: true,
          type: 'text',
        },
        {
          label: 'Preferred Installation Date',
          name: 'installation_date',
          required: true,
          type: 'date',
        }
      ]
    },
    {
      label: 'Cloud Service Enhancement',
      fields: [
        {
          label: 'Type of Enhancement Needed (e.g., Storage Increase, Security Upgrade)',
          name: 'type_of_enhancement',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for Enhancement',
          name: 'enhancement_reason',
          required: true,
          type: 'text',
        },
        {
          label: 'Desired Start Date',
          name: 'start_date',
          required: true,
          type: 'date',
        }
      ]
    },
    {
      label: 'Network Infrastructure Improvement',
      fields: [
        {
          label: 'Type of Improvement Needed (e.g., Bandwidth Increase, Network Redesign)',
          name: 'type_of_improvement',
          required: true,
          type: 'text',
        },
        {
          label: 'Number of Locations Affected',
          name: 'number_of_locations',
          required: true,
          type: 'number',
        },
        {
          label: 'Reason for Improvement',
          name: 'improvement_reason',
          required: true,
          type: 'text',
        },
        {
          label: 'Preferred Implementation Date',
          name: 'implementation_date',
          required: true,
          type: 'date',
        }
      ]
    },
    {
      label: 'Cybersecurity Upgrade',
      fields: [
        {
          label: 'Type of cybersecurity upgrade (e.g, EDR, MDR, Security Awareness, Penetration Testing)',
          name: 'type_of_upgrade',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for Upgrade',
          name: 'reason_for_upgrade',
          required: true,
          type: 'text',
        },
        {
          label: 'Desired Installation Date',
          name: 'installation_date',
          required: true,
          type: 'date',
        }
      ]
    },
    {
      label: 'Add New Services',
      fields: [
        {
          label: 'Services',
          multiple: true,
          name: 'services',
          required: true,
          type: 'services',
        }
      ]
    }
  ];
  formGroupOptions: string[] = [];
  isFetchingServices: boolean = false;
  isLoaderVisible: boolean = false;
  loggedInUser: User;
  selectedFormGroupIndex = model<number>(0);
  selectedFormGroup: AccountManagementFormGroup;
  services: Service[] = [];
  installedServices: InstalledService[] = [];
  addonServices: Service[] = [];

  constructor(private destroyRef: DestroyRef,
              private router: Router,
              private loaderService: LoaderService,
              private servicesService: ServicesService,
              private ticketService: TicketsService,
              private userService: UsersService)
  {
    effect(() => {
      this.initFormGroup();
    });
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });

    this.formGroupOptions = this.formGroups.map((item) => item.label);
  }

  onSubmit() {
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your request is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your request was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: TicketCreateDto = this.preparePayload();
    this.ticketService.createTicket(payload).subscribe({
      next: (response: any) => {
        this.formGroup.reset();
        this.loaderService.setProcessing(false);

        setTimeout(() => {
          this.loaderService.setLoaderVisible(false);
          if (response.data.attributes.code) {
            this.router
              .navigate(['/app', 'support', 'tickets', response.data.attributes.code])
              .then();
          }
        }, 2000);
      }
    });
  }

  private getInstalledServices() {
    this.isFetchingServices = true;
    this.servicesService.getInstalledServices()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.installedServices = response?.data?.map((bsData: any) => new InstalledService(bsData, response.included)) ?? [];
          this.isFetchingServices = false;
        },
        error: (error) => {
          console.error(error);
          this.isFetchingServices = false;
        }
      });
  }

  private getServices() {
    this.isFetchingServices = true;
    this.servicesService.getServices(1000,1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          const services = response.data.map((bsData: any) => new Service(bsData, response.included));
          this.servicesService.setServices(services);
          this.isFetchingServices = false;
        },
        error: (error) => {
          console.error(error);
          this.isFetchingServices = false;
        }
      })
  }

  private initFormGroup() {
    let hasServices = false;
    this.formGroup = new FormGroup({});

    this.selectedFormGroup = this.formGroups[this.selectedFormGroupIndex()];
    this.selectedFormGroup?.fields?.forEach((field: any) => {
      let initialValue: any = undefined;

      switch (field.type) {
        case 'boolean': initialValue = false; break;
        case 'date': initialValue = new Date(); break;
        case 'number': initialValue = 1; break;
        case 'text':
        case 'wysiwyg': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      this.formGroup.addControl(field.name, formControl);

      if (field.type == 'services') {
        hasServices = true;
      }
    });

    this.formGroup.updateValueAndValidity();

    if (hasServices && !this.services.length) {
      this.getInstalledServices();
      this.populateServices();
    }
  }

  private populateAddonServices() {
    const ids: number[] = [];
    this.installedServices.forEach((is: InstalledService) => {
      if (is.relationships?.service?.id) {
        ids.push(is.relationships?.service?.id)
      }
    });

    this.addonServices = this.services.filter((service: Service) => !ids.includes(service.id) && !service.attributes.system_key);
  }

  private populateServices() {
    this.isFetchingServices = true;
    this.servicesService.services$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (services: Service[]) => {
          if (!services.length) {
            this.getServices();
            this.isFetchingServices = false;
          } else {
            this.services = services;
            this.populateAddonServices();
            this.isFetchingServices = false;
          }
        }
      });
  }

  private prepareData(type: string, valueId: number) {
    return { data: { id: valueId, type: type }};
  }

  private preparePayload(): TicketCreateDto {
    const subject = 'Upgrades';
    let description = `<p>${this.selectedFormGroup.label}</p><br><ul>`;

    Object.keys(this.formGroup.value).forEach((key: string) => {
      const field = this.selectedFormGroup?.fields.find((field) => field.name == key);

      if (field) {
        let value = this.formGroup.value[key];

        if (value != null && typeof value !== undefined) {
          if (field.type == 'boolean') {
            value = (value == true) ? 'Yes' : 'No';
          }

          if (field.type == 'services') {
            value = value.join(', ');
          }

          description += `<li><b>${field.label}</b>: ${value}</li>`;
        }
      }
    });
    description += '<ul>';

    const ticketCreateAttributes = { subject, description };
    const ticketCreateRelationships: TicketCreateDtoRelationship = {
      account: this.prepareData('accounts', this.loggedInUser!.relationships!.account!.id),
      requester: this.prepareData('users', this.loggedInUser.id),
    };

    return new TicketCreateDto('tickets', ticketCreateAttributes, ticketCreateRelationships);
  }
}
