import { Component, DestroyRef, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { User } from 'src/app/utilities/models/user/user';
import { UsersService } from 'src/app/services/users.service';
import { TfaEnable } from 'src/app/utilities/models/user/tfaEnable';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'twoFactorAuthentication',
  templateUrl: './twoFactorAuthentication.component.html',
  styleUrls: ['./twoFactorAuthentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  user: User;
  tfaFormGroup: FormGroup;
  tfaEnable: TfaEnable;
  loading: boolean = false;

  constructor(private msg: NzMessageService,
              private modal: NzModalRef,
              private userService: UsersService,
              private destroyRef: DestroyRef) {

  }

  ngOnInit(): void {
    this.user = this.userService.loggedInUser;

    this.tfaFormGroup = new FormGroup({
      'otp_enabled': new FormControl<boolean>(!!this.user.attributes.otp_enabled),
      'tfa_code': new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern('[0-9]{6}'),
      ]),
    });

    this.onTwoFAformChanges();
  }

  otpEnabledLabel() {
    return (!this.tfaFormGroup.get('otp_enabled')?.value) ? 'Deactivate TFA' : 'Activate TFA';
  }

  secret() {
    const regex = new RegExp(/(?:.*secret=)(.*)(?:&.*)/gi);
    const secret = regex.exec(this.tfaEnable.attributes.provition_url);

    if (Array.isArray(secret) && secret.length) {
      return secret[1];
    } else {
      return undefined;
    }
  }

  onCloseModal() {
    this.modal.destroy();
  }

  onSubmit() {
    const tfa_code = this.tfaFormGroup.get('tfa_code');
    const otp_enabled = this.tfaFormGroup.get('otp_enabled');

    if (otp_enabled?.value && !tfa_code?.valid) {
      this.msg.error('Please enter a valid TFA code', {
        nzDuration: 5000,
        nzPauseOnHover: true
      });

      return false;
    }

    if(otp_enabled?.value == true && tfa_code?.valid){
      this.loading = true;
      const payload = this.prepareEnableTfaPayload();
      this.userService.enableTFA(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: _ => {
            this.loading = false;
            this.onCloseModal();
          },
          error: _ => {
            this.loading = false;
            this.msg.error('Your request failed due to an error, please try again', {
              nzDuration: 5000,
              nzPauseOnHover: true
            });
          }
        });
    }

    if(otp_enabled?.value == false){
      this.loading = true;
      const payload = this.prepareDisableTfaPayload();
      this.userService.disableTFA(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: _ => {
            this.loading = false;
            this.modal.destroy();
          },
          error: _ => {
            this.loading = false;
            this.msg.error('Your request failed due to an error, please try again', {
              nzDuration: 5000,
              nzPauseOnHover: true
            });
          }
        });
    }

    return true;
  }

  private prepareEnableTfaPayload(){
    return {
      tfa_code: this.tfaFormGroup.get('tfa_code')?.value
    }
  }

  private prepareDisableTfaPayload(){
    return {
      data:{
        id: this.user.id,
        type:'users',
        attributes:{
          otp_enabled: this.tfaFormGroup.get('otp_enabled')?.value
        }
      }
    }
  }

  private onTwoFAformChanges(): void {
    this.tfaFormGroup.get('otp_enabled')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((otp_enabled) => {
        if (otp_enabled === true && this.user.attributes?.otp_enabled === false) {
          this.userService.getProvisionURL().subscribe({
            next: (response: any) => {
              if (response?.data?.[0]?.attributes?.provition_url) {
                this.tfaEnable = new TfaEnable(response?.data[0]);
              } else {
                this.msg.error('There was an error, please try again', {
                  nzDuration: 5000,
                  nzPauseOnHover: true
                });
              }
            },
          })
        }
      })
  }
}
