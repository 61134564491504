import { Injectable } from "@angular/core";
import { of } from "rxjs";

@Injectable({ providedIn: "root" })
export class FeaturesService {

  constructor() {
  }

  getFeatures() {
    return of([
      {
        "title_1": "Improved",
        "title_2": "Support",
        "description": "See the status of your latest tickets, request callbacks, escalate tickets, and even close tickets on your own time directly from EMPIST 360.",
        "image": "../../../assets/images/icon_improved-support.png",
        "image_alt": "Improved Support",
      },{
        "title_1": "Accessible",
        "title_2": "Scheduling",
        "description": "Put an end to inefficient ping-ponging with in-platform meeting scheduling.",
        "image": "../../../assets/images/icon_accessible-scheduling.png",
        "image_alt": "Accessible Scheduling",
      },{
        "title_1": "Anywhere - Anytime",
        "title_2": "Access",
        "description": "Never miss an important message from your technology partner with built-in alerts.",
        "image": "../../../assets/images/icon_anywhere-anytime.png",
        "image_alt": "Anywhere - Anytime Access",
      },{
        "title_1": "Share",
        "title_2": "Information",
        "description": "Staying up-to-date on the latest technology developments from your team.",
        "image": "../../../assets/images/icon_share-information.png",
        "image_alt": "Share Information",
      },{
        "title_1": "Asset",
        "title_2": "Management",
        "description": "Gain oversight into current and new assets as they are updated, not after.",
        "image": "../../../assets/images/icon_asset-management.png",
        "image_alt": "Asset Management",
      },{
        "title_1": "Invoice",
        "title_2": "History",
        "description": "View the status of your billing for seamless invoicing in one central location.",
        "image": "../../../assets/images/icon_invoice-history.png",
        "image_alt": "Invoice History",
      },{
        "title_1": "Service",
        "title_2": "Management",
        "description": "Manage your services and subscriptions directly from the platform.",
        "image": "../../../assets/images/icon_service-management.png",
        "image_alt": "Service Management",
      },{
        "title_1": "Innovative",
        "title_2": "Automation",
        "description": "Solve simple fixes quickly with automated tickets and solutions.",
        "image": "../../../assets/images/icon_innovative-automation.png",
        "image_alt": "Innovative Automation",
      },
    ]);
  }
}