import { ReportParam } from "../parameters/reportParam/reportParam";

export class SavedFilter {
  title: string;
  totalCount: number;
  loading: boolean = true;
  reportParams: ReportParam[];
  optionId?: number;

  constructor(title: string, reportParams: ReportParam[], optionsId?: number) {
    this.title = title;
    this.reportParams = reportParams;
    this.optionId = optionsId;
  }
}