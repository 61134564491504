import { ObjectData } from "../objectData";

interface UploadAttributes{
  content_type: string;
  created_at: string;
  original_filename: string;
  permalink: string;
  title: string;
  token: string;
  updated_at: string;
  uploadable_type: string;
  uploaded_file: {
    thumb: {
      url : string;
    };
    url: string;
  }
  used_for: string;
}

interface UploadRelationships{

}

export class Upload extends ObjectData<UploadAttributes, UploadRelationships>{
  constructor(data: ObjectData<UploadAttributes, UploadRelationships>, included?: Object[]){
    super(data, included);
  }
}