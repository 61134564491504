import { Component, computed, DestroyRef, ElementRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { addMinutes, format } from 'date-fns';
import { TZDate, tzOffset } from '@date-fns/tz';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LoaderService } from 'src/app/services/loader.service';
import { MeetingsService } from 'src/app/services/meetings.service';
import { UsersService } from 'src/app/services/users.service';
import { MeetingUpdateDto } from 'src/app/utilities/models/dto/meetingUpdateDto';
import { Meeting } from 'src/app/utilities/models/meeting/meeting';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { User } from 'src/app/utilities/models/user/user';
import { MeetingComponent } from 'src/app/shared/meeting/meeting-form.component';

interface IMeeting extends Meeting {
  start: string;
  end: string;
}

interface IGroupedMeetings {
  date: string;
  meetings: IMeeting[];
}

@Component({
  selector: 'meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrl: './meetings-list.component.scss'
})
export class MeetingsListComponent implements OnInit {
  accountManager: User | undefined;
  canCreateMeeting: boolean = false;
  isAccountManagerAvailable: boolean = true;
  isCustomLoaderVisible: boolean = false;
  loading: boolean = false;
  loggedInUser: User;
  meetingCreatePermission: Permission | undefined;
  meetings = signal<IMeeting[]>([]);
  userRoleKey: string = '';
  userTimezone: string | undefined;

  constructor(private destroyRef: DestroyRef,
              private elementRef: ElementRef<HTMLElement>,
              private route: ActivatedRoute,
              private modal: NzModalService,
              private notification: NzNotificationService,
              private loaderService: LoaderService,
              private meetingsService: MeetingsService,
              private userService: UsersService) {}

  groupedMeetings = computed(() => {
    return this.meetings().reduce((acc: IGroupedMeetings[], item) => {
      const dateKey = item.attributes.start_time.split('T')[0];
      const existingDay = acc.find((day) => day.date == dateKey);

      if (!existingDay) {
        acc.push({
          date: dateKey,
          meetings: [item]
        });
      } else {
        existingDay.meetings = [
          ...existingDay.meetings,
          item
        ]
      }

      return acc;
    }, [])
  });

  ngOnInit() {
    this.userService.accountManager
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user: User | undefined) => {
          this.accountManager = user;

          this.isAccountManagerAvailable = !!(user?.relationships?.user_panel?.attributes?.meeting_days?.length
            && user?.relationships?.user_panel?.attributes?.meeting_available_from
            && user?.relationships?.user_panel?.attributes?.meeting_available_until);
          }
      });

    this.loggedInUser = this.userService.loggedInUser;
    this.userRoleKey = this.loggedInUser?.relationships?.role?.attributes?.system_key ?? '';
    this.canCreateMeeting = ['admin_contact_role', 'account_admin_contact_role', 'account_agent_role'].includes(this.userRoleKey);

    // get the IANA timezone of the current user, fallback to local
    this.userTimezone = (isNaN(tzOffset((this.loggedInUser.attributes?.time_zone ?? ''), new Date())))
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : this.loggedInUser.attributes.time_zone;

    this.meetingCreatePermission = this.userService.findPermission(
      'Ticketing::Meeting',
      'ticketing/operator/v1/meetings',
      'create'
    );

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isCustomLoaderVisible = value;
        }
      });

    this.getMeetings();
  }

  getMeetings() {
    this.loading = true;
    const now = format(new TZDate(new Date(), this.userTimezone), "yyyy-MM-dd'T'HH:mm:ss");

    this.meetingsService.getList(this.loggedInUser.id, now)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.meetings.set(response?.data?.map((item: any) => {
            const meeting = new Meeting(item, response?.included);
            const startTime = new TZDate(item.attributes.start_time, this.userTimezone);

            return {
              ...meeting,
              start: format(startTime, "HH:mm"),
              end: format(addMinutes(startTime, item.attributes.duration), "HH:mm"),
            }
          }) ?? []);
          this.scrollToSelectedMeeting();
          this.loading = false;
        },
        error: (error) => {
          console.log(error);
          this.loading = false;
        }
      });
  }

  onCancelEvent(eventId: number | string) {
    const payload = new MeetingUpdateDto(eventId, { status: 'contact_cancelled' });

    this.modal.confirm({
      nzTitle: 'Cancel event',
      nzContent: 'Please confirm if you want to cancel this event. A notification will be sent to all participants.',
      nzCancelText: 'Cancel',
      nzOkText: 'Confirm',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.loaderService.setLoadingText('Processing your request');
        this.loaderService.setLoadingSecondaryText('');
        this.loaderService.setLoadedText('The event has been canceled!');
        this.loaderService.setLoadedSecondaryText('');
        this.loaderService.setProcessing(true);
        this.loaderService.setLoaderVisible(true);
        this.meetingsService.update(payload)
          .subscribe({
            next: () => {
              this.loaderService.setProcessing(false);
              this.getMeetings();
              setTimeout(() => {
                this.loaderService.setLoaderVisible(false);
              }, 3000)
            },
            error: (error: any) => {
              this.loaderService.setProcessing(false);
              this.loaderService.setLoaderVisible(false);
              this.loaderService.setLoadedText('');
              this.loaderService.setLoadedSecondaryText('');
              this.notification.create(
                'error',
                `Error ${error?.status}`,
                'There was an error canceling this meeting!'
              );
            }
          });
      },
    });
  }


  onCreateEvent() {
    const modal = this.modal.create<MeetingComponent>({
      nzClassName: 'meeting-modal',
      nzClosable: false,
      nzContent: MeetingComponent,
      nzData: {
        requested_agent: this.accountManager,
        requester: this.loggedInUser,
      },
      nzFooter: null,
      nzMask: false,
      nzTitle: '',
      nzWidth: '90vw'
    });

    modal.afterClose.subscribe(() => {
      this.loaderService.setLoadedText('Your meeting was created!');
      this.loaderService.setLoadedSecondaryText('');

      setTimeout(() => {
        this.loaderService.setProcessing(false);
        this.loaderService.setLoaderVisible(false);
      }, 2000);
    });
  }

  private scrollToSelectedMeeting() {
    if (this.route.snapshot.fragment && this.meetings().length) {
      setTimeout(() => {
        const el = this.elementRef.nativeElement.querySelector(`#meeting_${this.route.snapshot.fragment}`);

        if (el) {
          el.classList.add('pulse');

          setTimeout(() => {
            el.classList.remove('pulse');
          }, 5000);

          try {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          } catch (error) {
            el.scrollIntoView(false);
          }
        }
      }, 0);
    }
  }
}
