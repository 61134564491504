import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CommentCreateDto } from "src/app/utilities/models/dto/commentCreateDto";

@Injectable({ providedIn: "root" })
export class CommentsService {
  constructor(private http: HttpClient) {
  }

  getComments(conversationId: number) {
    const requestUrl = environment.api_url + 'operator/v1/comments';

    let requestParams = {
      "q[conversation_id_eq]": conversationId,
      version: 'thumb',
      "page[per]": 1000,
      include: "participants,comment_participants,author,uploads",
      sort: 'updated_at'
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  createComment(payload: CommentCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/comments';

    return this.http.post<any>(requestUrl, payload);
  }
}