<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <div breadcrumb>
        <ng-content select="breadcrumbs"></ng-content>
      </div>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <ng-content select="back-button"></ng-content>
      </div>
    </div>

    <div class="page-title">
      <span>&nbsp;</span>
      <h1>Meeting List</h1>
      <div>
        @if (meetingCreatePermission && accountManager && isAccountManagerAvailable && canCreateMeeting) {
          <button nz-button nzShape="round" class="btn-add-meeting" (click)="onCreateEvent()">
            <span nz-icon nzType="plus"></span>
            Add meeting
          </button>
        }
      </div>
    </div>

    <div nz-row>
      <div nz-col class="meetings-wrapper text-center">
        This page displays all your meeting requests, allowing you to track the status of each meeting, whether it’s pending or confirmed.<br>For confirmed meetings, you’ll also find additional details like the meeting link provided by the selected agent.
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" class="meetings-wrapper">
        <nz-divider></nz-divider>
        <div nz-row nzJustify="center" *ngIf="loading">
          <div nz-col nzSpan="24" class="text-center">
            <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
          </div>
        </div>

        <div nz-row nzJustify="center" *ngIf="!meetings().length && !loading">
          <div nz-col nzSpan="24" class="text-center meetings-is-empty">
            <nz-empty nzNotFoundImage="simple"
                      [nzNotFoundContent]="notFoundContentTpl"
                      [nzNotFoundFooter]="notFoundFooterTpl"></nz-empty>

            <ng-template #notFoundContentTpl>
              You have no scheduled meetings here.
            </ng-template>

            <ng-template #notFoundFooterTpl>
              @if (meetingCreatePermission && accountManager && isAccountManagerAvailable && canCreateMeeting) {
                <button nz-button nzType="link" (click)="onCreateEvent()">
                  Create your first meeting
                </button>
              }
            </ng-template>
          </div>
        </div>

        @if (meetings().length && !loading) {
          @for (day of groupedMeetings(); track day.date) {
            <div class="meetings-group">
              <p>{{ day.date|date:'fullDate' }}</p>

              <ul class="meetings-list">
                <li *ngFor="let meeting of day.meetings"
                    class="meetings-list-item"
                    [ngClass]="{
                      'is-canceled': meeting.attributes.status && ['agent_cancelled', 'contact_cancelled'].includes(meeting.attributes.status),
                      'is-confirmed': meeting.attributes.status == 'confirmed',
                      'is-pending': meeting.attributes.status == 'pending',
                    }"
                    [id]="'meeting_' + meeting.id">
                  <div class="meetings-list-item-details">
                    <div class="summary">
                      <span>{{ meeting.start }}</span>
                      <span>{{ meeting.end }}</span>
                    </div>

                    <div class="separator"></div>

                    <div class="text">
                      @switch (meeting.attributes.status) {
                        @case ("confirmed") {
                          @if (meeting.attributes.media_type == 'web' && !!(meeting.attributes.media_details)) {
                            <nz-alert nzMessage="Your meeting request has been confirmed by the selected agent. You’re all set!"
                                      [nzCloseable]="false"
                                      class="is-confirmed"></nz-alert>
                          } @else {
                            <nz-alert nzMessage="Your meeting request has been confirmed by the selected agent. A meeting url will be provided for the requested time."
                                      [nzCloseable]="false"
                                      class="is-confirmed"></nz-alert>
                          }
                        }
                        @case ("pending") {
                          <nz-alert nzMessage="Your meeting request is currently pending confirmation from the selected agent. Once the agent confirms, the meeting will be accepted, and you'll receive a meeting link."
                                    [nzCloseable]="false"
                                    class="is-pending"></nz-alert>
                        }
                      }

                      <div *ngIf="meeting.attributes.description" [innerHTML]="meeting.attributes.description"></div>

                      @if (meeting?.relationships?.ticket?.id) {
                        <p class="ticket">
                          Ticket: <a [routerLink]="['/app','support', 'tickets', meeting?.relationships?.ticket?.attributes?.code]"><strong>{{meeting?.relationships?.ticket?.attributes?.subject}}</strong></a>
                        </p>
                      }
                    </div>

                    <div class="status">
                      <span>
                        @switch (meeting.attributes.status) {
                          @case ("confirmed") {
                            Confirmed
                          }
                          @case ("agent_cancelled") {
                            Cancelled
                          }
                          @case ("contact_cancelled") {
                            Cancelled
                          }
                          @default {
                            Pending
                          }
                        }
                      </span>
                    </div>

                    <div class="actions">
                      <div>
                        <p>
                          @if (meeting.attributes.media_type == 'phone') {
                            <span nz-icon nzType="phone"></span>
                            {{ meeting.attributes.media_details }}
                          } @else {
                            @if (meeting.attributes.media_details) {
                              <a [href]="meeting.attributes.media_details" target="_blank">
                                <span nz-icon nzType="video-camera"></span>
                                {{ meeting.attributes.media_details }}
                              </a>
                            } @else {
                              <span>-</span>
                            }
                          }
                          </p>
                      </div>

                      <div>
                        @if (meeting.attributes.status && ['confirmed', 'pending'].includes(meeting.attributes.status)) {
                          <button nz-button nzDanger nzType="text" (click)="onCancelEvent(meeting.id)">
                            Cancel meeting
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          }
        }
      </div>
    </div>

    <custom-loader *ngIf="isCustomLoaderVisible"></custom-loader>
  </nz-content>
</nz-layout>
