export interface TicketCreateDtoRelationship {
  account: { data: { id: number, type: string } };
  requester: { data: { id: number, type: string } };
  issue_type?: { data: { id: number, type: string } };
  request_type?: { data: { id: number, type: string } };
  queue?: { data: { id: number, type: string } };
}

interface TicketCreateDtoAttributes {
  subject: string;
  description: string;
}

export class TicketCreateDto {
  data:{
    type: string;
    attributes: TicketCreateDtoAttributes;
    relationships: TicketCreateDtoRelationship;
  }

  constructor(type: string, attributes: TicketCreateDtoAttributes, relationships: TicketCreateDtoRelationship) {
    this.data = {
      type: type,
      attributes: attributes,
      relationships: relationships
    }
  }
}
