<nz-row nzJustify="center">
  <nz-col nzSpan="24">
    <h1 nz-typography>Notification Preferences</h1>
    <form nz-form
          [formGroup]="userPreferencesForm">
      <nz-row class="preferences-form-content">
        <nz-col nzSpan="12" [nzXs]="24" [nzSm]="12">
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Receive Emails</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_receive_emails">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Receive Surveys</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_receive_survey">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="12" [nzXs]="24" [nzSm]="12">
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Receive Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_notifications">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Comment Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_notifications_comment">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Ticket Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_notifications_ticket">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Reminder Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_notifications_reminder">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Vote Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_notifications_vote">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Change Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_change_log_subscription">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ant-row-center">
            <nz-form-label [nzSpan]="18">Incident Notifications</nz-form-label>
            <nz-form-control [nzSpan]="6">
              <nz-switch formControlName="pref_incident_subscription">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </form>
  </nz-col>
</nz-row>
