interface InstalledServiceCreateData {
  type: string;
  attributes: InstalledServiceCreateDataAttributes;
  relationships: InstalledServiceCreateDataRelationships;
}

export interface InstalledServiceCreateDataAttributes {
}

export interface InstalledServiceCreateDataRelationships {
  account: { data: { id: number; type: string; } };
  service: { data: { id: number; type: string; } };
}

export class InstalledServiceCreateDto {
  data: InstalledServiceCreateData;

  constructor(data: InstalledServiceCreateData) {
    this.data = {
      type: data.type,
      attributes: data.attributes,
      relationships: data.relationships
    };
  }
}
