import { Injectable } from "@angular/core";

@Injectable()
export class CountryCodesWithFlag {
  public allCountries = {
    "AF": {
      "name": "Afghanistan",
      "region": "Asia",
      "timezones": {
        "Asia\/Kabul": "+04:30"
      },
      "iso": {
        "alpha-2": "AF",
        "alpha-3": "AFG",
        "numeric": "004"
      },
      "phone": [
        "+93"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AF.svg"
    },
    "AL": {
      "name": "Albania",
      "region": "Europe",
      "timezones": {
        "Europe\/Tirane": "+02:00"
      },
      "iso": {
        "alpha-2": "AL",
        "alpha-3": "ALB",
        "numeric": "008"
      },
      "phone": [
        "+355"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AL.svg"
    },
    "DZ": {
      "name": "Algeria",
      "region": "Africa",
      "timezones": {
        "Africa\/Algiers": "+01:00"
      },
      "iso": {
        "alpha-2": "DZ",
        "alpha-3": "DZA",
        "numeric": "012"
      },
      "phone": [
        "+213"
      ],
      "emoji": "\ud83c\udde9\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/DZ.svg"
    },
    "AS": {
      "name": "American Samoa",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Pago_Pago": "-11:00"
      },
      "iso": {
        "alpha-2": "AS",
        "alpha-3": "ASM",
        "numeric": "016"
      },
      "phone": [
        "+1-684"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AS.svg"
    },
    "AD": {
      "name": "Andorra",
      "region": "Europe",
      "timezones": {
        "Europe\/Andorra": "+02:00"
      },
      "iso": {
        "alpha-2": "AD",
        "alpha-3": "AND",
        "numeric": "020"
      },
      "phone": [
        "+376"
      ],
      "emoji": "\ud83c\udde6\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AD.svg"
    },
    "AO": {
      "name": "Angola",
      "region": "Africa",
      "timezones": {
        "Africa\/Luanda": "+01:00"
      },
      "iso": {
        "alpha-2": "AO",
        "alpha-3": "AGO",
        "numeric": "024"
      },
      "phone": [
        "+244"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AO.svg"
    },
    "AI": {
      "name": "Anguilla",
      "region": "Americas",
      "timezones": {
        "America\/Anguilla": "-04:00"
      },
      "iso": {
        "alpha-2": "AI",
        "alpha-3": "AIA",
        "numeric": "660"
      },
      "phone": [
        "+1-264"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AI.svg"
    },
    "AQ": {
      "name": "Antarctica",
      "region": "",
      "timezones": {
        "Antarctica\/Casey": "+11:00",
        "Antarctica\/Davis": "+07:00",
        "Antarctica\/DumontDUrville": "+10:00",
        "Antarctica\/Mawson": "+05:00",
        "Antarctica\/McMurdo": "+12:00",
        "Antarctica\/Palmer": "-03:00",
        "Antarctica\/Rothera": "-03:00",
        "Antarctica\/Syowa": "+03:00",
        "Antarctica\/Troll": "+02:00",
        "Antarctica\/Vostok": "+06:00"
      },
      "iso": {
        "alpha-2": "AQ",
        "alpha-3": "ATA",
        "numeric": "010"
      },
      "phone": [
        "+"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AQ.svg"
    },
    "AG": {
      "name": "Antigua and Barbuda",
      "region": "Americas",
      "timezones": {
        "America\/Antigua": "-04:00"
      },
      "iso": {
        "alpha-2": "AG",
        "alpha-3": "ATG",
        "numeric": "028"
      },
      "phone": [
        "+1-268"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AG.svg"
    },
    "AR": {
      "name": "Argentina",
      "region": "Americas",
      "timezones": {
        "America\/Argentina\/Buenos_Aires": "-03:00",
        "America\/Argentina\/Catamarca": "-03:00",
        "America\/Argentina\/Cordoba": "-03:00",
        "America\/Argentina\/Jujuy": "-03:00",
        "America\/Argentina\/La_Rioja": "-03:00",
        "America\/Argentina\/Mendoza": "-03:00",
        "America\/Argentina\/Rio_Gallegos": "-03:00",
        "America\/Argentina\/Salta": "-03:00",
        "America\/Argentina\/San_Juan": "-03:00",
        "America\/Argentina\/San_Luis": "-03:00",
        "America\/Argentina\/Tucuman": "-03:00",
        "America\/Argentina\/Ushuaia": "-03:00"
      },
      "iso": {
        "alpha-2": "AR",
        "alpha-3": "ARG",
        "numeric": "032"
      },
      "phone": [
        "+54"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AR.svg"
    },
    "AM": {
      "name": "Armenia",
      "region": "Asia",
      "timezones": {
        "Asia\/Yerevan": "+04:00"
      },
      "iso": {
        "alpha-2": "AM",
        "alpha-3": "ARM",
        "numeric": "051"
      },
      "phone": [
        "+374"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AM.svg"
    },
    "AW": {
      "name": "Aruba",
      "region": "Americas",
      "timezones": {
        "America\/Aruba": "-04:00"
      },
      "iso": {
        "alpha-2": "AW",
        "alpha-3": "ABW",
        "numeric": "533"
      },
      "phone": [
        "+297"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AW.svg"
    },
    "AU": {
      "name": "Australia",
      "region": "Oceania",
      "timezones": {
        "Antarctica\/Macquarie": "+10:00",
        "Australia\/Adelaide": "+09:30",
        "Australia\/Brisbane": "+10:00",
        "Australia\/Broken_Hill": "+09:30",
        "Australia\/Darwin": "+09:30",
        "Australia\/Eucla": "+08:45",
        "Australia\/Hobart": "+10:00",
        "Australia\/Lindeman": "+10:00",
        "Australia\/Lord_Howe": "+10:30",
        "Australia\/Melbourne": "+10:00",
        "Australia\/Perth": "+08:00",
        "Australia\/Sydney": "+10:00"
      },
      "iso": {
        "alpha-2": "AU",
        "alpha-3": "AUS",
        "numeric": "036"
      },
      "phone": [
        "+61"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AU.svg"
    },
    "AT": {
      "name": "Austria",
      "region": "Europe",
      "timezones": {
        "Europe\/Vienna": "+02:00"
      },
      "iso": {
        "alpha-2": "AT",
        "alpha-3": "AUT",
        "numeric": "040"
      },
      "phone": [
        "+43"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AT.svg"
    },
    "AZ": {
      "name": "Azerbaijan",
      "region": "Asia",
      "timezones": {
        "Asia\/Baku": "+04:00"
      },
      "iso": {
        "alpha-2": "AZ",
        "alpha-3": "AZE",
        "numeric": "031"
      },
      "phone": [
        "+994"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AZ.svg"
    },
    "BS": {
      "name": "Bahamas",
      "region": "Americas",
      "timezones": {
        "America\/Nassau": "-04:00"
      },
      "iso": {
        "alpha-2": "BS",
        "alpha-3": "BHS",
        "numeric": "044"
      },
      "phone": [
        "+1-242"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BS.svg"
    },
    "BH": {
      "name": "Bahrain",
      "region": "Asia",
      "timezones": {
        "Asia\/Bahrain": "+03:00"
      },
      "iso": {
        "alpha-2": "BH",
        "alpha-3": "BHR",
        "numeric": "048"
      },
      "phone": [
        "+973"
      ],
      "emoji": "\ud83c\udde7\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BH.svg"
    },
    "BD": {
      "name": "Bangladesh",
      "region": "Asia",
      "timezones": {
        "Asia\/Dhaka": "+06:00"
      },
      "iso": {
        "alpha-2": "BD",
        "alpha-3": "BGD",
        "numeric": "050"
      },
      "phone": [
        "+880"
      ],
      "emoji": "\ud83c\udde7\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BD.svg"
    },
    "BB": {
      "name": "Barbados",
      "region": "Americas",
      "timezones": {
        "America\/Barbados": "-04:00"
      },
      "iso": {
        "alpha-2": "BB",
        "alpha-3": "BRB",
        "numeric": "052"
      },
      "phone": [
        "+1-246"
      ],
      "emoji": "\ud83c\udde7\ud83c\udde7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BB.svg"
    },
    "BY": {
      "name": "Belarus",
      "region": "Europe",
      "timezones": {
        "Europe\/Minsk": "+03:00"
      },
      "iso": {
        "alpha-2": "BY",
        "alpha-3": "BLR",
        "numeric": "112"
      },
      "phone": [
        "+375"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BY.svg"
    },
    "BE": {
      "name": "Belgium",
      "region": "Europe",
      "timezones": {
        "Europe\/Brussels": "+02:00"
      },
      "iso": {
        "alpha-2": "BE",
        "alpha-3": "BEL",
        "numeric": "056"
      },
      "phone": [
        "+32"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BE.svg"
    },
    "BZ": {
      "name": "Belize",
      "region": "Americas",
      "timezones": {
        "America\/Belize": "-06:00"
      },
      "iso": {
        "alpha-2": "BZ",
        "alpha-3": "BLZ",
        "numeric": "084"
      },
      "phone": [
        "+501"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BZ.svg"
    },
    "BJ": {
      "name": "Benin",
      "region": "Africa",
      "timezones": {
        "Africa\/Porto-Novo": "+01:00"
      },
      "iso": {
        "alpha-2": "BJ",
        "alpha-3": "BEN",
        "numeric": "204"
      },
      "phone": [
        "+229"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddef",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BJ.svg"
    },
    "BM": {
      "name": "Bermuda",
      "region": "Americas",
      "timezones": {
        "Atlantic\/Bermuda": "-03:00"
      },
      "iso": {
        "alpha-2": "BM",
        "alpha-3": "BMU",
        "numeric": "060"
      },
      "phone": [
        "+1-441"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BM.svg"
    },
    "BT": {
      "name": "Bhutan",
      "region": "Asia",
      "timezones": {
        "Asia\/Thimphu": "+06:00"
      },
      "iso": {
        "alpha-2": "BT",
        "alpha-3": "BTN",
        "numeric": "064"
      },
      "phone": [
        "+975"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BT.svg"
    },
    "BO": {
      "name": "Bolivia, Plurinational State of",
      "region": "Americas",
      "timezones": {
        "America\/La_Paz": "-04:00"
      },
      "iso": {
        "alpha-2": "BO",
        "alpha-3": "BOL",
        "numeric": "068"
      },
      "phone": [
        "+591"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BO.svg"
    },
    "BQ": {
      "name": "Bonaire, Sint Eustatius and Saba",
      "region": "Americas",
      "timezones": {
        "America\/Kralendijk": "-04:00"
      },
      "iso": {
        "alpha-2": "BQ",
        "alpha-3": "BES",
        "numeric": "535"
      },
      "phone": [
        "+599"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BQ.svg"
    },
    "BA": {
      "name": "Bosnia and Herzegovina",
      "region": "Europe",
      "timezones": {
        "Europe\/Sarajevo": "+02:00"
      },
      "iso": {
        "alpha-2": "BA",
        "alpha-3": "BIH",
        "numeric": "070"
      },
      "phone": [
        "+387"
      ],
      "emoji": "\ud83c\udde7\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BA.svg"
    },
    "BW": {
      "name": "Botswana",
      "region": "Africa",
      "timezones": {
        "Africa\/Gaborone": "+02:00"
      },
      "iso": {
        "alpha-2": "BW",
        "alpha-3": "BWA",
        "numeric": "072"
      },
      "phone": [
        "+267"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BW.svg"
    },
    "BR": {
      "name": "Brazil",
      "region": "Americas",
      "timezones": {
        "America\/Araguaina": "-03:00",
        "America\/Bahia": "-03:00",
        "America\/Belem": "-03:00",
        "America\/Boa_Vista": "-04:00",
        "America\/Campo_Grande": "-04:00",
        "America\/Cuiaba": "-04:00",
        "America\/Eirunepe": "-05:00",
        "America\/Fortaleza": "-03:00",
        "America\/Maceio": "-03:00",
        "America\/Manaus": "-04:00",
        "America\/Noronha": "-02:00",
        "America\/Porto_Velho": "-04:00",
        "America\/Recife": "-03:00",
        "America\/Rio_Branco": "-05:00",
        "America\/Santarem": "-03:00",
        "America\/Sao_Paulo": "-03:00"
      },
      "iso": {
        "alpha-2": "BR",
        "alpha-3": "BRA",
        "numeric": "076"
      },
      "phone": [
        "+55"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BR.svg"
    },
    "IO": {
      "name": "British Indian Ocean Territory",
      "region": "Africa",
      "timezones": {
        "Indian\/Chagos": "+06:00"
      },
      "iso": {
        "alpha-2": "IO",
        "alpha-3": "IOT",
        "numeric": "086"
      },
      "phone": [
        "+246"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IO.svg"
    },
    "BN": {
      "name": "Brunei Darussalam",
      "region": "Asia",
      "timezones": {
        "Asia\/Brunei": "+08:00"
      },
      "iso": {
        "alpha-2": "BN",
        "alpha-3": "BRN",
        "numeric": "096"
      },
      "phone": [
        "+673"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BN.svg"
    },
    "BG": {
      "name": "Bulgaria",
      "region": "Europe",
      "timezones": {
        "Europe\/Sofia": "+03:00"
      },
      "iso": {
        "alpha-2": "BG",
        "alpha-3": "BGR",
        "numeric": "100"
      },
      "phone": [
        "+359"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BG.svg"
    },
    "BF": {
      "name": "Burkina Faso",
      "region": "Africa",
      "timezones": {
        "Africa\/Ouagadougou": "+00:00"
      },
      "iso": {
        "alpha-2": "BF",
        "alpha-3": "BFA",
        "numeric": "854"
      },
      "phone": [
        "+226"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BF.svg"
    },
    "BI": {
      "name": "Burundi",
      "region": "Africa",
      "timezones": {
        "Africa\/Bujumbura": "+02:00"
      },
      "iso": {
        "alpha-2": "BI",
        "alpha-3": "BDI",
        "numeric": "108"
      },
      "phone": [
        "+257"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BI.svg"
    },
    "KH": {
      "name": "Cambodia",
      "region": "Asia",
      "timezones": {
        "Asia\/Phnom_Penh": "+07:00"
      },
      "iso": {
        "alpha-2": "KH",
        "alpha-3": "KHM",
        "numeric": "116"
      },
      "phone": [
        "+855"
      ],
      "emoji": "\ud83c\uddf0\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KH.svg"
    },
    "CM": {
      "name": "Cameroon",
      "region": "Africa",
      "timezones": {
        "Africa\/Douala": "+01:00"
      },
      "iso": {
        "alpha-2": "CM",
        "alpha-3": "CMR",
        "numeric": "120"
      },
      "phone": [
        "+237"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CM.svg"
    },
    "CA": {
      "name": "Canada",
      "region": "Americas",
      "timezones": {
        "America\/Atikokan": "-05:00",
        "America\/Blanc-Sablon": "-04:00",
        "America\/Cambridge_Bay": "-06:00",
        "America\/Creston": "-07:00",
        "America\/Dawson": "-07:00",
        "America\/Dawson_Creek": "-07:00",
        "America\/Edmonton": "-06:00",
        "America\/Fort_Nelson": "-07:00",
        "America\/Glace_Bay": "-03:00",
        "America\/Goose_Bay": "-03:00",
        "America\/Halifax": "-03:00",
        "America\/Inuvik": "-06:00",
        "America\/Iqaluit": "-04:00",
        "America\/Moncton": "-03:00",
        "America\/Rankin_Inlet": "-05:00",
        "America\/Regina": "-06:00",
        "America\/Resolute": "-05:00",
        "America\/St_Johns": "-02:30",
        "America\/Swift_Current": "-06:00",
        "America\/Toronto": "-04:00",
        "America\/Vancouver": "-07:00",
        "America\/Whitehorse": "-07:00",
        "America\/Winnipeg": "-05:00"
      },
      "iso": {
        "alpha-2": "CA",
        "alpha-3": "CAN",
        "numeric": "124"
      },
      "phone": [
        "+1"
      ],
      "emoji": "\ud83c\udde8\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CA.svg"
    },
    "CV": {
      "name": "Cape Verde",
      "region": "Africa",
      "timezones": {
        "Atlantic\/Cape_Verde": "-01:00"
      },
      "iso": {
        "alpha-2": "CV",
        "alpha-3": "CPV",
        "numeric": "132"
      },
      "phone": [
        "+238"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddfb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CV.svg"
    },
    "KY": {
      "name": "Cayman Islands",
      "region": "Americas",
      "timezones": {
        "America\/Cayman": "-05:00"
      },
      "iso": {
        "alpha-2": "KY",
        "alpha-3": "CYM",
        "numeric": "136"
      },
      "phone": [
        "+1-345"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KY.svg"
    },
    "CF": {
      "name": "Central African Republic",
      "region": "Africa",
      "timezones": {
        "Africa\/Bangui": "+01:00"
      },
      "iso": {
        "alpha-2": "CF",
        "alpha-3": "CAF",
        "numeric": "140"
      },
      "phone": [
        "+236"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CF.svg"
    },
    "TD": {
      "name": "Chad",
      "region": "Africa",
      "timezones": {
        "Africa\/Ndjamena": "+01:00"
      },
      "iso": {
        "alpha-2": "TD",
        "alpha-3": "TCD",
        "numeric": "148"
      },
      "phone": [
        "+235"
      ],
      "emoji": "\ud83c\uddf9\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TD.svg"
    },
    "CL": {
      "name": "Chile",
      "region": "Americas",
      "timezones": {
        "America\/Punta_Arenas": "-03:00",
        "America\/Santiago": "-04:00",
        "Pacific\/Easter": "-06:00"
      },
      "iso": {
        "alpha-2": "CL",
        "alpha-3": "CHL",
        "numeric": "152"
      },
      "phone": [
        "+56"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CL.svg"
    },
    "CN": {
      "name": "China",
      "region": "Asia",
      "timezones": {
        "Asia\/Shanghai": "+08:00",
        "Asia\/Urumqi": "+06:00"
      },
      "iso": {
        "alpha-2": "CN",
        "alpha-3": "CHN",
        "numeric": "156"
      },
      "phone": [
        "+86"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CN.svg"
    },
    "CX": {
      "name": "Christmas Island",
      "region": "Oceania",
      "timezones": {
        "Indian\/Christmas": "+07:00"
      },
      "iso": {
        "alpha-2": "CX",
        "alpha-3": "CXR",
        "numeric": "162"
      },
      "phone": [
        "+61"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddfd",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CX.svg"
    },
    "CC": {
      "name": "Cocos (Keeling) Islands",
      "region": "Oceania",
      "timezones": {
        "Indian\/Cocos": "+06:30"
      },
      "iso": {
        "alpha-2": "CC",
        "alpha-3": "CCK",
        "numeric": "166"
      },
      "phone": [
        "+61"
      ],
      "emoji": "\ud83c\udde8\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CC.svg"
    },
    "CO": {
      "name": "Colombia",
      "region": "Americas",
      "timezones": {
        "America\/Bogota": "-05:00"
      },
      "iso": {
        "alpha-2": "CO",
        "alpha-3": "COL",
        "numeric": "170"
      },
      "phone": [
        "+57"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CO.svg"
    },
    "KM": {
      "name": "Comoros",
      "region": "Africa",
      "timezones": {
        "Indian\/Comoro": "+03:00"
      },
      "iso": {
        "alpha-2": "KM",
        "alpha-3": "COM",
        "numeric": "174"
      },
      "phone": [
        "+269"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KM.svg"
    },
    "CG": {
      "name": "Congo",
      "region": "Africa",
      "timezones": {
        "Africa\/Brazzaville": "+01:00"
      },
      "iso": {
        "alpha-2": "CG",
        "alpha-3": "COG",
        "numeric": "178"
      },
      "phone": [
        "+242"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CG.svg"
    },
    "CD": {
      "name": "Congo, the Democratic Republic of the",
      "region": "Africa",
      "timezones": {
        "Africa\/Kinshasa": "+01:00",
        "Africa\/Lubumbashi": "+02:00"
      },
      "iso": {
        "alpha-2": "CD",
        "alpha-3": "COD",
        "numeric": "180"
      },
      "phone": [
        "+243"
      ],
      "emoji": "\ud83c\udde8\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CD.svg"
    },
    "CK": {
      "name": "Cook Islands",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Rarotonga": "-10:00"
      },
      "iso": {
        "alpha-2": "CK",
        "alpha-3": "COK",
        "numeric": "184"
      },
      "phone": [
        "+682"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CK.svg"
    },
    "CR": {
      "name": "Costa Rica",
      "region": "Americas",
      "timezones": {
        "America\/Costa_Rica": "-06:00"
      },
      "iso": {
        "alpha-2": "CR",
        "alpha-3": "CRI",
        "numeric": "188"
      },
      "phone": [
        "+506"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CR.svg"
    },
    "HR": {
      "name": "Croatia",
      "region": "Europe",
      "timezones": {
        "Europe\/Zagreb": "+02:00"
      },
      "iso": {
        "alpha-2": "HR",
        "alpha-3": "HRV",
        "numeric": "191"
      },
      "phone": [
        "+385"
      ],
      "emoji": "\ud83c\udded\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/HR.svg"
    },
    "CU": {
      "name": "Cuba",
      "region": "Americas",
      "timezones": {
        "America\/Havana": "-04:00"
      },
      "iso": {
        "alpha-2": "CU",
        "alpha-3": "CUB",
        "numeric": "192"
      },
      "phone": [
        "+53"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CU.svg"
    },
    "CW": {
      "name": "Cura\u00e7ao",
      "region": "Americas",
      "timezones": {
        "America\/Curacao": "-04:00"
      },
      "iso": {
        "alpha-2": "CW",
        "alpha-3": "CUW",
        "numeric": "531"
      },
      "phone": [
        "+599"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CW.svg"
    },
    "CY": {
      "name": "Cyprus",
      "region": "Asia",
      "timezones": {
        "Asia\/Famagusta": "+03:00",
        "Asia\/Nicosia": "+03:00"
      },
      "iso": {
        "alpha-2": "CY",
        "alpha-3": "CYP",
        "numeric": "196"
      },
      "phone": [
        "+357"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CY.svg"
    },
    "CZ": {
      "name": "Czech Republic",
      "region": "Europe",
      "timezones": {
        "Europe\/Prague": "+02:00"
      },
      "iso": {
        "alpha-2": "CZ",
        "alpha-3": "CZE",
        "numeric": "203"
      },
      "phone": [
        "+420"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CZ.svg"
    },
    "CI": {
      "name": "C\u00f4te d'Ivoire",
      "region": "Africa",
      "timezones": {
        "Africa\/Abidjan": "+00:00"
      },
      "iso": {
        "alpha-2": "CI",
        "alpha-3": "CIV",
        "numeric": "384"
      },
      "phone": [
        "+225"
      ],
      "emoji": "\ud83c\udde8\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CI.svg"
    },
    "DK": {
      "name": "Denmark",
      "region": "Europe",
      "timezones": {
        "Europe\/Copenhagen": "+02:00"
      },
      "iso": {
        "alpha-2": "DK",
        "alpha-3": "DNK",
        "numeric": "208"
      },
      "phone": [
        "+45"
      ],
      "emoji": "\ud83c\udde9\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/DK.svg"
    },
    "DJ": {
      "name": "Djibouti",
      "region": "Africa",
      "timezones": {
        "Africa\/Djibouti": "+03:00"
      },
      "iso": {
        "alpha-2": "DJ",
        "alpha-3": "DJI",
        "numeric": "262"
      },
      "phone": [
        "+253"
      ],
      "emoji": "\ud83c\udde9\ud83c\uddef",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/DJ.svg"
    },
    "DM": {
      "name": "Dominica",
      "region": "Americas",
      "timezones": {
        "America\/Dominica": "-04:00"
      },
      "iso": {
        "alpha-2": "DM",
        "alpha-3": "DMA",
        "numeric": "212"
      },
      "phone": [
        "+1-767"
      ],
      "emoji": "\ud83c\udde9\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/DM.svg"
    },
    "DO": {
      "name": "Dominican Republic",
      "region": "Americas",
      "timezones": {
        "America\/Santo_Domingo": "-04:00"
      },
      "iso": {
        "alpha-2": "DO",
        "alpha-3": "DOM",
        "numeric": "214"
      },
      "phone": [
        "+1-809",
        "+1-829"
      ],
      "emoji": "\ud83c\udde9\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/DO.svg"
    },
    "EC": {
      "name": "Ecuador",
      "region": "Americas",
      "timezones": {
        "America\/Guayaquil": "-05:00",
        "Pacific\/Galapagos": "-06:00"
      },
      "iso": {
        "alpha-2": "EC",
        "alpha-3": "ECU",
        "numeric": "218"
      },
      "phone": [
        "+593"
      ],
      "emoji": "\ud83c\uddea\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/EC.svg"
    },
    "EG": {
      "name": "Egypt",
      "region": "Africa",
      "timezones": {
        "Africa\/Cairo": "+02:00"
      },
      "iso": {
        "alpha-2": "EG",
        "alpha-3": "EGY",
        "numeric": "818"
      },
      "phone": [
        "+20"
      ],
      "emoji": "\ud83c\uddea\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/EG.svg"
    },
    "SV": {
      "name": "El Salvador",
      "region": "Americas",
      "timezones": {
        "America\/El_Salvador": "-06:00"
      },
      "iso": {
        "alpha-2": "SV",
        "alpha-3": "SLV",
        "numeric": "222"
      },
      "phone": [
        "+503"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddfb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SV.svg"
    },
    "GQ": {
      "name": "Equatorial Guinea",
      "region": "Africa",
      "timezones": {
        "Africa\/Malabo": "+01:00"
      },
      "iso": {
        "alpha-2": "GQ",
        "alpha-3": "GNQ",
        "numeric": "226"
      },
      "phone": [
        "+240"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GQ.svg"
    },
    "ER": {
      "name": "Eritrea",
      "region": "Africa",
      "timezones": {
        "Africa\/Asmara": "+03:00"
      },
      "iso": {
        "alpha-2": "ER",
        "alpha-3": "ERI",
        "numeric": "232"
      },
      "phone": [
        "+291"
      ],
      "emoji": "\ud83c\uddea\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ER.svg"
    },
    "EE": {
      "name": "Estonia",
      "region": "Europe",
      "timezones": {
        "Europe\/Tallinn": "+03:00"
      },
      "iso": {
        "alpha-2": "EE",
        "alpha-3": "EST",
        "numeric": "233"
      },
      "phone": [
        "+372"
      ],
      "emoji": "\ud83c\uddea\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/EE.svg"
    },
    "ET": {
      "name": "Ethiopia",
      "region": "Africa",
      "timezones": {
        "Africa\/Addis_Ababa": "+03:00"
      },
      "iso": {
        "alpha-2": "ET",
        "alpha-3": "ETH",
        "numeric": "231"
      },
      "phone": [
        "+251"
      ],
      "emoji": "\ud83c\uddea\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ET.svg"
    },
    "FK": {
      "name": "Falkland Islands (Malvinas)",
      "region": "Americas",
      "timezones": {
        "Atlantic\/Stanley": "-03:00"
      },
      "iso": {
        "alpha-2": "FK",
        "alpha-3": "FLK",
        "numeric": "238"
      },
      "phone": [
        "+500"
      ],
      "emoji": "\ud83c\uddeb\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/FK.svg"
    },
    "FO": {
      "name": "Faroe Islands",
      "region": "Europe",
      "timezones": {
        "Atlantic\/Faroe": "+01:00"
      },
      "iso": {
        "alpha-2": "FO",
        "alpha-3": "FRO",
        "numeric": "234"
      },
      "phone": [
        "+298"
      ],
      "emoji": "\ud83c\uddeb\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/FO.svg"
    },
    "FJ": {
      "name": "Fiji",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Fiji": "+12:00"
      },
      "iso": {
        "alpha-2": "FJ",
        "alpha-3": "FJI",
        "numeric": "242"
      },
      "phone": [
        "+679"
      ],
      "emoji": "\ud83c\uddeb\ud83c\uddef",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/FJ.svg"
    },
    "FI": {
      "name": "Finland",
      "region": "Europe",
      "timezones": {
        "Europe\/Helsinki": "+03:00"
      },
      "iso": {
        "alpha-2": "FI",
        "alpha-3": "FIN",
        "numeric": "246"
      },
      "phone": [
        "+358"
      ],
      "emoji": "\ud83c\uddeb\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/FI.svg"
    },
    "FR": {
      "name": "France",
      "region": "Europe",
      "timezones": {
        "Europe\/Paris": "+02:00"
      },
      "iso": {
        "alpha-2": "FR",
        "alpha-3": "FRA",
        "numeric": "250"
      },
      "phone": [
        "+33"
      ],
      "emoji": "\ud83c\uddeb\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/FR.svg"
    },
    "GF": {
      "name": "French Guiana",
      "region": "Americas",
      "timezones": {
        "America\/Cayenne": "-03:00"
      },
      "iso": {
        "alpha-2": "GF",
        "alpha-3": "GUF",
        "numeric": "254"
      },
      "phone": [
        "+594"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GF.svg"
    },
    "PF": {
      "name": "French Polynesia",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Gambier": "-09:00",
        "Pacific\/Marquesas": "-09:30",
        "Pacific\/Tahiti": "-10:00"
      },
      "iso": {
        "alpha-2": "PF",
        "alpha-3": "PYF",
        "numeric": "258"
      },
      "phone": [
        "+689"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PF.svg"
    },
    "TF": {
      "name": "French Southern Territories",
      "region": "Africa",
      "timezones": {
        "Indian\/Kerguelen": "+05:00"
      },
      "iso": {
        "alpha-2": "TF",
        "alpha-3": "ATF",
        "numeric": "260"
      },
      "phone": [
        "+"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TF.svg"
    },
    "GA": {
      "name": "Gabon",
      "region": "Africa",
      "timezones": {
        "Africa\/Libreville": "+01:00"
      },
      "iso": {
        "alpha-2": "GA",
        "alpha-3": "GAB",
        "numeric": "266"
      },
      "phone": [
        "+241"
      ],
      "emoji": "\ud83c\uddec\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GA.svg"
    },
    "GM": {
      "name": "Gambia",
      "region": "Africa",
      "timezones": {
        "Africa\/Banjul": "+00:00"
      },
      "iso": {
        "alpha-2": "GM",
        "alpha-3": "GMB",
        "numeric": "270"
      },
      "phone": [
        "+220"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GM.svg"
    },
    "GE": {
      "name": "Georgia",
      "region": "Asia",
      "timezones": {
        "Asia\/Tbilisi": "+04:00"
      },
      "iso": {
        "alpha-2": "GE",
        "alpha-3": "GEO",
        "numeric": "268"
      },
      "phone": [
        "+995"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GE.svg"
    },
    "DE": {
      "name": "Germany",
      "region": "Europe",
      "timezones": {
        "Europe\/Berlin": "+02:00",
        "Europe\/Busingen": "+02:00"
      },
      "iso": {
        "alpha-2": "DE",
        "alpha-3": "DEU",
        "numeric": "276"
      },
      "phone": [
        "+49"
      ],
      "emoji": "\ud83c\udde9\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/DE.svg"
    },
    "GH": {
      "name": "Ghana",
      "region": "Africa",
      "timezones": {
        "Africa\/Accra": "+00:00"
      },
      "iso": {
        "alpha-2": "GH",
        "alpha-3": "GHA",
        "numeric": "288"
      },
      "phone": [
        "+233"
      ],
      "emoji": "\ud83c\uddec\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GH.svg"
    },
    "GI": {
      "name": "Gibraltar",
      "region": "Europe",
      "timezones": {
        "Europe\/Gibraltar": "+02:00"
      },
      "iso": {
        "alpha-2": "GI",
        "alpha-3": "GIB",
        "numeric": "292"
      },
      "phone": [
        "+350"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GI.svg"
    },
    "GR": {
      "name": "Greece",
      "region": "Europe",
      "timezones": {
        "Europe\/Athens": "+03:00"
      },
      "iso": {
        "alpha-2": "GR",
        "alpha-3": "GRC",
        "numeric": "300"
      },
      "phone": [
        "+30"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GR.svg"
    },
    "GL": {
      "name": "Greenland",
      "region": "Americas",
      "timezones": {
        "America\/Danmarkshavn": "+00:00",
        "America\/Nuuk": "-02:00",
        "America\/Scoresbysund": "+00:00",
        "America\/Thule": "-03:00"
      },
      "iso": {
        "alpha-2": "GL",
        "alpha-3": "GRL",
        "numeric": "304"
      },
      "phone": [
        "+299"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GL.svg"
    },
    "GD": {
      "name": "Grenada",
      "region": "Americas",
      "timezones": {
        "America\/Grenada": "-04:00"
      },
      "iso": {
        "alpha-2": "GD",
        "alpha-3": "GRD",
        "numeric": "308"
      },
      "phone": [
        "+1-473"
      ],
      "emoji": "\ud83c\uddec\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GD.svg"
    },
    "GP": {
      "name": "Guadeloupe",
      "region": "Americas",
      "timezones": {
        "America\/Guadeloupe": "-04:00"
      },
      "iso": {
        "alpha-2": "GP",
        "alpha-3": "GLP",
        "numeric": "312"
      },
      "phone": [
        "+590"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf5",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GP.svg"
    },
    "GU": {
      "name": "Guam",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Guam": "+10:00"
      },
      "iso": {
        "alpha-2": "GU",
        "alpha-3": "GUM",
        "numeric": "316"
      },
      "phone": [
        "+1-671"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GU.svg"
    },
    "GT": {
      "name": "Guatemala",
      "region": "Americas",
      "timezones": {
        "America\/Guatemala": "-06:00"
      },
      "iso": {
        "alpha-2": "GT",
        "alpha-3": "GTM",
        "numeric": "320"
      },
      "phone": [
        "+502"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GT.svg"
    },
    "GG": {
      "name": "Guernsey",
      "region": "Europe",
      "timezones": {
        "Europe\/Guernsey": "+01:00"
      },
      "iso": {
        "alpha-2": "GG",
        "alpha-3": "GGY",
        "numeric": "831"
      },
      "phone": [
        "+44-1481"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GG.svg"
    },
    "GN": {
      "name": "Guinea",
      "region": "Africa",
      "timezones": {
        "Africa\/Conakry": "+00:00"
      },
      "iso": {
        "alpha-2": "GN",
        "alpha-3": "GIN",
        "numeric": "324"
      },
      "phone": [
        "+224"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GN.svg"
    },
    "GW": {
      "name": "Guinea-Bissau",
      "region": "Africa",
      "timezones": {
        "Africa\/Bissau": "+00:00"
      },
      "iso": {
        "alpha-2": "GW",
        "alpha-3": "GNB",
        "numeric": "624"
      },
      "phone": [
        "+245"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GW.svg"
    },
    "GY": {
      "name": "Guyana",
      "region": "Americas",
      "timezones": {
        "America\/Guyana": "-04:00"
      },
      "iso": {
        "alpha-2": "GY",
        "alpha-3": "GUY",
        "numeric": "328"
      },
      "phone": [
        "+592"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GY.svg"
    },
    "HT": {
      "name": "Haiti",
      "region": "Americas",
      "timezones": {
        "America\/Port-au-Prince": "-04:00"
      },
      "iso": {
        "alpha-2": "HT",
        "alpha-3": "HTI",
        "numeric": "332"
      },
      "phone": [
        "+509"
      ],
      "emoji": "\ud83c\udded\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/HT.svg"
    },
    "VA": {
      "name": "Holy See (Vatican City State)",
      "region": "Europe",
      "timezones": {
        "Europe\/Vatican": "+02:00"
      },
      "iso": {
        "alpha-2": "VA",
        "alpha-3": "VAT",
        "numeric": "336"
      },
      "phone": [
        "+379"
      ],
      "emoji": "\ud83c\uddfb\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VA.svg"
    },
    "HN": {
      "name": "Honduras",
      "region": "Americas",
      "timezones": {
        "America\/Tegucigalpa": "-06:00"
      },
      "iso": {
        "alpha-2": "HN",
        "alpha-3": "HND",
        "numeric": "340"
      },
      "phone": [
        "+504"
      ],
      "emoji": "\ud83c\udded\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/HN.svg"
    },
    "HK": {
      "name": "Hong Kong",
      "region": "Asia",
      "timezones": {
        "Asia\/Hong_Kong": "+08:00"
      },
      "iso": {
        "alpha-2": "HK",
        "alpha-3": "HKG",
        "numeric": "344"
      },
      "phone": [
        "+852"
      ],
      "emoji": "\ud83c\udded\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/HK.svg"
    },
    "HU": {
      "name": "Hungary",
      "region": "Europe",
      "timezones": {
        "Europe\/Budapest": "+02:00"
      },
      "iso": {
        "alpha-2": "HU",
        "alpha-3": "HUN",
        "numeric": "348"
      },
      "phone": [
        "+36"
      ],
      "emoji": "\ud83c\udded\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/HU.svg"
    },
    "IS": {
      "name": "Iceland",
      "region": "Europe",
      "timezones": {
        "Atlantic\/Reykjavik": "+00:00"
      },
      "iso": {
        "alpha-2": "IS",
        "alpha-3": "ISL",
        "numeric": "352"
      },
      "phone": [
        "+354"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IS.svg"
    },
    "IN": {
      "name": "India",
      "region": "Asia",
      "timezones": {
        "Asia\/Kolkata": "+05:30"
      },
      "iso": {
        "alpha-2": "IN",
        "alpha-3": "IND",
        "numeric": "356"
      },
      "phone": [
        "+91"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IN.svg"
    },
    "ID": {
      "name": "Indonesia",
      "region": "Asia",
      "timezones": {
        "Asia\/Jakarta": "+07:00",
        "Asia\/Jayapura": "+09:00",
        "Asia\/Makassar": "+08:00",
        "Asia\/Pontianak": "+07:00"
      },
      "iso": {
        "alpha-2": "ID",
        "alpha-3": "IDN",
        "numeric": "360"
      },
      "phone": [
        "+62"
      ],
      "emoji": "\ud83c\uddee\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ID.svg"
    },
    "IR": {
      "name": "Iran, Islamic Republic of",
      "region": "Asia",
      "timezones": {
        "Asia\/Tehran": "+03:30"
      },
      "iso": {
        "alpha-2": "IR",
        "alpha-3": "IRN",
        "numeric": "364"
      },
      "phone": [
        "+98"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IR.svg"
    },
    "IQ": {
      "name": "Iraq",
      "region": "Asia",
      "timezones": {
        "Asia\/Baghdad": "+03:00"
      },
      "iso": {
        "alpha-2": "IQ",
        "alpha-3": "IRQ",
        "numeric": "368"
      },
      "phone": [
        "+964"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IQ.svg"
    },
    "IE": {
      "name": "Ireland",
      "region": "Europe",
      "timezones": {
        "Europe\/Dublin": "+01:00"
      },
      "iso": {
        "alpha-2": "IE",
        "alpha-3": "IRL",
        "numeric": "372"
      },
      "phone": [
        "+353"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IE.svg"
    },
    "IM": {
      "name": "Isle of Man",
      "region": "Europe",
      "timezones": {
        "Europe\/Isle_of_Man": "+01:00"
      },
      "iso": {
        "alpha-2": "IM",
        "alpha-3": "IMN",
        "numeric": "833"
      },
      "phone": [
        "+44-1624"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IM.svg"
    },
    "IL": {
      "name": "Israel",
      "region": "Asia",
      "timezones": {
        "Asia\/Jerusalem": "+03:00"
      },
      "iso": {
        "alpha-2": "IL",
        "alpha-3": "ISR",
        "numeric": "376"
      },
      "phone": [
        "+972"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IL.svg"
    },
    "IT": {
      "name": "Italy",
      "region": "Europe",
      "timezones": {
        "Europe\/Rome": "+02:00"
      },
      "iso": {
        "alpha-2": "IT",
        "alpha-3": "ITA",
        "numeric": "380"
      },
      "phone": [
        "+39"
      ],
      "emoji": "\ud83c\uddee\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/IT.svg"
    },
    "JM": {
      "name": "Jamaica",
      "region": "Americas",
      "timezones": {
        "America\/Jamaica": "-05:00"
      },
      "iso": {
        "alpha-2": "JM",
        "alpha-3": "JAM",
        "numeric": "388"
      },
      "phone": [
        "+1-876"
      ],
      "emoji": "\ud83c\uddef\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/JM.svg"
    },
    "JP": {
      "name": "Japan",
      "region": "Asia",
      "timezones": {
        "Asia\/Tokyo": "+09:00"
      },
      "iso": {
        "alpha-2": "JP",
        "alpha-3": "JPN",
        "numeric": "392"
      },
      "phone": [
        "+81"
      ],
      "emoji": "\ud83c\uddef\ud83c\uddf5",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/JP.svg"
    },
    "JE": {
      "name": "Jersey",
      "region": "Europe",
      "timezones": {
        "Europe\/Jersey": "+01:00"
      },
      "iso": {
        "alpha-2": "JE",
        "alpha-3": "JEY",
        "numeric": "832"
      },
      "phone": [
        "+44-1534"
      ],
      "emoji": "\ud83c\uddef\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/JE.svg"
    },
    "JO": {
      "name": "Jordan",
      "region": "Asia",
      "timezones": {
        "Asia\/Amman": "+03:00"
      },
      "iso": {
        "alpha-2": "JO",
        "alpha-3": "JOR",
        "numeric": "400"
      },
      "phone": [
        "+962"
      ],
      "emoji": "\ud83c\uddef\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/JO.svg"
    },
    "KZ": {
      "name": "Kazakhstan",
      "region": "Asia",
      "timezones": {
        "Asia\/Almaty": "+06:00",
        "Asia\/Aqtau": "+05:00",
        "Asia\/Aqtobe": "+05:00",
        "Asia\/Atyrau": "+05:00",
        "Asia\/Oral": "+05:00",
        "Asia\/Qostanay": "+06:00",
        "Asia\/Qyzylorda": "+05:00"
      },
      "iso": {
        "alpha-2": "KZ",
        "alpha-3": "KAZ",
        "numeric": "398"
      },
      "phone": [
        "+7"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KZ.svg"
    },
    "KE": {
      "name": "Kenya",
      "region": "Africa",
      "timezones": {
        "Africa\/Nairobi": "+03:00"
      },
      "iso": {
        "alpha-2": "KE",
        "alpha-3": "KEN",
        "numeric": "404"
      },
      "phone": [
        "+254"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KE.svg"
    },
    "KI": {
      "name": "Kiribati",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Kanton": "+13:00",
        "Pacific\/Kiritimati": "+14:00",
        "Pacific\/Tarawa": "+12:00"
      },
      "iso": {
        "alpha-2": "KI",
        "alpha-3": "KIR",
        "numeric": "296"
      },
      "phone": [
        "+686"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KI.svg"
    },
    "KP": {
      "name": "Korea, Democratic People's Republic of",
      "region": "Asia",
      "timezones": {
        "Asia\/Pyongyang": "+09:00"
      },
      "iso": {
        "alpha-2": "KP",
        "alpha-3": "PRK",
        "numeric": "408"
      },
      "phone": [
        "+850"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddf5",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KP.svg"
    },
    "KR": {
      "name": "Korea, Republic of",
      "region": "Asia",
      "timezones": {
        "Asia\/Seoul": "+09:00"
      },
      "iso": {
        "alpha-2": "KR",
        "alpha-3": "KOR",
        "numeric": "410"
      },
      "phone": [
        "+82"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KR.svg"
    },
    "KW": {
      "name": "Kuwait",
      "region": "Asia",
      "timezones": {
        "Asia\/Kuwait": "+03:00"
      },
      "iso": {
        "alpha-2": "KW",
        "alpha-3": "KWT",
        "numeric": "414"
      },
      "phone": [
        "+965"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KW.svg"
    },
    "KG": {
      "name": "Kyrgyzstan",
      "region": "Asia",
      "timezones": {
        "Asia\/Bishkek": "+06:00"
      },
      "iso": {
        "alpha-2": "KG",
        "alpha-3": "KGZ",
        "numeric": "417"
      },
      "phone": [
        "+996"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KG.svg"
    },
    "LA": {
      "name": "Lao People's Democratic Republic",
      "region": "Asia",
      "timezones": {
        "Asia\/Vientiane": "+07:00"
      },
      "iso": {
        "alpha-2": "LA",
        "alpha-3": "LAO",
        "numeric": "418"
      },
      "phone": [
        "+856"
      ],
      "emoji": "\ud83c\uddf1\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LA.svg"
    },
    "LV": {
      "name": "Latvia",
      "region": "Europe",
      "timezones": {
        "Europe\/Riga": "+03:00"
      },
      "iso": {
        "alpha-2": "LV",
        "alpha-3": "LVA",
        "numeric": "428"
      },
      "phone": [
        "+371"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddfb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LV.svg"
    },
    "LB": {
      "name": "Lebanon",
      "region": "Asia",
      "timezones": {
        "Asia\/Beirut": "+03:00"
      },
      "iso": {
        "alpha-2": "LB",
        "alpha-3": "LBN",
        "numeric": "422"
      },
      "phone": [
        "+961"
      ],
      "emoji": "\ud83c\uddf1\ud83c\udde7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LB.svg"
    },
    "LS": {
      "name": "Lesotho",
      "region": "Africa",
      "timezones": {
        "Africa\/Maseru": "+02:00"
      },
      "iso": {
        "alpha-2": "LS",
        "alpha-3": "LSO",
        "numeric": "426"
      },
      "phone": [
        "+266"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LS.svg"
    },
    "LR": {
      "name": "Liberia",
      "region": "Africa",
      "timezones": {
        "Africa\/Monrovia": "+00:00"
      },
      "iso": {
        "alpha-2": "LR",
        "alpha-3": "LBR",
        "numeric": "430"
      },
      "phone": [
        "+231"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LR.svg"
    },
    "LY": {
      "name": "Libya",
      "region": "Africa",
      "timezones": {
        "Africa\/Tripoli": "+02:00"
      },
      "iso": {
        "alpha-2": "LY",
        "alpha-3": "LBY",
        "numeric": "434"
      },
      "phone": [
        "+218"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LY.svg"
    },
    "LI": {
      "name": "Liechtenstein",
      "region": "Europe",
      "timezones": {
        "Europe\/Vaduz": "+02:00"
      },
      "iso": {
        "alpha-2": "LI",
        "alpha-3": "LIE",
        "numeric": "438"
      },
      "phone": [
        "+423"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LI.svg"
    },
    "LT": {
      "name": "Lithuania",
      "region": "Europe",
      "timezones": {
        "Europe\/Vilnius": "+03:00"
      },
      "iso": {
        "alpha-2": "LT",
        "alpha-3": "LTU",
        "numeric": "440"
      },
      "phone": [
        "+370"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LT.svg"
    },
    "LU": {
      "name": "Luxembourg",
      "region": "Europe",
      "timezones": {
        "Europe\/Luxembourg": "+02:00"
      },
      "iso": {
        "alpha-2": "LU",
        "alpha-3": "LUX",
        "numeric": "442"
      },
      "phone": [
        "+352"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LU.svg"
    },
    "MO": {
      "name": "Macao",
      "region": "Asia",
      "timezones": {
        "Asia\/Macau": "+08:00"
      },
      "iso": {
        "alpha-2": "MO",
        "alpha-3": "MAC",
        "numeric": "446"
      },
      "phone": [
        "+853"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MO.svg"
    },
    "MK": {
      "name": "Macedonia, the Former Yugoslav Republic of",
      "region": "Europe",
      "timezones": {
        "Europe\/Skopje": "+02:00"
      },
      "iso": {
        "alpha-2": "MK",
        "alpha-3": "MKD",
        "numeric": "807"
      },
      "phone": [
        "+389"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MK.svg"
    },
    "MG": {
      "name": "Madagascar",
      "region": "Africa",
      "timezones": {
        "Indian\/Antananarivo": "+03:00"
      },
      "iso": {
        "alpha-2": "MG",
        "alpha-3": "MDG",
        "numeric": "450"
      },
      "phone": [
        "+261"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MG.svg"
    },
    "MW": {
      "name": "Malawi",
      "region": "Africa",
      "timezones": {
        "Africa\/Blantyre": "+02:00"
      },
      "iso": {
        "alpha-2": "MW",
        "alpha-3": "MWI",
        "numeric": "454"
      },
      "phone": [
        "+265"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MW.svg"
    },
    "MY": {
      "name": "Malaysia",
      "region": "Asia",
      "timezones": {
        "Asia\/Kuala_Lumpur": "+08:00",
        "Asia\/Kuching": "+08:00"
      },
      "iso": {
        "alpha-2": "MY",
        "alpha-3": "MYS",
        "numeric": "458"
      },
      "phone": [
        "+60"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MY.svg"
    },
    "MV": {
      "name": "Maldives",
      "region": "Asia",
      "timezones": {
        "Indian\/Maldives": "+05:00"
      },
      "iso": {
        "alpha-2": "MV",
        "alpha-3": "MDV",
        "numeric": "462"
      },
      "phone": [
        "+960"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddfb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MV.svg"
    },
    "ML": {
      "name": "Mali",
      "region": "Africa",
      "timezones": {
        "Africa\/Bamako": "+00:00"
      },
      "iso": {
        "alpha-2": "ML",
        "alpha-3": "MLI",
        "numeric": "466"
      },
      "phone": [
        "+223"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ML.svg"
    },
    "MT": {
      "name": "Malta",
      "region": "Europe",
      "timezones": {
        "Europe\/Malta": "+02:00"
      },
      "iso": {
        "alpha-2": "MT",
        "alpha-3": "MLT",
        "numeric": "470"
      },
      "phone": [
        "+356"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MT.svg"
    },
    "MH": {
      "name": "Marshall Islands",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Kwajalein": "+12:00",
        "Pacific\/Majuro": "+12:00"
      },
      "iso": {
        "alpha-2": "MH",
        "alpha-3": "MHL",
        "numeric": "584"
      },
      "phone": [
        "+692"
      ],
      "emoji": "\ud83c\uddf2\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MH.svg"
    },
    "MQ": {
      "name": "Martinique",
      "region": "Americas",
      "timezones": {
        "America\/Martinique": "-04:00"
      },
      "iso": {
        "alpha-2": "MQ",
        "alpha-3": "MTQ",
        "numeric": "474"
      },
      "phone": [
        "+596"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MQ.svg"
    },
    "MR": {
      "name": "Mauritania",
      "region": "Africa",
      "timezones": {
        "Africa\/Nouakchott": "+00:00"
      },
      "iso": {
        "alpha-2": "MR",
        "alpha-3": "MRT",
        "numeric": "478"
      },
      "phone": [
        "+222"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MR.svg"
    },
    "MU": {
      "name": "Mauritius",
      "region": "Africa",
      "timezones": {
        "Indian\/Mauritius": "+04:00"
      },
      "iso": {
        "alpha-2": "MU",
        "alpha-3": "MUS",
        "numeric": "480"
      },
      "phone": [
        "+230"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MU.svg"
    },
    "YT": {
      "name": "Mayotte",
      "region": "Africa",
      "timezones": {
        "Indian\/Mayotte": "+03:00"
      },
      "iso": {
        "alpha-2": "YT",
        "alpha-3": "MYT",
        "numeric": "175"
      },
      "phone": [
        "+262"
      ],
      "emoji": "\ud83c\uddfe\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/YT.svg"
    },
    "MX": {
      "name": "Mexico",
      "region": "Americas",
      "timezones": {
        "America\/Bahia_Banderas": "-06:00",
        "America\/Cancun": "-05:00",
        "America\/Chihuahua": "-06:00",
        "America\/Ciudad_Juarez": "-06:00",
        "America\/Hermosillo": "-07:00",
        "America\/Matamoros": "-05:00",
        "America\/Mazatlan": "-07:00",
        "America\/Merida": "-06:00",
        "America\/Mexico_City": "-06:00",
        "America\/Monterrey": "-06:00",
        "America\/Ojinaga": "-05:00",
        "America\/Tijuana": "-07:00"
      },
      "iso": {
        "alpha-2": "MX",
        "alpha-3": "MEX",
        "numeric": "484"
      },
      "phone": [
        "+52"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddfd",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MX.svg"
    },
    "FM": {
      "name": "Micronesia, Federated States of",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Chuuk": "+10:00",
        "Pacific\/Kosrae": "+11:00",
        "Pacific\/Pohnpei": "+11:00"
      },
      "iso": {
        "alpha-2": "FM",
        "alpha-3": "FSM",
        "numeric": "583"
      },
      "phone": [
        "+691"
      ],
      "emoji": "\ud83c\uddeb\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/FM.svg"
    },
    "MD": {
      "name": "Moldova, Republic of",
      "region": "Europe",
      "timezones": {
        "Europe\/Chisinau": "+03:00"
      },
      "iso": {
        "alpha-2": "MD",
        "alpha-3": "MDA",
        "numeric": "498"
      },
      "phone": [
        "+373"
      ],
      "emoji": "\ud83c\uddf2\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MD.svg"
    },
    "MC": {
      "name": "Monaco",
      "region": "Europe",
      "timezones": {
        "Europe\/Monaco": "+02:00"
      },
      "iso": {
        "alpha-2": "MC",
        "alpha-3": "MCO",
        "numeric": "492"
      },
      "phone": [
        "+377"
      ],
      "emoji": "\ud83c\uddf2\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MC.svg"
    },
    "MN": {
      "name": "Mongolia",
      "region": "Asia",
      "timezones": {
        "Asia\/Choibalsan": "+08:00",
        "Asia\/Hovd": "+07:00",
        "Asia\/Ulaanbaatar": "+08:00"
      },
      "iso": {
        "alpha-2": "MN",
        "alpha-3": "MNG",
        "numeric": "496"
      },
      "phone": [
        "+976"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MN.svg"
    },
    "ME": {
      "name": "Montenegro",
      "region": "Europe",
      "timezones": {
        "Europe\/Podgorica": "+02:00"
      },
      "iso": {
        "alpha-2": "ME",
        "alpha-3": "MNE",
        "numeric": "499"
      },
      "phone": [
        "+382"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ME.svg"
    },
    "MS": {
      "name": "Montserrat",
      "region": "Americas",
      "timezones": {
        "America\/Montserrat": "-04:00"
      },
      "iso": {
        "alpha-2": "MS",
        "alpha-3": "MSR",
        "numeric": "500"
      },
      "phone": [
        "+1-664"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MS.svg"
    },
    "MA": {
      "name": "Morocco",
      "region": "Africa",
      "timezones": {
        "Africa\/Casablanca": "+00:00",
        "Africa\/El_Aaiun": "+00:00"
      },
      "iso": {
        "alpha-2": "MA",
        "alpha-3": "MAR",
        "numeric": "504"
      },
      "phone": [
        "+212"
      ],
      "emoji": "\ud83c\uddf2\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MA.svg"
    },
    "MZ": {
      "name": "Mozambique",
      "region": "Africa",
      "timezones": {
        "Africa\/Maputo": "+02:00"
      },
      "iso": {
        "alpha-2": "MZ",
        "alpha-3": "MOZ",
        "numeric": "508"
      },
      "phone": [
        "+258"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MZ.svg"
    },
    "MM": {
      "name": "Myanmar",
      "region": "Asia",
      "timezones": {
        "Asia\/Yangon": "+06:30"
      },
      "iso": {
        "alpha-2": "MM",
        "alpha-3": "MMR",
        "numeric": "104"
      },
      "phone": [
        "+95"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MM.svg"
    },
    "NA": {
      "name": "Namibia",
      "region": "Africa",
      "timezones": {
        "Africa\/Windhoek": "+02:00"
      },
      "iso": {
        "alpha-2": "NA",
        "alpha-3": "NAM",
        "numeric": "516"
      },
      "phone": [
        "+264"
      ],
      "emoji": "\ud83c\uddf3\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NA.svg"
    },
    "NR": {
      "name": "Nauru",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Nauru": "+12:00"
      },
      "iso": {
        "alpha-2": "NR",
        "alpha-3": "NRU",
        "numeric": "520"
      },
      "phone": [
        "+674"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NR.svg"
    },
    "NP": {
      "name": "Nepal",
      "region": "Asia",
      "timezones": {
        "Asia\/Kathmandu": "+05:45"
      },
      "iso": {
        "alpha-2": "NP",
        "alpha-3": "NPL",
        "numeric": "524"
      },
      "phone": [
        "+977"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddf5",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NP.svg"
    },
    "NL": {
      "name": "Netherlands",
      "region": "Europe",
      "timezones": {
        "Europe\/Amsterdam": "+02:00"
      },
      "iso": {
        "alpha-2": "NL",
        "alpha-3": "NLD",
        "numeric": "528"
      },
      "phone": [
        "+31"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NL.svg"
    },
    "NC": {
      "name": "New Caledonia",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Noumea": "+11:00"
      },
      "iso": {
        "alpha-2": "NC",
        "alpha-3": "NCL",
        "numeric": "540"
      },
      "phone": [
        "+687"
      ],
      "emoji": "\ud83c\uddf3\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NC.svg"
    },
    "NZ": {
      "name": "New Zealand",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Auckland": "+12:00",
        "Pacific\/Chatham": "+12:45"
      },
      "iso": {
        "alpha-2": "NZ",
        "alpha-3": "NZL",
        "numeric": "554"
      },
      "phone": [
        "+64"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NZ.svg"
    },
    "NI": {
      "name": "Nicaragua",
      "region": "Americas",
      "timezones": {
        "America\/Managua": "-06:00"
      },
      "iso": {
        "alpha-2": "NI",
        "alpha-3": "NIC",
        "numeric": "558"
      },
      "phone": [
        "+505"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NI.svg"
    },
    "NE": {
      "name": "Niger",
      "region": "Africa",
      "timezones": {
        "Africa\/Niamey": "+01:00"
      },
      "iso": {
        "alpha-2": "NE",
        "alpha-3": "NER",
        "numeric": "562"
      },
      "phone": [
        "+227"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NE.svg"
    },
    "NG": {
      "name": "Nigeria",
      "region": "Africa",
      "timezones": {
        "Africa\/Lagos": "+01:00"
      },
      "iso": {
        "alpha-2": "NG",
        "alpha-3": "NGA",
        "numeric": "566"
      },
      "phone": [
        "+234"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NG.svg"
    },
    "NU": {
      "name": "Niue",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Niue": "-11:00"
      },
      "iso": {
        "alpha-2": "NU",
        "alpha-3": "NIU",
        "numeric": "570"
      },
      "phone": [
        "+683"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NU.svg"
    },
    "NF": {
      "name": "Norfolk Island",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Norfolk": "+11:00"
      },
      "iso": {
        "alpha-2": "NF",
        "alpha-3": "NFK",
        "numeric": "574"
      },
      "phone": [
        "+672"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NF.svg"
    },
    "MP": {
      "name": "Northern Mariana Islands",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Saipan": "+10:00"
      },
      "iso": {
        "alpha-2": "MP",
        "alpha-3": "MNP",
        "numeric": "580"
      },
      "phone": [
        "+1-670"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddf5",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MP.svg"
    },
    "NO": {
      "name": "Norway",
      "region": "Europe",
      "timezones": {
        "Europe\/Oslo": "+02:00"
      },
      "iso": {
        "alpha-2": "NO",
        "alpha-3": "NOR",
        "numeric": "578"
      },
      "phone": [
        "+47"
      ],
      "emoji": "\ud83c\uddf3\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/NO.svg"
    },
    "OM": {
      "name": "Oman",
      "region": "Asia",
      "timezones": {
        "Asia\/Muscat": "+04:00"
      },
      "iso": {
        "alpha-2": "OM",
        "alpha-3": "OMN",
        "numeric": "512"
      },
      "phone": [
        "+968"
      ],
      "emoji": "\ud83c\uddf4\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/OM.svg"
    },
    "PK": {
      "name": "Pakistan",
      "region": "Asia",
      "timezones": {
        "Asia\/Karachi": "+05:00"
      },
      "iso": {
        "alpha-2": "PK",
        "alpha-3": "PAK",
        "numeric": "586"
      },
      "phone": [
        "+92"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PK.svg"
    },
    "PW": {
      "name": "Palau",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Palau": "+09:00"
      },
      "iso": {
        "alpha-2": "PW",
        "alpha-3": "PLW",
        "numeric": "585"
      },
      "phone": [
        "+680"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PW.svg"
    },
    "PS": {
      "name": "Palestine, State of",
      "region": "Asia",
      "timezones": {
        "Asia\/Gaza": "+02:00",
        "Asia\/Hebron": "+02:00"
      },
      "iso": {
        "alpha-2": "PS",
        "alpha-3": "PSE",
        "numeric": "275"
      },
      "phone": [
        "+970"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PS.svg"
    },
    "PA": {
      "name": "Panama",
      "region": "Americas",
      "timezones": {
        "America\/Panama": "-05:00"
      },
      "iso": {
        "alpha-2": "PA",
        "alpha-3": "PAN",
        "numeric": "591"
      },
      "phone": [
        "+507"
      ],
      "emoji": "\ud83c\uddf5\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PA.svg"
    },
    "PG": {
      "name": "Papua New Guinea",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Bougainville": "+11:00",
        "Pacific\/Port_Moresby": "+10:00"
      },
      "iso": {
        "alpha-2": "PG",
        "alpha-3": "PNG",
        "numeric": "598"
      },
      "phone": [
        "+675"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PG.svg"
    },
    "PY": {
      "name": "Paraguay",
      "region": "Americas",
      "timezones": {
        "America\/Asuncion": "-04:00"
      },
      "iso": {
        "alpha-2": "PY",
        "alpha-3": "PRY",
        "numeric": "600"
      },
      "phone": [
        "+595"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PY.svg"
    },
    "PE": {
      "name": "Peru",
      "region": "Americas",
      "timezones": {
        "America\/Lima": "-05:00"
      },
      "iso": {
        "alpha-2": "PE",
        "alpha-3": "PER",
        "numeric": "604"
      },
      "phone": [
        "+51"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PE.svg"
    },
    "PH": {
      "name": "Philippines",
      "region": "Asia",
      "timezones": {
        "Asia\/Manila": "+08:00"
      },
      "iso": {
        "alpha-2": "PH",
        "alpha-3": "PHL",
        "numeric": "608"
      },
      "phone": [
        "+63"
      ],
      "emoji": "\ud83c\uddf5\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PH.svg"
    },
    "PN": {
      "name": "Pitcairn",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Pitcairn": "-08:00"
      },
      "iso": {
        "alpha-2": "PN",
        "alpha-3": "PCN",
        "numeric": "612"
      },
      "phone": [
        "+870"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PN.svg"
    },
    "PL": {
      "name": "Poland",
      "region": "Europe",
      "timezones": {
        "Europe\/Warsaw": "+02:00"
      },
      "iso": {
        "alpha-2": "PL",
        "alpha-3": "POL",
        "numeric": "616"
      },
      "phone": [
        "+48"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PL.svg"
    },
    "PT": {
      "name": "Portugal",
      "region": "Europe",
      "timezones": {
        "Atlantic\/Azores": "+00:00",
        "Atlantic\/Madeira": "+01:00",
        "Europe\/Lisbon": "+01:00"
      },
      "iso": {
        "alpha-2": "PT",
        "alpha-3": "PRT",
        "numeric": "620"
      },
      "phone": [
        "+351"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PT.svg"
    },
    "PR": {
      "name": "Puerto Rico",
      "region": "Americas",
      "timezones": {
        "America\/Puerto_Rico": "-04:00"
      },
      "iso": {
        "alpha-2": "PR",
        "alpha-3": "PRI",
        "numeric": "630"
      },
      "phone": [
        "+1-787",
        "+1-939"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PR.svg"
    },
    "QA": {
      "name": "Qatar",
      "region": "Asia",
      "timezones": {
        "Asia\/Qatar": "+03:00"
      },
      "iso": {
        "alpha-2": "QA",
        "alpha-3": "QAT",
        "numeric": "634"
      },
      "phone": [
        "+974"
      ],
      "emoji": "\ud83c\uddf6\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/QA.svg"
    },
    "RO": {
      "name": "Romania",
      "region": "Europe",
      "timezones": {
        "Europe\/Bucharest": "+03:00"
      },
      "iso": {
        "alpha-2": "RO",
        "alpha-3": "ROU",
        "numeric": "642"
      },
      "phone": [
        "+40"
      ],
      "emoji": "\ud83c\uddf7\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/RO.svg"
    },
    "RU": {
      "name": "Russian Federation",
      "region": "Europe",
      "timezones": {
        "Asia\/Anadyr": "+12:00",
        "Asia\/Barnaul": "+07:00",
        "Asia\/Chita": "+09:00",
        "Asia\/Irkutsk": "+08:00",
        "Asia\/Kamchatka": "+12:00",
        "Asia\/Khandyga": "+09:00",
        "Asia\/Krasnoyarsk": "+07:00",
        "Asia\/Magadan": "+11:00",
        "Asia\/Novokuznetsk": "+07:00",
        "Asia\/Novosibirsk": "+07:00",
        "Asia\/Omsk": "+06:00",
        "Asia\/Sakhalin": "+11:00",
        "Asia\/Srednekolymsk": "+11:00",
        "Asia\/Tomsk": "+07:00",
        "Asia\/Ust-Nera": "+10:00",
        "Asia\/Vladivostok": "+10:00",
        "Asia\/Yakutsk": "+09:00",
        "Asia\/Yekaterinburg": "+05:00",
        "Europe\/Astrakhan": "+04:00",
        "Europe\/Kaliningrad": "+02:00",
        "Europe\/Kirov": "+03:00",
        "Europe\/Moscow": "+03:00",
        "Europe\/Samara": "+04:00",
        "Europe\/Saratov": "+04:00",
        "Europe\/Ulyanovsk": "+04:00",
        "Europe\/Volgograd": "+03:00"
      },
      "iso": {
        "alpha-2": "RU",
        "alpha-3": "RUS",
        "numeric": "643"
      },
      "phone": [
        "+7"
      ],
      "emoji": "\ud83c\uddf7\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/RU.svg"
    },
    "RW": {
      "name": "Rwanda",
      "region": "Africa",
      "timezones": {
        "Africa\/Kigali": "+02:00"
      },
      "iso": {
        "alpha-2": "RW",
        "alpha-3": "RWA",
        "numeric": "646"
      },
      "phone": [
        "+250"
      ],
      "emoji": "\ud83c\uddf7\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/RW.svg"
    },
    "RE": {
      "name": "R\u00e9union",
      "region": "Africa",
      "timezones": {
        "Indian\/Reunion": "+04:00"
      },
      "iso": {
        "alpha-2": "RE",
        "alpha-3": "REU",
        "numeric": "638"
      },
      "phone": [
        "+262"
      ],
      "emoji": "\ud83c\uddf7\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/RE.svg"
    },
    "BL": {
      "name": "Saint Barth\u00e9lemy",
      "region": "Americas",
      "timezones": {
        "America\/St_Barthelemy": "-04:00"
      },
      "iso": {
        "alpha-2": "BL",
        "alpha-3": "BLM",
        "numeric": "652"
      },
      "phone": [
        "+590"
      ],
      "emoji": "\ud83c\udde7\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/BL.svg"
    },
    "SH": {
      "name": "Saint Helena, Ascension and Tristan da Cunha",
      "region": "Africa",
      "timezones": {
        "Atlantic\/St_Helena": "+00:00"
      },
      "iso": {
        "alpha-2": "SH",
        "alpha-3": "SHN",
        "numeric": "654"
      },
      "phone": [
        "+290"
      ],
      "emoji": "\ud83c\uddf8\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SH.svg"
    },
    "KN": {
      "name": "Saint Kitts and Nevis",
      "region": "Americas",
      "timezones": {
        "America\/St_Kitts": "-04:00"
      },
      "iso": {
        "alpha-2": "KN",
        "alpha-3": "KNA",
        "numeric": "659"
      },
      "phone": [
        "+1-869"
      ],
      "emoji": "\ud83c\uddf0\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/KN.svg"
    },
    "LC": {
      "name": "Saint Lucia",
      "region": "Americas",
      "timezones": {
        "America\/St_Lucia": "-04:00"
      },
      "iso": {
        "alpha-2": "LC",
        "alpha-3": "LCA",
        "numeric": "662"
      },
      "phone": [
        "+1-758"
      ],
      "emoji": "\ud83c\uddf1\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LC.svg"
    },
    "MF": {
      "name": "Saint Martin (French part)",
      "region": "Americas",
      "timezones": {
        "America\/Marigot": "-04:00"
      },
      "iso": {
        "alpha-2": "MF",
        "alpha-3": "MAF",
        "numeric": "663"
      },
      "phone": [
        "+590"
      ],
      "emoji": "\ud83c\uddf2\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/MF.svg"
    },
    "PM": {
      "name": "Saint Pierre and Miquelon",
      "region": "Americas",
      "timezones": {
        "America\/Miquelon": "-02:00"
      },
      "iso": {
        "alpha-2": "PM",
        "alpha-3": "SPM",
        "numeric": "666"
      },
      "phone": [
        "+508"
      ],
      "emoji": "\ud83c\uddf5\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/PM.svg"
    },
    "VC": {
      "name": "Saint Vincent and the Grenadines",
      "region": "Americas",
      "timezones": {
        "America\/St_Vincent": "-04:00"
      },
      "iso": {
        "alpha-2": "VC",
        "alpha-3": "VCT",
        "numeric": "670"
      },
      "phone": [
        "+1-784"
      ],
      "emoji": "\ud83c\uddfb\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VC.svg"
    },
    "WS": {
      "name": "Samoa",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Apia": "+13:00"
      },
      "iso": {
        "alpha-2": "WS",
        "alpha-3": "WSM",
        "numeric": "882"
      },
      "phone": [
        "+685"
      ],
      "emoji": "\ud83c\uddfc\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/WS.svg"
    },
    "SM": {
      "name": "San Marino",
      "region": "Europe",
      "timezones": {
        "Europe\/San_Marino": "+02:00"
      },
      "iso": {
        "alpha-2": "SM",
        "alpha-3": "SMR",
        "numeric": "674"
      },
      "phone": [
        "+378"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SM.svg"
    },
    "ST": {
      "name": "Sao Tome and Principe",
      "region": "Africa",
      "timezones": {
        "Africa\/Sao_Tome": "+00:00"
      },
      "iso": {
        "alpha-2": "ST",
        "alpha-3": "STP",
        "numeric": "678"
      },
      "phone": [
        "+239"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ST.svg"
    },
    "SA": {
      "name": "Saudi Arabia",
      "region": "Asia",
      "timezones": {
        "Asia\/Riyadh": "+03:00"
      },
      "iso": {
        "alpha-2": "SA",
        "alpha-3": "SAU",
        "numeric": "682"
      },
      "phone": [
        "+966"
      ],
      "emoji": "\ud83c\uddf8\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SA.svg"
    },
    "SN": {
      "name": "Senegal",
      "region": "Africa",
      "timezones": {
        "Africa\/Dakar": "+00:00"
      },
      "iso": {
        "alpha-2": "SN",
        "alpha-3": "SEN",
        "numeric": "686"
      },
      "phone": [
        "+221"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SN.svg"
    },
    "RS": {
      "name": "Serbia",
      "region": "Europe",
      "timezones": {
        "Europe\/Belgrade": "+02:00"
      },
      "iso": {
        "alpha-2": "RS",
        "alpha-3": "SRB",
        "numeric": "688"
      },
      "phone": [
        "+381"
      ],
      "emoji": "\ud83c\uddf7\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/RS.svg"
    },
    "SC": {
      "name": "Seychelles",
      "region": "Africa",
      "timezones": {
        "Indian\/Mahe": "+04:00"
      },
      "iso": {
        "alpha-2": "SC",
        "alpha-3": "SYC",
        "numeric": "690"
      },
      "phone": [
        "+248"
      ],
      "emoji": "\ud83c\uddf8\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SC.svg"
    },
    "SL": {
      "name": "Sierra Leone",
      "region": "Africa",
      "timezones": {
        "Africa\/Freetown": "+00:00"
      },
      "iso": {
        "alpha-2": "SL",
        "alpha-3": "SLE",
        "numeric": "694"
      },
      "phone": [
        "+232"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SL.svg"
    },
    "SG": {
      "name": "Singapore",
      "region": "Asia",
      "timezones": {
        "Asia\/Singapore": "+08:00"
      },
      "iso": {
        "alpha-2": "SG",
        "alpha-3": "SGP",
        "numeric": "702"
      },
      "phone": [
        "+65"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SG.svg"
    },
    "SX": {
      "name": "Sint Maarten (Dutch part)",
      "region": "Americas",
      "timezones": {
        "America\/Lower_Princes": "-04:00"
      },
      "iso": {
        "alpha-2": "SX",
        "alpha-3": "SXM",
        "numeric": "534"
      },
      "phone": [
        "+599"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddfd",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SX.svg"
    },
    "SK": {
      "name": "Slovakia",
      "region": "Europe",
      "timezones": {
        "Europe\/Bratislava": "+02:00"
      },
      "iso": {
        "alpha-2": "SK",
        "alpha-3": "SVK",
        "numeric": "703"
      },
      "phone": [
        "+421"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SK.svg"
    },
    "SI": {
      "name": "Slovenia",
      "region": "Europe",
      "timezones": {
        "Europe\/Ljubljana": "+02:00"
      },
      "iso": {
        "alpha-2": "SI",
        "alpha-3": "SVN",
        "numeric": "705"
      },
      "phone": [
        "+386"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SI.svg"
    },
    "SB": {
      "name": "Solomon Islands",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Guadalcanal": "+11:00"
      },
      "iso": {
        "alpha-2": "SB",
        "alpha-3": "SLB",
        "numeric": "090"
      },
      "phone": [
        "+677"
      ],
      "emoji": "\ud83c\uddf8\ud83c\udde7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SB.svg"
    },
    "SO": {
      "name": "Somalia",
      "region": "Africa",
      "timezones": {
        "Africa\/Mogadishu": "+03:00"
      },
      "iso": {
        "alpha-2": "SO",
        "alpha-3": "SOM",
        "numeric": "706"
      },
      "phone": [
        "+252"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SO.svg"
    },
    "ZA": {
      "name": "South Africa",
      "region": "Africa",
      "timezones": {
        "Africa\/Johannesburg": "+02:00"
      },
      "iso": {
        "alpha-2": "ZA",
        "alpha-3": "ZAF",
        "numeric": "710"
      },
      "phone": [
        "+27"
      ],
      "emoji": "\ud83c\uddff\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ZA.svg"
    },
    "GS": {
      "name": "South Georgia and the South Sandwich Islands",
      "region": "Americas",
      "timezones": {
        "Atlantic\/South_Georgia": "-02:00"
      },
      "iso": {
        "alpha-2": "GS",
        "alpha-3": "SGS",
        "numeric": "239"
      },
      "phone": [
        "+"
      ],
      "emoji": "\ud83c\uddec\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GS.svg"
    },
    "SS": {
      "name": "South Sudan",
      "region": "Africa",
      "timezones": {
        "Africa\/Juba": "+02:00"
      },
      "iso": {
        "alpha-2": "SS",
        "alpha-3": "SSD",
        "numeric": "728"
      },
      "phone": [
        "+211"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SS.svg"
    },
    "ES": {
      "name": "Spain",
      "region": "Europe",
      "timezones": {
        "Africa\/Ceuta": "+02:00",
        "Atlantic\/Canary": "+01:00",
        "Europe\/Madrid": "+02:00"
      },
      "iso": {
        "alpha-2": "ES",
        "alpha-3": "ESP",
        "numeric": "724"
      },
      "phone": [
        "+34"
      ],
      "emoji": "\ud83c\uddea\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ES.svg"
    },
    "LK": {
      "name": "Sri Lanka",
      "region": "Asia",
      "timezones": {
        "Asia\/Colombo": "+05:30"
      },
      "iso": {
        "alpha-2": "LK",
        "alpha-3": "LKA",
        "numeric": "144"
      },
      "phone": [
        "+94"
      ],
      "emoji": "\ud83c\uddf1\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/LK.svg"
    },
    "SD": {
      "name": "Sudan",
      "region": "Africa",
      "timezones": {
        "Africa\/Khartoum": "+02:00"
      },
      "iso": {
        "alpha-2": "SD",
        "alpha-3": "SDN",
        "numeric": "729"
      },
      "phone": [
        "+249"
      ],
      "emoji": "\ud83c\uddf8\ud83c\udde9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SD.svg"
    },
    "SR": {
      "name": "Suriname",
      "region": "Americas",
      "timezones": {
        "America\/Paramaribo": "-03:00"
      },
      "iso": {
        "alpha-2": "SR",
        "alpha-3": "SUR",
        "numeric": "740"
      },
      "phone": [
        "+597"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SR.svg"
    },
    "SJ": {
      "name": "Svalbard and Jan Mayen",
      "region": "Europe",
      "timezones": {
        "Arctic\/Longyearbyen": "+02:00"
      },
      "iso": {
        "alpha-2": "SJ",
        "alpha-3": "SJM",
        "numeric": "744"
      },
      "phone": [
        "+47"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddef",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SJ.svg"
    },
    "SZ": {
      "name": "Swaziland",
      "region": "Africa",
      "timezones": {
        "Africa\/Mbabane": "+02:00"
      },
      "iso": {
        "alpha-2": "SZ",
        "alpha-3": "SWZ",
        "numeric": "748"
      },
      "phone": [
        "+268"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SZ.svg"
    },
    "SE": {
      "name": "Sweden",
      "region": "Europe",
      "timezones": {
        "Europe\/Stockholm": "+02:00"
      },
      "iso": {
        "alpha-2": "SE",
        "alpha-3": "SWE",
        "numeric": "752"
      },
      "phone": [
        "+46"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SE.svg"
    },
    "CH": {
      "name": "Switzerland",
      "region": "Europe",
      "timezones": {
        "Europe\/Zurich": "+02:00"
      },
      "iso": {
        "alpha-2": "CH",
        "alpha-3": "CHE",
        "numeric": "756"
      },
      "phone": [
        "+41"
      ],
      "emoji": "\ud83c\udde8\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/CH.svg"
    },
    "SY": {
      "name": "Syrian Arab Republic",
      "region": "Asia",
      "timezones": {
        "Asia\/Damascus": "+03:00"
      },
      "iso": {
        "alpha-2": "SY",
        "alpha-3": "SYR",
        "numeric": "760"
      },
      "phone": [
        "+963"
      ],
      "emoji": "\ud83c\uddf8\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/SY.svg"
    },
    "TW": {
      "name": "Taiwan, Province of China",
      "region": "Asia",
      "timezones": {
        "Asia\/Taipei": "+08:00"
      },
      "iso": {
        "alpha-2": "TW",
        "alpha-3": "TWN",
        "numeric": "158"
      },
      "phone": [
        "+886"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TW.svg"
    },
    "TJ": {
      "name": "Tajikistan",
      "region": "Asia",
      "timezones": {
        "Asia\/Dushanbe": "+05:00"
      },
      "iso": {
        "alpha-2": "TJ",
        "alpha-3": "TJK",
        "numeric": "762"
      },
      "phone": [
        "+992"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddef",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TJ.svg"
    },
    "TZ": {
      "name": "Tanzania, United Republic of",
      "region": "Africa",
      "timezones": {
        "Africa\/Dar_es_Salaam": "+03:00"
      },
      "iso": {
        "alpha-2": "TZ",
        "alpha-3": "TZA",
        "numeric": "834"
      },
      "phone": [
        "+255"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TZ.svg"
    },
    "TH": {
      "name": "Thailand",
      "region": "Asia",
      "timezones": {
        "Asia\/Bangkok": "+07:00"
      },
      "iso": {
        "alpha-2": "TH",
        "alpha-3": "THA",
        "numeric": "764"
      },
      "phone": [
        "+66"
      ],
      "emoji": "\ud83c\uddf9\ud83c\udded",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TH.svg"
    },
    "TL": {
      "name": "Timor-Leste",
      "region": "Asia",
      "timezones": {
        "Asia\/Dili": "+09:00"
      },
      "iso": {
        "alpha-2": "TL",
        "alpha-3": "TLS",
        "numeric": "626"
      },
      "phone": [
        "+670"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf1",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TL.svg"
    },
    "TG": {
      "name": "Togo",
      "region": "Africa",
      "timezones": {
        "Africa\/Lome": "+00:00"
      },
      "iso": {
        "alpha-2": "TG",
        "alpha-3": "TGO",
        "numeric": "768"
      },
      "phone": [
        "+228"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TG.svg"
    },
    "TK": {
      "name": "Tokelau",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Fakaofo": "+13:00"
      },
      "iso": {
        "alpha-2": "TK",
        "alpha-3": "TKL",
        "numeric": "772"
      },
      "phone": [
        "+690"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf0",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TK.svg"
    },
    "TO": {
      "name": "Tonga",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Tongatapu": "+13:00"
      },
      "iso": {
        "alpha-2": "TO",
        "alpha-3": "TON",
        "numeric": "776"
      },
      "phone": [
        "+676"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf4",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TO.svg"
    },
    "TT": {
      "name": "Trinidad and Tobago",
      "region": "Americas",
      "timezones": {
        "America\/Port_of_Spain": "-04:00"
      },
      "iso": {
        "alpha-2": "TT",
        "alpha-3": "TTO",
        "numeric": "780"
      },
      "phone": [
        "+1-868"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf9",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TT.svg"
    },
    "TN": {
      "name": "Tunisia",
      "region": "Africa",
      "timezones": {
        "Africa\/Tunis": "+01:00"
      },
      "iso": {
        "alpha-2": "TN",
        "alpha-3": "TUN",
        "numeric": "788"
      },
      "phone": [
        "+216"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TN.svg"
    },
    "TR": {
      "name": "Turkey",
      "region": "Asia",
      "timezones": {
        "Europe\/Istanbul": "+03:00"
      },
      "iso": {
        "alpha-2": "TR",
        "alpha-3": "TUR",
        "numeric": "792"
      },
      "phone": [
        "+90"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TR.svg"
    },
    "TM": {
      "name": "Turkmenistan",
      "region": "Asia",
      "timezones": {
        "Asia\/Ashgabat": "+05:00"
      },
      "iso": {
        "alpha-2": "TM",
        "alpha-3": "TKM",
        "numeric": "795"
      },
      "phone": [
        "+993"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TM.svg"
    },
    "TC": {
      "name": "Turks and Caicos Islands",
      "region": "Americas",
      "timezones": {
        "America\/Grand_Turk": "-04:00"
      },
      "iso": {
        "alpha-2": "TC",
        "alpha-3": "TCA",
        "numeric": "796"
      },
      "phone": [
        "+1-649"
      ],
      "emoji": "\ud83c\uddf9\ud83c\udde8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TC.svg"
    },
    "TV": {
      "name": "Tuvalu",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Funafuti": "+12:00"
      },
      "iso": {
        "alpha-2": "TV",
        "alpha-3": "TUV",
        "numeric": "798"
      },
      "phone": [
        "+688"
      ],
      "emoji": "\ud83c\uddf9\ud83c\uddfb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/TV.svg"
    },
    "UG": {
      "name": "Uganda",
      "region": "Africa",
      "timezones": {
        "Africa\/Kampala": "+03:00"
      },
      "iso": {
        "alpha-2": "UG",
        "alpha-3": "UGA",
        "numeric": "800"
      },
      "phone": [
        "+256"
      ],
      "emoji": "\ud83c\uddfa\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/UG.svg"
    },
    "UA": {
      "name": "Ukraine",
      "region": "Europe",
      "timezones": {
        "Europe\/Kyiv": "+03:00",
        "Europe\/Simferopol": "+03:00"
      },
      "iso": {
        "alpha-2": "UA",
        "alpha-3": "UKR",
        "numeric": "804"
      },
      "phone": [
        "+380"
      ],
      "emoji": "\ud83c\uddfa\ud83c\udde6",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/UA.svg"
    },
    "AE": {
      "name": "United Arab Emirates",
      "region": "Asia",
      "timezones": {
        "Asia\/Dubai": "+04:00"
      },
      "iso": {
        "alpha-2": "AE",
        "alpha-3": "ARE",
        "numeric": "784"
      },
      "phone": [
        "+971"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AE.svg"
    },
    "GB": {
      "name": "United Kingdom",
      "region": "Europe",
      "timezones": {
        "Europe\/London": "+01:00"
      },
      "iso": {
        "alpha-2": "GB",
        "alpha-3": "GBR",
        "numeric": "826"
      },
      "phone": [
        "+44"
      ],
      "emoji": "\ud83c\uddec\ud83c\udde7",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/GB.svg"
    },
    "US": {
      "name": "United States",
      "region": "Americas",
      "timezones": {
        "America\/Adak": "-09:00",
        "America\/Anchorage": "-08:00",
        "America\/Boise": "-06:00",
        "America\/Chicago": "-05:00",
        "America\/Denver": "-06:00",
        "America\/Detroit": "-04:00",
        "America\/Indiana\/Indianapolis": "-04:00",
        "America\/Indiana\/Knox": "-05:00",
        "America\/Indiana\/Marengo": "-04:00",
        "America\/Indiana\/Petersburg": "-04:00",
        "America\/Indiana\/Tell_City": "-05:00",
        "America\/Indiana\/Vevay": "-04:00",
        "America\/Indiana\/Vincennes": "-04:00",
        "America\/Indiana\/Winamac": "-04:00",
        "America\/Juneau": "-08:00",
        "America\/Kentucky\/Louisville": "-04:00",
        "America\/Kentucky\/Monticello": "-04:00",
        "America\/Los_Angeles": "-07:00",
        "America\/Menominee": "-05:00",
        "America\/Metlakatla": "-08:00",
        "America\/New_York": "-04:00",
        "America\/Nome": "-08:00",
        "America\/North_Dakota\/Beulah": "-05:00",
        "America\/North_Dakota\/Center": "-05:00",
        "America\/North_Dakota\/New_Salem": "-05:00",
        "America\/Phoenix": "-07:00",
        "America\/Sitka": "-08:00",
        "America\/Yakutat": "-08:00",
        "Pacific\/Honolulu": "-10:00"
      },
      "iso": {
        "alpha-2": "US",
        "alpha-3": "USA",
        "numeric": "840"
      },
      "phone": [
        "+1"
      ],
      "emoji": "\ud83c\uddfa\ud83c\uddf8",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/US.svg"
    },
    "UM": {
      "name": "United States Minor Outlying Islands",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Midway": "-11:00",
        "Pacific\/Wake": "+12:00"
      },
      "iso": {
        "alpha-2": "UM",
        "alpha-3": "UMI",
        "numeric": "581"
      },
      "phone": [
        "+1"
      ],
      "emoji": "\ud83c\uddfa\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/UM.svg"
    },
    "UY": {
      "name": "Uruguay",
      "region": "Americas",
      "timezones": {
        "America\/Montevideo": "-03:00"
      },
      "iso": {
        "alpha-2": "UY",
        "alpha-3": "URY",
        "numeric": "858"
      },
      "phone": [
        "+598"
      ],
      "emoji": "\ud83c\uddfa\ud83c\uddfe",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/UY.svg"
    },
    "UZ": {
      "name": "Uzbekistan",
      "region": "Asia",
      "timezones": {
        "Asia\/Samarkand": "+05:00",
        "Asia\/Tashkent": "+05:00"
      },
      "iso": {
        "alpha-2": "UZ",
        "alpha-3": "UZB",
        "numeric": "860"
      },
      "phone": [
        "+998"
      ],
      "emoji": "\ud83c\uddfa\ud83c\uddff",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/UZ.svg"
    },
    "VU": {
      "name": "Vanuatu",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Efate": "+11:00"
      },
      "iso": {
        "alpha-2": "VU",
        "alpha-3": "VUT",
        "numeric": "548"
      },
      "phone": [
        "+678"
      ],
      "emoji": "\ud83c\uddfb\ud83c\uddfa",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VU.svg"
    },
    "VE": {
      "name": "Venezuela, Bolivarian Republic of",
      "region": "Americas",
      "timezones": {
        "America\/Caracas": "-04:00"
      },
      "iso": {
        "alpha-2": "VE",
        "alpha-3": "VEN",
        "numeric": "862"
      },
      "phone": [
        "+58"
      ],
      "emoji": "\ud83c\uddfb\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VE.svg"
    },
    "VN": {
      "name": "Viet Nam",
      "region": "Asia",
      "timezones": {
        "Asia\/Ho_Chi_Minh": "+07:00"
      },
      "iso": {
        "alpha-2": "VN",
        "alpha-3": "VNM",
        "numeric": "704"
      },
      "phone": [
        "+84"
      ],
      "emoji": "\ud83c\uddfb\ud83c\uddf3",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VN.svg"
    },
    "VG": {
      "name": "Virgin Islands, British",
      "region": "Americas",
      "timezones": {
        "America\/Tortola": "-04:00"
      },
      "iso": {
        "alpha-2": "VG",
        "alpha-3": "VGB",
        "numeric": "092"
      },
      "phone": [
        "+1-284"
      ],
      "emoji": "\ud83c\uddfb\ud83c\uddec",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VG.svg"
    },
    "VI": {
      "name": "Virgin Islands, U.S.",
      "region": "Americas",
      "timezones": {
        "America\/St_Thomas": "-04:00"
      },
      "iso": {
        "alpha-2": "VI",
        "alpha-3": "VIR",
        "numeric": "850"
      },
      "phone": [
        "+1-340"
      ],
      "emoji": "\ud83c\uddfb\ud83c\uddee",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/VI.svg"
    },
    "WF": {
      "name": "Wallis and Futuna",
      "region": "Oceania",
      "timezones": {
        "Pacific\/Wallis": "+12:00"
      },
      "iso": {
        "alpha-2": "WF",
        "alpha-3": "WLF",
        "numeric": "876"
      },
      "phone": [
        "+681"
      ],
      "emoji": "\ud83c\uddfc\ud83c\uddeb",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/WF.svg"
    },
    "YE": {
      "name": "Yemen",
      "region": "Asia",
      "timezones": {
        "Asia\/Aden": "+03:00"
      },
      "iso": {
        "alpha-2": "YE",
        "alpha-3": "YEM",
        "numeric": "887"
      },
      "phone": [
        "+967"
      ],
      "emoji": "\ud83c\uddfe\ud83c\uddea",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/YE.svg"
    },
    "ZM": {
      "name": "Zambia",
      "region": "Africa",
      "timezones": {
        "Africa\/Lusaka": "+02:00"
      },
      "iso": {
        "alpha-2": "ZM",
        "alpha-3": "ZMB",
        "numeric": "894"
      },
      "phone": [
        "+260"
      ],
      "emoji": "\ud83c\uddff\ud83c\uddf2",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ZM.svg"
    },
    "ZW": {
      "name": "Zimbabwe",
      "region": "Africa",
      "timezones": {
        "Africa\/Harare": "+02:00"
      },
      "iso": {
        "alpha-2": "ZW",
        "alpha-3": "ZWE",
        "numeric": "716"
      },
      "phone": [
        "+263"
      ],
      "emoji": "\ud83c\uddff\ud83c\uddfc",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/ZW.svg"
    },
    "AX": {
      "name": "\u00c5land Islands",
      "region": "Europe",
      "timezones": {
        "Europe\/Mariehamn": "+03:00"
      },
      "iso": {
        "alpha-2": "AX",
        "alpha-3": "ALA",
        "numeric": "248"
      },
      "phone": [
        "+358-18"
      ],
      "emoji": "\ud83c\udde6\ud83c\uddfd",
      "image": "https:\/\/cdn.jsdelivr.net\/npm\/country-flag-emoji-json@2.0.0\/dist\/images\/AX.svg"
    }
  }
}