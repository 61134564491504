import { ObjectData } from "../objectData";
import { AccountAttributes } from "./accountAttributes";
import { AccountRelationships } from "./accountRelationships";

export class Account extends ObjectData<AccountAttributes, AccountRelationships> {
  constructor(data: ObjectData<AccountAttributes, AccountRelationships>, included: Object[]) {
    super(data, included);

    if (data.relationships && included) {
      this.relationships = Account.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): AccountRelationships {
    let invoiceRelationships = new AccountRelationships();


    return invoiceRelationships;
  }

}