import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LoaderService } from 'src/app/services/loader.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { User } from 'src/app/utilities/models/user/user';
import { MeetingComponent } from 'src/app/shared/meeting/meeting-form.component';

@Component({
  selector: 'accountManager',
  templateUrl: './accountManager.component.html',
  styleUrls: ['./accountManager.component.scss']
})
export class AccountManagerComponent implements OnInit {
  accountManager: User | undefined;
  isAccountManagerAvailable: boolean = false;
  loggedInUser: User;
  meetingCreatePermission: Permission | undefined;
  showInfoTooltip: boolean = true;
  showFullInfo: boolean = false;
  permission: Permission | undefined;

  constructor(private destroyRef: DestroyRef,
              private router: Router,
              private msg: NzMessageService,
              private modal: NzModalService,
              private loaderService: LoaderService,
              private userService: UsersService,
              private ticketService: TicketsService) {}

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    this.permission = this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'my_account_manager');
    this.meetingCreatePermission = this.userService.findPermission(
      'Ticketing::Meeting',
      'ticketing/operator/v1/meetings',
      'create'
    );
    this.getAccountManager();
  }

  private getAccountManager() {
    if(this.permission) {
      this.userService.getAccountManager()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            if(response?.data) {
              this.accountManager = new User(response.data, response.included);

              this.isAccountManagerAvailable = !!(this.accountManager?.id && this.accountManager?.relationships?.user_panel?.attributes?.meeting_days?.length && this.accountManager?.relationships?.user_panel?.attributes?.meeting_available_from && this.accountManager?.relationships?.user_panel?.attributes?.meeting_available_until);
            }
          }
        });
    }
  }

  closeInfoTooltip() {
    this.showInfoTooltip = false;
  }

  closeFullInfoTooltip() {
    this.showFullInfo = false;
  }

  showFullInfoTooltip() {
    this.showInfoTooltip = false;
    this.showFullInfo = true;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.msg.success('Text copied')
  }

  onShowMeetingModal() {
    this.showFullInfo = false;

    const modal = this.modal.create<MeetingComponent>({
      nzClassName: 'meeting-modal',
      nzClosable: false,
      nzContent: MeetingComponent,
      nzData: {
        requested_agent: this.accountManager,
        requester: this.loggedInUser,
      },
      nzFooter: null,
      nzMask: false,
      nzTitle: '',
      nzWidth: '90vw'
    });

    modal.afterClose.subscribe(() => {
      this.loaderService.setLoadedText('Your meeting was created!');
      this.loaderService.setLoadedSecondaryText('');

      setTimeout(() => {
        this.loaderService.setProcessing(false);
        this.loaderService.setLoaderVisible(false);
      }, 2000);
    });
  }
}
