import { Component, DestroyRef, effect, model, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { AccountManagementFormGroup } from 'src/app/utilities/models/accountManagement/forms';
import { User } from 'src/app/utilities/models/user/user';
import { TicketCreateDto, TicketCreateDtoRelationship } from 'src/app/utilities/models/dto/ticketCreateDto';

@Component({
  selector: 'account-management-returns',
  templateUrl: './returns.component.html',
  styleUrl: './returns.component.scss'
})
export class AccountManagementReturnsComponent implements OnInit {
  formGroup: FormGroup;
  formGroups: AccountManagementFormGroup[] = [
    {
      label: 'Damaged or Defective Item',
      fields: [
        {
          label: 'Order or Invoice Number',
          name: 'order_number',
          required: true,
          type: 'text',
        },
        {
          label: 'Description of Damage or Defect',
          name: 'description',
          required: true,
          type: 'wysiwyg',
        },
        {
          label: 'Preferred Resolution',
          name: 'preferred_resolution',
          options: [
            { label: 'Replacement', value: 'Replacement' },
            { label: 'Refund', value: 'Refund' },
            { label: 'Repair', value: 'Repair' }
          ],
          required: true,
          type: 'selector',
        },
        {
          label: 'Comments or Additional Details',
          name: 'additional_details',
          required: false,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'Incorrect Item Received',
      fields: [
        {
          label: 'Order or Invoice Number',
          name: 'order_number',
          required: true,
          type: 'text',
        },
        {
          label: 'Date of Delivery',
          name: 'delivery_date',
          required: true,
          type: 'date',
        },
        {
          label: 'Preferred Resolution (Exchange or Refund)',
          name: 'preferred_resolution',
          options: [
            { label: 'Exchange', value: 'Exchange' },
            { label: 'Refund', value: 'Refund' },
          ],
          required: true,
          type: 'selector',
        },
        {
          label: 'Comments or Additional Details',
          name: 'comments',
          required: false,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'Order Cancellation',
      fields: [
        {
          label: 'Order or Invoice Number',
          name: 'order_number',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for Cancellation',
          name: 'cancellation_reason',
          required: true,
          type: 'text',
        },
        {
          label: 'Items to Cancel (if partial order)',
          name: 'items_to_cancel',
          required: true,
          type: 'text',
        },
        {
          label: 'Comments or Additional Details',
          name: 'comments_additional',
          required: false,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'Other Return Reason',
      fields: [
        {
          label: 'Order or Invoice Number',
          name: 'order_number',
          required: true,
          type: 'text',
        },
        {
          label: 'Item Description',
          name: 'item_description',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for Return',
          name: 'reason_for_return',
          required: true,
          type: 'text',
        },
        {
          label: 'Comments or Additional Details',
          name: 'comment_details',
          required: false,
          type: 'wysiwyg',
        }
      ]
    }
  ];
  formGroupOptions: string[] = [];
  isLoaderVisible: boolean = false;
  loggedInUser: User;
  selectedFormGroupIndex = model<number>(0);
  selectedFormGroup: AccountManagementFormGroup;

  constructor(private destroyRef: DestroyRef,
              private router: Router,
              private loaderService: LoaderService,
              private ticketService: TicketsService,
              private userService: UsersService)
  {
    effect(() => {
      this.initFormGroup();
    });
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });

    this.formGroupOptions = this.formGroups.map((item) => item.label);
  }

  onSubmit() {
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your request is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your request was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: TicketCreateDto = this.preparePayload();
    this.ticketService.createTicket(payload).subscribe({
      next: (response: any) => {
        this.formGroup.reset();
        this.loaderService.setProcessing(false);

        setTimeout(() => {
          this.loaderService.setLoaderVisible(false);
          if (response.data.attributes.code) {
            this.router
              .navigate(['/app', 'support', 'tickets', response.data.attributes.code])
              .then();
          }
        }, 2000);
      }
    });
  }

  private initFormGroup() {
    this.formGroup = new FormGroup({});

    this.selectedFormGroup = this.formGroups[this.selectedFormGroupIndex()];
    this.selectedFormGroup?.fields?.forEach((field: any) => {
      let initialValue: any = '';

      switch (field.type) {
        case 'boolean': initialValue = false; break;
        case 'date': initialValue = new Date(); break;
        case 'number': initialValue = 1; break;
        case 'text':
        case 'wysiwyg': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      this.formGroup.addControl(field.name, formControl);
    });

    this.formGroup.updateValueAndValidity();
  }

  private prepareData(type: string, valueId: number) {
    return { data: { id: valueId, type: type }};
  }

  private preparePayload(): TicketCreateDto {
    const subject = 'Return an Order';
    let description = `<p>${this.selectedFormGroup.label}</p><br><ul>`;
    Object.keys(this.formGroup.value).forEach((key: string) => {
      const field = this.selectedFormGroup?.fields.find((field) => field.name == key);

      if (field) {
        let value = this.formGroup.value[key];

        if (value != null && typeof value !== undefined) {
          if (field.type == 'boolean') {
            value = (value == true) ? 'Yes' : 'No';
          }
        }
      }
    });
    description += '<ul>';

    const ticketCreateAttributes = { subject, description };
    const ticketCreateRelationships: TicketCreateDtoRelationship = {
      account: this.prepareData('accounts', this.loggedInUser!.relationships!.account!.id),
      requester: this.prepareData('users', this.loggedInUser.id),
    };

    return new TicketCreateDto('tickets', ticketCreateAttributes, ticketCreateRelationships);
  }
}
