import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MeetingCreateDto } from '../utilities/models/dto/meetingCreateDto';
import { MeetingUpdateDto } from '../utilities/models/dto/meetingUpdateDto';

@Injectable({ providedIn: 'root' })
export class MeetingsService {
  constructor(private http: HttpClient) {}

  create(payload: MeetingCreateDto) {
    return this.http.post(`${environment.api_url}operator/v1/meetings`, payload);
  }

  update(payload: MeetingUpdateDto) {
    return this.http.patch(`${environment.api_url}operator/v1/meetings/${payload.data.id}`, payload);
  }

  getList(userId: number, startTime?: string) {
    let requestParams: { [key: string]: string | number } = {
      "include": 'ticket',
      "page[per]": 10000,
      "page[number]": 1,
      "q[requester_id_eq]": userId,
      "sort": "start_time",
    };

    if (startTime) {
      requestParams = {
        ...requestParams,
        "q[start_time_gt]": startTime
      }
    }

    return this.http.get(`${environment.api_url}operator/v1/meetings`, { params: requestParams });
  }
}
