import { QueryParam } from "../parameters/queryParam/queryParam";

export class SavedQueryParam {
  title: string;
  totalCount: number = 0;
  loading: boolean = true;
  queryParams: QueryParam[];
  optionId?: number;

  constructor(title: string, queryParams: QueryParam[], optionsId?: number) {
    this.title = title;
    this.queryParams = queryParams;
    this.optionId = optionsId;
  }
}
