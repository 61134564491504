export interface ServiceAddRequestDataAttributes{
  is_removal: boolean;
  notes: string;
}

export interface ServiceAddRequestDataRelationships{
  service_metric: {
    data: {
      id: number;
      type: string;
    }
  }
}

export interface ServiceAddRequestDataNestedAttributes{
  contact_request_details: {
    data: ServiceAddRequestDetails[]
  }
}

export interface ServiceAddRequestDetails {
  type: string;
  attributes: {
    col1: any;
    col2: any;
    col3: any;
    col4: any;
    col5: any;
  }
}

export interface ServiceAddRequestData {
  type: string;
  attributes: ServiceAddRequestDataAttributes;
  relationships: ServiceAddRequestDataRelationships;
  nested_attributes: ServiceAddRequestDataNestedAttributes;
}

export class ServiceAddRequestDto {
  data: ServiceAddRequestData;

  constructor(data: ServiceAddRequestData){
    this.data = {
      type: data.type,
      attributes: data.attributes,
      relationships: data.relationships,
      nested_attributes: data.nested_attributes
    };
  }
}
