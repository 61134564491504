import { Permission } from "../models/permissions/permission";

export default class PermissionHelper {
  public static disabledByProperty(permission: Permission | undefined, attributeName: string): boolean {
    if(!permission) return false;

    switch (permission.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  public static disabledByAction(permission: Permission, action: string): boolean {
    return !permission;
  }

  public static permissionFor(permissions: Permission[], model: string, controller: string, action: string) {
    return permissions.find(perm => perm.associated_model === model && perm.associated_controller === controller && perm.associated_action === action);
  }

  public static isFieldRequired(permission: Permission | undefined, attributeName: string): boolean {
    if(!permission) return false;

    return permission.required_fields.includes(attributeName);
  }
}
