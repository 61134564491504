<form class="set-password-form"
      nz-form
      nzLayout="vertical"
      [formGroup]="setPasswordForm">
  <div class="ant-badge profile-photo">
    <div class="set-password-form-top-text">Welcome to EMPIST 360! You have temporary access because you have not created a password. Please create one below.</div>
    <nz-avatar *ngIf="user.attributes.avatar; else noImage" [nzSize]="90" nzIcon="user" [nzSrc]="user.attributes.avatar"></nz-avatar>
    <ng-template #noImage>
      <nz-avatar [nzSize]="90" [nzText]="user.initials()" style="background-color: var(--color-gray);"></nz-avatar>
    </ng-template>

    <nz-upload nzAccept="image/*"
               nzAction="..."
               nzFileType="image/png,image/jpeg,image/jpg,image/gif"
               nzLimit="1"
               [(nzFileList)]="fileList"
               [nzShowUploadList]="false"
               [nzBeforeUpload]="onBeforeUpload">
              <span class="ant-badge-count">
                <i nz-icon [nzType]="isUploading ? 'loading' : 'camera'" [nzTheme]="'outline'"></i>
              </span>
    </nz-upload>
  </div>
  <div class="set-password-form-title">Set a password</div>
  <div class="set-password-form-subtitle">
    Secure your account
  </div>
  <div class="form-inputs" *ngIf="!showSetPasswordForm">
    <div class="input-container">
      <input nz-input type="password" class="set-password-input" placeholder="Password" data-lpignore="true" (focus)="setShowPasswordForm(true)"/>
    </div>
    <div>
      <button nz-button class="btn-blue" nzShape="round" (click)="destroyModal()">I'll do it later</button>
    </div>
  </div>
  <div class="form-inputs" *ngIf="showSetPasswordForm">
    <div class="input-container">
      <input nz-input type="password" #passwordInput formControlName="password" class="set-password-input" placeholder="Password" data-lpignore="true"/>
    </div>
    <div class="input-container">
      <input nz-input type="password" formControlName="confirm_password" class="set-password-input" placeholder="Re-type password" data-lpignore="true"/>
    </div>
    <div class="set-password-form-button-container">
      <button nz-button class="btn-blue" nzShape="round" (click)="onSubmit()">Set Password</button>
    </div>
    <div class="set-password-form-button-container">
      <button nz-button class="btn-gray" nzShape="round" (click)="destroyModal()">I'll do it later</button>
    </div>
  </div>
</form>

<custom-loader *ngIf="visibleLoader"></custom-loader>