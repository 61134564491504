import { ObjectData } from "../objectData";
import { InstalledServiceValueAttributes } from "./installedServiceValueAttributes";
import { InstalledServiceValueRelationships } from "./installedServiceValueRelationships";
import { ServiceMetricRelationAttributes, ServiceMetricRelationRelationships } from "./serviceMetricRelation";
import { InstalledServiceRelationAttributes, InstalledServiceRelationRelationships } from "./InstalledServiceRelation";

class InstalledServiceValue extends ObjectData<InstalledServiceValueAttributes, InstalledServiceValueRelationships>{
  constructor(data: ObjectData<InstalledServiceValueAttributes, InstalledServiceValueRelationships>, included: Object[]) {
    super(data, included);
    if(included){
      this.relationships = InstalledServiceValue.normalizeRelationships(data.relationships, included);
    }
  }

  public static normalizeRelationships(relationObject:any, included: any): InstalledServiceValueRelationships
  {
    const relationships: InstalledServiceValueRelationships = {};

    relationships.service_metric = InstalledServiceValue.normalizeSingleRelation<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>(relationObject, included, 'service_metric');
    relationships.installed_service = InstalledServiceValue.normalizeSingleRelation<InstalledServiceRelationAttributes, InstalledServiceRelationRelationships>(relationObject, included, 'installed_service');

    return relationships;
  }
}

export default InstalledServiceValue;
