export interface ServiceRemoveRequestDataAttributes {
  is_removal: boolean;
  affected_identifiers: string;
  notes: string;
  due_date: string;
  additional_action?: boolean;
  all_sub_services?: boolean;
}

export interface ServiceRemoveRequestDataRelationships {
  service_metric: {
    data: {
      id: number;
      type: string;
    }
  }
}

export interface ServiceRemoveRequestData {
  type: string;
  attributes: ServiceRemoveRequestDataAttributes;
  relationships: ServiceRemoveRequestDataRelationships;
}

export class ServiceRemoveRequestDto {
  data: ServiceRemoveRequestData;

  constructor(data: ServiceRemoveRequestData){
    this.data = {
      type: 'contact_requests',
      attributes: data.attributes,
      relationships: data.relationships
    };
  }
}
