import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class LoaderService {
  loadingText: string;
  loadingSecondaryText: string;
  loadedText: string;
  loadedSecondaryText: string;
  processing: boolean;
  processingSubject = new Subject<boolean>();
  loaderVisible: boolean;
  loaderVisibleSubject = new Subject<boolean>();

  setLoadingText(text: string) {
    this.loadingText = text;
  }

  setLoadingSecondaryText(text: string) {
    this.loadingSecondaryText = text;
  }

  setLoadedText(text: string) {
    this.loadedText = text;
  }

  setLoadedSecondaryText(text: string) {
    this.loadedSecondaryText = text;
  }

  setProcessing(status: boolean) {
    this.processing = status;
    this.processingSubject.next(status);
  }

  setLoaderVisible(value: boolean) {
    this.loaderVisible = value;
    this.loaderVisibleSubject.next(value);
  }
}