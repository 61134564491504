<nz-layout>
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flows']">Flows</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>New</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','flows']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24" class="text-center">
        <h1>New Flow</h1>
      </div>
    </div>

    <nz-spin nzSimple *ngIf="loading"></nz-spin>

    <nz-row nzJustify="center">
      <nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <form class="user-information-form"
            nz-form
            nzLayout="vertical"
            [formGroup]="form"
            (ngSubmit)="onSubmit()">

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24" [nzRequired]="true">Active</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-switch formControlName="active"></nz-switch>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24" [nzRequired]="true">Title</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="title" placeholder="Type the flow title"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24" [nzRequired]="true">Platform</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-select formControlName="platform_name" nzPlaceHolder="Select the platform to submit to">
              <nz-option *ngFor="let option of platforms"
                [nzLabel]="option.label"
                [nzValue]="option.value">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">New Ticket Subject</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="subject" placeholder="Type the subject here"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24">Label (Used internally to identify the flow)</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="label" placeholder="Type the label here"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="ant-row-center">
          <nz-form-label [nzSpan]="24" [nzRequired]="true">Category</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-select formControlName="flow_categories"
                       nzAllowClear
                       nzShowSearch
                       nzPlaceHolder="Select the flow category"
                       nzMode="multiple">
              <nz-option *ngFor="let flowCategory of flowCategories"
                [nzLabel]="flowCategory.attributes.title"
                [nzValue]="flowCategory.id">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-row nzJustify="end" nzGutter="10">
          <nz-col>
            <button nz-button
                    type="submit"
                    nzType="primary"
                    nzShape="round"
                    nzSize="large"
                    [nzLoading]="loading">
              Submit
            </button>
          </nz-col>
        </nz-row>
      </form>
      </nz-col>
    </nz-row>
  </nz-content>
</nz-layout>
