export class Uid {
  object: {
    id: string;
    model: string;
  };
  related_object?: Uid;

  constructor(data: Uid){
    this.object = data.object;
    if(data.related_object) {
      this.related_object = data.related_object;
    }
  }
}
