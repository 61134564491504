interface ServiceMetricUpdateData{
  id: number;
  type: string;
  attributes: ServiceMetricUpdateDataAttributes;
}

export interface ServiceMetricUpdateDataAttributes{
  title: string;
  metric_type: string;
}

export class ServiceMetricUpdateDto {
  data: ServiceMetricUpdateData;

  constructor(data: ServiceMetricUpdateData){
    this.data = {
      id: data.id,
      type: data.type,
      attributes: data.attributes
    };
  }
}
