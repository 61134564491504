import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, Observable, tap, throwError } from "rxjs";
import { TokenService } from "./token.service";
import * as CryptoJS from "crypto-js";
import { environment } from 'src/environments/environment';

const OAUTH_CLIENT = environment.oauth_client_id;
const API_URL = environment.oauth_url;
const APP_URL = environment.local_url;
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + OAUTH_SECRET)
  })
};

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private http: HttpClient,
              private tokenService: TokenService) {
  }

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  login() {
    let baseUrl = API_URL + 'oauth/authorize?';
    const codeVerifier = this.generateRandomString(128);
    localStorage.setItem('codeVerifier', codeVerifier);

    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    const params = [
      'tenant=empist',
      'response_type=code',
      'client_id=' + OAUTH_CLIENT,
      'scope=Deskware',
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + encodeURIComponent(APP_URL + "/oauth"),
    ];

    window.location.href = (baseUrl + params.join('&'));
  }

  getToken(code: string): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    let codeChallenge = localStorage.getItem('codeVerifier');

    const params = {
      'client_id': OAUTH_CLIENT,
      'code': code,
      'code_verifier': codeChallenge,
      'grant_type': 'authorization_code',
      'redirect_uri': APP_URL + "/oauth"
    }


    return this.http.post<any>(API_URL + 'oauth/token', params, HTTP_OPTIONS)
      .pipe(
        tap({
          next: res => {
            this.tokenService.saveToken(res.access_token);
            this.tokenService.saveRefreshToken(res.refresh_token);
          },
          error: err => {
            console.log(err);
          }
        })
      );
  }

  refreshToken(refreshData: any): Observable<any> {
    console.log('Auth service refresh!');
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();

    const params = {
      'refresh_token': refreshData.refresh_token,
      'client_id': OAUTH_CLIENT,
      'grant_type': 'refresh_token'
    };

    return this.http.post<any>(API_URL + 'oauth/token', params, HTTP_OPTIONS)
      .pipe(
        tap(res => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
        }),
        catchError(AuthService.handleError)
      );
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  generateRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}