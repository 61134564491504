interface AssetResourceUpdateDtoData {
  id: number;
  type: string;
  attributes?: AssetResourceUpdateDtoAttributes;
  relationships?: AssetResourceUpdateDtoRelationships
}
interface AssetResourceUpdateDtoAttributes {
  title?: string;
  description?: string;
}
interface AssetResourceUpdateDtoRelationships {
  contact: {
    data: {
      id: number;
      type: string;
    }
  };
}

export class AssetResourceUpdateDto {
  data: AssetResourceUpdateDtoData;

  constructor(id: number, type: string, attributes: AssetResourceUpdateDtoAttributes, relationships: AssetResourceUpdateDtoRelationships){
    this.data = {
      id: id,
      type: type
    }
    if(Object.keys(attributes).length) {
      this.data.attributes = attributes;
    }
    if(Object.keys(relationships).length) {
      this.data.relationships = relationships;
    }
  }
}