import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AccountReviewUsersDto } from "../utilities/models/dto/accountReviewUsersDto";
import { QueryParam } from "../utilities/models/parameters/queryParam/queryParam";
import { forkJoin, map } from "rxjs";
import { ArrayHelper } from "../utilities/helpers/arrayHelpers";

@Injectable({ providedIn: "root" })
export class DattoLicensesService {

  constructor(private http: HttpClient) {
  }

  getDattoLicenses(accountId?: number) {
    const requestUrl = environment.api_url + 'operator/v1/datto_licenses';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[billable_eq]": "true",
      "page[number]": 1,
      "page[per]": 10000,
    };
    if(accountId) requestParams['q[account_id_eq]'] = accountId;

    return this.http.get(requestUrl, { params: requestParams });
  }

  getDattoLicensesCount(accountId?: number) {
    const requestUrl = environment.api_url + 'operator/v1/datto_licenses';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[billable_eq]": "true",
      "page[per]": 1,
    };
    if(accountId) requestParams['q[account_id_eq]'] = accountId;

    return this.http.get(requestUrl, { params: requestParams });
  }

  getUnreviewedDattoLicenses() {
    //const requestUrl = environment.api_url + 'operator/v1/datto_licenses?include=account';
    const requestUrl = environment.api_url + 'operator/v1/accounts/list';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[datto_licenses_reviewed_eq]":"false",
      //"q[reviewed_eq]": "false",
      //"q[billable_eq]": "true",
      "page[per]": 10000,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  setAccountDattoLicensesAsReviewed(payload: AccountReviewUsersDto) {
    const requestUrl = environment.api_url + 'operator/v1/accounts/' + payload.data.id + '/review_datto_licenses';

    return this.http.post(requestUrl, payload);
  }

  getDattoLicensesByParams(queryParams: QueryParam[]) {
    const requestUrl = environment.api_url + 'operator/v1/datto_licenses';
    let requestParams: { [key: string]: string | number | number[] } = {
      "page[number]": 1,
      "page[per]": 10000,
    };
    queryParams.forEach((queryParam: QueryParam) => {
      requestParams[`q[${queryParam.key}]`] = queryParam.value;
    })

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAccountDattoLicensesIncludeDeleted(accountId?: number) {
    const queryParams: QueryParam[] = [];
    if(accountId) queryParams.push({ key: 'account_id_eq', value: accountId.toString() });
    queryParams.push({ key: 'billable_eq', value: 'true' });

    const deletedParams: QueryParam[] = [];
    if(accountId) deletedParams.push({ key: 'account_id_eq', value: accountId.toString() });
    deletedParams.push({ key: 'billable_eq', value: 'true' });
    deletedParams.push({ key: 'reviewed_eq', value: 'false' });
    deletedParams.push({ key: 'deleted_at_null', value: '0' });

    return forkJoin([
      this.getDattoLicensesByParams(queryParams),
      this.getDattoLicensesByParams(deletedParams)
    ]).pipe(
      map(([response1, response2]: any) => {
        return {
          data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id'])
            .map((row: any) => {
              return {
                id: row.id,
                type: row.type,
                attributes: {
                  col1: row.attributes.name,
                  col2: row.attributes.main_identifier,
                  col3: row.attributes.date_added,
                  col4: row.attributes.status ? 'Active' : 'Archived',
                  col5: row.attributes.billable ? 'Yes' : 'No',
                  reviewed: row.attributes.reviewed,
                  deleted_at: !!row.attributes.deleted_at ?? false,
                  mark_for_deletion: row.attributes.mark_for_deletion,
                }
              }
            }),
          included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
          meta: {
            total_count: response1.meta.total_count + response2.meta.total_count,
            total_pages: 1,
            current_page: 1,
          }
        }
      }))
  }

  getDattoLicensesByIds(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/datto_licenses';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[id_in]": ids.join(','),
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getDattoLicensesByIdsIncludeDeleted(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/datto_licenses';
    let requestParams1: { [key: string]: string | number | number[] } = {
      "q[id_in]": ids.join(','),
      "page[per]": 10000,
      "page[number]": 1,
    };
    let requestParams2: { [key: string]: string | number | number[] } = {
      "q[id_in]": ids.join(','),
      "page[per]": 10000,
      "page[number]": 1,
      "q[deleted_at_null]": "0"
    };

    return forkJoin([
      this.http.get(requestUrl, { params: requestParams1 }),
      this.http.get(requestUrl, { params: requestParams2 }),
    ])
    .pipe(
      map(([response1, response2]: any) => {
        return {
          data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id']),
          included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
          meta: {
            total_count: response1.meta.total_count + response2.meta.total_count,
            total_pages: 1,
            current_page: 1,
          }
        }
      })
    );
  }
}
