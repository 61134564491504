<meetings-list>
  <breadcrumbs>
    <nz-breadcrumb nzSeparator="·">
      <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
      <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Solutions</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Meetings</nz-breadcrumb-item>
    </nz-breadcrumb>
  </breadcrumbs>

  <back-button>
    <span [routerLink]="['/app','solutions']" class="back-button">
      <i nz-icon nzType="left"></i>
      <span class="txt-grey"> Back</span>
    </span>
  </back-button>
</meetings-list>
