<div nz-row class="list-container" *ngIf="service">
  <div nz-col nzSpan="24">
    <div nz-row nzJustify="start">
      <div nz-col [nzSm]="24" nzOffset="0" class="text-center">
        <h1>Service Metrics</h1>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col [nzSm]="24" nzOffset="0" class="text-center">
        This is the list of service metrics available for {{ service.attributes.title }}.
      </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row class="new-metric-container">
      <div nz-col nzSpan="18" class="text-right"></div>
      <div nz-col nzSpan="6" class="text-right" *ngIf="service && !service.attributes.external_identifier">
        <button nz-button class="btn-black" nzShape="round" (click)="openServiceMetricCreateModal()">
          Add
        </button>
      </div>
    </div>

    <div nz-row class="list-description" *ngIf="service">
      <div nz-col nzSpan="24">
        <nz-table [nzData]="serviceMetrics"
                  nzHideOnSinglePage>
          <thead>
          <tr>
            <th nzColumnKey="title">Title</th>
            <th nzColumnKey="type">Type</th>
            <th nzColumnKey="action" nzWidth="40" nzAlign="center">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let serviceMetric of serviceMetrics" (click)="openServiceMetricEditModal(serviceMetric.id)">
            <td>{{ serviceMetric.attributes.title }}</td>
            <td>{{ serviceMetric.attributes.metric_type }}</td>
            <td nzAlign="center"><a><span nz-icon nzType="edit" nzTheme="outline"></span></a></td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>