import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ReportParam } from 'src/app/utilities/models/parameters/reportParam/reportParam';
import { Ticket } from 'src/app/utilities/models/ticket/ticket';
import { LoaderService } from 'src/app/services/loader.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { TicketChageStatusDto } from 'src/app/utilities/models/dto/ticketChageStatusDto';

@Component({
  selector: 'account-management-escalatation',
  templateUrl: './escalatation.component.html',
  styleUrl: './escalatation.component.scss'
})
export class AccountManagementEscalatationComponent implements OnInit {
  loaderVisible: boolean = false;
  loading = false;
  filters: ReportParam[] = [
    { key: 'statuses.state', value: ['in_progress'], operator: ['in'] },
    { key: 'merged_into', value: ['false'], operator: ['eq'] }
  ];
  pageIndex: number = 1;
  pageSize: number = 20;
  searchString: string = '';
  searchSubject$ = new BehaviorSubject<string>('');
  tickets: Ticket[] = [];
  total: number;
  totalPages: number;

  constructor(private destroyRef: DestroyRef,
              private msg: NzMessageService,
              private loaderService: LoaderService,
              private ticketService: TicketsService,
              private userService: UsersService) {}

  ngOnInit() {
    this.setSearchSubject();
    this.getTickets();
    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.loaderVisible = value;
        }
      });
  }

  searchTickets() {
    if(this.searchString.length == 0 || this.searchString.length > 2) {
      this.searchSubject$.next(this.searchString);
    }
  }

  onEscalate(ticket: Ticket) {
    const payload = new TicketChageStatusDto(ticket.id, 'tickets', 'escalate_status');
    this.loaderService.setLoadingText('Processing your request');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your ticket has been escalated!');
    this.loaderService.setLoadedSecondaryText('Thank you');
    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);

    this.ticketService.changeStatus(ticket.attributes.code, payload)
      .subscribe({
        next:( _ : any) => {
          this.loaderService.setProcessing(false);
          setTimeout(() => {
            this.getTickets();
            this.loaderService.setLoaderVisible(false);
          }, 4000)
        }
      });
  }

  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.getTickets();
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.getTickets();
  }

  private getTickets() {
    this.loading = true;

    const query = { q: this.searchSubject$.getValue(), searchFields: ['code','subject','stripped_description'] };
    this.ticketService.getTickets({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      query,
      reportParams: this.filters,
    })
      .subscribe({
        next: (response: any) => {
          if(response.meta) {
            this.pageIndex = response.meta.current_page;
            this.total = response.meta.total_count;
            this.totalPages = response.meta.total_pages;
          }

          this.tickets = response.data.map((ticket: any) => {
            let tmpTicket = new Ticket(ticket, response.included);
            tmpTicket.relationships!.status!.attributes!.status_data = {
              background_color: this.userService.statusArray.find((status) => {
                return status.id == tmpTicket.relationships?.status?.id
              })?.background_color || 'Black'
            }

            return tmpTicket;
          });

          this.loading = false;
        },
        error: (error) => {
          this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
          this.loading = false;
          console.log(error);
        }
      });
  }

  private setSearchSubject() {
    this.searchSubject$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
      )
      .subscribe({
        next: (query: string) => {
          if (query.length > 0 && query.length < 3) return;
          this.getTickets();
        }
      })
  }
}
