export interface FlowCategoryUpdateAttributes {
  title: string;
  description: string;
  public: boolean;
  parent_id: number | null;
}

export interface UploadsData {
  data: {
    id: number;
    type: string;
  }[]
}

export interface AccountData {
  data: {
    id: number;
    type: string;
  }
}

export interface FlowCategoryUpdateRelationships {
  uploads?: UploadsData;
  accounts?: AccountData;
}

export interface FlowCategoryUpdateData {
  id: number;
  type: string;
  attributes: FlowCategoryUpdateAttributes;
  relationships: FlowCategoryUpdateRelationships;
}


export class FlowCategoryUpdateDto {
  data: FlowCategoryUpdateData;

  constructor(data: FlowCategoryUpdateData) {
    this.data = data;
  }
}