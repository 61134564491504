<nz-row nzJustify="center">
  <nz-col nzSpan="24">
    <h1 nz-typography>Two Factor Authentication</h1>
    <p *ngIf="user.attributes.otp_enabled; else notEnabled">
      Two factor authentication is enabled for your account
    </p>
    <ng-template #notEnabled>
      <p>Two factor authentication is not enabled for your account</p>
    </ng-template>

    <form [formGroup]="tfaFormGroup" nz-form (ngSubmit)="onSubmit()">
      <nz-form-item class="ant-row-center">
        <nz-form-label nzFor="otp_enabled">{{ otpEnabledLabel() }}</nz-form-label>
        <nz-switch formControlName="otp_enabled"></nz-switch>
      </nz-form-item>
      <div *ngIf="tfaFormGroup.get('otp_enabled')?.value && tfaEnable?.attributes?.provition_url">
        <div *ngIf="tfaEnable?.attributes?.provition_url" class="ant-row ant-row-center">
          <p>Scan the QR code to get your code from authenticator</p>
          <qr-code [value]="tfaEnable.attributes.provition_url" [size]="256"></qr-code>
          <p>
            or enter the following key: <b>{{ secret() }}</b><br/>
            to your authenticator app to setup your account and get your code
          </p>
        </div>

        <nz-form-item class="ant-row-center tfa-code-form-item">
          <nz-form-label nzFor="tfa_code">Code</nz-form-label>
          <input nz-input formControlName="tfa_code" placeholder="TFA code" />
        </nz-form-item>
      </div>

      <nz-row nzJustify="end">
        <nz-col>
          <button nz-button
                  type="submit"
                  nzType="primary"
                  nzShape="round"
                  nzSize="large"
                  [nzLoading]="loading"
                  [disabled]="!tfaFormGroup.get('tfa_code')?.valid">
            Submit
          </button>
        </nz-col>
      </nz-row>
    </form>
  </nz-col>
</nz-row>
