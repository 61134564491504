<nz-layout class="profile-wrapper">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Profile</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="profile-content" *ngIf="user">
    <nz-row nzJustify="center">
      <nz-col [nzSpan]="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <div class="profile-intro">
          <div class="ant-badge profile-photo">
            <nz-avatar *ngIf="user.attributes.avatar; else noImage" [nzSize]="90" nzIcon="user" [nzSrc]="user.attributes.avatar"></nz-avatar>

            <ng-template #noImage>
              <nz-avatar [nzSize]="90" [nzText]="user.initials()" style="background-color: var(--color-gray);"></nz-avatar>
            </ng-template>

            <nz-upload nzAccept="image/*"
                       nzAction="..."
                       nzFileType="image/png,image/jpeg,image/jpg,image/gif"
                       nzLimit="1"
                       [(nzFileList)]="fileList"
                       [nzShowUploadList]="false"
                       [nzBeforeUpload]="onBeforeUpload">
              <span class="ant-badge-count">
                <i nz-icon [nzType]="isUploading ? 'loading' : 'camera'" [nzTheme]="'outline'"></i>
              </span>
            </nz-upload>
          </div>

          <h1 nz-typography>Welcome, {{ user.fullname() }}</h1>
          <p nz-typography nzType="secondary">Manage your profile settings across all EMPIST products.</p>
        </div>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center" class="profile-settings">
      <nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <nz-row nzJustify="center">
          <nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="12" class="profile-privacy">
            <nz-card>
              <article>
                <nz-row nzAlign="middle" class="profile-setting">
                  <nz-col class="profile-settings-icon">
                    <nz-avatar nzIcon="user-switch" [nzSize]="90" style="background-color: var(--color-blue)"></nz-avatar>
                  </nz-col>
                  <nz-col class="profile-settings-texts">
                    <h1>Privacy & Personalization</h1>
                    <p>Set your preferences for receiving emails, notifications, and surveys.</p>
                  </nz-col>
                </nz-row>

                <footer>
                  <p ant-typography><a (click)="openManageNotificationsModal()">Manage Your Notifications</a></p>
                </footer>
              </article>
            </nz-card>
          </nz-col>
          <nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="12" class="profile-mfa">
            <nz-card>
              <article>
                <nz-row nzAlign="middle" class="profile-setting">
                  <nz-col class="profile-settings-icon">
                    <nz-avatar nzIcon="unlock" [nzSize]="90"></nz-avatar>
                  </nz-col>
                  <nz-col class="profile-settings-texts">
                    <h1>Security Recommendations</h1>
                    <p>Secure your account by enabling two-factor authentication (2FA)</p>
                  </nz-col>
                </nz-row>

                <footer>
                  <p ant-typography><a (click)="openManage2FAModal()">Activate Now</a></p>
                </footer>
              </article>
            </nz-card>
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="center">
      <nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <user-information></user-information>
      </nz-col>
    </nz-row>
  </nz-content>
</nz-layout>
