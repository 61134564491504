export interface UserUpdateAttributesDto {
  active?: boolean;
  additional_email?: string[];
  avatar?: string;
  email?: string;
  extension?: string;
  firstname?: string;
  lastname?: string;
  middlename?: string;
  mobile_phone?: string;
  office_phone?: string;
  timezone?: string;
  password?: string;
  confirm_password?: string;
  job_title?: string;

  pref_receive_emails?: boolean;
  pref_receive_survey?: boolean;
  pref_notifications?: boolean;
  pref_change_log_subscription?: boolean;
  pref_incident_subscription?: boolean;
  pref_notifications_comment?: boolean;
  pref_notifications_mention?: boolean;
  pref_notifications_reminder?: boolean;
  pref_notifications_ticket?: boolean;
  pref_notifications_vote?: boolean;
  pref_teams_channel?: boolean;
}

export interface UserUpdateRelationshipsDto {
  account_address?: {
    data: {
      id: number;
      type: string;
    }
  }
}

export class UserUpdateDto {
  data:{
    id: number
    type: string;
    attributes: UserUpdateAttributesDto;
    relationships?: UserUpdateRelationshipsDto;
  }
  id: number;
  type: string;
  attributes: UserUpdateAttributesDto;

  constructor(id: number, type: string, attributes: UserUpdateAttributesDto, relationships?: UserUpdateRelationshipsDto) {
    this.data = {
      id: id,
      type: type,
      attributes: attributes
    }
    if(relationships && Object.keys(relationships).length){
      this.data.relationships = relationships;
    }
  }
}