<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'assets']">Assets</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span
            class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div class="list-container">
          <div nz-row>
            <div nz-col nzSpan="24" class="assets-top">
              <div>
                <h1>Assets <span *ngIf="!loading">({{ total }})</span></h1>
                <div>
                  Explore all the assets in your organization. Easily filter and track asset status and assignments.<br/>
                </div>
              </div>
              <div class="info-boxes">
                <div *ngFor="let assetResourceView of staticAssetResourceViews"
                     [ngClass]="assetResourceView.title"
                     class="info-box"
                     (click)="applyAssetResourceView(assetResourceView)">
                  <div class="info-box-title"><span>{{assetResourceView.title}}</span></div>
                  <div class="info-box-content">
                    <span *ngIf="!assetResourceView.loading; else loadingCnt">{{ assetResourceView.totalCount }}</span>
                    <ng-template #loadingCnt>
                      <nz-spin nzSimple></nz-spin>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div nz-row class="list-description">
            <div nz-col nzSpan="24">
              <!-- filters begin -->
              <form nz-form
                    nzLayout="vertical"
                    [formGroup]="filtersFormGroup">
                <div nz-row>
                  <div nz-col nzSpan="24" nzOffset="0" class="asset-resource-filters">
                    <span class="no-filters-start-phrase" *ngIf="filtersCountFormGroup == 0">All assets</span>
                    <span class="clear-all-filters" *ngIf="filtersCountFormGroup > 0" (click)="clearAllFilters()">Clear all</span>
                    <span class="filters-start-phrase" *ngIf="filtersCountFormGroup > 0">Show me assets that</span>
                    <div *ngFor="let filter of getFiltersByFormGroupControls(); let i = index" class="asset-resource-filter">
                      <span class="remove-filter" (click)="removeFilterControl(filter)">
                        <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
                      </span>
                      <span class="filter-prefix">
                        {{ i > 0 ? ' and' : ''}} {{getFilterLabel(filter.computed_key, filter.label) }}
                      </span>
                      <div class="filter-control">
                        <div class="filter-control-container">
                          <div *ngIf="filter.type == 'select'">
                            <nz-select [formControlName]="filter.computed_key"
                                       [nzPlaceHolder]="'Select'"
                                       nzShowSearch
                                       nzMode="multiple"
                                       [nzTokenSeparators]="[',']"
                                       nzBorderless
                                       [nzBackdrop]="false"
                                       [nzShowArrow]="true"
                                       (ngModelChange)="onSelect($event, filter)">
                              <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                         [nzValue]="option.value"
                                         [nzLabel]="option.label + '('+option.doc_count+')'"></nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="['text','string'].includes(filter.type)">
                            <input nz-input
                                   [formControlName]="filter.computed_key"
                                   (ngModelChange)="onSelect($event, filter)"
                                   nzBorderless
                                   placeholder="type to search"/>
                          </div>
                          <div *ngIf="filter.type == 'simplified_date'">
                            <nz-select [nzPlaceHolder]="'Select'"
                                       [formControlName]="filter.computed_key"
                                       nzBorderless
                                       [nzShowArrow]="true"
                                       [nzBackdrop]="false"
                                       (ngModelChange)="onSelect($event, filter)">
                              <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                         [nzValue]="option.value"
                                         [nzLabel]="option.label"></nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="filter.type == 'date'">
                            <nz-range-picker [nzShowTime]="false"
                                             [formControlName]="filter.computed_key"
                                             (ngModelChange)="onSelect($event, filter)"
                                             [nzAllowClear]="false">
                            </nz-range-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="add-filter-container disabled"
                          *ngIf="!addFilterEnabled">
                      <span class="tooltip" nz-tooltip [nzTooltipTitle]="'Please fill in all selected filters to add more'">
                        <span class="add-filter-label">Add filter</span>
                        <a class="add-filter">
                          <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                        </a>
                      </span>
                    </span>
                    <span class="add-filter-container"
                          nz-dropdown nzTrigger="click"
                          [nzDropdownMenu]="filtersAvailable"
                          [nzPlacement]="'bottomLeft'"
                          [nzDisabled]="!addFilterEnabled"
                          *ngIf="addFilterEnabled && availableFilterOptions.length > 0">
                        <span class="add-filter-label" *ngIf="filtersCountFormGroup == 0">Add filter</span>
                        <a class="add-filter">
                          <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                        </a>
                      </span>
                    <nz-dropdown-menu #filtersAvailable="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item *ngFor="let filter of availableFilterOptions" (click)="addFilterControl(filter.key)">
                          <span>{{ filter.label }}</span>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </form>
              <!-- filters end -->
              <div nz-row *ngIf="loading" nzJustify="center">
                <div nz-col nzSpan="24" class="list-loading">
                  <nz-spin nzSimple></nz-spin>
                </div>
              </div>
              <div nz-row *ngIf="!loading">
                <div nz-col nzSpan="24" nzOffset="0">
                  <div nz-row class="list-head">
                    <div nz-col nzSpan="6" [nzXs]="16" [nzSm]="6" class="sort-column" (click)="setSort('title')">
                      Title
                      <span nz-icon nzType="caret-up" [nzTheme]="'outline'" *ngIf="sort.attribute == 'title' && sort.order == 'desc'"></span>
                      <span nz-icon nzType="caret-down" [nzTheme]="'outline'" *ngIf="sort.attribute == 'title' && sort.order == 'asc'"></span>
                    </div>
                    <div nz-col nzSpan="3" [nzXs]="8" [nzSm]="3" class="">Status</div>
                    <div nz-col nzSpan="3" [nzXs]="0" [nzSm]="3" class="sort-column" (click)="setSort('asset_resource_type.title')">
                      Type
                      <span nz-icon nzType="caret-up" [nzTheme]="'outline'" *ngIf="sort.attribute == 'asset_resource_type.title' && sort.order == 'desc'"></span>
                      <span nz-icon nzType="caret-down" [nzTheme]="'outline'" *ngIf="sort.attribute == 'asset_resource_type.title' && sort.order == 'asc'"></span>
                    </div>
                    <div nz-col nzSpan="3" [nzXs]="0" [nzSm]="3" class="sort-column" (click)="setSort('asset_resource_type.title')">
                      Assigned User
                    </div>
                    <div nz-col nzSpan="3" [nzXs]="0" [nzSm]="3" class="sort-column" (click)="setSort('asset_resource_manufacturer.title')">
                      Manufacturer
                      <span nz-icon nzType="caret-up" [nzTheme]="'outline'" *ngIf="sort.attribute == 'asset_resource_manufacturer.title' && sort.order == 'desc'"></span>
                      <span nz-icon nzType="caret-down" [nzTheme]="'outline'" *ngIf="sort.attribute == 'asset_resource_manufacturer.title' && sort.order == 'asc'"></span>
                    </div>
                    <div nz-col nzSpan="3" [nzXs]="0" [nzSm]="3">Serial Number</div>
                    <div nz-col nzSpan="3" [nzXs]="0" [nzSm]="3" class="sort-column" (click)="setSort('warranty_expiration_date')">Warranty Expiration</div>
                  </div>
                  <nz-divider class="head-divider"></nz-divider>
                  <div nz-row class="list-row"
                       *ngFor="let asset of assetResources"
                       [routerLink]="(!!hasAssetUpdatePermission) ? ['/app', 'assets', asset.id] : null">
                    <div nz-col
                         nzSpan="6" [nzXs]="16" [nzSm]="6"
                         class="asset-title">{{ asset.attributes.title }}</div>
                    <div nz-col
                         nzSpan="3" [nzXs]="8" [nzSm]="3"
                         class="asset-list-item">{{ asset.relationships?.asset_resource_status?.attributes?.title }}</div>
                    <div nz-col
                         nzSpan="3" [nzXs]="0" [nzSm]="3"
                         class="asset-list-item">{{ asset.relationships?.asset_resource_type?.attributes?.title }}</div>
                    <div nz-col
                         nzSpan="3" [nzXs]="0" [nzSm]="3"
                         class="asset-list-item">{{ asset.relationships?.contact?.attributes?.fullname }}</div>
                    <div nz-col
                         nzSpan="3" [nzXs]="0" [nzSm]="3"
                         class="asset-list-item">{{ asset.relationships?.asset_resource_manufacturer?.attributes?.title }}</div>
                    <div nz-col
                         nzSpan="3" [nzXs]="0" [nzSm]="3"
                         class="asset-list-item">{{ asset.attributes.serial_number }}</div>
                    <div nz-col
                        nzSpan="3" [nzXs]="0" [nzSm]="3"
                        class="asset-list-item">{{ asset.attributes.warranty_expiration_date }}</div>
                  </div>
                  <div nz-row class="pagination-container" *ngIf="!loading">
                    <div nz-col nzSpan="24">
                      <nz-pagination [nzPageIndex]="pageIndex"
                                     (nzPageIndexChange)="onPageIndexChange($event)"
                                     [nzTotal]="total"
                                     nzShowSizeChanger
                                     nzResponsive="true"
                                     [nzPageSize]="pageSize"
                                     (nzPageSizeChange)="onPageSizeChange($event)">
                      </nz-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
