import { ObjectData } from "../../objectData";
import { FlowAttributes } from "./flowAttributes";
import { FlowRelationships } from "./flowRelationships";
import { RelationshipData } from "../../relationshipData";
import { IncludedData } from "../../includedData";
import { FlowControlAttribute } from "./flowControlAttribute";
import { FlowCategoryAttribute } from "./flowCategoryAttribute";
import { FlowGroupAttribute } from "./flowGroupAttribute";

export class Flow extends ObjectData<FlowAttributes, FlowRelationships> {

  constructor(data: ObjectData<FlowAttributes, FlowRelationships>, included: Object[]) {
    super(data, included);
    this.type = data.type;
    if (data.relationships && included) {
      this.relationships = Flow.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): FlowRelationships {
    let ticketFlowRelationships = new FlowRelationships();

    ticketFlowRelationships.flow_controls = Flow.normalizeControlsRelation(relationObject, included);
    ticketFlowRelationships.flow_categories = Flow.normalizeCategoriesRelation(relationObject, included);
    ticketFlowRelationships.flow_group = Flow.normalizeGroupRelation(relationObject, included);

    return ticketFlowRelationships;
  }

  private static normalizeCategoriesRelation(relationObject: any, included: Object[]): RelationshipData<FlowCategoryAttribute>[] | undefined {
    if (!relationObject?.flow_categories?.data?.length) return undefined;

    return relationObject.flow_categories.data.map((upload: any) => {
      let relation = new RelationshipData<FlowCategoryAttribute>();

      relation.id = +upload.id;
      relation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == relation.type &&
          includedObject.id == relation.id
      });
      relation.attributes = (tmp as IncludedData<FlowCategoryAttribute>).attributes
      return relation;
    })
  }

  private static normalizeControlsRelation(relationObject: any, included: Object[]): RelationshipData<FlowControlAttribute>[] | undefined {
    if (!relationObject?.flow_controls?.data?.length) return undefined;

    return relationObject.flow_controls.data.map((data: any) => {
      let controlsRelation = new RelationshipData<FlowControlAttribute>();

      controlsRelation.id = +data.id;
      controlsRelation.type = data.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == controlsRelation.type &&
          includedObject.id == controlsRelation.id
      });
      controlsRelation.attributes = (tmp as IncludedData<FlowControlAttribute>).attributes
      return controlsRelation;
    });
  }

  private static normalizeGroupRelation(relationObject: any, included: Object[]): RelationshipData<FlowGroupAttribute> | undefined {
    let groupRelation = new RelationshipData<FlowGroupAttribute>();
    if (!relationObject?.flow_group?.data?.id) return undefined;

    groupRelation.id = +relationObject.flow_group.data.id;
    groupRelation.type = relationObject.flow_group.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == groupRelation.type &&
        includedObject.id == groupRelation.id
    });
    groupRelation.attributes = (tmp as IncludedData<FlowGroupAttribute>).attributes

    return groupRelation;
  }
}