interface ChangeRequestCreateDtoData {
  subject: string;
  description?: string;
}

export class ChangeRequestCreateDto {
  subject: string;
  description?: string;
  constructor(attributes: ChangeRequestCreateDtoData){
    this.subject = attributes.subject;
    this.description = attributes.description ?? '';
  }
}