import { ErrorHandler, Injectable } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private msg: NzMessageService){

  }

  handleError(error: any): void {
    console.log(error);
    //debugger;

    if(error.status && error.message){
     switch(error.status) {
       case 400:
         if(error.message) this.msg.error(error.message, { nzDuration: 3000, nzPauseOnHover: false });
         break;
       case 401:
         break;
       case 403:
         break;
       case 422:
         if(error.message) this.msg.error(error.message, { nzDuration: 3000, nzPauseOnHover: false });
         break;
       case 500:
         //console.error(error);
         break;
     }
    }else {
      if(error.message) {
        //this.msg.error(error.message, { nzDuration: 3000, nzPauseOnHover: false });
      }
    }
  }
}
