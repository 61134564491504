<div nz-row style="height: 100%">
  <div nz-col nzSpan="24">
    <div nz-row nzAlign="top">
      <div nz-col nzSpan="24">
        <div class="ticket-attachments" *ngIf="ticket?.relationships?.uploads?.length">
          <div nz-row class="uploads-head">
            <div nz-col nzSpan="16" [nzXs]="24" [nzSm]="24" [nzMd]="16" class="filename">Attachment</div>
            <div nz-col nzSpan="4" [nzXs]="0" [nzSm]="0" [nzMd]="4" class="date">Date</div>
            <div nz-col nzSpan="4" [nzXs]="0" [nzSm]="0" [nzMd]="4" class="size">Size</div>
          </div>
          <nz-divider></nz-divider>
          <div nz-row *ngFor="let upload of ticket?.relationships?.uploads" class="uploads-row">
            <div nz-col nzSpan="16" [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16" class="filename">
              <a class="name" [href]="upload.attributes.permalink+'&download=true'" target="_blank">
                <span *ngIf="isImage(upload)">
                  <span nz-icon nzType="eye" [nzTheme]="'outline'"></span> Image:
                </span>
                <span *ngIf="!isImage(upload)">
                  <span nz-icon nzType="file-text" [nzTheme]="'outline'"></span> Document:
                </span>
                {{ upload.attributes.title }}
              </a>
              <a class="view" [href]="upload.attributes.permalink" target="_blank">View</a>
              <a class="download" [href]="upload.attributes.permalink+'&download=true'" target="_blank">Download</a>
            </div>
            <div nz-col nzSpan="4" class="date">{{ upload.attributes.created_at | date:'mediumDate' }}</div>
            <div nz-col nzSpan="4" class="size">{{ upload.attributes.size | filesize }}</div>
          </div>
        </div>
        <div class="ticket-attachments" *ngIf="!ticket?.relationships?.uploads?.length">
          No uploads
        </div>
      </div>
    </div>
  </div>
</div>
