import { Component, DestroyRef, effect, model, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip, switchMap } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { AccountManagementFormGroup } from 'src/app/utilities/models/accountManagement/forms';
import { TicketCreateDto, TicketCreateDtoRelationship } from 'src/app/utilities/models/dto/ticketCreateDto';
import { Ticket } from 'src/app/utilities/models/ticket/ticket';
import { User } from 'src/app/utilities/models/user/user';

@Component({
  selector: 'account-management-issue',
  templateUrl: './issue.component.html',
  styleUrl: './issue.component.scss'
})
export class AccountManagementIssueComponent implements OnInit {
  formGroup: FormGroup;
  formGroups: AccountManagementFormGroup[] = [
    {
      label: 'Support Coordination',
      fields: [
        {
          label: 'Support Request Type (e.g., Technical Assistance, Account Management)',
          name: 'request_type',
          required: true,
          type: 'text',
        },
        {
          label: 'Related Ticket(s)',
          name: 'related_tickets',
          required: true,
          type: 'tickets',
        },
        {
          label: 'Issue Description',
          name: 'issue_description',
          required: true,
          type: 'wysiwyg',
        },
        {
          label: 'Priority Level',
          name: 'priority_level',
          options: [
            { label: 'Low', value: 'Low' },
            { label: 'Medium', value: 'Medium' },
            { label: 'High', value: 'High' }
          ],
          required: true,
          type: 'selector',
        }
      ]
    },
    {
      label: 'Post-Incident Follow-Up',
      fields: [
        {
          label: 'Follow-Up Request Type (e.g., Status Update, Resolution Review)',
          name: 'follow_up',
          required: true,
          type: 'text',
        },
        {
          label: 'Date of Original Incident',
          name: 'incident_date',
          required: true,
          type: 'date',
        },
        {
          label: 'Incident Summary',
          name: 'incident_summary',
          required: true,
          type: 'text',
        },
        {
          label: 'Desired Outcome or Clarification Needed',
          name: 'desired_outcome',
          required: true,
          type: 'text',
        },
        {
          label: 'Additional Feedback or Suggestions',
          name: 'additional_feedback',
          required: true,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'General Support Inquiry',
      fields: [
        {
          label: 'Inquiry Type (e.g., Service Status, Technical Question)',
          name: 'inquiry_type',
          required: true,
          type: 'text',
        },
        {
          label: 'Related Department or Service',
          name: 'related_department',
          required: true,
          type: 'text',
        },
        {
          label: 'Detailed Description of Inquiry',
          name: 'description',
          required: true,
          type: 'wysiwyg',
        }
      ]
    }
  ];
  formGroupOptions: string[] = [];
  isFetchingTickets: boolean = false;
  isLoaderVisible: boolean = false;
  loggedInUser: User;
  selectedFormGroupIndex = model<number>(0);
  selectedFormGroup: AccountManagementFormGroup;
  tickets: Ticket[] = [];
  ticketSearchSubject$ = new BehaviorSubject<string>('');

  constructor(private destroyRef: DestroyRef,
              private router: Router,
              private ticketsService: TicketsService,
              private loaderService: LoaderService,
              private userService: UsersService)
  {
    effect(() => {
      this.initFormGroup();
    });
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });

    this.formGroupOptions = this.formGroups.map((item) => item.label);
    this.setTicketSearchSubject();
  }

  onSearchTickets(value: string) {
    const query = value.trim();

    if (query.length == 0 || query.length > 2) {
      this.ticketSearchSubject$.next(query);
    }
  }

  onSubmit(){
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your request is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your request was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: TicketCreateDto = this.preparePayload();
    this.ticketsService.createTicket(payload).subscribe({
      next: (response: any) => {
        this.formGroup.reset();
        this.loaderService.setProcessing(false);

        setTimeout(() => {
          this.loaderService.setLoaderVisible(false);
          if (response.data.attributes.code) {
            this.router
              .navigate(['/app', 'support', 'tickets', response.data.attributes.code])
              .then();
          }
        }, 2000);
      }
    });
  }

  private initFormGroup() {
    this.formGroup = new FormGroup({});

    this.selectedFormGroup = this.formGroups[this.selectedFormGroupIndex()];
    this.selectedFormGroup?.fields?.forEach((field: any) => {
      let initialValue: any = '';

      switch (field.type) {
        case 'boolean': initialValue = false; break;
        case 'date': initialValue = new Date(); break;
        case 'number': initialValue = 1; break;
        case 'tickets': initialValue = []; break;
        case 'text':
        case 'wysiwyg': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      this.formGroup.addControl(field.name, formControl);
    });

    this.formGroup.updateValueAndValidity();
  }

  private setTicketSearchSubject() {
    this.ticketSearchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        switchMap(() => {
          this.tickets = [];
          this.isFetchingTickets = true;
          return this.ticketsService.getTickets({
            include: '',
            query: {
              q: this.ticketSearchSubject$.getValue(),
              searchFields: ['code','subject','stripped_description']
            },
            reportParams: [
              { key: 'statuses.state', value: ['in_progress'], operator: ['in'] },
              { key: 'merged_into', value: ['false'], operator: ['eq'] }
            ]
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (response: any) => {
          this.tickets = response?.data?.map((item: any) => new Ticket(item, response.included)) ?? [];
          this.isFetchingTickets = false;
        },
        error: (error) => {
          this.isFetchingTickets = false;
          console.log(error);
        }
      })
  }

  private prepareData(type: string, valueId: number) {
    return { data: { id: valueId, type: type }};
  }

  private preparePayload(): TicketCreateDto {
    const subject = 'Issue Resolution and Management';
    let description = `<p>${this.selectedFormGroup.label}</p><br><ul>`;

    Object.keys(this.formGroup.value).forEach((key: string) => {
      const field = this.selectedFormGroup?.fields.find((field) => field.name == key);

      if (field) {
        let value = this.formGroup.value[key];

        if (value != null && typeof value !== undefined) {
          if (field.type == 'boolean') {
            value = (value == true) ? 'Yes' : 'No';
          }

          if (field.type == 'tickets' && field.multiple) {
            value = value.join(', ');
          }

          description += `<li><b>${field.label}</b>: ${value}</li>`;
        }
      }
    });
    description += '<ul>';

    const ticketCreateAttributes = { subject, description };
    const ticketCreateRelationships: TicketCreateDtoRelationship = {
      account: this.prepareData('accounts', this.loggedInUser!.relationships!.account!.id),
      requester: this.prepareData('users', this.loggedInUser.id),
    };

    return new TicketCreateDto('tickets', ticketCreateAttributes, ticketCreateRelationships);
  }
}
