import { ObjectData } from '../../objectData';
import { ConversationRelationships } from './conversationRelationships';
import { ConversationAttributes } from './conversationAttributes';
import { RelationshipData } from "../../relationshipData";
import { UserRelationAttribute } from "../userRelationAttribute";
import { IncludedData } from "../../includedData";
import { EmailParticipantAttribute } from "../emailParticipantAttribute";

export class Conversation extends ObjectData<ConversationAttributes, ConversationRelationships> {

  constructor(data: ObjectData<ConversationAttributes, ConversationRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included)
      this.relationships = Conversation.normalizeRelation(data.relationships, included);
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ConversationRelationships {
    let userParticipantRelationships = new ConversationRelationships();

    userParticipantRelationships.user_participants = Conversation.normalizeUserParticipantsRelationship(relationObject, included, 'user_participants');
    userParticipantRelationships.conversation_participant = Conversation.normalizeConversationParticipantsRelationship(relationObject, included, 'conversation_participants');

    return userParticipantRelationships;
  }

  private static normalizeUserParticipantsRelationship(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttribute>[] | undefined {
    if (!(relationObject[key]?.data?.length > 0)) return undefined;

    return relationObject[key].data.map((upload: any) => {
      let userRelation = new RelationshipData<UserRelationAttribute>();

      userRelation.id = upload.id;
      userRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == userRelation.type &&
          includedObject.id == userRelation.id
      });
      userRelation.attributes = (tmp as IncludedData<UserRelationAttribute>).attributes
      return userRelation;
    })
  }

  private static normalizeConversationParticipantsRelationship(relationObject: any, included: Object[], key: string): RelationshipData<EmailParticipantAttribute>[] | undefined {
    if (!(relationObject[key]?.data?.length > 0)) return undefined;

    return relationObject[key].data.map((upload: any) => {
      let conversationRelation = new RelationshipData<EmailParticipantAttribute>();

      conversationRelation.id = upload.id;
      conversationRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == conversationRelation.type &&
          includedObject.id == conversationRelation.id
      });
      conversationRelation.attributes = (tmp as IncludedData<EmailParticipantAttribute>).attributes

      return conversationRelation;
    })
  }
}