import { ObjectData } from '../objectData';
import { RelationshipData } from '../relationshipData';
import { TicketRelationAttribute } from '../assetResource/ticketRelationAttribute';
import { UserRelationAttribute } from '../ticket/userRelationAttribute';

export interface MeetingAttributes {
  description: string;
  duration: number;
  media_details: string;
  media_type: 'web' | 'phone';
  start_time: string;
  end_time?: string;
  status?: string;
}

export interface MeetingRelationships {
  requester?: RelationshipData<UserRelationAttribute>;
  requested_agent?: RelationshipData<UserRelationAttribute>;
  ticket?: RelationshipData<TicketRelationAttribute>;
}

export class Meeting extends ObjectData<MeetingAttributes, MeetingRelationships> {
  constructor(data: ObjectData<MeetingAttributes, MeetingRelationships>, included: Object[]) {
    super(data, included);

    if (data.relationships && included) {
      this.relationships = Meeting.normalizeRelations(data.relationships, included);
    }
  }

  private static normalizeRelations(relationObject: any, included: Object[]): MeetingRelationships {
    let ticketRelationships: MeetingRelationships = {};

    ticketRelationships.requested_agent = Meeting.normalizeSingleRelation(relationObject, included, 'requested_agent');
    ticketRelationships.requester = Meeting.normalizeSingleRelation(relationObject, included, 'requester');
    ticketRelationships.ticket = Meeting.normalizeSingleRelation(relationObject, included, 'ticket');

    return ticketRelationships;
  }
}
