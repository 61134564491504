import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FlowsService } from "src/app/services/flows.service";
import { UsersService } from "src/app/services/users.service";
import PermissionHelper from "src/app/utilities/helpers/permissionHelper";
import { FlowControlCreateData, FlowControlCreateDto } from "src/app/utilities/models/dto/flowControlCreateDto";
import { FlowControlUpdateData, FlowControlUpdateDto } from "src/app/utilities/models/dto/flowControlUpdateDto";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { Flow } from "src/app/utilities/models/ticket/flow/flow";
import { FlowControl } from "src/app/utilities/models/ticket/flowControl/flowControl";

@Component({
  selector: 'app-flow-control-form',
  templateUrl: './flow-control-form.component.html',
  styleUrls: ['./flow-control-form.component.scss'],
})
export class FlowControlFormComponent implements OnInit {
  @Input() flow: Flow;
  @Input() flowControl: FlowControl | undefined;
  @Output() formSubmit: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() formClose: EventEmitter<string | null> = new EventEmitter<string | null>();

  flowControlForm: FormGroup;
  flowControlTypes: string[] = ['input','select','date','wysiwyg','textarea','boolean','checkbox','radio','hidden'];
  loading: boolean;
  createPermission: Permission | undefined;
  updatePermission: Permission | undefined;
  optionValue: string;
  controlType: string;
  controlOptions: {value: string, label: string}[] = [];
  flowControlTypesWithOptions: string[] = ['select','radio'];

  constructor(private flowService: FlowsService,
    private userService: UsersService,
    private destroyRef: DestroyRef) {

  }

  ngOnInit() {
    this.createPermission = this.userService.findPermission('Ticketing::FlowControl', 'ticketing/operator/v1/flow_controls', 'create');
    this.updatePermission = this.userService.findPermission('Ticketing::FlowControl', 'ticketing/operator/v1/flow_controls', 'update');

    if(this.flowControl){
      this.initUpdateForm();
      this.controlType = this.flowControl.attributes.input_type;
      if(this.flowControlTypesWithOptions.includes(this.flowControl.attributes.input_type) && this.flowControl.attributes.options) {
        this.controlOptions = this.flowControl.attributes.options;
        this.controlOptions.forEach((option, index) => {
          this.flowControlForm.addControl(`optionValue${index}`, new FormControl<string | number>( option.value ));
          this.flowControlForm.addControl(`optionLabel${index}`, new FormControl<string>( option.label ));
        });
      }
    }else{
      this.initCreateForm();
    }
    this.handleFormChanges();
  }

  closeForm() {
    this.formClose.emit();
  }

  onSubmit() {
    if(this.flowControl?.id) {
      this.updateFlowControl();
    }else{
      this.createFlowControl();
    }
  }

  delete() {
    if(!this.flowControl?.id) return;

    this.flowService.deleteFlowControl(this.flowControl.id)
      .pipe()
      .subscribe({
        next: () => {
          this.formSubmit.emit();
          this.formClose.emit();
        },
        error: (error) => { console.log(error); },
        complete: () => {},
      })
  }

  handleFormChanges() {
    this.flowControlForm.get('input_type')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: string) => {
          this.controlType = value;
        }
      })
  }

  addToOptions() {
    const optionValue = this.flowControlForm.get('optionValue')?.value;
    const optionLabel = this.flowControlForm.get('optionLabel')?.value;
    if(optionValue && optionValue.length > 0 && !this.controlOptions.find(option => option.value == optionValue && option.label == optionLabel)) {
      this.controlOptions.push({ value: optionValue, label: optionLabel });
      this.flowControlForm.get('optionValue')?.setValue('');
      this.flowControlForm.get('optionLabel')?.setValue('');

      const num = this.controlOptions.length - 1;
      this.flowControlForm.addControl(`optionValue${num}`, new FormControl<string | number>( optionValue ));
      this.flowControlForm.addControl(`optionLabel${num}`, new FormControl<string>( optionLabel ));
    }
  }

  removeFromOptions(option: string) {
    const index = this.controlOptions.findIndex((item: {value: string, label: string}) => item.value == option)
    if(index > -1) this.controlOptions.splice(index, 1);
  }

  private prepareCreateFlowControlPayload() {
    const data: FlowControlCreateData = {
      type: 'flow_controls',
      attributes: {
        input_type: this.flowControlForm.get('input_type')?.value,
        label: this.flowControlForm.get('label')?.value,
        name: this.flowControlForm.get('name')?.value,
        placeholder: this.flowControlForm.get('placeholder')?.value,
        required: this.flowControlForm.get('required')?.value,
      },
      relationships: {
        flow: {
          data: {
            id: this.flow.id,
            type: 'flows'
          }
        }
      }
    }
    if((this.flowControlTypesWithOptions).includes(data.attributes.input_type)){
      data.attributes.options = this.controlOptions;
    }

    return new FlowControlCreateDto(data);
  }

  private prepareUpdateFlowControlPayload() {
    const data: FlowControlUpdateData = {
      id: this.flowControl?.id ?? 0,
      type: 'flow_controls',
      attributes: {
        input_type: this.flowControlForm.get('input_type')?.value,
        label: this.flowControlForm.get('label')?.value,
        name: this.flowControlForm.get('name')?.value,
        placeholder: this.flowControlForm.get('placeholder')?.value,
        required: this.flowControlForm.get('required')?.value,
      },
      relationships: {}
    }
    if(this.flowControlTypesWithOptions.includes(data.attributes.input_type)){
      data.attributes.options = this.controlOptions;
    }

    return new FlowControlUpdateDto(data);
  }

  private setFlowControlForm() {
    if(this.flowControl) {
      this.flowControlForm.get('input_type')?.setValue(this.flowControl.attributes.input_type);
      this.flowControlForm.get('label')?.setValue(this.flowControl.attributes.label);
      this.flowControlForm.get('name')?.setValue(this.flowControl.attributes.name);
      this.flowControlForm.get('placeholder')?.setValue(this.flowControl.attributes.placeholder);
      this.flowControlForm.get('required')?.setValue(this.flowControl.attributes.required);
    }
  }

  private initCreateForm() {
    this.flowControlForm = new FormGroup({
      'input_type': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.createPermission, 'input_type') }, Validators.required ),
      'label': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.createPermission, 'label') }, Validators.required ),
      'name': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.createPermission, 'name') } ),
      'placeholder': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.createPermission, 'placeholder') } ),
      'required': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.createPermission, 'required') } ),
      'optionValue': new FormControl<string | number>({ value: '', disabled: false }),
      'optionLabel': new FormControl<string>({ value: '', disabled: false }),
    })
  }

  private initUpdateForm() {
    this.flowControlForm = new FormGroup({
      'input_type': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.updatePermission, 'input_type') }, Validators.required ),
      'label': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.updatePermission, 'label') }, Validators.required ),
      'name': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.updatePermission, 'name') } ),
      'placeholder': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.updatePermission, 'placeholder') } ),
      'required': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.updatePermission, 'required') } ),
      'optionValue': new FormControl<string | number>({ value: '', disabled: false }),
      'optionLabel': new FormControl<string>({ value: '', disabled: false }),
    })
    this.setFlowControlForm();
  }

  private createFlowControl() {
    if(!this.flowControlForm.valid) return;

    this.loading = true;
    const payload: FlowControlCreateDto = this.prepareCreateFlowControlPayload();
    this.flowService.createFlowControl(payload)
    .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (_response: any) => {
          this.formSubmit.emit();
          this.formClose.emit();
          this.loading = false;
        },
        error: (error) => {
          console.log(error);
          this.loading = false;
         },
        complete: () => {},
      })
  }

  private updateFlowControl() {
    if(!this.flowControlForm.valid) return;

    this.loading = true;
    const payload: FlowControlUpdateDto = this.prepareUpdateFlowControlPayload();
    this.flowService.updateFlowControl(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (_response: any) => {
          this.formSubmit.emit();
          this.formClose.emit();
          this.loading = false;
        },
        error: (error) => {
          console.log(error);
          this.loading = false;
        },
        complete: () => {},
      })
  }
}