export class ReportParam {
  key: string;
  operator: string[];
  value: string | string [] | boolean | number | number[];

  constructor(key: string, operator: string[], value: string | string [] | boolean | number | number[]) {
    this.key = key;
    this.operator = operator;
    this.value = value;
  }
}