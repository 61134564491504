interface AccountReviewUsersDataDto {
  id: number,
  type: string;
}

export class AccountReviewUsersDto {
  data: AccountReviewUsersDataDto;

  constructor(id: number){
    this.data = {
      id: id,
      type: 'accounts'
    }
  }
}
