interface MeetingCreateDtoAttributes {
  description: string;
  duration: number;
  media_details: string|null;
  media_type: string|null;
  start_time: string;
}

interface MeetingCreateDtoRelationships {
  requester: { data: { id: number, type: string } };
  requested_agent: { data: { id: number, type: string } };
  ticket?: { data: { id: number, type: string } };
}

export class MeetingCreateDto {
  data: {
    type: string;
    attributes: MeetingCreateDtoAttributes;
    relationships: MeetingCreateDtoRelationships;
  }

  constructor(payload: any) {
    this.data = {
      type: 'meetings',
      attributes: {
        description: payload?.description ?? '',
        duration: 30,
        media_details: payload?.media_details ?? null,
        media_type: payload?.media_type ?? null,
        start_time: payload.start_time,
      },
      relationships: {
        requested_agent: {
          data: { id: payload.requested_agent_id, type: 'agents' }
        },
        requester: {
          data: { id: payload.requester_id, type: 'users' }
        }
      }
    }

    if (payload?.ticket_id) {
      this.data.relationships.ticket = { data: { id: payload.ticket_id, type: 'tickets' } }
    }
  }
}
