<ng-template [nzModalTitle]>
  <p>Schedule a Meeting</p>
</ng-template>

@if (eventForm) {
  <form [formGroup]="eventForm" nz-form nzLayout="vertical">
    <nz-form-item class="form-item-requested-agent">
      <nz-form-label [nzNoColon]="true">Requested agent</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="requested_agent">
          @for (agent of assignees(); track agent.id) {
            <nz-option [nzValue]="agent.id" [nzLabel]="agent.attributes.fullname"></nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    @if (eventForm.get('requested_agent')?.value) {
      @if (isNotAvailable) {
        <nz-alert nzType="error" nzMessage="The selected agent is not available!"></nz-alert>
      } @else {
        <fieldset class="date-time-wrapper">
          <legend>Choose date & time</legend>

          <div class="date-time-fields">
            <nz-form-item class="form-item-date">
              <nz-form-control>
                <nz-date-picker *ngIf="!isBusy"
                                formControlName="start_time"
                                nzInline
                                [nzDisabledDate]="disabledDates"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="form-item-time">
              <nz-form-control>
                @if (!eventForm.get('start_time')?.value) {
                  <nz-skeleton></nz-skeleton>
                } @else if (availableTimeSlots.length) {
                  <nz-radio-group formControlName="meeting_time">
                    <label *ngFor="let slot of availableTimeSlots" nz-radio-button [nzValue]="slot">{{ slot }}</label>
                  </nz-radio-group>
                } @else {
                  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="notFoundContentTpl"></nz-empty>

                  <ng-template #notFoundContentTpl>
                    The selected agent is not available to meet on this day.
                  </ng-template>
                }
              </nz-form-control>
            </nz-form-item>
          </div>
        </fieldset>
      }
    }

    @if (eventForm.get('start_time')?.value && eventForm.get('meeting_time')?.value) {
      <fieldset class="details-wrapper">
        <legend>Enter meeting type & details</legend>

        <nz-form-item class="form-item-type">
          <nz-form-control>
            <nz-radio-group formControlName="media_type" nzButtonStyle="solid">
              <label nz-radio-button nzValue="web">Online</label>
              <label nz-radio-button nzValue="phone">Phone</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>

        @if (eventForm.get('media_type')?.value === 'phone') {
          <nz-form-item class="form-item-phone" [ngClass]="{ 'is-disabled': eventForm.get('media_details')?.disabled}">
            <nz-form-label [nzNoColon]="true">Type your phone number</nz-form-label>
            <nz-form-control>
              <app-phone-input
                [phone]="phone"
                (phoneChanged)="setPhoneNumber($event)"
              />
            </nz-form-control>
          </nz-form-item>
        }

        <nz-form-item class="form-item-description">
          <nz-form-control>
            <froala-editor formControlName="description"></froala-editor>
          </nz-form-control>
        </nz-form-item>
      </fieldset>
    }
  </form>
}

<ng-template [nzModalFooter]>
  <button nz-button
          nzShape="round"
          nzSize="large"
          (click)="onCancel()">Cancel</button>
  <button *ngIf="eventForm"
          nz-button
          nzShape="round"
          nzSize="large"
          nzType="primary"
          [disabled]="!eventForm.valid"
          (click)="onCreate()">Create</button>
</ng-template>
