import { Component, DestroyRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { UsersService } from "src/app/services/users.service";
import { User } from "src/app/utilities/models/user/user";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'mainHeader',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: User;
  menuVisible: boolean = false;

  constructor(private userService: UsersService,
              private authService: AuthService,
              private router: Router,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    this.userService.userSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(user: any) => {
          this.user = user
        }
      });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/info']).then(_ => false);
  }

  showMenu() {
    this.menuVisible = true;
  }

  hideMenu() {
    this.menuVisible = false;
  }
}