<nz-layout class="posts-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>News</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col [nzSm]="24" nzOffset="0" class="text-center">
        <h1>{{ userAccountTitle }}</h1>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col [nzSm]="24" nzOffset="0" class="text-center">
        View, create, or update news from your organization here.
      </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row class="posts-list">
      <div nz-col nzSpan="12" class="text-left"><h3><strong>All Νews</strong></h3></div>
      <div nz-col nzSpan="12" class="text-right">
        @if (hasPostCreatePermission) {
          <button nz-button class="btn-blue" nzShape="round" [routerLink]="['/app','news','new']">
            New Post
          </button>
        }
      </div>
    </div>
    <nz-list nzGrid [nzLoading]="postsLoading">
      <div *ngIf="posts?.length"
           nz-row
           [nzGutter]="16"
           class="account-posts">
        <a nz-col nzSpan="8"
             *ngFor="let post of posts"
              [nzXs]="{ span: 24 }"
              [nzSm]="{ span: 24 }"
              [nzMd]="{ span: 8 }"
              class="post-entry"
              [routerLink]="['/app','news', post.id]">
          <div *ngIf="post.attributes.featured_image_url; else noImagePost"
               [style.background-image]="'url('+post.attributes.featured_image_url+')'"
               class="post-image">
            <span *ngIf="post.attributes.sticky" class="featured">FEATURED</span>
            <span nz-icon nzType="arrow-up" [nzTheme]="'outline'" class="link-arrow"></span>
          </div>
          <ng-template #noImagePost>
            <div class="post-image no-image">
              <span *ngIf="post.attributes.sticky" class="featured">FEATURED</span>
            </div>
          </ng-template>
          <div class="post-title">
            {{ post.attributes.title }}
          </div>
          <div class="post-text">
            {{ post.attributes.description }}
          </div>
        </a>
      </div>
    </nz-list>
  </nz-content>
</nz-layout>
