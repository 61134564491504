<div nz-row nzJustify="center" *ngIf="commentsLoading">
  <nz-spin nzSimple></nz-spin>
</div>
<div nz-row class="ticket-conversation" *ngIf="!commentsLoading">
  <div nz-col nzSpan="24">
    <div nz-row *ngFor="let comment of comments" class="comment">
      <div nz-col class="comment-width">
        <div nz-row class="comment-header">
          <div nz-col nzSpan="4" [nzXs]="4" [nzSm]="4" class="comment-avatar">
            <nz-avatar nzIcon="user"
                       [nzSrc]="comment.relationships?.author?.attributes?.avatar">
            </nz-avatar>
          </div>
          <div nz-col nzSpan="18" [nzXs]="18" [nzSm]="18" class="comment-details">
            <div nz-row>
              <div nz-col nzSpan="24" class="author-name">{{comment.relationships?.author?.attributes?.fullname}}</div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24" class="comment-dates">{{comment.attributes.created_at | date: 'long'}}</div>
            </div>
          </div>
        </div>
        <div nz-row class="comment-content">
          <div nz-col [innerHtml]="comment.attributes.description" class="comment-description"></div>
        </div>
        <div *ngIf="comment?.relationships?.uploads">
          <div *ngFor="let upload of comment?.relationships?.uploads; let i = index">
            <div nz-row [class]="expandedCommentAttachments == comment.id || i < 2 ? 'uploads-row visible' : 'uploads-row'">
              <div nz-col nzSpan="24" class="filename">
                  <a class="name" [href]="upload.attributes.permalink+'&download=true'" target="_blank">
                    <span *ngIf="isImage(upload)">
                      <span nz-icon nzType="eye" [nzTheme]="'outline'"></span> Image:
                    </span>
                    <span *ngIf="!isImage(upload)">
                      <span nz-icon nzType="file-text" [nzTheme]="'outline'"></span> Document:
                    </span>
                    {{ upload.attributes.title }}
                  </a>
                <a class="view" [href]="upload.attributes.permalink" target="_blank">View</a>
                <a class="download" [href]="upload.attributes.permalink+'&download=true'" target="_blank">Download</a>
              </div>
            </div>
            <a *ngIf="i==1 && commentHasMoreAttachments(comment) && !(expandedCommentAttachments == comment.id)" (click)="expandAttachments(comment.id)" class="more-attachments">+ {{ restAttachmentsNumber(comment) }} attachments</a>
          </div>
          <a *ngIf="expandedCommentAttachments == comment.id" (click)="expandAttachments(0)">Show less</a>
        </div>
      </div>
    </div>
    <div *ngIf="comments.length == 0">
      Add a comment to start the conversation.
    </div>
  </div>
</div>
<!--  <nz-list *ngFor="let comment of comments">-->
<!--    <nz-comment [nzAuthor]="comment.relationships?.author?.attributes?.fullname"-->
<!--                [nzDatetime]="(comment.attributes.created_at | date: 'short')?.toString()"-->
<!--                [attr.id]="'#' + conversationId + '_' + comment.id">-->
<!--      <nz-avatar nz-comment-avatar-->
<!--                 nzIcon="user"-->
<!--                 [nzSrc]="comment.relationships?.author?.attributes?.avatar">-->
<!--      </nz-avatar>-->
<!--      <nz-comment-content>-->
<!--        <div class="comment-content" [innerHtml]="comment.attributes.description"></div>-->
<!--      </nz-comment-content>-->
<!--      <nz-comment-action>-->
<!--        <button nz-button-->
<!--                nz-popover-->
<!--                nzType="text"-->
<!--                [nzPopoverContent]="contentTemplate"-->
<!--                nzPopoverPlacement="top"-->
<!--                nzPopoverTrigger="hover"-->
<!--                *ngIf="comment.relationships?.participants?.length">-->
<!--          <i nz-icon nzType="user-switch" nzTheme="outline"></i>-->
<!--          <span class="count">{{ comment.relationships?.participants?.length }}</span>-->
<!--        </button>-->
<!--      </nz-comment-action>-->
<!--      <ng-template #contentTemplate>-->
<!--        <div *ngFor="let participant of comment?.relationships?.participants" class="comment-recipient">-->
<!--          <nz-avatar nz-comment-avatar-->
<!--                     nzIcon="user"-->
<!--                     [nzSize]="20"-->
<!--                     [nzSrc]="participant.attributes?.avatar">-->
<!--          </nz-avatar>-->
<!--          <span>{{ participant.attributes.fullname }}</span>-->
<!--        </div>-->
<!--        <div *ngFor="let participant of comment?.relationships?.comment_participants">-->
<!--          {{ participant.attributes.email }}-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </nz-comment>-->
<!--  </nz-list>-->
