export class TicketChageStatusDto{
  data:{
    id: number,
    type: string,
    attributes:{
      status_key: string
    }
  }

  constructor(id: number, type: string, status_key: string) {
    this.data = {
      id: id,
      type: type,
      attributes: {
        status_key: status_key
      }
    }
  }
}