<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'additional-services']">Additional Services</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="service?.id"><a>{{ service.attributes.title }}</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col nzSpan="12">
        <a [routerLink]="['/app','additional-services']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></a>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24" class="text-center">
        <h1 *ngIf="service && service.id > 0">Edit Service</h1>
        <h1 *ngIf="!service || (service && service.id == 0)">Create Service</h1>
      </div>
    </div>
    <div nz-row nzJustify="center">
      <div nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16">
        <form class="service-form"
              nz-form
              nzLayout="vertical"
              [formGroup]="serviceForm"
              (ngSubmit)="onSubmit()">
          <div class="service-edit-container">
            <div class="service-image" *ngIf="service">
              <nz-form-item class="ant-row-center">
                <nz-form-control [nzSpan]="24">
                  <div class="service-upload-cnt">
                    <div class="service-image-uploader">
                      <nz-upload nzAccept="image/*"
                                 nzAction="..."
                                 nzFileType="image/png,image/jpeg,image/jpg,image/gif"
                                 nzLimit="1"
                                 [(nzFileList)]="fileList"
                                 [nzShowUploadList]="false"
                                 [nzBeforeUpload]="onBeforeUpload">
                        <div class="upload-container" *ngIf="!service || (service && service.relationships?.uploads && service.relationships?.uploads?.length == 0)">
                          <p class="ant-upload-drag-icon">
                            <span nz-icon nzType="inbox"></span>
                          </p>
                          <p class="ant-upload-text">Click or drag file to this area to upload</p>
                        </div>
                        <span class="ant-badge-count">
                          <i nz-icon nzType="loading" [nzTheme]="'outline'" *ngIf="isUploading"></i>
                          <dw-icon type="dw:addImage" *ngIf="!isUploading"/>
                        </span>
                      </nz-upload>
                    </div>
                    <div class="service-logo">
                      <ng-container *ngIf="service && service.relationships?.uploads && service.relationships?.uploads?.length; else noImage">
                        <img [src]="upload.attributes.permalink" alt="" *ngFor="let upload of service.relationships?.uploads"/>
                      </ng-container>
                      <ng-template #noImage>
                      </ng-template>
                    </div>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="service-information">
              <nz-form-item class="ant-row-center">
                <nz-form-label [nzSpan]="24">Title</nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <input nz-input formControlName="title"/>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="ant-row-center">
                <nz-form-label [nzSpan]="24">Description</nz-form-label>
                <nz-form-control [nzSpan]="24">
              <textarea nz-input
                        formControlName="description"
                        placeholder="Provide a description for the service"
                        [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <nz-row>
            <nz-col class="update-container">
              <ng-container *ngIf="service && !service.attributes.external_identifier">
                <button nz-button
                        nz-popconfirm
                        type="button"
                        nzType="default"
                        nzDanger
                        nzShape="round"
                        nzSize="large"
                        [nzPopconfirmTitle]="DeleteConfirmContent"
                        nzPopconfirmPlacement="top"
                        (nzOnConfirm)="delete()">
                  Delete
                </button>
                <ng-template #DeleteConfirmContent>
                  Are you sure you want to delete this service?<br/>
                  <strong>All the data</strong> of the service will be deleted.<br/>
                  This action is not reversible. Proceed?
                </ng-template>
              </ng-container>
              <button nz-button
                      type="submit"
                      nzType="primary"
                      nzShape="round"
                      nzSize="large"
                      [nzLoading]="loading">
                {{ service && service.id > 0 ? 'Update' : 'Create' }}
              </button>
            </nz-col>
          </nz-row>
        </form>
      </div>
    </div>

    <div nz-row nzJustify="center" *ngIf="service && service.id > 0 && !serviceLoading" class="service-metrics">
      <div nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16" class="text-center">
        <app-manual-service-metric-list (fetchDetailsEvent)="fetchData()"></app-manual-service-metric-list>
      </div>
    </div>

    <div *ngIf="service && !service.attributes.external_identifier && !isStaticService" class="installed-services">
      <nz-divider nzType="horizontal"></nz-divider>
      <div nz-row nzJustify="center" *ngIf="service && service.id > 0">
        <div nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16" class="text-center">
          <h2 class="add-services-label">Add service to Account</h2>
        </div>
        <div nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16" class="text-center">
          <form [formGroup]="addServiceToAccountForm"
                class="add-service-to-account"
                (ngSubmit)="onAddAccountSubmit()">
            <label>Select an account and hit submit to add this service to an account</label>
            <div class="account-selector">
              <nz-select formControlName="account"
                         [nzLoading]="accountsLoading"
                         [nzPlaceHolder]="'Select account'"
                         nzSize="large"
                         nzShowSearch
                         nzServerSearch
                         (nzOnSearch)="setAccountSearchStr($event)">
                <nz-option *ngFor="let account of accounts"
                           [nzValue]="account.id"
                           [nzLabel]="account.attributes.title"
                           [nzDisabled]="accountIdsInstalled.includes(account.id)">
                </nz-option>
              </nz-select>
            </div>
            <div class="account-select-action">
              <button nz-button
                      type="submit"
                      nzType="primary"
                      nzShape="round"
                      nzSize="large"
                      class="btn-black">
                Add
              </button>
            </div>
          </form>
        </div>
      </div>

      <div nz-row nzJustify="center" *ngIf="service && service.id > 0 && installedServices?.length">
        <div nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16" class="text-center">
          <h2 *ngIf="service && service.id > 0">Services Installed to Accounts</h2>
        </div>
        <div nz-col nzSpan="24">
          <div nz-row nzJustify="center" *ngIf="installedServiceDetailsLoading">
            <nz-spin nzSimple></nz-spin>
          </div>
        </div>
        <div nz-col nzSpan="24" [nzMd]="20" [nzLg]="18" [nzXl]="16" class="text-center" *ngIf="!installedServiceDetailsLoading">
          <div *ngFor="let installedServiceData of sortedDetailsPerInstalledService">
            <div class="account-row">
              <div class="account-title">
                <span>{{ installedServiceData.account.attributes.title }}</span>
                <span nz-icon
                      nzType="delete"
                      nzTheme="outline"
                      class="delete"
                      nz-popconfirm
                      [nzPopconfirmTitle]="DeleteInstalledServiceFromAccountConfirmContent"
                      nzPopconfirmPlacement="top"
                      (nzOnConfirm)="removeServiceFromAccount(installedServiceData.installedService.id)">
                </span>
              </div>
              <div class="account-metrics" *ngIf="serviceHasDetailMetric">
                <div class="account-metric" *ngFor="let serviceMetric of detailMetrics">
                  <div class="account-metric-label">{{ serviceMetric.attributes.title }}</div>
                  <div class="account-metric-value" *ngIf="accountIdInEditMode != installedServiceData.account.id || metricIdInEditMode != serviceMetric.id">
                    <span>{{ installedServiceData.metricData[serviceMetric.id].count }}</span>
                    <span nz-icon nzType="edit" nzTheme="outline" (click)="toggleEditMode(installedServiceData.account.id, serviceMetric.id, true)"></span>
                  </div>
                  <div class="account-metric-value" *ngIf="accountIdInEditMode == installedServiceData.account.id && metricIdInEditMode == serviceMetric.id">
                    <input nz-input class="metric-input" [(ngModel)]="installedServiceData.metricData[serviceMetric.id].count"/>
                    <span nz-icon nzType="save" nzTheme="outline" (click)="updateInstalledServiceMetricDetail(installedServiceData.installedService, serviceMetric.id)"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #DeleteInstalledServiceFromAccountConfirmContent>
          Are you sure you want to delete this service for this account?<br/>
          This action is not reversible. Proceed?
        </ng-template>
      </div>
    </div>
  </nz-content>
</nz-layout>