import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { AssetResourceUpdateDto } from "src/app/utilities/models/dto/assetResourceUpdateDto";
import { AccountReviewUsersDto } from "src/app/utilities/models/dto/accountReviewUsersDto";
import { SortParam } from "src/app/utilities/models/parameters/sortParam/sortParam";
import { forkJoin, map } from "rxjs";
import { ArrayHelper } from "../utilities/helpers/arrayHelpers";

@Injectable({ providedIn: "root" })
export class AssetResourcesService {
  constructor(private http: HttpClient) {
  }

  getAssetResources(perPage: number = 20, page: number = 1, reportParams: ReportParam[], sort: SortParam) {
    const includes = ['account', 'asset_resource_manufacturer', 'asset_resource_status', 'asset_resource_type', 'contact'];
    const requestUrl = environment.api_url + 'operator/v1/asset_resources?include=' + includes.join(',');
    let requestParams: { [key: string]: string | number } = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "page[number]": page,
      "page[per]": perPage,
      "query_type": "documents"
    };
    //reset sort when having like report param operator
    if(sort.order && sort.attribute && !reportParams.some((param: ReportParam) => param.operator[0] == 'like')){
      requestParams['s['+sort.attribute+']'] = sort.order;
    }

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAssetResourcesDocumentCount(reportParams: ReportParam[]) {
    const requestUrl = environment.api_url + 'operator/v1/asset_resources';
    let requestParams: { [key: string]: string | number } = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "query_type": "documents_count"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getManagedEndpoints(perPage: number = 20, page: number = 1, reportParams: ReportParam[]) {
    const includes = ['asset_resource_type', 'kaseya_detail','contact'];
    const requestUrl = environment.api_url + 'operator/v1/asset_resources?include=' + includes.join(',');
    let requestParams: { [key: string]: string | number } = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "page[number]": page,
      "page[per]": perPage,
      "query_type": "documents"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAssetResource(id: number) {
    const includes = ['account', 'contact', 'managed_by', 'asset_resource_manufacturer', 'asset_resource_status', 'asset_resource_type', 'related_asset_resources', 'uploads', 'tickets', 'kaseya_detail', 'itglue_configuration'];
    const requestUrl = environment.api_url + 'operator/v1/asset_resources/' + id + '?include=' + includes.join(',');

    return this.http.get(requestUrl);
  }

  updateAssetResource(payload: AssetResourceUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/asset_resources/' + payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }

  getManagedEndpointsPerAccount(reportParams: ReportParam[]) {
    const requestUrl = environment.api_url + 'operator/v1/asset_resources';
    let requestParams = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "aggregations": '["accounts"]',
      "query_type": "aggregations",
    }

    return this.http.get(requestUrl,{ params: requestParams });
  }

  setAccountAssetsAsReviewed(payload: AccountReviewUsersDto) {
    const requestUrl = environment.api_url + 'operator/v1/accounts/' + payload.data.id + '/review_assets';

    return this.http.post(requestUrl, payload);
  }

  getAccountManagedEndpointsIncludeDeleted(accountId: number) {
    let reportArr: ReportParam[] = [];
    reportArr.push({ "key": "accounts.id", "operator": ["eq"], "value": [accountId] });
    reportArr.push({ "key": "kaseya_detail.external_identifier", "operator": ["exist"], "value": ["-"] });

    const deletedParams: ReportParam[] = [];
    deletedParams.push({ "key": "accounts.id", "operator": ["eq"], "value": [accountId] });
    deletedParams.push({ "key": "kaseya_detail.external_identifier", "operator": ["exist"], "value": ["-"] });
    deletedParams.push({ "key": "reviewed", "operator": ["eq"], "value": "false" });
    deletedParams.push({ "key": "deleted_at", "operator": ["exist"],  "value": "-" });

    return forkJoin([
        this.getManagedEndpoints(10000, 1, reportArr),
        this.getManagedEndpoints(10000, 1, deletedParams)
      ])
      .pipe(
        map(([response1, response2]: any) => {
          return {
            data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id']),
            included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
            meta: {
              total_count: response1.meta.total_count + response2.meta.total_count,
              total_pages: 1,
              current_page: 1,
            }
          }
        })
      )
  }

  getAssetResourcesByIds(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/asset_resources?include=kaseya_detail';
    const reportParams = [
      {key: 'id', operator: ['in'], value: ids},
      {key:"kaseya_detail.external_identifier",operator:["exist"],"value":["-"]}
    ]
    let requestParams: { [key: string]: string | number | number[] } = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAssetResourcesByIdsIncludeDeleted(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/asset_resources?include=kaseya_detail';
    const reportParams1 = [
      {key: 'id', operator: ['in'], value: ids},
      {key:"kaseya_detail.external_identifier",operator:["exist"],"value":["-"]}
    ]
    let requestParams1: { [key: string]: string | number | number[] } = {
      "w[report_params]": JSON.stringify(reportParams1).replace(/\\"/g, '"'),
      "page[per]": 10000,
      "page[number]": 1,
    };
    const reportParams2 = [
      {key: 'id', operator: ['in'], value: ids},
      {key:"kaseya_detail.external_identifier", operator:["exist"],"value":["-"]},
      {key: "deleted_at", operator: ["exist"], value: "-"}
    ]
    let requestParams2: { [key: string]: string | number | number[] } = {
      "w[report_params]": JSON.stringify(reportParams2).replace(/\\"/g, '"'),
      "page[per]": 10000,
      "page[number]": 1,
    };

    return forkJoin([
      this.http.get(requestUrl, { params: requestParams1 }),
      this.http.get(requestUrl, { params: requestParams2 }),
    ])
    .pipe(
      map(([response1, response2]: any) => {
        return {
          data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id']),
          included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
          meta: {
            total_count: response1.meta.total_count + response2.meta.total_count,
            total_pages: 1,
            current_page: 1,
          }
        }
      })
    );
  }
}
