import { AccountAttributes } from "../account/accountAttributes";
import { AccountRelationships } from "../account/accountRelationships";
import { IncludedData } from "../includedData";
import { ObjectData } from "../objectData";
import { RelationshipData } from "../relationshipData";
import { InstalledServiceAttributes } from "../service/installedServiceAttributes";
import { InstalledServiceRelationships } from "../service/installedServiceRelationships";
import { ServiceMetricAttributes } from "../service/serviceMetricAttributes";
import { ServiceMetricRelationships } from "../service/serviceMetricRelationships";
import { AccountRelationAttribute } from "../ticket/accountRelationAttribute";
import { ServiceHistoryAttributes } from "./serviceHistoryAttributes";
import { ServiceHistoryRelationships } from "./serviceHistoryRelationships";

export class ServiceHistory extends ObjectData<ServiceHistoryAttributes, ServiceHistoryRelationships> {

  constructor(data: ObjectData<ServiceHistoryAttributes, ServiceHistoryRelationships>, included: Object[]) {
    super(data, included);

    if (data.relationships && included) {
      this.relationships = ServiceHistory.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ServiceHistoryRelationships {
    let relationships = new ServiceHistoryRelationships();

    relationships.account = ServiceHistory.normalizeSingleRelation<AccountAttributes, AccountRelationships>(relationObject, included, 'account');
    relationships.installed_service = ServiceHistory.normalizeSingleRelation<InstalledServiceAttributes, InstalledServiceRelationships>(relationObject, included, 'installed_service');
    relationships.service_metric = ServiceHistory.normalizeSingleRelation<ServiceMetricAttributes, ServiceMetricRelationships>(relationObject, included, 'service_metric');
    relationships.serviceable = ServiceHistory.normalizeSingleRelation(relationObject, included, 'serviceable');

    return relationships;
  }
}