interface MeetingUpdateDtoAttributes {
  description?: string;
  duration?: number;
  media_details?: string | null;
  media_type?: string | null;
  start_time?: string;
  status: string;
}

interface MeetingUpdateDtoRelationships {
  requester?: { data: { id: number, type: string } };
  requested_agent?: { data: { id: number, type: string } };
  ticket?: { data: { id: number, type: string } };
}

export class MeetingUpdateDto {
  data: {
    id: number | string;
    type: string;
    attributes?: MeetingUpdateDtoAttributes;
    relationships?: MeetingUpdateDtoRelationships;
  }

  constructor(
    id: number | string,
    attributes?: MeetingUpdateDtoAttributes | null,
    relationships?: MeetingUpdateDtoRelationships | null
  ) {
    this.data = {
      id: id,
      type: 'meetings'
    }

    if (attributes) {
      this.data.attributes = attributes;
    }

    if (relationships) {
      this.data.relationships = relationships;
    }
  }
}
