interface ServiceMetricCreateData{
  type: string;
  attributes: ServiceMetricCreateDataAttributes;
  relationships: ServiceMetricCreateRelationships;
}

export interface ServiceMetricCreateDataAttributes{
  title: string;
  metric_type: string;
}

export interface ServiceMetricCreateRelationships{
  service: { data: { id: number; type: string; }}
}

export class ServiceMetricCreateDto {
  data: ServiceMetricCreateData;

  constructor(data: ServiceMetricCreateData){
    this.data = {
      type: data.type,
      attributes: data.attributes,
      relationships: data.relationships
    };
  }
}
