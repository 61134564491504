import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: 'loginComponent',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  constructor(private authService: AuthService) {
    authService.login();
  }
}
