export class ArrayHelper {
  static mergeUnique(array1: any[], array2: any[], attrs: string[] = ['id']) {

    const mergedArray = array1.concat(array2);
    const uniqueValues = new Set();
    const uniqueObjects = mergedArray.filter((obj) => {
      const propertyValue = attrs.map(x => obj[x]).join(',');

      if (!uniqueValues.has(propertyValue)) {
        uniqueValues.add(propertyValue);
        return true;
      }

      return false;
    });

    return uniqueObjects;
  }
}