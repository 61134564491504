<div nz-row style="height: 100%">
  <div nz-col nzSpan="24">
    <div nz-row nzAlign="top">
      <div nz-col nzSpan="24">
        <div class="ticket-conversation-items">
          <conversationComments [fragment]="urlFragment"></conversationComments>
        </div>
      </div>
    </div>
  </div>
</div>
