<nz-layout>
  <div class="top">
    <div nz-row class="header">
      <div nz-col nzSpan="12" class="logo">
        <img src="../../../assets/images/empist360-logo.svg" alt="EMPIST"/>
      </div>
      <div nz-col nzSpan="12" class="login" (click)="login()">
        <i nz-icon nzType="user" [nzTheme]="'outline'"></i>
        Login
      </div>
    </div>
    <div nz-row nzAlign="middle" class="hero-content">
      <div class="hero-icons icons-left">
        <img src="../../../assets/images/homepage-left.png" alt=""/>
      </div>
      <div class="hero-icons icons-right">
        <img src="../../../assets/images/homepage-right.png" alt=""/>
      </div>
      <div nz-col nzSpan="24" class="hero-mid">
        <div class="overImage">
          <div class="animated-circle">
            <svg version="1.1" viewBox="0 0 2409.4 1355.3" xml:space="preserve">
              <path class="st0 base-animation" d="M1195.2,350.6c-603.7,0-1093.1,129.7-1093.1,289.8c0,160,489.4,289.8,1093.1,289.8s1093.1-129.7,1093.1-289.8 C2288.3,480.4,1798.9,350.6,1195.2,350.6z M1196.7,813.4c-519.4,0-940.4-89.8-940.4-200.5c0-110.8,421-200.5,940.4-200.5 s940.4,89.8,940.4,200.5C2137.1,723.7,1716.1,813.4,1196.7,813.4z"/>
              <path id="ellipse" d="M2229.1,628.7c0,137.7-463.6,249.3-1035.5,249.3S158.1,766.4,158.1,628.7s463.6-249.3,1035.5-249.3 S2229.1,491,2229.1,628.7z" style="fill:none;"></path>
              <g class="st1">
                <polygon class="white-triangle" points="1,116 1,-116 117.7,0.6"/>
                <polygon class="blue-triangle" points="0,77.1 0,-77.1 77.5,0.4"/>
              </g>

              <g class="st2">
                <polygon class="white-triangle" points="1,116 1,-116 117.7,0.6"/>
                <polygon class="blue-triangle" points="0,77.1 0,-77.1 77.5,0.4"/>
              </g>
            </svg>
          </div>
          <p class="home-text-1">
            <span>Welcome to</span><br/>
            <span class="gradient-text">EMPIST 360</span>
          </p>
          <p class="home-text-2">
            Take the guesswork out of your IT.
            Get a 360° view of your technology partnership
            in one convenient place.
          </p>
          <p>
            <button nz-button nzType="primary" nzSize="large" nzShape="round" class="get-started" (click)="login()">
              <span>Get Started</span>
            </button>
          </p>
          <p class="home-text-3">Part of <span>EMPIST Services</span> for modern businesses.</p>
        </div>
      </div>
    </div>
  </div>
  <div nz-row class="main-text-1-container">
    <div nz-col nzSpan="18" nzOffset="3" class="main-text-1 dotted-corners-container">
      <p>
        From support to billing, EMPIST 360 was created with the success of your business in mind. Our goal is to give you as much insight into and control over how – and why – your technology ecosystem works in an easy-to-use, intuitive format.
      </p>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzSpan="18" nzOffset="3">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24" class="features-label">EMPIST 360 <span class="gradient-text">Features</span></div>
      </div>
      <div nz-row nzGutter="16" [class]="(i%2 == 0) ? 'features-row' : 'features-row reverse'" *ngFor="let feature of features; let i = index">
        <div nz-col nzSpan="10" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="10" class="features-text">
          <div class="features-text-1" [innerHtml]="feature.title_1"></div>
          <div class="features-text-2 gradient-text" [innerHtml]="feature.title_2"></div>
          <div class="features-text-3">{{ feature.description }}</div>
        </div>
        <div nz-col nzSpan="10" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="10" class="features-image">
          <img [src]="feature.image" [alt]="feature.alt" />
        </div>
      </div>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzSpan="18" nzOffset="3">
      <div nz-row nzGutter="16" nzAlign="middle" nzJustify="center">
        <div nz-col nzSpan="24" class="main-text-2">
          <p class="main-text-21 dotted-corners-container">Let's <span class="gradient-text">create</span> something<br/>amazing <span class="gradient-text">together</span></p>
          <p class="main-text-22">Support that goes beyond issue resolution. Stop using your technology to survive and start harnessing IT to thrive.</p>
          <p>
            <button nz-button nzType="primary" nzSize="large" nzShape="round" class="get-started" (click)="login()">
              <span>Get Started</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzSpan="18" nzOffset="3">
      <nz-divider class="footer-divider"></nz-divider>
    </div>
  </div>
  <nz-footer class="footer">
    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="3">
        <div nz-row nzGutter="16" nzAlign="middle">
          <div nz-col class="gutter-row footer-left" nzSpan="12" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
            <img src="../../../assets/images/empist360-logo.svg" alt="EMPIST" class="footer-logo"/>
          </div>
          <div nz-col class="gutter-row footer-right" nzSpan="12" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
            Part of <span>EMPIST Services</span> for modern businesses.
          </div>
        </div>
      </div>
    </div>
  </nz-footer>
</nz-layout>
