import { ObjectData } from "../objectData";
import { PostAttributes } from "./postAttributes";
import { PostRelationships } from "./postRelationships";

export class Post extends ObjectData<PostAttributes, PostRelationships> {

  constructor(data: ObjectData<PostAttributes, PostRelationships>, included: Object[]) {
    super(data, included);
  }

}