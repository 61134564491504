import { ObjectData } from "../objectData";
import { InstalledServiceAttributes } from "./installedServiceAttributes";
import { InstalledServiceRelationships } from "./installedServiceRelationships";
import { ServiceRelationAttributes, ServiceRelationRelationships } from "./serviceRelation";
import { AccountRelationAttributes, AccountRelationRelationships } from "./accountRelation";

class InstalledService extends ObjectData<InstalledServiceAttributes, InstalledServiceRelationships>{
  constructor(data: ObjectData<InstalledServiceAttributes, InstalledServiceRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = InstalledService.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): InstalledServiceRelationships {
    const relationships: InstalledServiceRelationships = {};

    relationships.service = InstalledService.normalizeSingleRelation<ServiceRelationAttributes, ServiceRelationRelationships>(relationObject, included, 'service');
    relationships.account = InstalledService.normalizeSingleRelation<AccountRelationAttributes, AccountRelationRelationships>(relationObject, included, 'account');

    return relationships;
  }
}

export default InstalledService;
