import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PostCreateDto } from "../utilities/models/dto/postCreateDto";
import { PostUpdateDto } from "../utilities/models/dto/postUpdateDto";

@Injectable({ providedIn: "root" })
export class PostsService {
  constructor(private http: HttpClient) {
  }

  getPostsDashboard() {
    const requestUrl = environment.api_url + 'operator/v1/posts/dashboard';
    let requestParams = {
      "sort": "-updated_at",
      "include": "author,account"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getPosts(pageSize: number = 3, pageIndex: number = 1) {
    const requestUrl = environment.api_url + 'operator/v1/posts';
    let requestParams = {
      "page[per]": pageSize,
      "page[number]": pageIndex,
      "sort": "-updated_at"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getPost(id: number) {
    const params = { include: "author,account" };
    const requestUrl = environment.api_url + 'operator/v1/posts/'+id;

    return this.http.get(requestUrl, { params: params });
  }

  createPost(payload: PostCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/posts';

    return this.http.post<any>(requestUrl, payload);
  }

  updatePost(payload: PostUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/posts/'+payload.data.id;

    return this.http.patch<any>(requestUrl, payload);
  }

}