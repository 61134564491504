import { Component, DestroyRef, OnInit } from '@angular/core';
import { PostsService } from "src/app/services/posts.service";
import { Post } from "src/app/utilities/models/post/post";
import { ActivatedRoute, Params } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  post: Post;
  editPermission: Permission | undefined;
  loading: boolean = false;

  constructor(private postService: PostsService,
              private userService: UsersService,
              private activateRoute: ActivatedRoute,
              private destroyRef: DestroyRef) { }

  ngOnInit(): void {
    this.editPermission = this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'update');
    this.activateRoute.params
      .subscribe({
        next: (params: Params) => {
          this.showPost(params['id']);
        }
      }
    );
  }

  showPost(id: number) {
    this.loading = true;
    this.postService.getPost(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          this.post = new Post(response.data, response.included);
          this.loading = false;
        }
      });
  }
}