import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { differenceInCalendarDays, format, isSameDay } from 'date-fns';
import { TZDate, tzOffset } from '@date-fns/tz';
import { LoaderService } from 'src/app/services/loader.service';
import { MeetingsService } from 'src/app/services/meetings.service';
import { UsersService } from 'src/app/services/users.service';
import { TicketAvailableAgent } from 'src/app/utilities/models/user/ticketAvailableAgent';
import { User } from 'src/app/utilities/models/user/user';
import { MeetingCreateDto } from 'src/app/utilities/models/dto/meetingCreateDto';

interface IAvailability {
  days: string[];
  from: string | null;
  until: string | null;
}

interface IModalData {
  requested_agent: User;
  requester?: User;
  ticketId?: number;
}

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting-form.component.html',
  styleUrl: './meeting-form.component.scss'
})
export class MeetingComponent implements OnInit {
  availableTimeSlots: string[] = [];
  availability: IAvailability = {
    days: [],
    from: null,
    until: null,
  };
  assignees = signal<TicketAvailableAgent[]>([]);
  isBusy: boolean = false;
  isNotAvailable: boolean = false;
  eventForm: FormGroup;
  loggedInUser: User;
  phone: string = '';
  today = new Date();
  userTimezone: string | undefined;
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  disabledDates = (current: Date) => {
    return !this.availability.days.includes(format(current, 'EEE'))
      || (differenceInCalendarDays(this.today, current) > 0)
      || (differenceInCalendarDays(current, this.today) > 14);
  }

  constructor(private destroyRef: DestroyRef,
              private modalRef: NzModalRef,
              private notification: NzNotificationService,
              private loaderService: LoaderService,
              private meetingsService: MeetingsService,
              private userService: UsersService) {}

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    // get the IANA timezone of the current user, fallback to local
    this.userTimezone = (isNaN(tzOffset((this.loggedInUser.attributes?.time_zone ?? ''), new Date())))
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : this.loggedInUser.attributes.time_zone;

    this.eventForm = this.initForm();
    this.setInitialFormValues();
    this.applyFormRules();
    this.subscribeToFormChanges();
    this.getAvailableAgents();
  }

  onCancel() {
    this.modalRef.destroy(false);
  }

  onCreate() {
    const start_time = `${format(this.eventForm.value.start_time, "yyyy-MM-dd")}T${this.eventForm.value.meeting_time}:00`;
    const payload = new MeetingCreateDto({
      ...this.eventForm.value,
      requested_agent_id: this.eventForm.value.requested_agent,
      requester_id: this.loggedInUser.id,
      ticket_id: this.nzModalData?.ticketId,
      start_time
    });

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your meeting is being scheduled.');
    this.loaderService.setLoadingSecondaryText('');

    this.meetingsService.create(payload)
      .subscribe({
        next: () => {
          this.modalRef.destroy(true);
        },
        error: (error) => {
          this.loaderService.setProcessing(false);
          this.loaderService.setLoaderVisible(false);
          this.notification.create(
            'error',
            `Error ${error?.status}`,
            'There was an error scheduling this meeting!'
          );
          console.log(error);
        }
      });
  }

  setPhoneNumber(phone: string | null) {
    this.eventForm.get('media_details')?.setValue(phone, { emitEvent: false });
  }

  private applyFormRules() {
    const start_time = this.eventForm.get('start_time');
    const meeting_time = this.eventForm.get('meeting_time');
    const media_type = this.eventForm.get('media_type');
    const media_details = this.eventForm.get('media_details');
    const description = this.eventForm.get('description');

    if (start_time?.value) {
      meeting_time?.enable({ emitEvent: false });
      meeting_time?.setValidators(Validators.required);
      this.calculateAvailableTimeSlots();
    } else {
      meeting_time?.disable({ emitEvent: false });
      meeting_time?.clearValidators();
      meeting_time?.setValue(null, { emitEvent: false });
    }

    if (start_time?.value && meeting_time?.value) {
      description?.enable({ emitEvent: false });
      media_details?.enable({ emitEvent: false });
      media_type?.enable({ emitEvent: false });
    } else {
      description?.disable({ emitEvent: false });
      media_details?.disable({ emitEvent: false });
      media_type?.disable({ emitEvent: false });
    }

    if (media_type?.value == 'web') {
      media_details?.disable({ emitEvent: false });
    } else {
      media_details?.enable({ emitEvent: false });
    }

    this.eventForm.updateValueAndValidity({ emitEvent: false });
  }

  private calculateAvailableTimeSlots() {
    this.availableTimeSlots = [];

    const { from, until } = this.availability;
    if (from && until) {
      let timeSlots: string[] = [];

      // build an array of strings like this: ['00:00', '00:30', '01:00', '01:30', ... '23:30']
      Array.from({ length: 24 }, (v: any, i: number) => {
        const hours = i.toString().padStart(2, '0');
        timeSlots.push(`${hours}:00`);
        timeSlots.push(`${hours}:30`);
      });

      // return an array of strings like ['08:00', '15:00'], converting “localized” times to the user’s timezone
      const range = [
        format(new TZDate(from, this.userTimezone), 'HH:mm'),
        format(new TZDate(until, this.userTimezone), 'HH:mm'),
      ];

      // find the Hours and Half-hours that are common
      const isTimeInRange = (time: string, start: string, end: string): boolean => {
        if (start <= end) {
          // Range does not span midnight
          return time >= start && time <= end;
        } else {
          // Range spans midnight
          return time >= start || time <= end;
        }
      }

      // keep only "common" timeSlots
      this.availableTimeSlots = timeSlots.filter((time) =>
        isTimeInRange(time, range[0], range[1])
      );

      // remove hours slots in the past for today
      this.availableTimeSlots = this.availableTimeSlots.filter((slot: string) => {
        const selectedDate = this.eventForm.get('start_time')?.value;
        const now = new Date();

        if (isSameDay(selectedDate, now)) {
          const [hours, minutes] = slot.split(':');
          return (now.getHours() < parseInt(hours));
        }

        return true;
      });
    }
  }

  private getAvailableAgents() {
    if (this.nzModalData?.ticketId) {
      this.userService.getAvailableAgentsForTicket(this.nzModalData.ticketId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            if (response?.data?.length) {
              const assignees = response.data?.map((user: any) => new TicketAvailableAgent(user));

              assignees.sort((a: TicketAvailableAgent, b: TicketAvailableAgent) => a.attributes.fullname.localeCompare(b.attributes.fullname));

              this.assignees.set(assignees);
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
    }
  }

  private initForm(): FormGroup {
    return new FormGroup({
      'description': new FormControl<string>({ value: '', disabled: true }),
      'meeting_time': new FormControl<string>({ value: '', disabled: true }, Validators.required),
      'media_type': new FormControl<string>({ value: 'web', disabled: true }, Validators.required),
      'media_details': new FormControl<string>({ value: '', disabled: true }, Validators.required),
      'requested_agent': new FormControl<number | null>({ value: null, disabled: false }, Validators.required),
      'start_time': new FormControl<Date | null>({ value: null, disabled: false }, Validators.required),
    });
  }

  private prepareAvailability(agent?: TicketAvailableAgent) {
    this.isNotAvailable = true;
    this.availability = {
      days: [],
      from: null,
      until: null,
    }

    if (agent && agent?.attributes?.meeting_days?.length && agent?.attributes?.meeting_available_from && agent?.attributes?.meeting_available_until) {
      this.isNotAvailable = false;
      this.availability = {
        days: agent.attributes.meeting_days,
        from: agent.attributes.meeting_available_from,
        until: agent.attributes.meeting_available_until,
      };
    }
  }

  private setInitialFormValues() {
    if (this.nzModalData.requested_agent) {
      this.eventForm.get('requested_agent')?.setValue(this.nzModalData.requested_agent.id, { emitEvent: false });

      const agent = this.nzModalData.requested_agent;
      const requested_agent: TicketAvailableAgent = {
        id: agent.id,
        type: 'ticket_available_agents',
        attributes: {
          avatar: agent.attributes?.avatar ?? null,
          fullname: `${agent.fullname()} (Account Manager)`,
          meeting_days: agent?.relationships?.user_panel?.attributes?.meeting_days ?? [],
          meeting_available_from: agent?.relationships?.user_panel?.attributes?.meeting_available_from ?? null,
          meeting_available_until: agent?.relationships?.user_panel?.attributes?.meeting_available_until ?? null,
        }
      }

      this.assignees.set([requested_agent]);
      this.prepareAvailability(requested_agent);
    }
  }

  private subscribeToFormChanges() {
    this.eventForm.controls['requested_agent'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((requesterId: number) => {
        this.eventForm.get('start_time')?.setValue(null, { emitEvent: false });

        this.isBusy = true;
        const agent = this.assignees()?.find((user) => user.id == requesterId);
        setTimeout(() => {
          // this delay here will allow the calendar to disappear and render again,
          // effectively running the disabledDates function again
          this.prepareAvailability(agent);
          this.isBusy = false;
        }, 0);
      });

    this.eventForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.applyFormRules());
  }
}
