// Angular
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Empist360 - Routing
import { AppRoutingModule } from './app-routing.module';

// Empist360 - Auth
import { AuthInterceptor } from "./auth/auth.interceptor";

// Empist360 - Components
import { AppComponent } from './app.component';
import { AuthComponent } from "./components/auth/auth.component";
import { TicketComponent } from "./components/mainApp/ticket/ticket.component";
import { HeaderComponent } from "./components/mainApp/header/header.component";
import { HomepageComponent } from "./components/homepage/homepage.component";
import { ResetPasswordSetMobileComponent } from "./components/homepage/reset-password-set-mobile/resetPasswordSetMobile.component";
import { MainAppComponent } from "./components/mainApp/mainApp.component";
import { TicketListComponent } from "./components/mainApp/ticket/ticketList/ticketList.component";
import { TicketPageComponent } from "./components/mainApp/ticket/ticketPage/ticketPage.component";
import { SolutionComponent } from "./components/mainApp/solution/solution.component";
import { ProfileComponent } from "./components/mainApp/profile/profile.component";
import { TicketConversationsComponent } from "./components/mainApp/ticket/ticketPage/ticketConversations/ticketConversations.component";
import { ConversationCommentsComponent } from "./components/mainApp/ticket/ticketPage/ticketConversations/conversationComments/conversationComments.component";
import { ConversationCreateComponent } from "./components/mainApp/ticket/ticketPage/ticketConversations/conversationCreate/conversationCreate.component";
import { ConversationUpdateComponent } from "./components/mainApp/ticket/ticketPage/ticketConversations/conversationUpdate/conversationUpdate.component";
import { CommentFormComponent } from "./components/mainApp/ticket/ticketPage/ticketConversations/commentForm/commentForm.component";
import { TicketNewComponent } from "./components/mainApp/ticket/ticketNew/ticketNew.component";
import { TwoFactorAuthenticationComponent } from './components/mainApp/profile/twoFactorAuthentication/twoFactorAuthentication.component';
import { NotificationPreferencesComponent } from './components/mainApp/profile/notificationPreferences/notificationPreferences.component';
import { UserInformationComponent } from './components/mainApp/profile/userInformation/userInformation.component';
import { SupportComponent } from './components/mainApp/support/support.component';
import { DashboardComponent } from './components/mainApp/dashboard/dashboard.component';
import { OutsideClickDirective } from './directives/outsideClick';
import { CustomLoaderComponent } from './components/mainApp/customLoader/customLoader.component';
import { PostComponent } from "./components/mainApp/post/post.component";
import { PostListComponent } from "./components/mainApp/post/postList/postList.component";
import { NewPostComponent } from "./components/mainApp/post/newPost/newPost.component";
import { EditPostComponent } from "./components/mainApp/post/editPost/editPost.component";
import { UidComponent } from "./components/uid/uid.component";
import { GlobalErrorHandler } from "./utilities/helpers/globalErrorHandler";
import { AccountManagerComponent } from "./components/mainApp/accountManager/accountManager.component";
import { TicketOptionsComponent } from "./components/mainApp/ticket/ticketPage/ticketOptions/ticketOptions.component";
import { TicketAttachmentsComponent } from "./components/mainApp/ticket/ticketPage/ticketAttachments/ticketAttachments.component";
import { SetPasswordFormComponent } from "./components/mainApp/setPasswordForm/setPasswordForm.component";
import { LoaderComponent } from "./components/mainApp/loader/loader.component";
import { LoginComponent } from "./components/login/login.component";
import { AssetListComponent } from './components/mainApp/assetList/assetList.component';
import { AssetComponent } from './components/mainApp/asset/asset.component';
import { InstalledServiceComponent } from './components/mainApp/services/installedService.component';
import { InstalledServiceDetailsComponent } from './components/mainApp/services/installedServicesDetails/installedServiceDetails.component';
import { ImpersonateComponent } from './components/mainApp/impersonate/impersonate.component';
import { DetailsRequestComponent } from './components/mainApp/services/detailsRequest/detailsRequest.component';
import { AddOnServiceRequestComponent } from './components/mainApp/services/addOnServiceRquest/addOnServiceRequest.component';
import { FlowComponent } from './components/mainApp/flows/flow/flow.component';
import { FlowNewComponent } from './components/mainApp/flows/flow-new/flow-new.component';
import { FlowListComponent } from './components/mainApp/flows/flow-list/flow-list.component';
import { FlowCategoryComponent } from './components/mainApp/flows/flow-category/flow-category.component';
import { FlowCategoryNewComponent } from './components/mainApp/flows/flow-category-new/flow-category-new.component';
import { FlowCategoryListComponent } from './components/mainApp/flows/flow-category-list/flow-category-list.component';
import { FlowControlFormComponent } from './components/mainApp/flows/flow-control-form/flow-control-form.component';
import { FlowCategoryItemComponent } from './components/mainApp/support/flowCategoryItem/flowCategoryItem.component';
import { ServicesHistoryComponent } from './components/mainApp/services-history/services-history.component';

import { MeetingsSolutionComponent } from './components/mainApp/meetings/meetings.component';
import { MeetingComponent } from './shared/meeting/meeting-form.component';
import { MeetingsListComponent } from './shared/meeting/meetings-list.component';

// Pipes
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { FileSizePipe } from "./pipes/filesize.pipe";

// NG Zorro

// Froala
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaComponent } from './shared/froala/froala.component';

import { QrCodeModule } from 'ng-qrcode';
import { InvoiceListComponent } from './components/mainApp/invoice/invoice-list/invoice-list.component';
import { InvoiceComponent } from './components/mainApp/invoice/invoice/invoice.component';
import { ChangeRequestComponent } from './components/mainApp/services/changeRequest/changeRequest.component';
import { ServicesAuditComponent } from './components/mainApp/services-audit/services-audit.component';
import { AddServiceComponent } from './components/mainApp/services/add-service/add-service.component';
import { RemoveServiceComponent } from './components/mainApp/services/remove-service/remove-service.component';
import { DwIconComponent } from "./components/icon/icon.component";
import { InvoiceSupportRequestComponent } from "./components/mainApp/invoice/invoice-support-request/invoice-support-request.component";
import { PhoneInputComponent } from './shared/phone-input/phone-input.component';
import { AdditionalServiceListComponent } from './components/mainApp/additional-services/additional-service-list/additional-service-list.component';
import { AdditionalServiceComponent } from './components/mainApp/additional-services/additional-service/additional-service.component';
import { AdditionalServiceMetricListComponent } from './components/mainApp/additional-services/additional-service-metric-list/additional-service-metric-list.component';
import { AdditionalServiceMetricComponent } from './components/mainApp/additional-services/additional-service-metric/additional-service-metric.component';
import { NgZorroModule } from "./shared/ng-zorro.module";
import { NgxSortableModule } from 'ngx-sortable';
import { AccountFlowsComponent } from './components/mainApp/flows/account-flows/account-flows.component';

import { AccountManagementComponent } from './components/mainApp/account-management/account-management.component';
import { AccountManagementBillingComponent } from './components/mainApp/account-management/billing/billing.component';
import { AccountManagementContractsComponent } from './components/mainApp/account-management/contracts/contracts.component';
import { AccountManagementEscalatationComponent } from './components/mainApp/account-management/escalatation/escalatation.component';
import { AccountManagementFeedbackComponent } from './components/mainApp/account-management/feedback/feedback.component';
import { AccountManagementIssueComponent } from './components/mainApp/account-management/issue/issue.component';
import { AccountManagementMeetingsComponent } from './components/mainApp/account-management/meetings/meetings.component';
import { AccountManagementReturnsComponent } from './components/mainApp/account-management/returns/returns.component';
import { AccountManagementUpgradesComponent } from './components/mainApp/account-management/upgrades/upgrades.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    TicketComponent,
    HeaderComponent,
    HomepageComponent,
    ResetPasswordSetMobileComponent,
    MainAppComponent,
    TicketListComponent,
    TicketPageComponent,
    SolutionComponent,
    ProfileComponent,
    TicketConversationsComponent,
    ConversationCommentsComponent,
    ConversationCreateComponent,
    ConversationUpdateComponent,
    CommentFormComponent,
    TwoFactorAuthenticationComponent,
    NotificationPreferencesComponent,
    UserInformationComponent,
    SupportComponent,
    TicketNewComponent,
    DashboardComponent,
    OutsideClickDirective,
    CustomLoaderComponent,
    PostComponent,
    PostListComponent,
    NewPostComponent,
    EditPostComponent,
    DateAgoPipe,
    UidComponent,
    SafePipe,
    AccountManagerComponent,
    TicketOptionsComponent,
    TicketAttachmentsComponent,
    FileSizePipe,
    AssetListComponent,
    AssetComponent,
    SetPasswordFormComponent,
    LoaderComponent,
    LoginComponent,
    InstalledServiceComponent,
    InstalledServiceDetailsComponent,
    ChangeRequestComponent,
    ServicesAuditComponent,
    AddServiceComponent,
    RemoveServiceComponent,
    FroalaComponent,
    DwIconComponent,
    InvoiceListComponent,
    InvoiceComponent,
    InvoiceSupportRequestComponent,
    PhoneInputComponent,
    AdditionalServiceListComponent,
    AdditionalServiceComponent,
    AdditionalServiceMetricListComponent,
    AdditionalServiceMetricComponent,
    ImpersonateComponent,
    DetailsRequestComponent,
    AddOnServiceRequestComponent,
    FlowComponent,
    FlowNewComponent,
    FlowListComponent,
    FlowCategoryComponent,
    FlowCategoryNewComponent,
    FlowCategoryListComponent,
    FlowControlFormComponent,
    AccountFlowsComponent,
    FlowCategoryItemComponent,
    ServicesHistoryComponent,
    MeetingsSolutionComponent,
    MeetingComponent,
    MeetingsListComponent,
    AccountManagementComponent,
    AccountManagementBillingComponent,
    AccountManagementContractsComponent,
    AccountManagementEscalatationComponent,
    AccountManagementFeedbackComponent,
    AccountManagementIssueComponent,
    AccountManagementMeetingsComponent,
    AccountManagementReturnsComponent,
    AccountManagementUpgradesComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    QrCodeModule,
    NgxSortableModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule {
}
