import { RelationshipData } from "../relationshipData";
import { AccountRelationAttribute } from "./accountRelationAttribute";
import { UserRelationAttribute } from "./userRelationAttribute";
import { Issue_typeRelationAttribute } from "./issue_typeRelationAttribute";
import { PriorityRelationAttribute } from "./priorityRelationAttribute";
import { Request_typeRelationAttribute } from "./request_typeRelationAttribute";
import { StatusRelationAttribute } from "./statusRelationAttribute";
import { UploadRelationAttribute } from "./uploadRelationAttribute";
import { EscalationRelationAttribute } from "./escalationRelationAttribute";

export class TicketRelationships {
  account?: RelationshipData<AccountRelationAttribute>;
  creator?: RelationshipData<UserRelationAttribute>;
  issue_type?: RelationshipData<Issue_typeRelationAttribute>;
  priority?: RelationshipData<PriorityRelationAttribute>;
  request_type?: RelationshipData<Request_typeRelationAttribute>;
  requester?: RelationshipData<UserRelationAttribute>;
  status?: RelationshipData<StatusRelationAttribute>;
  uploads?: RelationshipData<UploadRelationAttribute>[];
  watchers?: RelationshipData<UserRelationAttribute>[];
  escalations?: RelationshipData<EscalationRelationAttribute>[];
}