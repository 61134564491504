export interface DocumentCountResponse {
  data?: {
    attributes: {
      count: number
    }
  }[]
}

export class DocumentCount {
  static getCount(response: DocumentCountResponse): number {
    return response.data ? response.data[0].attributes.count : 0
  }
}
