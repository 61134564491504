<app-loader *ngIf="!appLoaded"></app-loader>
<nz-layout *ngIf="appLoaded">
  <nz-sider [nzWidth]="60">
    <div class="nz-sider-content">
      <div class="logo" [routerLink]="['/app', 'dashboard']"></div>
      <ul nz-menu nzMode="inline" class="main-app-menu" [nzInlineIndent]="0">
        <li nz-menu-item [routerLink]="['/app', 'dashboard']" routerLinkActive="ant-menu-item-selected" nz-tooltip nzTooltipTitle="Dashboard" nzTooltipPlacement="right">
          <i nz-icon nzType="pic-left" [nzTheme]="'outline'"></i>
        </li>
        <li nz-menu-item [routerLink]="['/app', 'solutions']" routerLinkActive="ant-menu-item-selected" nz-tooltip nzTooltipTitle="Solutions" nzTooltipPlacement="right">
          <i nz-icon nzType="appstore" [nzTheme]="'outline'"></i>
        </li>
        <li nz-menu-item [routerLink]="['/app', 'profile']" routerLinkActive="ant-menu-item-selected" nz-tooltip nzTooltipTitle="Profile" nzTooltipPlacement="right">
          <i nz-icon nzType="setting" [nzTheme]="'outline'"></i>
        </li>
      </ul>
      <div class="logout-button-container">
        <button nz-button
                nz-popconfirm
                nzPopconfirmTitle="You are going to logout, proceed?"
                nz-tooltip nzTooltipTitle="Logout" nzTooltipPlacement="right"
                nzType="default"
                nzShape="circle"
                (nzOnConfirm)="logout()">
          <i nz-icon nzType="poweroff"></i>
        </button>
        <span class="version">{{ appVersion }}</span>
      </div>
    </div>
  </nz-sider>
  <nz-content>
    <router-outlet></router-outlet>
    <accountManager></accountManager>
  </nz-content>
</nz-layout>
