<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Services</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div nz-row nzJustify="center">
          <div nz-col class="service-history-head">
            <h1>Services Change History</h1>
            <div *ngIf="isAgent">
              Please select an account and the desired range of dates<br>
              to get a list of changes that occured in the services of this account during these dates.
            </div>
            <div *ngIf="!isAgent">
              Please select the desired range of dates<br>
              to get a list of changes that occured for the offered services to your account during these dates.
            </div>
          </div>
        </div>
        <div nz-row class="history-filters">
          <div nz-col nzSpan="24" class="account-selector" *ngIf="isAgent">
            <div class="filter-label">
              For which account do you want to display the changes?
            </div>
            <nz-select nzShowSearch
                       [nzLoading]="accountsLoading"
                       nzPlaceHolder="Please select account"
                       [(ngModel)]="selectedAccountId"
                       (nzOpenChange)="getAccounts()"
                       nzServerSearch
                       (nzOnSearch)="setAccountSearchStr($event)"
                       (ngModelChange)="getServiceHistory()">
              <nz-option *ngFor="let accountOption of accounts"
                        [nzLabel]="accountOption.attributes.title"
                        [nzValue]="accountOption.id">
              </nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="24" class="date-selector" *ngIf="selectedAccountId">
            <div class="filter-label">
              What is the time period to look for?
            </div>
            <nz-range-picker [(ngModel)]="dateRange" (ngModelChange)="getServiceHistory()"></nz-range-picker>
          </div>
        </div>
      </div>

      <div *ngIf="dataLoading()" class="loader">
        <nz-spin nzSimple></nz-spin>
      </div>

      <div class="service-history-changes" *ngIf="!dataLoading()">
        <ng-container *ngFor="let item of serviceableInformation | keyvalue">
          <div class="service-changes">
            <div class="service-label">{{ getSectionTitle(item.value) }}</div>
            <nz-table [nzData]="serviceableInformation[item.key].data" [nzShowPagination]="false">
              <thead>
                <tr>
                  <th *ngFor="let column of item.value['columns']">
                    {{ column.title }}
                  </th>
                  <th>Change Date</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let row of item.value['data']">
                  <tr [ngClass]="row.className">
                    <td *ngFor="let attr of item.value['keys']" [innerHTML]="displayValue(row, attr, '', item.value['serviceMetric'])"></td>
                    <td>{{ row.createdAt|date:'MMM d, y, HH:mm' }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>
        </ng-container>

        <div class="no-content" *ngIf="serviceHistories && serviceHistories.length == 0 && dateRange && dateRange.length">
          No changes occured between {{ dateRange[0] | date: 'MMM d YYYY' }} and {{ dateRange[1] | date: 'MMM d YYYY' }}
        </div>

        <nz-empty *ngIf="isForbidden"
                  nzNotFoundImage="simple"
                  nzNotFoundContent="You are not authorized!"></nz-empty>
      </div>
    </div>
  </nz-content>
</nz-layout>
