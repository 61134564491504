import { Component, DestroyRef, OnInit } from '@angular/core';
import { AuthService } from "src/app/auth/auth.service";
import { environment } from "src/environments/environment";
import { FeaturesService } from "src/app/services/features.service";
import { Feature } from "src/app/utilities/models/feature/feature";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { User } from "src/app/utilities/models/user/user";
import { Router } from "@angular/router";
import { TokenService } from "src/app/auth/token.service";

@Component({
  selector: 'homepage',
  templateUrl: 'homepage.component.html',
  styleUrls: ['homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  isProduction: boolean = false;
  features: any[];
  loggedInUser: User;

  constructor(
    private authService: AuthService,
    private featuresService: FeaturesService,
    private tokenService: TokenService,
    private destroyRef: DestroyRef,
    private router: Router) {
  }

  ngOnInit() {
    if (environment.production)
      this.isProduction = true;

    this.featuresService.getFeatures()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.features = response.map((featureData: any) => new Feature(featureData))
        }
      })

    if (this.tokenService.getToken()) {
      this.router.navigateByUrl('/app/dashboard');
    }
  }

  login(): void {
    this.authService.login();
  }
}
