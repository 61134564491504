import { ObjectData } from "../objectData";
import { DattoLicenseAttributes } from "./dattoLicenseAttributes";
import { DattoLicenseRelationships } from "./dattoLicenseRelationships";
import { RelationshipData } from "../relationshipData";
import { AccountRelationAttribute } from "../ticket/accountRelationAttribute";
import { IncludedData } from "../includedData";

export class DattoLicense extends ObjectData<DattoLicenseAttributes, DattoLicenseRelationships>{
  constructor(data: ObjectData<DattoLicenseAttributes, DattoLicenseRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = DattoLicense.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): DattoLicenseRelationships {
    let relationships = new DattoLicenseRelationships();

    relationships.account = DattoLicense.normalizeAccountRelation(relationObject, included);

    return relationships;
  }

  private static normalizeAccountRelation(relationObject: any, included: Object[]): RelationshipData<AccountRelationAttribute> | undefined {
    let accountRelation = new RelationshipData<AccountRelationAttribute>();
    if (!relationObject?.account?.data?.id) return undefined;

    accountRelation.id = +relationObject.account.data.id;
    accountRelation.type = relationObject.account.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == accountRelation.type &&
        includedObject.id == accountRelation.id
    });
    accountRelation.attributes = (tmp as IncludedData<AccountRelationAttribute>).attributes

    return accountRelation;
  }
}
