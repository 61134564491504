<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'dashboard']">Dashboard</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'account-management']">Account Management</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Ticket Escalations</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','account-management']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col>
        <h1>Ticket Escalations</h1>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col>
        Use this to escalate a ticket for priority resolution.
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" class="account-management-escalations-wrapper">
        <nz-divider></nz-divider>

        <div class="search-row">
          <nz-input-group nzSearch
                          [nzPrefix]="prefixIcon"
                          [nzSuffix]="suffixIconButton"
                          [nzSize]="'large'">
            <input type="text"
                   nz-input
                   placeholder="Search by Ticket Number or Subject or Description..."
                   [(ngModel)]="searchString"
                   (ngModelChange)="searchTickets()" />
          </nz-input-group>
          <ng-template #prefixIcon>
            <span nz-icon nzType="search"></span>
          </ng-template>
          <ng-template #suffixIconButton>
            <button nz-button
                    nzType="primary"
                    nzSearch>
              <span nz-icon nzType="right" (click)="searchTickets()"></span>
            </button>
          </ng-template>
        </div>

        <div nz-row *ngIf="loading" nzJustify="center">
          <div nz-col nzSpan="24" class="tickets-loading">
            <nz-spin nzSimple></nz-spin>
          </div>
        </div>

        <div nz-row *ngIf="!loading">
          <div nz-col nzSpan="24">
            <div nz-row *ngFor="let ticket of tickets">
              <div nz-col nzSpan="24" class="ticket-list-item">
                <div class="ticket-row-column ticket-row-avatar" [routerLink]="['/app', 'support', 'tickets', ticket.attributes.code]">
                  <nz-avatar nzIcon="user" [nzSrc]="ticket.relationships?.requester?.attributes?.avatar" *ngIf="ticket.relationships?.requester?.attributes?.avatar"></nz-avatar>
                  <nz-avatar nzIcon="user" *ngIf="!ticket.relationships?.requester?.attributes?.avatar"></nz-avatar>
                </div>

                <div class="ticket-list-item-details" [routerLink]="['/app', 'support', 'tickets', ticket.attributes.code]">
                  <div>
                    <span class="ticket-status" title="Status">{{ ticket?.relationships?.status?.attributes?.title }}</span>
                  </div>

                  <div class="ticket-list-item-details-top">
                    <span class="ticket-item-code-date">
                      <span class="ticket-code">Ticket: {{ticket.attributes.code}}</span>
                      <span class="ticket-requester" *ngIf="ticket.relationships?.requester?.attributes?.fullname">by {{ ticket.relationships?.requester?.attributes?.fullname }}</span>
                      <span class="ticket-created-at">{{ticket.attributes.created_at | date:'medium'}}</span>
                    </span>
                  </div>

                  <div class="ticket-list-item-subject-description">
                    <div *ngIf="!ticket.attributes.striped_description">
                      <strong>{{ticket.attributes.subject}}</strong>
                    </div>
                    <div *ngIf="ticket.attributes.striped_description">
                      <strong>{{ticket.attributes.subject}}:</strong> {{ticket.attributes.striped_description}}
                    </div>
                  </div>

                </div>

                <div *ngIf="ticket.relationships?.status?.attributes?.title !== 'Escalate'"
                     class="ticket-list-item-actions">
                  <button nz-button class="btn-blue btn-escalate" nzShape="round" (click)="onEscalate(ticket)">
                    Escalate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-row class="pagination-container" *ngIf="!loading">
          <div nz-col nzSpan="16">
            <nz-pagination [nzPageIndex]="pageIndex"
                          (nzPageIndexChange)="onPageIndexChange($event)"
                          [nzTotal]="total"
                          nzShowSizeChanger
                          nzResponsive="true"
                          [nzPageSize]="pageSize"
                          (nzPageSizeChange)="onPageSizeChange($event)">
            </nz-pagination>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>

<custom-loader *ngIf="loaderVisible"></custom-loader>
