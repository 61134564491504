<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flows']">Flows</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'flow-categories']">Categories</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','flows']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span
            class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div class="list-container">
          <div nz-row>
            <div nz-col nzSpan="18" class="list-top">
              <div>
                <h1>Flow Categories <span *ngIf="!loading">({{ total }})</span></h1>
                <div>
                  Manage the categories available for the flows in EMPIST 360.<br/>
                </div>
              </div>
            </div>
            <div nz-col nzSpan="6" class="text-right">
              <button nz-button class="btn-blue" nzShape="round" [routerLink]="['/app','flow-categories','new']">
                New Category
              </button>
            </div>
          </div>

          <div *ngIf="!loading" class="search-container">
            <div class="search-by">Search By Title</div>
            <nz-input-group [nzPrefix]="prefixIconSearch">
              <input nz-input placeholder="Type here to search" [(ngModel)]="searchString" (ngModelChange)="searchFlows()" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
              <span nz-icon nzType="search"></span>
            </ng-template>
          </div>

          <div nz-row class="list-description">
            <div nz-col nzSpan="24">
              <nz-table [nzData]="flowCategories"
                        [nzFrontPagination]="false"
                        [nzLoading]="loading"
                        [nzTotal]="total"
                        [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex"
                        [nzBordered]="false"
                        (nzQueryParams)="onQueryParamsChange($event)">
                <thead>
                <tr>
                  <th nzColumnKey="title" [nzSortFn]="true">Title</th>
                  <th nzColumnKey="parent_id" [nzSortFn]="true">Parent</th>
                  <th nzColumnKey="specific">Specific to Accounts</th>
                  <th nzColumnKey="excluded">Excluded from Accounts</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let flowCategory of flowCategories">
                  <td><a [routerLink]="['/app', 'flow-categories', flowCategory.id]">{{ flowCategory.attributes.title }}</a></td>
                  <td>{{ flowCategory.attributes.parent_id || '-'}}</td>
                  <td>
                    <span *ngIf="flowCategory.attributes.public == false">
                      <ng-container *ngIf="flowCategory.relationships?.accounts?.length">
                        <ng-container *ngFor="let account of flowCategory.relationships?.accounts">
                          {{ account.attributes.title }}
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!flowCategory.relationships?.accounts?.length">-</ng-container>
                    </span>
                    <span *ngIf="flowCategory.attributes.public == true">-</span>
                  </td>
                  <td>
                    <span *ngIf="flowCategory.attributes.public == true">
                      <ng-container *ngIf="flowCategory.relationships?.accounts?.length">
                        <ng-container *ngFor="let account of flowCategory.relationships?.accounts">
                          {{ account.attributes.title }}
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!flowCategory.relationships?.accounts?.length">-</ng-container>
                    </span>
                    <span *ngIf="flowCategory.attributes.public == false">-</span>
                  </td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
