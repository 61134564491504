import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ReportParam } from "../utilities/models/parameters/reportParam/reportParam";
import { FilterDropdownOption } from "../utilities/models/filters/filterDropdownOption";
import { AvailableFiltersResponse } from "../utilities/models/filters/availableFiltersResponse";
import { AvailableFilter } from "../utilities/models/filters/availableFilter";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AvailableFilterDetail } from "../utilities/models/filters/availableFilterDetail";
import * as invoiceAvailableFiltersResponse from "../utilities/static-data/invoice-available-filters.json"

@Injectable({ providedIn: "root" })
export class FiltersService {
  private availableFilter: AvailableFilter;
  private selectedReportParams: ReportParam[] = [];
  private selectedKeys: Set<string> = new Set<string>();
  private customControlValues = new Map();
  selectedReportParamsChange: Subject<string> = new Subject<string>();
  private customFilterKeys: Set<string> = new Set<string>(['warranty_expiration_date']);

  constructor(private http: HttpClient) {
  }

  requestAvailableFilters(resource: string, request_for: string) {
    const requestUrl = `${environment.api_url}operator/v1/${resource}/available_filters`;

    return this.http.get(requestUrl, { params: { "request_for": request_for } });
  }

  getFilterOptionsWithAggs(resource: string, aggregation_key: string, key: string) {
    const requestUrl = environment.api_url + 'operator/v1/' + resource;
    let requestParams: { [key: string]: string | string[] | number } = {
      "w[report_params]": JSON.stringify(this.excludeReportParamByKey(key)),
      "page[number]": 1,
      "page[per]": 1,
      "sort": "title",
      "query_type": "aggregations",
      "aggregations": '["' + aggregation_key + '"]'
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getFilterOptions(resource: string, searchString?: string) {
    const requestUrl = environment.api_url + 'operator/v1/' + resource;
    let requestParams: { [key: string]: string | string[] | number } = {
      "page[number]": 1,
      "page[per]": 50,
      "sort": "title"
    };

    if(searchString) {
      requestParams['q[title_cont]'] = searchString;
    }

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAvailableFilters(resource: string, request_for: string, pageName: string): Observable<FilterDropdownOption[]> {
    //if (this.availableFilter) {
    //  return of(this.availableFilter.getFiltersOptions().filter(filterOption => !this.selectedKeys.has(filterOption.key)));
    //} else {
      if(pageName == 'invoice_list') {
        return of(invoiceAvailableFiltersResponse).pipe(
          map((response: AvailableFiltersResponse) => {
            this.availableFilter = new AvailableFilter(response, pageName);
            return this.availableFilter.getFiltersOptions().filter(filterOption => !this.selectedKeys.has(filterOption.key));
          })
        );
      }
      return this.requestAvailableFilters(resource, request_for).pipe(
        map((response: AvailableFiltersResponse) => {
          this.availableFilter = new AvailableFilter(response, pageName);
          return this.availableFilter.getFiltersOptions().filter(filterOption => !this.selectedKeys.has(filterOption.key));
        })
      );
    //}
  }

  getFilterDetails(key: string): AvailableFilterDetail {
    return this.availableFilter.getFilterDetailByKey(key);
  }

  putFilterToSelectedParams(filter: AvailableFilterDetail, value: any) {
    if (this.selectedKeys.has(filter.computed_key)) {
      this.selectedReportParams = this.selectedReportParams.filter(reportParam => reportParam.key != filter.computed_key);
    }

    this.selectedReportParams.push(new ReportParam(filter.computed_key, filter.getOperatorByValue(value), filter.getValueByType(value)));
    this.selectedKeys.add(filter.computed_key);
    this.selectedReportParamsChange.next(filter.computed_key);
    if(this.customFilterKeys.has(filter.computed_key)){
      this.customControlValues.set(filter.computed_key, value);
    }
  }

  putReportParamsToSelectedParams(reportParam: ReportParam) {
    this.selectedReportParams.push(reportParam);
    this.selectedKeys.add(reportParam.key);
  }

  removeFilterFromSelectedParams(filter: AvailableFilterDetail) {
    if (this.selectedKeys.has(filter.computed_key)) {
      this.selectedReportParams = this.selectedReportParams.filter(reportParam => reportParam.key != filter.computed_key);
      this.selectedKeys.delete(filter.computed_key);
      this.selectedReportParamsChange.next(filter.computed_key);
      if (this.customControlValues.has(filter.computed_key)) {
        this.customControlValues.delete(filter.computed_key);
      }
    }
  }

  getSelectedFilters(): ReportParam[] {
    return this.selectedReportParams;
  }

  clearAllFilters(notifyChange: boolean = true) {
    this.selectedReportParams = [];
    this.selectedKeys.clear();
    this.customControlValues.clear();
    if (notifyChange)
      this.selectedReportParamsChange.next('');
  }

  excludeReportParamByKey(key: string): ReportParam[] {
    return this.selectedReportParams.filter(reportParam => reportParam.key != key);
  }

  getSelectedReportParams(): ReportParam[] {
    return this.selectedReportParams || [];
  }

  getCustomControlValue(key: string) {
    return this.customControlValues.get(key);
  }

  resetFilters() {
    this.selectedReportParams = [];
    this.selectedKeys = new Set<string>();
    this.customFilterKeys = new Set<string>();
    this.customControlValues = new Map();
    this.availableFilter = new AvailableFilter(
      {
        data:[
          {
            id:0,
            type: '',
            attributes: {
              model_associations: [],
              model_attributes: [],
              model_custom_attributes: [],
            }
          }
        ]
      }, '');
  }
}