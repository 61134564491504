export interface FlowCategoryCreateAttributes {
  title: string;
  description: string;
  public: boolean;
  parent_id: number | null;
}

export interface UploadsData {
  data: {
    id: number;
    type: string;
  }[]
}

export interface AccountData {
  data: {
    id: number;
    type: string;
  }
}


export interface FlowCategoryCreateRelationships {
  uploads?: UploadsData;
  accounts?: AccountData;
}

export interface FlowCategoryCreateData {
  type: string;
  attributes: FlowCategoryCreateAttributes;
  relationships: FlowCategoryCreateRelationships;
}


export class FlowCategoryCreateDto {
  data: FlowCategoryCreateData;

  constructor(data: FlowCategoryCreateData) {
    this.data = data;
  }
}