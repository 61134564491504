import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { forkJoin } from "rxjs";

@Injectable({ providedIn: "root" })
export class UsersListService {

  constructor(private http: HttpClient) {
  }

  getAccountRequester(accountId: number, searchString: string = '', _pageNumber = 1) {
    const listUrl = environment.api_url + 'operator/v1/users/list';
    let wParams: ReportParam[] = [];
    wParams.push({ key: "accounts.id", operator: ["in"], value: [accountId] });
    wParams.push({ key: "active", operator: ["eq"], value: true });
    if (searchString) {
      wParams.push({ key: "fullname", operator: ["like"], value: searchString });
    }
    let listParams = {
      "s[firstname]": "asc",
      "page[per]": 50,
      query_type: 'documents'
    }
    
    return this.makeUserCall(listUrl, wParams, listParams)
  }

  getAccountWatchers(accountId: number, searchString: string = '') {
    const contactWatchersResult = this.getContactWatchers(accountId, searchString);
    const agentWatchersResult = this.getAgentWatchers(searchString);

    return forkJoin([contactWatchersResult, agentWatchersResult]);
  }

  getConversationParticipantsList(accountId: string, query: string) {
    const listUrl = environment.api_url + 'operator/v1/users/list';
    let wParams: ReportParam[] = [];

    wParams.push({ key: "accounts.id", operator: ["in"], value: [accountId] });
    wParams.push({ key: "active", operator: ["eq"], value: true });
    wParams.push({ key: "type", operator: ["in"], value: ["Digcore::AccountAgent", "Digcore::Contact"] });

    let listParams = {
      "s[firstname]": "asc",
      "page[per]": 10000,
      query_type: 'documents'
    }
    if (query && query.length > 2) {
      wParams.push({ key: 'fullname', operator: ['like'], value: query });
    }
    return this.makeUserCall(listUrl, wParams, listParams);
  }

  private getContactWatchers(accountId: number, searchString: string = '') {
    const listUrl = environment.api_url + 'operator/v1/users/list';
    let wParams: ReportParam[] = [];
    wParams.push({ key: "accounts.id", operator: ["in"], value: [accountId] });
    wParams.push({ key: "active", operator: ["eq"], value: true });
    wParams.push({ key: "type", operator: ["in"], value: ["Digcore::AccountAgent", "Digcore::Contact"] });
    if (searchString) {
      wParams.push({ key: "fullname", operator: ["like"], value: searchString });
    }
    let listParams = {
      "s[firstname]": "asc",
      "page[per]": 50,
      query_type: 'documents'
    }

    return this.makeUserCall(listUrl, wParams, listParams)
  }

  private getAgentWatchers(searchString: string = '') {
    const listUrl = environment.api_url + 'operator/v1/users/list';
    let wParams: ReportParam[] = [];
    wParams.push({ key: "accounts.id", operator: ["in"], value: ["1"] });
    wParams.push({ key: "active", operator: ["eq"], value: true });
    wParams.push({ key: "type", operator: ["in"], value: ["Ticketing::Agent"] });
    if (searchString) {
      wParams.push({ key: "fullname", operator: ["like"], value: searchString });
    }
    let listParams = {
      "s[firstname]": "asc",
      "page[per]": 50,
      query_type: 'documents'
    }

    return this.makeUserCall(listUrl, wParams, listParams)
  }

  private makeUserCall(url: string, wParams: ReportParam[], params: { [p: string]: string | number }) {
    return this.http.get(url + '?w[report_params]=' + JSON.stringify(wParams),
      { params: params })
  }
}