import { ObjectData } from "../objectData";
import { InstalledServiceDetailRelationships } from "./installedServiceDetailRelationships";
import { ServiceMetricRelationAttributes, ServiceMetricRelationRelationships } from "./serviceMetricRelation";
import { InstalledServiceRelationAttributes, InstalledServiceRelationRelationships } from "./InstalledServiceRelation";
import { InstalledServiceDetailAttributes } from "./installedServiceDetailAttributes";

class InstalledServiceDetail extends ObjectData<InstalledServiceDetailAttributes, InstalledServiceDetailRelationships>{
  constructor(data: ObjectData<InstalledServiceDetailAttributes, InstalledServiceDetailRelationships>, included: Object[]) {
    super(data, included);
    if(data.relationships && included){
      this.relationships = InstalledServiceDetail.normalizeRelationships(data.relationships, included);
    }
  }

  public static normalizeRelationships(relationObject:any, included: any): InstalledServiceDetailRelationships
  {
    const relationships: InstalledServiceDetailRelationships = {};

    relationships.service_metric = InstalledServiceDetail.normalizeSingleRelation<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>(relationObject, included, 'service_metric');
    relationships.installed_service = InstalledServiceDetail.normalizeSingleRelation<InstalledServiceRelationAttributes, InstalledServiceRelationRelationships>(relationObject, included, 'installed_service');

    return relationships;
  }
}

export default InstalledServiceDetail;
