 <form class="user-information-form"
       nz-form
       nzLayout="vertical"
       [formGroup]="userInformationForm">

  <ul nz-list nzSize="large" class="profile-information">
    <nz-list-header><h2 nz-typography>User Information</h2></nz-list-header>

    <li nz-list-item [ngClass]="fieldInEditMode == 'firstname' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">First Name</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'firstname'; else firsNameEdit" (click)="setEditModeForField('firstname')">
            {{ user.attributes.firstname }}
          </span>
          <ng-template #firsNameEdit>
            <input nz-input formControlName="firstname" class="profile-input"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'firstname'; else firstNameEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('firstname')"></span>
        </nz-col>
        <ng-template #firstNameEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('firstname')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'middlename' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Middle Name</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'middlename'; else middleNameEdit" (click)="setEditModeForField('middlename')">
            {{ user.attributes.middlename }}
          </span>
          <ng-template #middleNameEdit>
            <input nz-input formControlName="middlename" class="profile-input"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'middlename'; else middleNameEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('middlename')"></span>
        </nz-col>
        <ng-template #middleNameEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('middlename')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'lastname' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Last Name</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'lastname'; else lastNameEdit" (click)="setEditModeForField('lastname')">
            {{ user.attributes.lastname }}
          </span>
          <ng-template #lastNameEdit>
            <input nz-input formControlName="lastname" class="profile-input"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'lastname'; else lastNameEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('lastname')"></span>
        </nz-col>
        <ng-template #lastNameEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('lastname')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'email' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Email</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'email'; else emailEdit" (click)="setEditModeForField('email')">
            {{ user.attributes.email }}
          </span>
          <ng-template #emailEdit>
            <input nz-input formControlName="email" class="profile-input"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'email'; else emailEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('email')" *ngIf="!disableByProperty('email')"></span>
          <span nz-icon nzType="lock" [nzTheme]="'outline'" *ngIf="disableByProperty('email')" class="no-edit"></span>
        </nz-col>
        <ng-template #emailEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('email')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'password' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">
          <span>Credentials</span><br/>
        </nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'password'; else passwordEdit" (click)="setEditModeForField('password')">
            ********
          </span>
          <ng-template #passwordEdit>
            <input nz-input type="password" formControlName="password" class="profile-input" placeholder="password"/>
            <input nz-input type="password" formControlName="confirm_password" class="profile-input" placeholder="re-type password"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'password'; else passwordEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('password')"></span>
        </nz-col>
        <ng-template #passwordEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('password')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'mobile_phone' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Mobile Phone</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'mobile_phone'; else mobilePhoneEdit" (click)="setEditModeForField('mobile_phone')">
            {{ user.attributes.mobile_phone }}
          </span>
          <ng-template #mobilePhoneEdit>
            <app-phone-input [phone]="user.attributes.mobile_phone" (phoneChanged)="setPhoneFieldData($event, 'mobile_phone')"></app-phone-input>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'mobile_phone'; else mobilePhoneEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('mobile_phone')"></span>
        </nz-col>
        <ng-template #mobilePhoneEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('mobile_phone')" [disabled]="isDisabled('mobile_phone')">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'office_phone' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Office Phone</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'office_phone'; else officePhoneEdit" (click)="setEditModeForField('office_phone')">
            {{ user.attributes.office_phone }}
          </span>
          <ng-template #officePhoneEdit>
            <app-phone-input [phone]="user.attributes.office_phone" (phoneChanged)="setPhoneFieldData($event, 'office_phone')"></app-phone-input>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'office_phone'; else officePhoneEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('office_phone')"></span>
        </nz-col>
        <ng-template #officePhoneEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('office_phone')" [nzLoading]="loading" [disabled]="isDisabled('office_phone')">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'extension' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Extension</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'extension'; else extensionEdit" (click)="setEditModeForField('extension')">
            {{ user.attributes.extension }}
          </span>
          <ng-template #extensionEdit>
            <input nz-input formControlName="extension" class="profile-input"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'extension'; else extensionEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('extension')"></span>
        </nz-col>
        <ng-template #extensionEditActions>

          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('extension')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'time_zone' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Time Zone</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'time_zone'; else timeZoneEdit" (click)="setEditModeForField('time_zone')">
            {{ user.attributes.time_zone }}
          </span>
          <ng-template #timeZoneEdit>
            <nz-select formControlName="time_zone" nzShowSearch class="profile-input">
              <nz-option [nzLabel]="time_zone" [nzValue]="time_zone" *ngFor="let time_zone of time_zones"></nz-option>
            </nz-select>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'time_zone'; else timeZoneEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('time_zone')"></span>
        </nz-col>
        <ng-template #timeZoneEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('time_zone')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>

    <li nz-list-item [ngClass]="fieldInEditMode == 'job_title' ? 'active-edit' : ''">
      <nz-row>
        <nz-col nzSpan="6" [nzXs]="24" [nzSm]="6">Job Title</nz-col>
        <nz-col nzSpan="10" [nzXs]="20" [nzSm]="10">
          <span *ngIf="fieldInEditMode != 'job_title'; else jobTitleEdit" (click)="setEditModeForField('job_title')">
            {{ user.attributes.job_title }}
          </span>
          <ng-template #jobTitleEdit>
            <input nz-input formControlName="job_title" class="profile-input"/>
          </ng-template>
        </nz-col>
        <nz-col nzSpan="8" [nzXs]="4" [nzSm]="8" class="row-actions" *ngIf="fieldInEditMode != 'job_title'; else jobTitleEditActions">
          <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('job_title')"></span>
        </nz-col>
        <ng-template #jobTitleEditActions>
          <nz-col nzSpan="8" [nzXs]="24" [nzSm]="8" class="edit-actions">
            <button nz-button nzShape="round" class="cancel-btn" (click)="setEditModeForField('')">Cancel</button>
            <button nz-button nzShape="round" class="save-btn" (click)="updateAttribute('job_title')" [nzLoading]="loading">Save</button>
          </nz-col>
        </ng-template>
      </nz-row>
    </li>
  </ul>
</form>
