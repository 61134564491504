import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { Subject } from "rxjs";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { TicketCreateDto } from "../utilities/models/dto/ticketCreateDto";
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TicketChageStatusDto } from "src/app/utilities/models/dto/ticketChageStatusDto";

@Injectable({ providedIn: "root" })
export class TicketsService {
  ticket = new Subject<Ticket>();
  activeTicket: Ticket;
  previousSelectedNavFilter: any;

  constructor(private http: HttpClient,
              private msg: NzMessageService,
              private router: Router) {
  }

  getTicketByCode(code: string): void {
    let inclusions = "account,issue_type,priority,requester,watchers,status,request_type,creator,uploads";
    const ticketUrl = environment.api_url + `operator/v1/tickets/${code}/?include=` + inclusions;

    this.http.get(ticketUrl).subscribe(
      {
        next: (response: any) => {
          this.activeTicket = new Ticket(response.data, response.included);
          this.ticket.next(this.activeTicket);
        },
        error: err => {
          switch (err.status) {
            case 400:
            case 403:
            case 404:
            case 422:
              // also need to handle message to the user
              this.router.navigate(['app', 'tickets']).finally()
              break;
          }
        }
      }
    );
  }

  getTickets({
    include = 'creator,lists,priority,account,status,requester,escalations',
    pageSize = 20,
    pageIndex = 1,
    query = undefined,
    reportParams = [],
  }: {
    include?: string;
    pageSize?: number,
    pageIndex?: number,
    query?: {
      q: string,
      searchFields: string[]
    } | undefined,
    reportParams?: ReportParam[],
  }) {
    const ticketUrl = environment.api_url + 'operator/v1/tickets';
    let ticketParams: any = {
      "query_type": 'documents',
      "page[per]": pageSize,
      "page[number]": pageIndex,
      "s[code]": "desc",
      include,
      "w[report_params]": JSON.stringify(reportParams)
    }

    if(query?.q && query?.searchFields){
      ticketParams['query'] = query.q;
      ticketParams['search_fields'] = `[${query.searchFields.map((field: string) => `"${field}"`).join(',')}]`;
    }

    return this.http.get(ticketUrl, { params: ticketParams });
  }

  getTicketListFromView(reportParams: ReportParam[], pageSize: number, pageIndex: number) {
    const ticketUrl = environment.api_url + 'operator/v1/tickets';
    let ticketParams = {
      "query_type": 'documents',
      "page[per]": pageSize,
      "page[number]": pageIndex,
      "s[code]": "desc",
      "include": "creator,lists,priority,account,status,requester"
    }

    return this.http.get(ticketUrl + '?w[report_params]=' + JSON.stringify(reportParams),
      { params: ticketParams });
  }

  createTicket(payload: TicketCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/tickets';

    return this.http.post<any>(requestUrl, payload);
  }

  changeStatus(code: string, payload: TicketChageStatusDto) {
    const requestUrl = environment.api_url + 'operator/v1/tickets/' + code + '/change_status';

    return this.http.patch<any>(requestUrl, payload);
  }

  getPreviousSelectedNavFilter() {
    return this.previousSelectedNavFilter;
  }

  setPreviousSelectedNavFilter(selectedNavFilter: any) {
    this.previousSelectedNavFilter = selectedNavFilter;
  }
}