import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import {  ServiceFields } from "src/app/utilities/models/service/serviceField";
import { User } from "../models/user/user";
import { AssetResource } from "../models/assetResource/assetResource";
import { DattoLicense } from "../models/service/dattoLicense";
//import { managedEndpointsService, managedUsersService, saasProtectionService } from "src/app/utilities/static-data/staticServices";

export default class ServiceHelper {
  static getAddActionFieldsByService(serviceMetric: ServiceMetric): ServiceFields {
    let fields: ServiceFields = { repeatable: [], nonRepeatable: [] };
    fields.repeatable = serviceMetric.attributes.request_explanation?.map((row: any) => {
      return {
        attributes: {
          ...row
        }
      }
    }) ?? [];
    fields.nonRepeatable = [
      {
        attributes: {
          column: 'comments', name: 'comments', label: 'Comments', placeholder: 'Add comments...', type: 'wysiwyg', required: false
        }
      }
    ];

    return fields;
  }

  static normalizeManagedUsersServiceData(user: User) {
    return {
      id: user.id,
      type: user.type,
      attributes: {
        col1: user.attributes?.firstname ?? '',
        col2: user.attributes?.lastname ?? '',
        col3: user.attributes?.email ?? '',
        col4: user.attributes?.created_at ?? '',
        col5: user.attributes?.last_login ?? undefined,
        col6: user.relationships?.price_tier?.attributes.title ?? undefined,
        reviewed: user.attributes?.reviewed ?? true,
        deleted_at: !!user.attributes?.deleted_at ?? undefined,
        mark_for_deletion: user.attributes?.mark_for_deletion ?? '',
      }
    }
  }

  static normalizeManagedEndpointsServiceData(asset: AssetResource) {
    return {
      id: asset.id,
      type: asset.type,
      attributes: {
        col1: asset.attributes.title,
        col2: (asset.relationships?.kaseya_detail?.id) ? asset.relationships.kaseya_detail.attributes.computer_name : '',
        col3: (asset.relationships?.asset_resource_type) ? asset.relationships.asset_resource_type?.attributes.title : '',
        col4: (asset.relationships?.contact) ? asset.relationships.contact.attributes.fullname : '',
        col5: (asset.relationships?.kaseya_detail?.id) ? asset.relationships.kaseya_detail.attributes.os_info : '',
        col6: (asset.attributes.serial_number) ? asset.attributes.serial_number : '',
        col7: (asset.relationships?.kaseya_detail?.attributes?.first_check_in) ? asset.relationships.kaseya_detail.attributes.first_check_in : '',
        col8: (asset.relationships?.kaseya_detail?.attributes?.last_check_in_time) ? asset.relationships.kaseya_detail.attributes.last_check_in_time : '',
        reviewed: asset.attributes.reviewed,
        deleted_at: !!asset.attributes.deleted_at ?? false,
        mark_for_deletion: asset.attributes.mark_for_deletion,
      }
    }
  }

  static normalizeSaasProtectionServiceData(dattoLicense: DattoLicense) {
    return {
      id: dattoLicense.id,
      type: dattoLicense.type,
      attributes: {
        col1: dattoLicense.attributes.name,
        col2: dattoLicense.attributes.main_identifier,
        col3: dattoLicense.attributes.date_added,
        col4: dattoLicense.attributes.status ? 'Active' : 'Archived',
        col5: dattoLicense.attributes.billable ? 'Yes' : 'No',
        reviewed: dattoLicense.attributes.reviewed,
        deleted_at: !!dattoLicense.attributes.deleted_at ?? false,
        mark_for_deletion: dattoLicense.attributes.mark_for_deletion,
      }
    }
  }
}