import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenService } from "../../../auth/token.service";
import { UsersService } from "../../../services/users.service";

@Component({
  selector: "impersonate-component",
  template: `<ng-content></ng-content>`
})
export class ImpersonateComponent implements OnInit{
  constructor(private route: ActivatedRoute,
              private userService: UsersService,
              private tokenService: TokenService,
              private router: Router) {

  }

  ngOnInit() {
    this.parseTokenFromUrl();
  }

  private parseTokenFromUrl() {
    this.route.queryParams
      .subscribe(params => {
        const token = params['token'] || null;
        if(token) {
          // set new user token and fetch user information from scratch
          this.tokenService.saveToken(token);
          //this.userService.setShowPasswordModal();
          this.userService.prepareApp();
          this.router.navigate(['app', 'dashboard']).finally();
        }
      });
  }
}