import { Component, DestroyRef, OnInit } from '@angular/core';
import Service from "src/app/utilities/models/service/service";
import { ServicesService } from "src/app/services/services.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RansackParam } from "../../../../utilities/models/parameters/ransackParam/ransackParam";
import { NzTableQueryParams } from "ng-zorro-antd/table";

@Component({
  selector: 'app-additonal-service-list',
  templateUrl: './additional-service-list.component.html',
  styleUrls: ['./additional-service-list.component.scss']
})
export class AdditionalServiceListComponent implements OnInit{
  loading: boolean = false;
  services: Service[] = [];
  pageIndex: number = 1;
  pageSize: number = 20;
  total: number = 0;
  totalPages: number = 0;

  constructor(private serviceService: ServicesService,
              private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.getServices();
  }

  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.getServices(this.pageSize, this.pageIndex);
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.getServices(this.pageSize, this.pageIndex);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.getServices(params.pageSize, params.pageIndex);
  }

  removeService(serviceId: number) {
    this.serviceService.removeInstalledService(serviceId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(_response: any) => {
          this.getServices();
        },
        error: () => {}
      })
  }

  getLogoByService(service: Service){
    const logo = service.getLogo();

    return logo?.includes('https://') ? logo : '../../../' + logo;
  }

  private getServices(pageSize: number = this.pageSize, pageIndex: number = 1) {
    const params: RansackParam[] = [];
    //params.push({ key: 'external_identifier', operator: 'blank', value: 'true'});
    this.serviceService.getServices(pageSize, pageIndex, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.services = response.data.map((bsData: any) => new Service(bsData, response.included));
          this.total = response.meta.total_count;
        },
        error: () => {}
      })
  }
}
