<div class="account-manager-container" *ngIf="accountManager">
  <div class="account-manager-info" *ngIf="showInfoTooltip">
    <span class="close" (click)="closeInfoTooltip()">
      <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
    </span>
    <span class="">
      We’re ready when you are. Get in touch with your Account Manager or book a meeting, here.
    </span>
  </div>
  <div class="avatar-container" (click)="showFullInfoTooltip()">
    <nz-avatar nzIcon="user" [nzSrc]="accountManager.attributes.avatar" [nzSize]="40"></nz-avatar>
  </div>

  <div class="account-manager-full-info" *ngIf="showFullInfo">
    <span class="close" (click)="closeFullInfoTooltip()">
      <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
    </span>
    <div class="account-manager-top-info">
      <nz-avatar nzIcon="user" [nzSrc]="accountManager.attributes.avatar" [nzSize]="22"></nz-avatar>
      {{ accountManager.attributes.firstname }} {{ accountManager.attributes.lastname }}
    </div>
    <div class="account-manager-main-info">
      <input type="hidden" id="clipboardCopy" value=""/>
      <div class="row-info" *ngIf="accountManager.attributes.email">
        <span class="label">Email: </span> {{ accountManager.attributes.email }}
        <span nz-icon nzType="copy" [nzTheme]="'outline'" (click)="copyToClipboard(accountManager.attributes.email)"></span>
      </div>
      <div class="row-info" *ngIf="accountManager.attributes.job_title">
        <span class="label">Job Title: </span> {{ accountManager.attributes.job_title }}
        <span nz-icon nzType="copy" [nzTheme]="'outline'" (click)="copyToClipboard(accountManager.attributes.job_title)"></span>
      </div>
      <div class="row-info" *ngIf="accountManager.attributes.office_phone">
        <span class="label">Phone: </span> {{ accountManager.attributes.office_phone }}
        <span nz-icon nzType="copy" [nzTheme]="'outline'" (click)="copyToClipboard(accountManager.attributes.office_phone)"></span>
      </div>
      <div class="row-info" *ngIf="accountManager.attributes.extension">
        <span class="label">Extension: </span> {{ accountManager.attributes.extension }}
        <span nz-icon nzType="copy" [nzTheme]="'outline'" (click)="copyToClipboard(accountManager.attributes.extension)"></span>
      </div>
      <div class="row-info" *ngIf="accountManager.attributes.mobile_phone && false">
        <span class="label">Mobile phone: </span> {{ accountManager.attributes.mobile_phone }}
        <span nz-icon nzType="copy" [nzTheme]="'outline'" (click)="copyToClipboard(accountManager.attributes.mobile_phone)"></span>
      </div>
      <div *ngIf="meetingCreatePermission && accountManager?.id">
        <nz-divider></nz-divider>
        <div class="book-meeting-btn">
          <button nz-button
                  nzShape="round"
                  nzType="primary"
                  [disabled]="!isAccountManagerAvailable"
                  (click)="onShowMeetingModal()">
            Book a Meeting
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
