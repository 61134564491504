<nz-layout>
  <div class="top">
    <div nz-row class="header">
      <div nz-col nzSpan="12" class="logo">
        <a [routerLink]="['/info']"><img src="../../../assets/images/empist360-logo.svg" alt="EMPIST"/></a>
      </div>
      <div nz-col nzSpan="12" class="login" (click)="login()">
        <i nz-icon nzType="user" [nzTheme]="'outline'"></i>
        Login
      </div>
    </div>
  </div>
  <div nz-row class="main-text-1-container">
    <div nz-col nzSpan="18" nzOffset="3" class="main-text-1 dotted-corners-container">
      <div>How to</div>
      <div class="gradient-text">Reset your Password</div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row">
      <div class="block-item">
        <div class="block-number">1.</div>
        <div class="block-text-container">
          <div class="block-label">Try to <strong>Login</strong></div>
          <div class="block-text">and click on <span class="link-text">I forgot my password</span></div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_login.png" alt="login" />
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row reverse">
      <div class="block-item">
        <div class="block-number">2.</div>
        <div class="block-text-container">
          <div class="block-label">Enter your <strong>Registered<br>Email Address</strong></div>
          <div class="block-text">A reset password link will be sent to your email</div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_mail-link.png" alt="mail link" />
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row">
      <div class="block-item">
        <div class="block-number">3.</div>
        <div class="block-text-container">
          <div class="block-label">Set <strong>New Password</strong></div>
          <div class="block-text">The new password should have at least 6 characters.</div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_reset-pass.png" alt="login" />
      </div>
    </div>
  </div>

  <div nz-row class="main-text-1-container">
    <div nz-col nzSpan="18" nzOffset="3" class="main-text-2 dotted-corners-container">
      <div>How to</div>
      <div class="gradient-text">Set your Mobile Phone</div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row">
      <div class="block-item">
        <div class="block-number">1.</div>
        <div class="block-text-container">
          <div class="block-label">First <strong>Login</strong></div>
          <div class="block-text">to your account</div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_login.png" alt="login" />
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row reverse">
      <div class="block-item">
        <div class="block-number">2.</div>
        <div class="block-text-container">
          <div class="block-label">Select <strong>My Profile</strong></div>
          <div class="block-text">On the top right corner, under your profile picture</div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_my-profile.png" alt="my profile" />
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row">
      <div class="block-item">
        <div class="block-number">3.</div>
        <div class="block-text-container">
          <div class="block-label">Scroll down to <strong>User Information</strong></div>
          <div class="block-text">Edit your mobile phone and Save</div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_user-info.png" alt="user information" />
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24" class="block-row reverse">
      <div class="block-item">
        <div class="block-number">4.</div>
        <div class="block-text-container">
          <div class="block-label">Scroll down to <strong>User Information</strong></div>
          <div class="block-text">Edit your mobile phone and Save</div>
        </div>
      </div>
      <div class="block-item image-cnt">
        <img src="../../../assets/images/icon_mobile.png" alt="change mobile" />
      </div>
    </div>
  </div>

  <nz-footer class="footer">
    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="3">
        <div nz-row nzGutter="16" nzAlign="middle">
          <div nz-col class="gutter-row footer-left" nzSpan="12" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
            <img src="../../../assets/images/empist360-logo.svg" alt="EMPIST" class="footer-logo"/>
          </div>
          <div nz-col class="gutter-row footer-right" nzSpan="12" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
            Part of <span>EMPIST Services</span> for modern businesses.
          </div>
        </div>
      </div>
    </div>
  </nz-footer>
</nz-layout>