<div nz-row nzJustify="center">
  <div nz-col nzSpan="24" class="text-center">
    <h1 *ngIf="serviceMetricId > 0">Edit Service Metric</h1>
    <h1 *ngIf="serviceMetricId == 0">Create Service Metric</h1>
  </div>
</div>
<form class="service-form"
      nz-form
      nzLayout="vertical"
      [formGroup]="serviceMetricForm"
      (ngSubmit)="onSubmit()">
  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Title</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <input nz-input formControlName="title"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Metric Type</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-select formControlName="metric_type" [nzDisabled]="nzModalData.serviceMetricId ? true : false" nzSize="large">
        <!--<nz-option nzValue="count" nzLabel="Count"></nz-option>-->
        <nz-option nzValue="detail" nzLabel="Details"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>


  <nz-row nzJustify="end">
    <nz-col *ngIf="nzModalData.serviceMetricId">
      <button nz-button
              nz-popconfirm
              type="button"
              nzType="default"
              nzDanger
              nzShape="round"
              nzSize="large"
              [nzPopconfirmTitle]="DeleteConfirmContent"
              nzPopconfirmPlacement="top"
              (nzOnConfirm)="delete()">
        Delete
      </button>
      <ng-template #DeleteConfirmContent>
        Are you sure you want to delete this service metric?<br/>
        <strong>All the data</strong> of the service metric will be deleted.<br/>
        This action is not reversible. Proceed?
      </ng-template>
    </nz-col>
    <nz-col class="update-container">
      <button nz-button
              type="submit"
              nzType="primary"
              nzShape="round"
              nzSize="large"
              [nzLoading]="loading">
        Submit
      </button>
    </nz-col>
  </nz-row>
</form>