interface AccountReviewDattoLicensesDataDto {
  id: number,
  type: string;
}

export class AccountReviewDattoLicensesDto {
  data: AccountReviewDattoLicensesDataDto;

  constructor(id: number){
    this.data = {
      id: id,
      type: 'accounts'
    }
  }
}
