<form class="form-control-form"
      [formGroup]="flowControlForm"
      nz-form
      nzLayout="vertical"
      (ngSubmit)="onSubmit()">

  <nz-spin nzSimple *ngIf="loading"></nz-spin>

  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Type</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-select formControlName="input_type">
        <nz-option *ngFor="let type of flowControlTypes"
                    [nzLabel]="type"
                    [nzValue]="type"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="ant-row-center select-options" *ngIf="controlType == 'select' || controlType == 'radio'">
    <nz-form-label [nzSpan]="24">Control Options</nz-form-label>
    <div [nzSpan]="24" *ngFor="let option of controlOptions; let i = index">
      <div class="option-container">
        <nz-form-control class="option-value">
          <nz-form-label [nzSpan]="24">Value</nz-form-label>
          <input nz-input [formControlName]="'optionValue'+i" class="option-input" placeholder="value" />
        </nz-form-control>
        <nz-form-control class="option-label">
          <nz-form-label [nzSpan]="24">Label</nz-form-label>
          <input nz-input [formControlName]="'optionLabel'+i" class="option-input" placeholder="label" />
        </nz-form-control>
        <span class="remove-option" (click)="removeFromOptions(option.value)">
          <span nz-icon nzType="close-circle" nzTheme="outline"></span>
        </span>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div class="add-new-option-container">
      <label>New Option</label>
      <div class="option-container">
        <nz-form-control class="option-value">
          <nz-form-label [nzSpan]="24">Value</nz-form-label>
          <input nz-input formControlName="optionValue" class="option-input" placeholder="value"/>
        </nz-form-control>
        <nz-form-control class="option-label">
          <nz-form-label [nzSpan]="24">Label</nz-form-label>
          <input nz-input formControlName="optionLabel" class="option-input" placeholder="label"/>
        </nz-form-control>
        <span class="add-option" (click)="addToOptions()">
          <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
        </span>
      </div>
    </div>
  </nz-form-item>
  <nz-divider></nz-divider>

  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Label</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <input nz-input formControlName="label"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Name</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <input nz-input formControlName="name"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Placeholder</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <input nz-input formControlName="placeholder"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="ant-row-center">
    <nz-form-label [nzSpan]="24">Required</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-switch nzSize="small" formControlName="required"/>
    </nz-form-control>
  </nz-form-item>

  <nz-row nzJustify="end" nzGutter="10">
    <nz-col class="align-left" *ngIf="flowControl">
      <button nz-button
              nzDanger
              type="button"
              nzShape="round"
              nzSize="large"
              [nzLoading]="loading"
              nz-popconfirm
              nzPopconfirmTitle="Are you sure?"
              [nzPopconfirmShowArrow]="false"
              (nzOnConfirm)="delete()">
        Delete
      </button>
    </nz-col>
    <nz-col>
      <button nz-button
              type="button"
              nzShape="round"
              nzSize="large"
              [nzLoading]="loading"
              (click)="closeForm()">
        Cancel
      </button>
    </nz-col>
    <nz-col>
      <button nz-button
              type="submit"
              nzType="primary"
              nzShape="round"
              nzSize="large"
              [nzLoading]="loading">
        Submit
      </button>
    </nz-col>
  </nz-row>
</form>