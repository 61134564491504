import { Component, DestroyRef, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";

import { NzMessageService } from 'ng-zorro-antd/message';

import { User } from 'src/app/utilities/models/user/user';
import { UsersService } from 'src/app/services/users.service';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { UserUpdateDto } from 'src/app/utilities/models/dto/userUpdateDto';
import { pairwise } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'notificationPreferences',
  templateUrl: './notificationPreferences.component.html',
  styleUrls: ['./notificationPreferences.component.scss']
})
export class NotificationPreferencesComponent implements OnInit {
  userPreferencesForm: FormGroup;
  user: User;
  loading: boolean = false;
  permission: Permission | undefined;

  constructor(private msg: NzMessageService,
              private userService: UsersService,
              private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.user = this.userService.loggedInUser;
    this.permission = this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'update');

    this.userPreferencesForm = new FormGroup({
      'pref_receive_emails': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_receive_emails') }),
      'pref_receive_survey': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_receive_survey') }),
      'pref_notifications': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_notifications') }),
      'pref_notifications_comment': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_notifications_comment') }),
      'pref_notifications_reminder': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_notifications_reminder') }),
      'pref_notifications_ticket': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_notifications_ticket') }),
      'pref_notifications_vote': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_notifications_vote') }),
      'pref_change_log_subscription': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_change_log_subscription') }),
      'pref_incident_subscription': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('pref_incident_subscription') }),
    });

    this.userPreferencesForm.valueChanges
      .pipe(
        pairwise(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (value) => {
          Object.keys(this.userPreferencesForm.controls).forEach(control => {
            let currentControl = this.userPreferencesForm.controls[control];

            if (currentControl.dirty && (value[0][control] != value[1][control])) {
              const payload = this.prepareSinglePayload(control);
              this.userService.updateUser(payload)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                  next: _ => {
                    this.msg.success('Preference updated', { nzDuration: 3000, nzPauseOnHover: false });
                    this.userService.getCurrentUser();
                  }
                });
            }
          })
        }
      });

    this.setForm();
  }

  private setForm(){
    this.userPreferencesForm.get('pref_receive_emails')?.setValue(this.user.attributes.pref_receive_emails);
    this.userPreferencesForm.get('pref_receive_survey')?.setValue(this.user.attributes.pref_receive_survey);
    this.userPreferencesForm.get('pref_notifications')?.setValue(this.user.attributes.pref_notifications);
    this.userPreferencesForm.get('pref_notifications_comment')?.setValue(this.user.attributes.pref_notifications_comment);
    this.userPreferencesForm.get('pref_notifications_reminder')?.setValue(this.user.attributes.pref_notifications_reminder);
    this.userPreferencesForm.get('pref_notifications_ticket')?.setValue(this.user.attributes.pref_notifications_ticket);
    this.userPreferencesForm.get('pref_notifications_vote')?.setValue(this.user.attributes.pref_notifications_vote);
    this.userPreferencesForm.get('pref_incident_subscription')?.setValue(this.user.attributes.pref_incident_subscription);
    this.userPreferencesForm.get('pref_change_log_subscription')?.setValue(this.user.attributes.pref_change_log_subscription);
  }

  private disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private prepareSinglePayload(attributeChanged: string): UserUpdateDto{
    const userUpdateAttributes = {
      [attributeChanged]: this.userPreferencesForm.get(attributeChanged)?.value
    }
    const userUpdateRelationships = {};

    return new UserUpdateDto(this.user.id, "users", userUpdateAttributes, userUpdateRelationships);

  }
}
