<div nz-row class="ticket-controls">
  <div nz-col nzSpan="24">
    <div class="btn-container">
      <nz-select *ngIf="activeContent == 'conversations'"
                 [ngModel]="selectedConversation"
                 [nzSuffixIcon]="conversationArray.length == 1 ? '' : 'caret-down'"
                 [nzDisabled]="conversationArray.length == 1"
                 [nzShowArrow]="conversationArray.length > 1"
                 (ngModelChange)=setActiveConversation($event)
                 class="conversation-select">
        <nz-option *ngFor="let conversation of conversationArray"
                   [nzValue]="conversation"
                   [nzLabel]="conversation.attributes.title">
        </nz-option>
      </nz-select>
    </div>
    <div class="btn-container">
      <button nz-button class="btn-blue" nzShape="round" *ngIf="activeContent != 'conversations'" (click)="setActiveContent('conversations')">
        Conversations
      </button>
    </div>
    <div class="btn-container" *ngIf="ticket">
      <button nz-button nzShape="round" (click)="setActiveContent('attachments')" class="btn-attachments">
        <span class="btn-text">Attachments</span>
        <span nz-icon nzType="paper-clip" [nzTheme]="'outline'"></span>
      </button>
      <div class="counter-badge">{{ ticket.relationships?.uploads?.length || 0 }}</div>
    </div>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24">
    <ticketConversations [class]="activeContent == 'conversations' ? 'conversations visible' : 'conversations'"></ticketConversations>
    <ticket-attachments *ngIf="activeContent == 'attachments'"></ticket-attachments>
  </div>
</div>