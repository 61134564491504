<div class="empist-loader-container">
  <div class="empist-loader" (outsideClick)="hideLoader()" outsideClick>
    <div class="close" (click)="hideLoader()"><span nz-icon nzType="close" [nzTheme]="'outline'"></span></div>
    <div class="user-avatar">
      <nz-avatar [nzSize]="68" nzIcon="user" [nzSrc]="loggedInUser.attributes.avatar" *ngIf="processing"></nz-avatar>
      <span nz-icon nzType="check-circle" [nzTheme]="'outline'" *ngIf="!processing"></span>
    </div>
    <div class="loader-text" [innerHTML]="loadingText" *ngIf="processing"></div>
    <div class="loader-secondary-text" [innerHTML]="loadingSecondaryText" *ngIf="processing"></div>
    <div class="loader-text" [innerHTML]="loadedText" *ngIf="!processing"></div>
    <div class="loader-secondary-text" [innerHTML]="loadedSecondaryText" *ngIf="!processing"></div>
    <div class="loader-bar" *ngIf="processing"></div>
  </div>
</div>