<div class="ticket-list-container">
  <div nz-row nzJustify="center">
    <div nz-col>
      <h1>Ticket List</h1>
    </div>
  </div>
  <div nz-row class="support-top">
    <div nz-col nzSpan="24">
      <div nz-row nzJustify="center">
        <div nz-col>
          View all the existing tickets in your organization here.<br/>
          Keep track of the status of support requests in the feed or <br/>
          select an individual ticket for more information.
        </div>
      </div>
      <nz-divider></nz-divider>
    </div>
  </div>
  <div nz-row class="list-selection-row">
    <div nz-col nzSpan="12" [nzXs]="24" [nzSm]="12" class="filter-selection">
      <span class="total-tickets fr-align-left" *ngIf="total > 0">{{ total }}</span>
      <nz-select nzShowSearch
                 nzBorderless
                 nzPlaceHolder="Select a View"
                 class="fr-align-left"
                 [(ngModel)]="selectedNavFilter"
                 (ngModelChange)="navFilterChange(this.pageSize, 1)">
        <nz-option *ngFor="let option of navFilter.options"
                   [nzLabel]="option.title"
                   [nzValue]="option.id">
        </nz-option>
      </nz-select>
    </div>
    <div nz-col nzSpan="12" [nzXs]="24" [nzSm]="12" class="create-ticket-container">
      <button nz-button [routerLink]="['/app', 'support']" class="button-black" nzShape="round">Create Ticket</button>
    </div>
  </div>

  <div class="search-row">
    <nz-input-group nzSearch [nzPrefix]="prefixIcon" [nzSuffix]="suffixIconButton" [nzSize]="'large'">
      <input type="text" nz-input placeholder="Search by Ticket Number or Subject or Description..." [(ngModel)]="searchString" (ngModelChange)="searchTickets()"/>
    </nz-input-group>
    <ng-template #prefixIcon>
      <span nz-icon nzType="search"></span>
    </ng-template>
    <ng-template #suffixIconButton>
      <button nz-button nzType="primary" nzSearch><span nz-icon nzType="right" nzTheme="outline" (click)="searchTickets()"></span></button>
    </ng-template>
  </div>
  <div nz-row *ngIf="loading" nzJustify="center">
    <div nz-col nzSpan="24" class="tickets-loading">
      <nz-spin nzSimple></nz-spin>
    </div>
  </div>

  <div nz-row *ngIf="!loading">
    <div nz-col nzSpan="24" nzOffset="0">
      <div nz-row
           *ngFor="let ticket of tickets">
        <div nz-col
             nzSpan="24"
             [routerLink]="['/app', 'support', 'tickets', ticket.attributes.code]"
             class="ticket-list-item">
          <div class="ticket-row-column ticket-row-avatar">
            <nz-avatar nzIcon="user" [nzSrc]="ticket.relationships?.requester?.attributes?.avatar" *ngIf="ticket.relationships?.requester?.attributes?.avatar"></nz-avatar>
            <nz-avatar nzIcon="user" *ngIf="!ticket.relationships?.requester?.attributes?.avatar"></nz-avatar>
          </div>
          <div class="ticket-list-item-details">
            <div class="ticket-list-item-details-top">
              <span class="ticket-item-code-date">
                <span class="ticket-code">Ticket: {{ticket.attributes.code}}</span>
                <span class="ticket-requester" *ngIf="ticket.relationships?.requester?.attributes?.fullname">by {{ ticket.relationships?.requester?.attributes?.fullname }}</span>
                <span class="ticket-created-at">{{ticket.attributes.created_at | date:'medium'}}</span>
              </span>
              <span [ngClass]="ticket.relationships?.status?.attributes?.state == 'in_progress' ? 'status-state status-open' : 'status-state status-completed'">
                {{ ticket.relationships?.status?.attributes?.state == 'in_progress' ? 'Open' : 'Completed'  }}
              </span>
            </div>
            <div class="ticket-list-item-subject-description">
              <div *ngIf="!ticket.attributes.striped_description">
                <strong>{{ticket.attributes.subject}}</strong>
              </div>
              <div *ngIf="ticket.attributes.striped_description">
                <strong>{{ticket.attributes.subject}}:</strong> {{ticket.attributes.striped_description}}
              </div>
            </div>

          </div>
          <div class="ticket-list-item-action">
            <a [routerLink]="['/app', 'support', 'tickets', ticket.attributes.code]">
              <span nz-icon nzType="arrow-right" [nzTheme]="'outline'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div nz-row class="pagination-container" *ngIf="!loading">
    <div nz-col nzSpan="16">
      <nz-pagination [nzPageIndex]="pageIndex"
                     (nzPageIndexChange)="onPageIndexChange($event)"
                     [nzTotal]="total"
                     nzShowSizeChanger
                     nzResponsive="true"
                     [nzPageSize]="pageSize"
                     (nzPageSizeChange)="onPageSizeChange($event)">
      </nz-pagination>
    </div>
  </div>
</div>
