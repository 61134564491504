import { ObjectData } from "../objectData";
import { ServiceAttributes } from "./serviceAttributes";
import { ServiceRelationships } from "./serviceRelationships";
import { ServiceMetricRelationAttributes, ServiceMetricRelationRelationships } from "./serviceMetricRelation";
import { environment } from "../../../../environments/environment";
import { RelationshipDataWithRelation } from "../relationshipDataWithRelations";
import { ServiceUploadRelationAttributes } from "./serviceUploadRelationAttributes";
import { ServiceUploadRelationRelationships } from "./serviceUploadRelationRelationships";
import ServiceMetric from "./serviceMetric";
import { ServiceFields } from "./serviceField";

class Service extends ObjectData<ServiceAttributes, ServiceRelationships>{
  constructor(data: ObjectData<ServiceAttributes, ServiceRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = Service.normalizeRelation(data.relationships, included);

      if(this.relationships?.service_metrics?.length) {
        if (this.relationships.service_metrics.length <= 2) {
          this.relationships.service_metrics.forEach((metric: any) => {
            metric.attributes.metric_group = this.attributes.system_key ?? this.attributes.title.replace(/\s+/g, '-').toLowerCase();
          })
        } else {
          Service.mapMetrics(this);
        }
      }
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ServiceRelationships {
    const relationships: ServiceRelationships = {};

    relationships.service_metrics = Service.normalizeServiceMetricsRelation(relationObject, included, 'service_metrics');
    relationships.uploads = Service.normalizeMultipleRelation<ServiceUploadRelationAttributes, ServiceUploadRelationRelationships>(relationObject, included, 'uploads');

    return relationships;
  }

  private static normalizeServiceMetricsRelation(relationData: any, included: Object[], key: string, callback?: Function): RelationshipDataWithRelation<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>[] | undefined {
    if (!(relationData[key]?.data?.length > 0)) return undefined;

    return relationData[key].data.map((data: any) => {
      const includedObj: any = included.find((inc: any) => inc.type == data.type && inc.id == data.id);
      let details_explanation = [];
      let request_explanation = [];
      if(typeof includedObj?.attributes.details_explanation != 'object'){
        details_explanation = (includedObj && includedObj.attributes.details_explanation?.length) ? JSON.parse(includedObj.attributes.details_explanation) : [];
      }else{
        details_explanation = includedObj.attributes.details_explanation ?? [];
      }
      if(includedObj.attributes.title.toLowerCase().includes('datastore')){
        details_explanation.push({ column: 'col8', key: 'used_space', label: 'Used Space', type: 'size_in_bytes' })
      }

      if(typeof includedObj?.attributes.request_explanation != 'object'){
        request_explanation = (includedObj && includedObj.attributes.request_explanation?.length) ? JSON.parse(includedObj.attributes.request_explanation) : [];
      }else{
        request_explanation = includedObj.attributes.request_explanation ?? [];
      }

      if(request_explanation == "null" || !request_explanation || request_explanation == null){
        request_explanation = [];
      }

      const dData = {
        ...data,
        attributes: {
          ...data.attributes,
          details_explanation: [...details_explanation],
          request_explanation: [...request_explanation],
        }
      }

      return ObjectData.normalizeRelatedObject<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>(dData, included, callback)
    })

  }

  private static mapMetrics(service: Service): void {
    switch(service.id){
      case 2:
        service.relationships?.service_metrics?.forEach((metric: any) => {
          if(metric.id == 5 || metric.id == 6) {
            metric.attributes.metric_group = service.attributes.system_key;
          }else{
            metric.attributes.metric_group = 'office3655-inspector-licenses';
          }
        })
        break;
      case 6:
        service.relationships?.service_metrics?.forEach((metric: any) => {
          if(metric.id == 12 || metric.id == 13) {
            metric.attributes.metric_group = "vmware-vsphere-inspector-vms";
          }
          if(environment.production){
            if(metric.id == 16 || metric.id == 17) {
              metric.attributes.metric_group = "vmware-vsphere-inspector-datastores";
            }
          }else{
            if(metric.id == 33 || metric.id == 34) {
              metric.attributes.metric_group = "vmware-vsphere-inspector-datastores";
            }
          }
        })
        break;
    }
  }

  getTitle(): string {
    if(!this?.id) return '';

    return this?.attributes.title || '';
  }

  getLogo(): string{
    if(this.relationships?.uploads?.length){
      return this.relationships?.uploads[0].attributes.permalink;
    }

    switch(this?.attributes.title?.toLowerCase()){
      case 'managed users':
        return 'assets/images/icon_managed-users.png';
      case 'managed endpoints':
        return 'assets/images/icon_managed-endpoints.png';
      case 'saas protection':
        return 'assets/images/icon_saas-protection.png';
      default:
        break;
    }

    return 'assets/images/no-image-found.svg';
  }

  getDescription() {
    if(this.attributes.description) return this.attributes.description;

    switch (this.attributes.title?.toLowerCase()) {
      case 'managed users':
        return 'Managed Users includes the list of authorized users for your company.';
      case 'managed endpoints':
        return 'Managed Endpoints are the servers, laptops, and desktops that we are managing for your company.';
      case 'saas protection':
        return 'SaaS Protection includes the mailboxes that are configured for cloud-based protection for Microsoft 365 and Google Workplace.';
    }

    return '';
  }

  hasAddMethod(serviceMetric: ServiceMetric): boolean {
    let hasAdd = false;
    switch(this.attributes.system_key?.toLowerCase()) {
      case 'office365-inspector':
        hasAdd = true; // serviceMetric.attributes.title != 'Office 365: Licenses Overview';
        break;
      case 'sentinelone-inspector':
        hasAdd = true;
        break;
      case 'duo-security-inspector':
        hasAdd = true;
        break;
      case 'knowbe4-inspector':
        hasAdd = true;
        break;
      case 'datto-inspector':
        hasAdd = false;
        break;
      case 'vmware-vsphere-inspector':
        hasAdd = false;
        break;
      case 'cisco-umbrella-inspector':
        hasAdd = true;
        break;
      default:
        if(this.attributes.title?.toLowerCase() == 'managed users'){
          hasAdd = true;
        }
        if(this.attributes.title?.toLowerCase() == 'managed endpoints'){
          hasAdd = false;
        }
        if(this.attributes.title?.toLowerCase() == 'saas protection'){
          hasAdd = true;
        }
        break;
    }

    return hasAdd;
  }

  hasRemoveMethod(serviceMetric: ServiceMetric): boolean {
    let hasRemove = false;
    switch(this.attributes.system_key?.toLowerCase()) {
      case 'office365-inspector':
        hasRemove = true; // serviceMetric.attributes.title != 'Office 365: Licenses Overview'
        break;
      case 'sentinelone-inspector':
        hasRemove = true;
        break;
      case 'duo-security-inspector':
        hasRemove = true;
        break;
      case 'knowbe4-inspector':
        hasRemove = true;
        break;
      case 'datto-inspector':
        hasRemove = false;
        break;
      case 'vmware-vsphere-inspector':
        hasRemove = true;
        break;
      case 'cisco-umbrella-inspector':
        hasRemove = true;
        break;
      default:
        if(this.attributes.title?.toLowerCase() == 'managed users'){
          hasRemove = true;
        }
        if(this.attributes.title?.toLowerCase() == 'managed endpoints'){
          hasRemove = true;
        }
        if(this.attributes.title?.toLowerCase() == 'saas protection'){
          hasRemove = true;
        }
        break;
    }

    return hasRemove;
  }

  getServiceAddButtonTitle(serviceMetric: ServiceMetric): string {
    let title = '';
    switch(this.attributes.system_key?.toLowerCase()) {
      case 'office365-inspector':
        if(serviceMetric.attributes.title == 'Office 365: Licenses Overview'){
          title = 'Add licenses';
        }else{
          title = 'Add Users';
        }
        break;
      case 'sentinelone-inspector':
        title = 'Add Users';
        break;
      case 'duo-security-inspector':
        title = 'Add Users';
        break;
      case 'knowbe4-inspector':
        title = 'Add Users';
        break;
      case 'datto-inspector':
        title = 'Add Storage/Nodes';
        break;
      case 'vmware-vsphere-inspector':
        if(serviceMetric.attributes.title == 'VMware vCenter: Virtual Machines') {
          title = 'Add VMs';
        }else{
          title = 'Add Storage';
        }
        break;
      case 'cisco-umbrella-inspector':
        title = 'Add Computers';
        break;
      default:
        if(this.attributes.title?.toLowerCase() == 'managed users'){
          title = 'Add Users';
        }
        if(this.attributes.title?.toLowerCase() == 'managed endpoints'){
          title = '';
        }
        if(this.attributes.title?.toLowerCase() == 'saas protection'){
          title = 'Add Seats';
        }
        break;
    }

    return title;
  }

}

export default Service;
